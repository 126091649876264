import gql from 'graphql-tag';

export const publicAlarmRuleInfoFragment = gql`
  fragment publicAlarmRuleInfo on PublicAlarmRule {
    alarmName
    priority
    rootCause
    solutions {
      language
      steps
    }
  }
`;
