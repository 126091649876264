import React, { Component } from 'react';
import styled from 'styled-components';

import Button from '../../../../atoms/Button';
import ProgressBar from '../../ProgressBar/index';

const CompletionContainer = styled.div`
  text-align: center;
  max-width: 60em;
`;

const Title = styled.div`
  color: ${props => props.theme.primaryThemeColor};
  font-size: 2.5em;
  margin: 1em 0;
`;

const MessageContainer = styled.div`
  color: #707070;
  font-size: 1.25em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.4em;
`;

class Complete extends Component {
  render() {
    const { onDone, maxSteps, step } = this.props;

    return (
      <React.Fragment>
        <CompletionContainer>
          <ProgressBar maxSteps={maxSteps} currentStep={step} />
          <Title>Nice!</Title>
          <MessageContainer>Device is now installed.</MessageContainer>
          <ButtonContainer>
            <Button onClick={onDone}>Done</Button>
          </ButtonContainer>
        </CompletionContainer>
      </React.Fragment>
    );
  }
}

export default Complete;
