import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import { withQS } from '../../contexts/QSContext';
import ToasterContext from '../../contexts/ToasterContext';
import { Flex, Box } from '@rebass/grid';

import PageTemplate from '../../templates/PageTemplate';
import LoaderLine from '../../atoms/LoaderLine';
import LoadMoreTable from '../../organisms/LoadMoreTable';
import SearchPane from '../../organisms/SearchPane';
import TagsSidebar from '../../molecules/TagsSidebar';

const StyledLoadMoreTable = styled(LoadMoreTable)`
  overflow-x: hidden;
  word-break: break-word;
  margin-bottom: ${(props) => props.theme.tableBottomMargin};
`;

const StyledLoaderLine = styled(LoaderLine)`
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const pageSize = 20;
let _variables = { after: '0' };

const DashboardListPage = ({
  context,
  query,
  pollInterval,
  variables,
  filterable,
  actions,
  columns,
  searchComponents,
  useFlexibleSearch,
  updateQuery,
  getQueryString,
  setQueryString,
  skip,
  location,
  children,
  history,
  tagsSidebar = {},
  fetchPolicy,
  hideBanner,
}) => {
  const [isTagsSidebarOpened, setIsTagsSidebarOpened] = useState(
    tagsSidebar.isOpenedByDefault
  );
  const [QS, setQS] = useState(getQueryString(location));
  const [gqlVariables, setGqlVariables] = useState({
    after: _variables.after.toString(),
    limit: pageSize,
    filter: {},
  });

  const { addToast } = useContext(ToasterContext);

  const { loading, error, data, fetchMore, refetch } = useQuery(query, {
    variables: gqlVariables,
    pollInterval,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: fetchPolicy || 'cache-and-network',
    skip:
      skip && gqlVariables && gqlVariables.filter && !gqlVariables.filter[skip],
  });

  let viewData;
  if (error) {
    addToast({
      type: 'error',
      title: 'Error',
      message: error.message,
    });
    viewData = null;
  } else {
    viewData = _get(data, context);
  }

  const applyFilter = (query) => {
    setQS({ ...QS, ...query });
  };
  const debounceFilter = _debounce(applyFilter, 400);

  const loadMore = (after, fetchMore) => {
    const { search, filter, sort, tag } = QS;

    const state = {
      after: after.toString(),
      limit: pageSize,
      filter: {},
    };

    if (filter) {
      filter.forEach((f) => {
        const [key, value] = f.split(':');
        state.filter[key] = value;
      });
    }
    if (search && search.length) {
      if (useFlexibleSearch) state.filter.search = search;
      else state.filter.fullText = search;
    }

    if (sort) state.sort = sort;
    fetchMore({
      variables: {
        ...gqlVariables,
        after: Number(state.after).toString(),
      },
      updateQuery:
        updateQuery ||
        ((prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return Object.assign({}, prev, {
            [context]: {
              ...prev[context],
              pageInfo: {
                ...prev[context].pageInfo,
                ...fetchMoreResult[context].pageInfo,
              },
              edges: [
                ...prev[context].edges,
                ...fetchMoreResult[context].edges,
              ],
            },
          });
        }),
    });
  };
  useEffect(() => {
    setQueryString(QS, history, location);
  }, [
    QS.limit,
    QS.sort,
    QS.timestamp && QS.timestamp.gte,
    QS.timestamp && QS.timestamp.lte,
    QS.filter,
    QS.search,
    QS.tag,
  ]);

  useEffect(() => {
    const { search, filter, timestamp, limit, sort, tag } = QS;

    const q = {
      after: _variables.after.toString(),
      limit: limit || pageSize,
      filter: (variables && variables.filter) || {},
    };

    if (tag && tag.length) {
      q.filter.tag = tag;
    } else {
      delete q.filter.tag;
      delete q.filter.tagWithParents;
    }

    if (timestamp) {
      q.filter.timestamp = timestamp;
    }

    if (variables && variables.sort) {
      q.sort = variables.sort;
    }

    if (search && search.length) {
      if (useFlexibleSearch) {
        q.filter.search = search;
      } else q.filter.fullText = search;
    } else {
      delete q.filter.fullText;
      delete q.filter.search;
    }
    if (sort) q.sort = sort;
    if (filter) {
      filter.forEach((f) => {
        const [key, value] = f.split(':');
        q.filter[key] = value;
      });
    }

    setGqlVariables(q);
  }, [
    QS.limit,
    QS.sort,
    QS.timestamp && QS.timestamp.gte,
    QS.timestamp && QS.timestamp.lte,
    QS.filter,
    QS.search,
    QS.tag,
  ]);

  const edges = _get(viewData, 'edges');
  const pageInfo = _get(viewData, 'pageInfo');
  const f = _get(edges, '[0]', {});

  const col =
    columns ||
    Object.keys(f)
      .filter((p) => p !== '__typename')
      .map((p) => ({ key: p, name: p }));
  const { search, filter, timestamp, sort } = QS;

  return (
    <PageTemplate
      actionsPane={
        <SearchPane
          onChange={debounceFilter}
          actions={actions}
          defaultValue={search}
          defaultFilters={filter}
          searchComponents={searchComponents}
          filterable={filterable}
          tagsSidebar={{
            buttonText: tagsSidebar.buttonText,
            isOpened: isTagsSidebarOpened,
            onTagsSidebarClick: () => {
              setIsTagsSidebarOpened(!isTagsSidebarOpened);
            },
            showTagsSidebar: tagsSidebar.showSidebar,
          }}
          timestamp={timestamp}
        />
      }
      hideBanner={hideBanner}
    >
      {children}
      <StyledLoaderLine loading={loading} />
      <Flex>
        {tagsSidebar.showSidebar && (
          <Box>
            <TagsSidebar
              onChange={debounceFilter}
              isOpened={isTagsSidebarOpened}
              onTagChanged={tagsSidebar.onTagChanged}
              location={location}
            />
          </Box>
        )}
        {edges && (
          <Box width={'100%'}>
            <StyledLoadMoreTable
              onSortClick={(sort) => applyFilter({ sort })}
              sortOrder={sort}
              columns={col}
              data={edges}
              onLoadMore={() =>
                loadMore(_get(data, `${context}.edges.length`), fetchMore)
              }
              hasMore={pageInfo.hasNext}
            />
          </Box>
        )}
      </Flex>
    </PageTemplate>
  );
};

DashboardListPage.propTypes = {
  context: PropTypes.string.isRequired,
  columns: PropTypes.array,
  fetchPolicy: PropTypes.string,
  query: PropTypes.object.isRequired,
  variables: PropTypes.object,
  actions: PropTypes.array,
  onRowClick: PropTypes.func,
  filterable: PropTypes.array,
  tagSidebar: PropTypes.objectOf({
    isOpenedByDefault: PropTypes.boolean,
    showSidebar: PropTypes.boolean,
    onTagChanged: PropTypes.func,
    buttonText: PropTypes.string,
  }),
};

export default withRouter(withQS(DashboardListPage));
