import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Block from '../Block';
import Button from '../Button';
import Icon from '../Icon';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import dayjs from 'dayjs';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${props =>
    props.theme.monthPickerChevron || props.theme.tertiaryThemeColor};
`;

const StyledMonth = styled(Button)`
  background-color: ${props => props.theme.monthPickerBackground};
  text-align: center;
  font-weight: 200;
  font-size: 14px;
  min-width: 60px;
  margin-bottom: 0px;
  margin-top: 2px;
  margin-right: 2px;
  padding: 0.5em 0.5em;
  border: solid 1px
    ${props =>
      props.active
        ? props.theme.monthPickerColor
        : props.theme.monthPickerBackground};
  border-radius: 5%;
  &:active {
    background-color: ${props => props.theme.monthPickerBackground};
  }
  &:hover {
    background-color: ${props => props.theme.monthPickerBackground};
    border: solid 1px ${props => props.theme.monthPickerColor};
    border-radius: 5%;
  }
`;

const StyledDateLabel = styled(Block)`
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: ${props => props.theme.monthPickerDateBackground};
    color: ${props => props.theme.monthPickerFontColor}
  &:hover {
    background-color: ${props => props.theme.monthPickerDateBackground};
      color: ${props => props.theme.monthPickerColor}
  }
`;
const StyledYear = styled(Block)`
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: ${props => props.theme.monthPickerBackground};
    color: ${props => props.theme.monthPickerFontColor}
  &:hover {
    background-color: ${props => props.theme.monthPickerBackground};
      color: ${props => props.theme.monthPickerColor}
  }
`;

const StyledBox = styled(Box)`
  text-align: center;
`;
const StyledDateButton = styled(Button)`
padding: 0;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  font-size: 1.2em;
  line-height: 1em;
  color: ${props => props.theme.monthPickerColor}
  border: solid 1px ${props => props.theme.monthPickerDateBackground}
  border-radius: 5%;
  background-color: ${props => props.theme.monthPickerDateBackground};
  &:active {
    background-color: ${props => props.theme.monthPickerDateBackground};
      color: ${props => props.theme.monthPickerFontColor}
  }
  &:hover {
    background-color: ${props => props.theme.monthPickerDateBackground};
      color: ${props => props.theme.monthPickerFontColor}
  }
`;
const StyledYearButton = styled(Button)`
padding: 0;
  margin-right: 10px;
  margin-left: 10px;
  text-align: center;
  font-size: 1.2em;
  line-height: 1em;
  color: ${props => props.theme.monthPickerColor}
  border: solid 1px ${props => props.theme.monthPickerBackground}
  border-radius: 5%;
  background-color: ${props => props.theme.monthPickerBackground};
  &:active {
    background-color: ${props => props.theme.monthPickerBackground};
      color: ${props => props.theme.monthPickerFontColor}
  }
  &:hover {
    background-color: ${props => props.theme.monthPickerBackground};
      color: ${props => props.theme.monthPickerFontColor}
  }
`;

const StyledDate = styled(Flex)`
  background-color: ${props => props.theme.monthPickerDateBackground};
  width: 100%;
  color: ${props => props.theme.secondaryThemeColor};
  border-radius: ${props => props.theme.inputBorderRadius};
  border: 2px solid
    ${props =>
      props.invalid
        ? props.theme.inputBorderError
        : props.theme.monthPickerDateBackground};
  border-width: ${props => props.theme.inputBorderWidth};
  outline: none;
  margin: 0;
  box-sizing: border-box;
  opacity: 1;
  font-weight: ${props => props.theme.inputFontWeight};
  font-size: ${props => props.theme.inputFontSize};
  min-height: 2.75em;
`;

const StyledMonthPicker = styled(Flex)`
  color: ${props => props.theme.primaryThemeColor};
  min-width: 14em;
`;

const StyledWrapper = styled(Box)`
  background-color: ${props => props.theme.monthPickerBackground};
  position: absolute;
  top: 160px;
  left: 70px;
  margin-top: 8px;
  padding-top: 8px;
  padding-right: 20px;
  padding-left: 17px;
  border-radius: ${props => props.theme.inputBorderRadius};
  z-index: 10;
`;

const Date = ({ date, format, setDate, visible, setVisible }) => (
  <StyledDate justifyContent="space-around">
    <StyledBox width={1 / 3}>
      <StyledDateButton
        onClick={() => setDate(dayjs(date).subtract(1, 'month'))}
      >
        <StyledIcon size={'1.2em'} icon={'chevron-left'} />
      </StyledDateButton>
    </StyledBox>
    <Box width={1 / 3} m="auto">
      <StyledDateLabel
        onClick={() => setVisible(!visible)}
        onBlur={() => setVisible(false)}
      >
        {dayjs(date).format('MMM YYYY')}
      </StyledDateLabel>
    </Box>
    <StyledBox width={1 / 3}>
      <StyledDateButton onClick={() => setDate(dayjs(date).add(1, 'month'))}>
        <StyledIcon size={'1.2em'} icon={'chevron-right'} />
      </StyledDateButton>
    </StyledBox>
  </StyledDate>
);

const Months = ({ date, setDate }) => {
  const month = dayjs(date).month();
  return (
    <Flex flexDirection="column">
      <Box width={1}>
        <StyledMonth
          active={month === 0}
          onClick={() => setDate(dayjs(date).month(0))}
        >
          Jan
        </StyledMonth>
        <StyledMonth
          active={month === 1}
          onClick={() => setDate(dayjs(date).month(1))}
        >
          Feb
        </StyledMonth>
        <StyledMonth
          active={month === 2}
          onClick={() => setDate(dayjs(date).month(2))}
        >
          Mar
        </StyledMonth>
      </Box>
      <Box width={1}>
        <StyledMonth
          active={month === 3}
          onClick={() => setDate(dayjs(date).month(3))}
        >
          Apr
        </StyledMonth>
        <StyledMonth
          active={month === 4}
          onClick={() => setDate(dayjs(date).month(4))}
        >
          May
        </StyledMonth>
        <StyledMonth
          active={month === 5}
          onClick={() => setDate(dayjs(date).month(5))}
        >
          Jun
        </StyledMonth>
      </Box>
      <Box width={1}>
        <StyledMonth
          active={month === 6}
          onClick={() => setDate(dayjs(date).month(6))}
        >
          Jul
        </StyledMonth>
        <StyledMonth
          active={month === 7}
          onClick={() => setDate(dayjs(date).month(7))}
        >
          Aug
        </StyledMonth>
        <StyledMonth
          active={month === 8}
          onClick={() => setDate(dayjs(date).month(8))}
        >
          Sep
        </StyledMonth>
      </Box>
      <Box width={1}>
        <StyledMonth
          active={month === 9}
          onClick={() => setDate(dayjs(date).month(9))}
        >
          Oct
        </StyledMonth>
        <StyledMonth
          active={month === 10}
          onClick={() => setDate(dayjs(date).month(10))}
        >
          Nov
        </StyledMonth>
        <StyledMonth
          active={month === 11}
          onClick={() => setDate(dayjs(date).month(11))}
        >
          Dec
        </StyledMonth>
      </Box>
    </Flex>
  );
};

const Years = ({ date, setDate }) => (
  <Flex justifyContent="space-around">
    <StyledBox width={1 / 3}>
      <StyledYearButton
        onClick={() => setDate(dayjs(date).subtract(1, 'year'))}
      >
        <StyledIcon size={'1.2em'} icon={'chevron-left'} />
      </StyledYearButton>
    </StyledBox>
    <Box width={1 / 3} m="auto">
      <StyledYear>{dayjs(date).year()}</StyledYear>
    </Box>
    <StyledBox width={1 / 3}>
      <StyledYearButton onClick={() => setDate(dayjs(date).add(1, 'year'))}>
        <StyledIcon size={'1.2em'} icon={'chevron-right'} />
      </StyledYearButton>
    </StyledBox>
  </Flex>
);

const MonthPicker = ({ value, format, onChange }) => {
  let [date, setDate] = useState(dayjs(value));
  let [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(false);
    setDate(dayjs(date));
  }, [value]);

  useEffect(() => {
    onChange(date);
  }, [date]);

  return (
    <StyledMonthPicker width={[1, 1 / 4]} justifyContent="space-around">
      <Box width={1}>
        <Date
          date={date}
          format={format}
          setDate={setDate}
          visible={visible}
          setVisible={setVisible}
        />
        {visible && (
          <StyledWrapper>
            <Months date={date} setDate={setDate} />
            <Years date={date} setDate={setDate} />
          </StyledWrapper>
        )}
      </Box>
    </StyledMonthPicker>
  );
};

MonthPicker.propTypes = {
  value: PropTypes.string,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  disabledMonths: PropTypes.arrayOf(PropTypes.object),
};

MonthPicker.defaultProps = {
  value: '',
  onChange: () => {},
  invalid: false,
  disabledMonths: [],
};

export default MonthPicker;
