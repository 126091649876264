import React, { useContext, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import PasswordField from '@components/fields/PasswordField';
import Fieldset from '@components/atoms/Fieldset';
import ToasterContext from '@components/contexts/ToasterContext';
import Block from '@components/atoms/Block';
import styled from 'styled-components';
import FlexibleForm from '@components/organisms/FlexibleForm';
import _get from 'lodash/get';

import { UPDATE_USER_PASSWORD } from '../api/mutations/users';

const StyledBlock = styled(Block)`
  padding: 0 7.75em;
  @media screen and (max-width: ${({ theme }) =>
      _get(theme, 'breakpoints[0]', '40em')}) {
    padding: 0.4em;
  }
`;

const timeout = 24 * 60 * 60;
const min = 6;
const minLength = (value) => {
  return value && value.length >= min
    ? undefined
    : `The password must be a string with a least 6 characters.`;
};

const isPasswordEqual = ({ password }, password2) => {
  return password2 !== password ? 'The passwords are not same' : undefined;
};

const Password = ({ id, logout }) => {
  const { addToast } = useContext(ToasterContext);
  const [updatePassword] = useMutation(UPDATE_USER_PASSWORD);
  const onSubmit = useCallback(
    ({ password }) => {
      updatePassword({
        variables: {
          id,
          password,
        },
      })
        .then(() => {
          addToast({
            type: 'success',
            title: `Success`,
            message: `Password updated successfully`,
          });
          logout();
        })
        .catch((error) => {
          addToast({
            type: 'error',
            title: `Error`,
            message: error.message,
            timeout,
          });
        });
    },
    [addToast, id, logout, updatePassword]
  );

  const initialValues = {
    password: '',
    password2: '',
  };
  return (
    <StyledBlock>
      <Fieldset label="Password">
        <FlexibleForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          submitButton
          submitText={'Update Password'}
        >
          {(form) => {
            return (
              <>
                <PasswordField
                  name="password"
                  label="New Password"
                  validate={minLength}
                />
                <PasswordField
                  name="password2"
                  label="Repeat New Password"
                  validate={(val) => isPasswordEqual(form.values, val)}
                />
              </>
            );
          }}
        </FlexibleForm>
      </Fieldset>
    </StyledBlock>
  );
};

export default Password;
