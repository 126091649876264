import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { hidden } from '../Hide';
import Icon from '../Icon';

const styles = css`
  font-family: ${props => props.theme.primaryFont};
  background: ${props => props.theme.tableHeadBackground};
  color: ${props => props.theme.tableHeadColor};
  font-weight: ${props => props.theme.tableHeadFontWeight};
  line-height: ${props => props.theme.tableHeadLineHeight};
  font-size: ${props => props.theme.tableHeadFontSize};
  text-transform: ${props => props.theme.tableHeadTextTransform};
  text-align: ${props => props.theme.tableHeadTextAlign};
  padding: ${props => props.theme.tableHeadPadding};
  margin: ${props => props.theme.tableHeadMargin};
  border-top: ${props => props.theme.tableHeadTopBorder};
  ${props =>
    props.sortable
      ? `
  cursor:pointer;
  svg {
    width: 1.2em;
    height: 1.2em; 
    fill: transparent;
    margin-left: 1em;
    &.desc {
      fill: ${props.theme.tableHeadColor}; 
      transform: scaleY(-1);
    }
    &.asc {
      fill: ${props.theme.tableHeadColor}; 
    }
  }`
      : ''};
`;

const xs = hidden('xs');
const sm = hidden('sm');
const md = hidden('md');
const lg = hidden('lg');

/**
 * @render react
 * @name TableHead
 * @description TableHead component.
 * @example
 *   <table>
    <thead>
      <tr>
        <TableHead>Heading Cell 1</TableHead>
        <TableHead>Heading Cell 2</TableHead>
        <TableHead>Heading Cell 3</TableHead>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </table>
 */

function nextSort(s) {
  if (!s) return 1;
  if (s === 1) return -1;
  return null;
}
const StyledHead = styled.th([], styles, xs, sm, md, lg);

const TableHead = ({ theme, children, onSort, sortOrder, ...props }) => {
  return (
    <StyledHead
      onClick={onSort && (() => onSort(nextSort(sortOrder)))}
      sortable={onSort}
      {...props}
    >
      {children}
      {onSort && (
        <div style={{ display: 'inline-block' }}>
          <Icon
            className={sortOrder === 1 ? 'asc' : sortOrder === -1 ? 'desc' : ''}
            icon="arrow-up-right"
          />
        </div>
      )}
    </StyledHead>
  );
};

TableHead.defaultProps = {
  theme: {
    //breakpoints: ['40em', '52em', '64em'],
    tableHeadBackground: 'none',
    tableHeadColor: '#A3A3A3',
    tableHeadFontWeight: '500',
    tableHeadLineHeight: 'normal',
    tableHeadFontSize: '0.75em',
    tableHeadTextTransform: 'none',
    tableHeadTextAlign: 'left',
    tableHeadPadding: '1.5em 1.8em 1.5em 1.8em',
    tableHeadMargin: '0',
    tableHeadTopBorder: 'solid 4px #EBEBEB',
  },
};

TableHead.propTypes = {
  children: PropTypes.any,
  /**
   * @property {propTypes.bool} xs - Hide in xtra small screens.
   */
  xs: PropTypes.bool,
  /**
   * @property {propTypes.bool} sm - Hide in small screens.
   */
  sm: PropTypes.bool,
  /**
   * @property {propTypes.bool} md - Hide in medium screens.
   */
  md: PropTypes.bool,
  /**
   * @property {propTypes.bool} lg - Hide in large screen.
   */
  lg: PropTypes.bool,
};

export default TableHead;
