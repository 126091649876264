import React, { useEffect, useState } from 'react';

import { getSelectedTag, apolloClient } from '../../connect-react-lib';
import { GET_COUNTRY_AREA_TAGS, GET_TAG } from '../../api/queries/tags';
import { Flex, Box } from '@rebass/grid';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import Select from '../../atoms/Select';

import { withAuth } from '../../contexts/FirebaseContext';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';

import styled from 'styled-components';
const StyledSelect = styled(Select)`
  text-transform: capitalize;
`;

const ALL = 'All';

const MultiTagTypeSelect = ({
  value,
  types = ['country', 'area'],
  onChange,
  flexDirection,
  alignItems,
  justifyContent,
  disabled,
  extractTags,
}) => {
  const client = useApolloClient();

  let [values, setValues] = useState({});
  let [options, setOptions] = useState({});
  let [organisation, setOrganisation] = useState('');

  const isID = (id) => (id ? false : true);

  useEffect(() => {
    async function fetchOrga() {
      const tag = getSelectedTag();
      const { organization } = (await extractTags(tag)) || {};
      setOrganisation(organization.id);
    }
    fetchOrga();
  });

  useEffect(() => {
    const fetchCountryAndAreas = async () => {
      const res = await apolloClient.query({
        query: GET_COUNTRY_AREA_TAGS,
        variables: {
          id: getSelectedTag(),
        },
      });

      const tags = res.data.countriesAndAreasTree || [];
      let _allOptions = {};

      const _countryOptions = [];
      const _areaOptions = [];

      tags.forEach(({ name, id, areas }) => {
        if (name && !_countryOptions.some((obj) => obj.id === id)) {
          _countryOptions.push({ id: id.split('/').pop(), name });
          if (value && value.includes(id))
            areas.forEach(({ id, name }) => {
              if (name && !_areaOptions.some((obj) => obj.id === id)) {
                _areaOptions.push({ id: id.split('/').pop(), name });
              }
            });
        }
      });
      _allOptions['country'] = _countryOptions;
      _allOptions['area'] = _areaOptions;

      setOptions(_allOptions);
    };
    fetchCountryAndAreas();
  }, [value]);

  const {
    data: tagData,
    error: tagDataError,
    loading: tagDataLoading,
  } = useQuery(GET_TAG, {
    variables: {
      id: value,
    },
    skip: isID(value),
  });

  useEffect(() => {
    if (!value) {
      setValues({ country: '', area: '' });
    }
    if (!tagDataLoading && tagData && tagData.tag) {
      const { tagParents } = tagData.tag;
      let newValues = {};
      tagParents &&
        Object.keys(tagParents).forEach((tagParent) => {
          const t = tagParents[tagParent];

          if (types.includes(t.type))
            newValues[t.type] = tagParent.split('/').pop();
        });

      setValues(newValues);
    }
  }, [tagDataLoading, value]);

  const handleChange = (type, value, onChange) => {
    const typeIndex = types.findIndex((t) => t === type);
    let _allValues = _cloneDeep(values);
    for (let i = typeIndex; i < types.length; i++) {
      if (_allValues[types[i]]) {
        delete _allValues[types[i]];
      }
    }
    _allValues[type] = value;

    setValues(_allValues);
    let tag = '';
    for (const v in _allValues)
      tag =
        tag[tag.length - 1] === '/'
          ? tag.slice(0, -1).concat(`/${_allValues[v]}`)
          : tag.concat(`/${_allValues[v]}`);
    onChange && onChange(organisation.concat(tag));
  };

  return (
    <Flex
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
    >
      {types &&
        types.length &&
        types.map((t, i) => (
          <Box key={`select-${i}`} width={[1, 1, 1 / 2]} ml={i > 0 ? 2 : 0}>
            <StyledSelect
              value={values[types[i]] ? values[types[i]] : t}
              select={{
                options: options[types[i]],
                valueKey: 'id',
                labelKey: 'name',
                placeholder: t,
                isDisabled: (i > 0 && !values[types[i - 1]]) || disabled,
              }}
              onChange={(value) => handleChange(t, value, onChange)}
            />
          </Box>
        ))}
    </Flex>
  );
};

export default withAuth(MultiTagTypeSelect);
