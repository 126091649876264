import React, { useState } from 'react';
import PropTypes from 'prop-types';

import discardIcon from '../../images/close.svg';
import { getIconFromType } from '../../utils';
import { Flex, Box } from '@rebass/grid';
import Icon from '../../atoms/Icon';
import styled from 'styled-components';

/**
 * @render react
 * @name Banner
 * @description Banner component
 * @example
 * <Banner>Banner</Banner>
 */
const StyledBanner = styled(Flex)`
  width: 100%;
  text-align: center;
  line-height: 2.5;
  overflow: hidden;
  color: #fff;
  background: ${props => {
    switch (props.type) {
      case 'info':
      case 'news':
      case 'update':
        return '#00BCF1';
      case 'warning':
        return '#f0c534';
      case 'incident-network':
      case 'incident-maintenance':
      case 'incident-broken':
        return '#f46060';
      case 'resolution':
        return '#9bde7e';

      default:
        return '#5a5a5a';
    }
  }};
  display: ${props => {
    if (props.visible) {
      return 'flex';
    } else {
      return 'none';
    }
  }}; /* Hidden by default */
`;
const StyledContent = styled(Box)`
  line-height: 1;
  text-align: left;
`;
const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 1.2em;
`;
const StyledIcon = styled(Box)`
  padding-top: 10px;
  padding-left: 10px;
`;
const StyledDiscard = styled(Box)`
  padding-top: 10px;
  padding-right: 10px;
`;

const Banner = ({ type, title, discardable, onDiscard, onNavigate }) => {
  const [visible, setVisible] = useState(true);

  const handleDiscard = async () => {
    await onDiscard();
    setVisible(false);
  };

  return (
    <StyledBanner
      type={type}
      visible={visible}
      alignItems="center"
      justifyContent="space-between"
    >
      <StyledIcon>{getIconFromType(type)}</StyledIcon>
      <StyledContent onClick={onNavigate}>
        <StyledTitle>{title}</StyledTitle>
      </StyledContent>
      <StyledDiscard>
        {' '}
        {discardable && (
          <Icon id="discardButton" svg={discardIcon} onClick={handleDiscard} />
        )}
      </StyledDiscard>
    </StyledBanner>
  );
};

Banner.propTypes = {
  type: PropTypes.string,
  onDiscard: PropTypes.func,
  title: PropTypes.string,
  discardable: PropTypes.bool,
};

Banner.defaultProps = {
  type: 'info',
  onDiscard: null,
  title: '',
  discardable: true,
};

export default Banner;
