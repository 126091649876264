import React from 'react';
import has from 'lodash/has';
import set from 'lodash/set';
import { parsePhoneNumber } from 'libphonenumber-js';
import Icon from '../atoms/Icon';
import info from '../images/info-banner.svg';
import news from '../images/news-banner.svg';
import update from '../images/update-banner.svg';
import warning from '../images/warning-banner.svg';
import incidentNetwork from '../images/incidentNetwork-banner.svg';
import incidentMaintenance from '../images/incidentMaintenance-banner.svg';
import incidentBroken from '../images/incidentBroken-banner.svg';
import resolution from '../images/resolution-banner.svg';

export const recursiveRemoveKeys = (item, keys) => {
  if (Array.isArray(item)) {
    item.forEach((i) => {
      recursiveRemoveKeys(i, keys);
    });
  } else if (item && typeof item === 'object') {
    keys.forEach((key) => {
      delete item[key];
    });

    Object.values(item).forEach((i) => {
      i && recursiveRemoveKeys(i, keys);
    });
  }
};

export const passwordLengthMin = (value) =>
  value.length >= 8 ? undefined : 'Password length must at least 8.';
export const passwordLengthMax = (value) =>
  value.length <= 63 ? undefined : 'Password length too long max 63 chars.';

export const numeric = (value) =>
  isNaN(value) && value ? 'Number required' : undefined;

export const nospecialchars = (value, t) =>
  value && !/^[a-zA-Z0-9]*$/g.test(value)
    ? 'Only alphanumeric without space!'
    : undefined;
export const isNumber = (value) => {
  const re = /^\d+$/;
  return re.test(value) || !value ? undefined : 'Number required';
};
export const required = (value) => {
  return value || (value !== '' && (value === 0 || value === false))
    ? undefined
    : 'Required';
};
export const validJson = (value) => {
  try {
    JSON.parse(value);
    return undefined;
  } catch (err) {
    return err.message || 'validJSON error';
  }
};

export const clean = (obj) => {
  let cleanObj = [];
  obj &&
    obj.forEach((o) => {
      let newO = {};
      Object.keys(o).forEach((k) => {
        if (k === '__typename' || k === 'tempCreateTime') {
        } else {
          newO[k] = o[k];
        }
      });
      cleanObj.push(newO);
    });
  return cleanObj;
};

export const mustBeEmail = (value) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value) ? undefined : 'Invalid email';
};

export function duration(duration) {
  const seconds = duration / 1000;
  var days = Math.floor(seconds / 86400);
  var hours = Math.floor((seconds % 86400) / 3600);
  var mins = Math.floor(((seconds % 86400) % 3600) / 60);
  var secs = Math.floor(((seconds % 86400) % 3600) % 60);
  var rets = '';
  if (days > 0) rets += days + 'd ';
  if (hours > 0) rets += hours + 'h ';
  if (mins > 0) rets += mins + 'm ';
  if (secs > 0) rets += secs + 's ';
  if (!rets.length) rets += secs + 's ';

  return rets;
}

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const decodeQueryString = (queryString) => {
  const splitedQueryString = queryString.split('&');
  const decodedQueryString = {};

  for (const param of splitedQueryString) {
    let [key, value] = param.split('=');
    value = value ? (value === 'null' ? null : value) : '';
    if (key === 'limit' && value) value = Number(value);
    if (has(decodedQueryString, key)) {
      const currentValueForKey = decodedQueryString[key];
      if (!Array.isArray(currentValueForKey)) {
        set(decodedQueryString, key, [currentValueForKey, value]);
      } else {
        currentValueForKey.push(value);
      }
    } else {
      set(decodedQueryString, key, value);
    }
  }

  return decodedQueryString[''] === '' ? null : decodedQueryString;
};

export const encodeQueryString = function encode(queryObj, nesting = '') {
  const pairs = Object.entries(queryObj).map(([key, val]) => {
    if (!val) {
      return [nesting + key, 'null'].map(escape).join('=');
    } else if (Array.isArray(val)) {
      return val
        .map((subVal) => [nesting + key, subVal].map(escape).join('='))
        .join('&');
    } else if (typeof val === 'object') {
      return encode(val, nesting + `${key}.`);
    } else {
      return [nesting + key, val].map(escape).join('=');
    }
  });
  return pairs.join('&');
};

export const setQueryString = (history, queryString) => {
  history.replace(`${history.location.pathname}?${queryString}`);
};

export const mustBeValidPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return;
  const pnl = phoneNumber.replace(/[^\d]/g, '').length;
  if (pnl <= 5) return 'Phone number too short';
  else if (pnl >= 17) return 'Phone number too long';
  else {
    try {
      if (!parsePhoneNumber(phoneNumber, 'FI').isValid()) {
        return 'Invalid phonenumber';
      }
    } catch (e) {
      return 'Invalid phonenumber';
    }
  }
};

export const parseEventsToTimeline = (
  events,
  typeFunc,
  inConditionFunc,
  outConditionFunc
) => {
  const timelineEvents = [];

  let ie, oe, lie;
  for (var i = events.length - 1; i >= 0; i--) {
    if (inConditionFunc(events[i])) {
      ie = events[i];
      oe = null;
      for (var j = i - 1; j >= 0; j--) {
        if (outConditionFunc(events[j])) {
          oe = events[j];
          break;
        }
      }

      if (oe) {
        timelineEvents.push({
          type: typeFunc(ie),
          data: ie.data,
          startTime: ie.timestamp,
          endTime: oe.timestamp,
        });
      } else {
        let lastIe;
        for (var j = i - 1; j >= 0; j--) {
          if (inConditionFunc(events[j])) {
            lastIe = events[j];
          }
        }

        if (ie && lastIe) {
          lie = lastIe;
          timelineEvents.push({
            type: typeFunc(ie),
            data: ie.data,
            startTime: ie.timestamp,
            endTime: lastIe.timestamp,
            updated: true,
          });
        } else if (ie !== lie) {
          timelineEvents.push({
            type: typeFunc(ie),
            data: ie.data,
            startTime: ie.timestamp,
            endTime: new Date(),
            updated: true,
          });
        }
      }
    }
  }

  return timelineEvents;
};

export const parseKeyEventsToTimeline = (events) =>
  parseEventsToTimeline(
    events,
    (e) => e.data.text,
    (e) => parseInt(e.data.value, 10) === 1,
    (e) => parseInt(e.data.value, 10) === 0
  );

export const parseConnectionEventsToTimeline = (events) =>
  parseEventsToTimeline(
    events,
    (e) => e.data.after.state,
    (e) => e.data.after.state === 'connected',
    (e) => e.data.after.state !== 'connected'
  );
export const getIconFromType = (type) => {
  switch (type) {
    case 'info':
      return <Icon svg={info} />;
    case 'news':
      return <Icon svg={news} />;
    case 'update':
      return <Icon svg={update} />;
    case 'warning':
      return <Icon svg={warning} />;
    case 'incident-network':
      return <Icon svg={incidentNetwork} />;
    case 'incident-maintenance':
      return <Icon svg={incidentMaintenance} />;
    case 'incident-broken':
      return <Icon svg={incidentBroken} />;
    case 'resolution':
      return <Icon svg={resolution} />;

    default:
      return <Icon svg={info} />;
  }
};
export const validSSID = (value) =>
  !/.*[€´¨@`"'~!#$%^&§*()°=\\[\]{}/?<>;].*/i.test(value) &&
  !/.*[À-ÿ].*/i.test(value)
    ? undefined
    : 'invalid SSID. Do not use special characters';

export const getLanguageForCommonApps = (i18n) => {
  if (!i18n) return 'en';
  return i18n.language.substring(0, 2); // always return 2 chars
};

export const formatCustomerData = (unit) => {
  console.log('formatCustomerData', unit);
  return {
    netvisorKey: unit?.netvisorKey,
    differentDeliveryAddress: !!unit?.differentDeliveryAddress,
    CustomerBaseInformation: {
      InternalIdentifier:
        unit?.CustomerBaseInformation?.InternalIdentifier ?? '',
      ExternalIdentifier:
        unit?.CustomerBaseInformation?.ExternalIdentifier ?? '',
      OrganizationUnitNumber:
        unit?.CustomerBaseInformation?.OrganizationUnitNumber ?? '',
      Name: unit?.CustomerBaseInformation?.Name ?? '',
      StreetAddress: unit?.CustomerBaseInformation?.StreetAddress ?? '',
      City: unit?.CustomerBaseInformation?.City ?? '',
      PostNumber: unit?.CustomerBaseInformation?.PostNumber ?? '',
      Country: unit?.CustomerBaseInformation?.Country ?? '',
      PhoneNumber: unit?.CustomerBaseInformation?.PhoneNumber ?? '',
      Email: unit?.CustomerBaseInformation?.Email ?? '',
      EmailInvoicingAddress:
        unit?.CustomerBaseInformation?.EmailInvoicingAddress ?? '',
    },
    CustomerFinvoiceDetails: {
      FinvoiceAddress: unit?.CustomerFinvoiceDetails?.FinvoiceAddress ?? '',
      FinvoiceRouterCode:
        unit?.CustomerFinvoiceDetails?.FinvoiceRouterCode ?? '',
    },
    CustomerDeliveryDetails: !!unit?.differentDeliveryAddress
      ? {
          DeliveryName: unit?.CustomerDeliveryDetails?.DeliveryName ?? '',
          DeliveryStreetAddress:
            unit?.CustomerDeliveryDetails?.DeliveryStreetAddress ?? '',
          DeliveryCity: unit?.CustomerDeliveryDetails?.DeliveryCity ?? '',
          DeliveryPostNumber:
            unit?.CustomerDeliveryDetails?.DeliveryPostNumber ?? '',
          DeliveryCountry:
            unit?.CustomerDeliveryDetails?.DeliveryCountry ?? undefined,
        }
      : undefined,
    CustomerContactDetails: {
      ContactPerson: unit?.CustomerContactDetails?.ContactPerson ?? '',
      ContactPersonPhone:
        unit?.CustomerContactDetails?.ContactPersonPhone ?? '',
      ContactPersonEmail:
        unit?.CustomerContactDetails?.ContactPersonEmail ?? '',
    },
    CustomerAdditionalInformation: {
      InvoicingLanguage:
        unit?.CustomerAdditionalInformation?.InvoicingLanguage ?? '',
    },
    CustomerInvoiceData: {
      vatRate: unit?.CustomerInvoiceData?.vatRate ?? 0,
      vatCode: unit?.CustomerInvoiceData?.vatCode ?? '',
      sellerAccount: unit?.CustomerInvoiceData?.sellerAccount ?? undefined,
      paymentTerms: unit?.CustomerInvoiceData?.paymentTerms ?? undefined,
      reference: unit?.CustomerInvoiceData?.reference ?? '',
      disableTaxClause: !!unit?.CustomerInvoiceData?.disableTaxClause,
    },
    CustomerContactPersons: {
      CustomerContactPerson:
        unit?.CustomerContactPersons?.CustomerContactPerson &&
        unit?.CustomerContactPersons?.CustomerContactPerson?.ContactPersonID
          ? {
              ContactPersonID:
                unit?.CustomerContactPersons?.CustomerContactPerson
                  ?.ContactPersonID ?? '',
              ContactPersonFirstName:
                unit?.CustomerContactPersons?.CustomerContactPerson
                  ?.ContactPersonFirstName ?? '',
              ContactPersonLastName:
                unit?.CustomerContactPersons?.CustomerContactPerson
                  ?.ContactPersonLastName ?? '',
              ContactPersonPhoneNumber:
                unit?.CustomerContactPersons?.CustomerContactPerson
                  ?.ContactPersonPhoneNumber ?? '',
              ContactPersonEmail:
                unit?.CustomerContactPersons?.CustomerContactPerson
                  ?.ContactPersonEmail ?? '',
              ContactPersonOfficeNetvisorKey:
                unit?.CustomerContactPersons?.CustomerContactPerson
                  ?.ContactPersonOfficeNetvisorKey ?? '',
            }
          : undefined,
    },
  };
};
