import PropTypes from 'prop-types';
import styled from 'styled-components';
import c from 'color';

/**
 * @render react
 * @description Styled button component
 *
 * @example
 * <Button>Primary</Button>
 */
const Button = styled.button`
  display: inline-block;
  outline: none;
  transition: all 100ms ease;
  cursor: pointer;
  box-sizing: border-box;
  background: #595959;
  background: ${(props) =>
    props.secondary
      ? props.theme.secondaryBackground
      : props.theme.primaryThemeColor};
  color: ${(props) =>
    props.secondary
      ? props.theme.secondaryColor
      : props.theme.primaryButtonTextColor};
  border: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonBorder
      : props.theme.primaryButtonBorder};

  font-size: ${(props) =>
    props.small
      ? props.theme.smallButtonFontSize
      : props.secondary
      ? props.theme.secondaryButtonFontSize
      : props.theme.primaryButtonFontSize};
  letter-spacing: ${(props) =>
    props.secondary
      ? props.theme.secondaryLetterSpacing
      : props.theme.primaryLetterSpacing};

  font-weight: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonFontWeight
      : props.theme.primaryButtonFontWeight};
  margin: ${(props) =>
    props.secondary
      ? (props) => props.theme.secondaryButtonMargin
      : props.theme.primaryButtonMargin};

  padding: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonTextPadding
      : props.theme.primaryButtonTextPadding};

  text-transform: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonTextTransform
      : props.theme.primaryButtonTextTransform};

  text-align: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonTextAlign
      : props.theme.primaryButtonTextAlign};
  min-width: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonMinWidth
      : props.theme.primaryButtonMinWidth};
  min-height: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonMinHeight
      : props.theme.primaryButtonMinHeight};
  width: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonWidth
      : props.theme.primaryButtonWidth};
  height: ${(props) =>
    props.secondary
      ? props.theme.secondaryButtonHeight
      : props.theme.primaryButtonHeight};
  max-width: ${(props) => props.small && props.theme.smallButtonMaxWidth};

  border-radius: ${(props) =>
    props.secondary
      ? props.theme.secondaryBorderRadius
      : props.theme.primaryBorderRadius};
  font-family: ${(props) => props.theme.primaryFont};

  &:hover {
    background: ${(props) => props.theme.primaryHoverBackground};
  }

  &:active {
    background: ${(props) => props.theme.primaryActiveBackground};

    border-color: ${(props) =>
      props.secondary
        ? c(props.theme.secondaryActiveBackground).darken(0.2).string()
        : 'none'};
  }

  &:disabled {
    background: ${(props) =>
      props.secondary
        ? props.theme.secondaryDisabledBackground
        : props.theme.primaryDisabledBackground};
    color: ${(props) =>
      props.secondary
        ? props.theme.secondaryDisabledColor
        : props.theme.primaryDisabledColor};

    border-color: ${(props) =>
      props.secondary ? props.theme.secondaryDisabledColor : 'none'};
    cursor: inherit;
  }
`;

Button.defaultProps = {
  theme: {
    primaryBackground: '#0789CC',
    primaryButtonTextColor: '#fff',
    primaryButtonBorder: 'none',
    primaryBorderRadius: '0',
    primaryButtonMargin: '0.5em 0',
    primaryButtonTextPadding: '0.75em 1.05em',
    primaryButtonTextTransform: 'uppercase',
    primaryHoverBackground: '#0789CC',
    primaryActiveBackground: '#0789CC',
    primaryDisabledBackground: '#CECECE',
    primaryDisabledColor: '#9C9C9C',
    primaryButtonFontSize: '0.95em',
    primaryButtonMinWidth: '0',
    primaryButtonMinHeight: '0',
    primaryButtonFontWeight: '400',
    primaryLetterSpacing: '0.045em',
    primaryButtonTextAlign: 'center',

    secondaryBackground: 'none',
    secondaryColor: '#0789CC',
    secondaryButtonMargin: '0.5em 0',
    secondaryBorderRadius: '0',
    secondaryButtonBorder: '2px solid #0789CC',
    secondaryButtonTextPadding: '0.61em 1.05em',
    secondaryButtonTextTransform: 'uppercase',
    secondaryDisabledBackground: 'none',
    secondaryHoverBackground: '#0789CC',
    secondaryActiveBackground: '#0789CC',
    secondaryDisabledColor: '#bbbbbb',
    secondaryButtonFontSize: '0.95em',
    secondaryButtonMinWidth: '0',
    secondaryButtonMinHeight: '0',
    secondaryButtonFontWeight: '400',
    secondaryLetterSpacing: '0.045em',
    secondaryButtonTextAlign: 'center',

    smallButtonMaxWidth: '160px',
    smallButtonFontSize: '0.8em',
  },
};

Button.propTypes = {
  /**
   * @property {propTypes.bool} disabled - button to disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * @property {propTypes.bool} small - small button.
   */
  small: PropTypes.bool,
  /**
   * @property {propTypes.bool} secondary - secondary button.
   */
  secondary: PropTypes.bool,
};

export default Button;
