import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import InputLabel from '../../atoms/InputLabel';
import Select from '../../atoms/Select';
import Error from '../../atoms/Error';
import InputWrapper from '../../atoms/InputWrapper';
import { composeValidators } from '../../utils';
import Hint from '../utils/Hint';

const SelectField = ({
  name,
  validate,
  label,
  options,
  isMulti,
  onChange,
  menuPlacement,
  wrapperStyles,
  validateOnInit,
  value,
  required,
  optional,
  disabled,
  ...select
}) => (
  <Field name={name} validate={composeValidators(validate)}>
    {({ input, meta }) => {
      const selectProps = {
        ...select,
        options,
        isMulti,
        menuPlacement,
      };

      if (value) input.value = value;
      return (
        <InputWrapper style={wrapperStyles} data-cy={name}>
          {label && <InputLabel htmlFor={input.name}>{label}</InputLabel>}
          <Select
            {...input}
            select={selectProps}
            onChange={(event, option) => {
              if (onChange) onChange(event, option);

              input.onChange(event, option);
            }}
            invalid={(meta.touched || validateOnInit) && meta.error}
            controlled
            isDisabled={disabled}
          />

          {(meta.touched || validateOnInit) && meta.error && (
            <Error id={`${input.name}Error`} role="alert" palette="danger">
              {meta.error}
            </Error>
          )}
          {!((meta.touched || validateOnInit) && meta.error) && required && (
            <Hint role="info">Required</Hint>
          )}
          {!((meta.touched || validateOnInit) && meta.error) && optional && (
            <Hint role="info">Optional</Hint>
          )}
        </InputWrapper>
      );
    }}
  </Field>
);

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  controlled: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  validateOnInit: PropTypes.bool,
};

SelectField.defaultProps = {
  label: null,
  options: [],
  isMulti: false,
  onChange: null,
  controlled: false,
  validate: () => {},
};

export default SelectField;
