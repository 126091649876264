import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ComposedChart,
  YAxis,
  XAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
} from 'recharts';

const generateColor = (style, color, index) =>
  style +
  `
  .recharts-item-${index} {
    .recharts-bar-rectangles .recharts-bar-rectangle {
      fill: ${color};
      stroke: ${color};
    }

    &.recharts-line {
      stroke: ${color};
    }
  }
`;

const StyledComposedChart = styled(ComposedChart)`
  * {
    font-family: ${({ theme }) => theme.primaryFont};
  }

  .recharts-cartesian-axis-tick-value {
    font-size: 0.8rem;
  }

  width: 100%;

  ${({ theme }) => (theme.chartColors || []).reduce(generateColor, '')};
`;

StyledComposedChart.defaultProps = {
  theme: {
    primaryFont: 'system-ui, sans-serif',
    chartColors: ['#333', '#444', '#555', '#666', '#777'],
  },
};

export const StyledTooltip = styled.div`
  background: ${({ theme }) => theme.background};
  font-family: ${({ theme }) => theme.primaryFont};
  padding: 0.75rem;
  border-radius: 2px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);

  div {
    color: ${props => props.theme.chartTooltipColor};
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li:not(:last-of-type) {
    margin-bottom: 0.333rem;
  }

  ${({ theme }) =>
    (theme.chartColors || []).reduce(
      (acc, color, index) =>
        acc +
        `
    li:nth-child(${index + 1}) {
      color: ${color};
    }
  `,
      ''
    )};
`;

StyledTooltip.defaultProps = {
  theme: {
    background: '#fff',
    primaryFont: 'system-ui, sans-serif',
  },
};

const renderTooltip = ({ payload, label, separator }) => (
  <StyledTooltip>
    <div>{label}</div>
    <ul>
      {payload.map(({ name, value }) => (
        <li key={name}>
          {name}
          {separator}
          {value}
        </li>
      ))}
    </ul>
  </StyledTooltip>
);

class Chart extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    dataKey: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    tooltip: PropTypes.func,
  };

  static defaultProps = {
    tooltip: renderTooltip,
  };

  computeChildren = () => {
    const { children } = this.props;

    if (!children) {
      return <React.Fragment />;
    } else if (Array.isArray(children)) {
      return children.map((element, index) => {
        switch (element.type.displayName) {
          case 'Bar':
          case 'Line':
            return React.cloneElement(element, {
              ...element.props,
              key: `recharts-item-${index}`,
              className: `recharts-item-${index}`,
            });
          default:
            return element;
        }
      });
    }

    return React.cloneElement(children, {
      ...children.props,
      className: `recharts-item-0`,
    });
  };

  render() {
    const { data, dataKey, tooltip } = this.props;
    const children = this.computeChildren();

    return (
      <ResponsiveContainer width="100%" height="100%">
        <StyledComposedChart data={data} className="custom">
          <YAxis dx={-5} padding={{ right: 10 }} />
          <XAxis dy={5} padding={{ top: 10 }} dataKey={dataKey} />
          <CartesianGrid vertical={false} />
          <ChartTooltip
            className="recharts--tooltip"
            animationDuration={100}
            content={tooltip}
          />
          {children}
        </StyledComposedChart>
      </ResponsiveContainer>
    );
  }
}

export default Chart;
