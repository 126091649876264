import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Block from '../Block';
import InputLabel from '../InputLabel';
import c from 'color';

const StyledBlock = styled(Block)`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled(InputLabel)`
  text-transform: initial;

  ${({ theme, checked }) =>
    (theme.chartColors || []).reduce(
      (acc, color, index) =>
        acc +
        `
    &:nth-child(${index + 1}) {
      color: ${
        checked
          ? color
          : c(color, 'hex')
              .alpha(0.5)
              .string()
      };
    }
  `,
      ''
    )};
`;

const Legends = ({ items, toggleable, onChange }) => (
  <StyledBlock>
    {Object.entries(items).map(([name, value]) => (
      <StyledLabel key={name} checked={value}>
        {toggleable && (
          <input
            onChange={() => onChange({ name, value: !value })}
            checked={value}
            type="checkbox"
            name={name}
          />
        )}
        {`  ${name}`}
      </StyledLabel>
    ))}
  </StyledBlock>
);

Legends.propTypes = {
  items: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  toggleable: PropTypes.bool,
};

Legends.defaultProps = {
  items: {},
  toggleable: false,
};

export default Legends;
