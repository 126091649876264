import gql from 'graphql-tag';

export const devicesInfoFragment = gql`
  fragment devicesInfo on Device {
    id
    name
    tag
    sensors {
      id
      type
      datapoints {
        id
        name
        maxInterval
        unit
      }
    }
    configuration
    socketServer
    connectionState
    meta
    connectionEvent {
      timestamp
      data {
        after
        sourceTime
      }
    }
    lifecycleEvent {
      data {
        after
      }
    }
    extras {
      name
      state
      description
    }
    settings {
      data
    }
  }
`;

export const deviceInfoFragment = gql`
  fragment deviceInfo on Device {
    id
    name
    tag
    meta
    settings {
      data
    }
    configuration
    lifecycleEvent {
      data {
        after
      }
    }
    sensors {
      name
      id
      type
      datapoints {
        id
        name
        maxInterval
        unit
      }
      locations {
        id
        timestamp
        data {
          value
          text
          after
        }
        originator {
          id
          name
        }
      }
      locationEvents(limit: 100) {
        edges {
          timestamp
          data {
            value
            text
            after
          }
          originator {
            id
            name
          }
        }
      }
    }
    socketServer
    extras {
      name
      state
      description
      info {
        language
        name
      }
    }
    hwConfiguration {
      router
    }
  }
`;

export const GET_DEVICES = gql`
  query devices(
    $filter: DeviceFilter
    $limit: Int
    $after: String
    $sort: JSON
  ) {
    devices(filter: $filter, limit: $limit, after: $after, sort: $sort) {
      edges {
        ...devicesInfo
      }
      pageInfo {
        start
        end
        hasNext
      }
    }
  }
  ${devicesInfoFragment}
`;

export const GET_DEVICE = gql`
  query device($id: String!) {
    device(id: $id) {
      ...deviceInfo
    }
  }
  ${deviceInfoFragment}
`;
export const GET_DEVICE_AND_ENV = gql`
  query device($id: String!, $envId: ID!) {
    device(id: $id) {
      ...deviceInfo
    }
    deviceEnv(id: $envId)
  }
  ${deviceInfoFragment}
`;

export const GET_KEY_DEVICE = gql`
  query device($id: String!) {
    device(id: $id) {
      id
      name
      settings {
        data
      }
    }
  }
`;

export const GET_SENSOR = gql`
  query sensor($id: String!) {
    sensor(id: $id) {
      id
      hwId
      name
    }
  }
`;

export const GET_DEVICESTATE = gql`
  query device($id: String!) {
    device(id: $id) {
      id
      state {
        data
      }
    }
  }
`;

const runFragment = gql`
  fragment runInfo on MineValue {
    run
    runStartValue
    runEndValue
    startTime
    endTime
    date
  }
`;

export const GET_DATAUSAGE = gql`
  query mineSensorValues($filter: MineFilter!) {
    mineSensorValues(filter: $filter) {
      ...runInfo
    }
  }
  ${runFragment}
`;

export const GET_KEY_EVENTS = gql`
  query events($filter: Event!, $limit: Int!) {
    events(filter: $filter, limit: $limit) {
      sourceId
      data {
        after
      }
    }
  }
`;

export const GET_LAST_PRESENCE_VALUE = gql`
  query sensorValues($filter: SensorValueFilter!, $limit: Int!, $sort: JSON) {
    sensorValues(filter: $filter, limit: $limit, sort: $sort) {
      edges {
        timestamp
      }
    }
  }
`;

export const getMineQuery = (aggregates) => {
  return gql`
  query mineSensorValues($filter: MineFilter!) {
    mineSensorValues(filter: $filter) {
      ${aggregates.join(' ')}
    }
  }
`;
};
/*
export const MINE_AVG = gql`
  query mineSensorValues($filter: MineFilter!) {
    mineSensorValues(filter: $filter) {
      avg
      date
    }
  }
`;
*/
