import gql from 'graphql-tag';

export const alarmInfoFragment = gql`
  fragment alarmInfo on Alarm {
    id
    ruleId
    name
    sourceId
    end
    start
    tag
    source
    ack {
      user
      comment
      timestamp
    }
  }
`;
export const alarmEventHistoryFragment = gql`
  fragment alarmEventHistory on Alarm {
    eventHistory {
      time
      event {
        action
        timestamp
        sourceId
      }
    }
  }
`;
