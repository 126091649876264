import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { adopt } from 'react-adopt';
import { Query, Mutation } from 'react-apollo';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import _get from 'lodash/get';
import dayjs from 'dayjs';
import _filter from 'lodash/filter';

import { withLivionConnect } from '@components/contexts/LivionConnectContext';
import Label from '@components/atoms/Label';
import Icon from '@components/atoms/Icon';

import SpeedTestCard from '@components/cards/SpeedTestCard';
import InfoCard from './InfoCard';
import { SharedToasterConsumer } from '@components/contexts/ToasterContext';
import { getMineQuery, GET_DEVICE } from '../api/queries/devices';
import { GET_ALARM_RULES_WITH_ALARMS } from '../api/queries/alarm-rules';
import { ACK_ALARM } from '../api/mutations/alarms';
import DataUsageCard from './DataUsageCard';
import RoomMeasurementsCard from './RoomMeasurementsCard';
import PresenceGraphCard from './PresenceGraphCard';
// import ReservationsCard from './ReservationsCard';
import PersonDetectCard from './PersonDetectCard';
import SoundGraphCard from './SoundGraphCard';
import SignalStrengthCard from './SignalStrengthCard';
import SupportCard from './SupportCard';
import SpeedCard from './SpeedCard';

import DashboardDefaultPage from '@components/pages/DashboardDefaultPage';
import AlarmsCards from './AlarmsCards';
import { convertDatausageValue } from '../utils/util';

const StyledFlex = styled(Flex)`
  padding: 0.5em 4.375em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    padding: 0.2em;
  }
`;

const StyledBox = styled(Box)`
  ${(props) =>
    props.open &&
    `
    width:100%;
    height:100%;
  `} ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StatusLabel = styled(Label)`
  font-size: ${(props) => props.theme.statusLabelSize};
  &.connected {
    color: ${(props) => props.theme.statusLabelConnectedColor};
  }
  &.disconnected {
    color: ${(props) => props.theme.statusLabelDisconnectedColor};
  }
`;

const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.plugColor};
  padding: 0.1em 0.5em;
`;
const pollInterval = process.env.NODE_ENV !== 'test' ? 30 * 1000 : null;
/* multiple apollo graphql components causes easily callback hell, adopt is like Promise.All for react components */
const Composed = adopt({
  Query: ({ addToast, params, render }) => (
    <Query
      query={GET_DEVICE}
      pollInterval={pollInterval}
      variables={{ id: params.id }}
      notifyOnNetworkStatusChange
      fetchPolicy="cache-and-network"
      onError={(error) => {
        addToast({
          type: 'error',
          title: 'Error',
          message: error.message,
        });
      }}
    >
      {({ loading, error, data }) =>
        render({ queryLoading: loading, queryError: error, queryData: data })
      }
    </Query>
  ),
  AlarmQuery: ({ addToast, params, render }) => (
    <Query
      query={GET_ALARM_RULES_WITH_ALARMS}
      pollInterval={pollInterval}
      variables={{
        filter: {
          product: 'roomrobot',
          categories: ['customer'],
          priority: 'high',
        },
        alarmsLimit: 0,
        alarmsFilter: {
          sourceId: params.id,
          end: null,
        },
      }}
      notifyOnNetworkStatusChange
      fetchPolicy="cache-and-network"
      onError={(error) => {
        addToast({
          type: 'error',
          title: 'Error',
          message: error.message,
        });
      }}
    >
      {({ loading, error, data }) =>
        render({
          AlarmsQueryLoading: loading,
          AlarmsQueryError: error,
          AlarmsQueryData: data,
        })
      }
    </Query>
  ),
  AckAlarm: ({ history, addToast, params, render, ...rest }) => (
    <Mutation
      mutation={ACK_ALARM}
      onCompleted={() => {
        const message = `Alarm acknowledged successfully`;
        addToast({
          type: 'success',
          title: `Success`,
          message,
        });
      }}
      onError={(error) => {
        addToast({
          type: 'error',
          title: `Error`,
          message: error.message,
        });
      }}
    >
      {(ackAlarm, { loading, error }) =>
        render({
          ackAlarm,
          ackAlarmLoading: loading,
          ackAlarmError: error,
        })
      }
    </Mutation>
  ),
});

const MinedComposed = adopt({
  TemperatureQuery: ({ params, render }) =>
    params.temperatureVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery(['avg', 'startTime', 'count'])}
        variables={params.temperatureVariables}
      >
        {({ loading, error, data }) =>
          render({
            temperatureLoading: loading,
            error,
            temperatureData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  HumidityQuery: ({ params, render }) =>
    params.humidityVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery(['startValue', 'count'])}
        variables={params.humidityVariables}
      >
        {({ loading, error, data }) =>
          render({
            humidityLoading: loading,
            error,
            humidityData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  MovementQuery: ({ params, render }) =>
    params.movementVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery([
          'count',
          'run',
          'date',
          'startTime',
          'endTime',
          'sum',
        ])}
        variables={params.movementVariables}
      >
        {({ loading, error, data }) =>
          render({
            movementLoading: loading,
            error,
            movementData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  CO2Query: ({ params, render }) =>
    params.co2Variables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery([
          'count',
          'startValue',
          'date',
          'startTime',
          'endTime',
        ])}
        variables={params.co2Variables}
      >
        {({ loading, error, data }) =>
          render({
            co2Loading: loading,
            error,
            co2Data: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  DataUsageQuery: ({ params, render }) =>
    params.datausageVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery(['run', 'startTime', 'endTime', 'date', 'count'])}
        variables={params.datausageVariables}
      >
        {({ loading, error, data }) =>
          render({
            datausageLoading: loading,
            error,
            datausageData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  ClientsQuery: ({ params, render }) =>
    params.clientsVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery(['max', 'count', 'startTime'])}
        variables={params.clientsVariables}
      >
        {({ loading, error, data }) =>
          render({
            clientsLoading: loading,
            error,
            clientsData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  VnStatQuery: ({ params, render }) =>
    params.vnstatVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery(['run', 'count'])}
        variables={params.vnstatVariables}
      >
        {({ loading, error, data }) =>
          render({
            vnstatLoading: loading,
            error,
            vnstatData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  SpeedQuery: ({ params, render }) =>
    params.speedVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery(['avg', 'count'])}
        variables={params.speedVariables}
      >
        {({ loading, error, data }) =>
          render({
            speedLoading: loading,
            error,
            speedData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  SoundBackgroundQuery: ({ params, render }) =>
    params.soundBgVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery([
          'min',
          'variance',
          'startTime',
          'endTime',
          'count',
        ])}
        variables={params.soundBgVariables}
      >
        {({ loading, error, data }) => {
          return render({
            soundBackgrondLoading: loading,
            error,
            soundBackgroundData: data,
          });
        }}
      </Query>
    ) : (
      render({})
    ),
  SoundLastQuery: ({ params, render }) =>
    params.soundLastVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery([
          'avg',
          'variance',
          'startTime',
          'endTime',
          'count',
        ])}
        variables={params.soundLastVariables}
      >
        {({ loading, error, data }) => {
          return render({
            soundLastLoading: loading,
            error,
            soundLastData: data,
          });
        }}
      </Query>
    ) : (
      render({})
    ),
  SoundMaxQuery: ({ params, render }) =>
    params.soundMaxVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery([
          'max',
          'variance',
          'startTime',
          'endTime',
          'count',
        ])}
        variables={params.soundMaxVariables}
      >
        {({ loading, error, data }) => {
          return render({
            soundMaxLoading: loading,
            error,
            soundMaxData: data,
          });
        }}
      </Query>
    ) : (
      render({})
    ),
  RssiQuery: ({ params, render }) =>
    params.rssiVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery([
          'max',
          'variance',
          'startTime',
          'endTime',
          'count',
        ])}
        variables={params.rssiVariables}
      >
        {({ loading, error, data }) => {
          return render({
            rssiLoading: loading,
            error,
            rssiData: data,
          });
        }}
      </Query>
    ) : (
      render({})
    ),
});

class Device extends Component {
  static isPrivate = true;
  state = {
    open: this.props.match.params.view,
    deviceStatus: 'connecting...',
    start: dayjs().subtract(7, 'days').startOf('day'),
    end: dayjs().endOf('day'),
    resolution: 'hour',
  };
  _deviceConnection = null;
  _interval = null;

  componentWillUnmount() {
    const {
      match: { params },
      actions: { disconnectDevice },
    } = this.props;
    clearInterval(this._interval);
    disconnectDevice(params.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.view !== this.props.match.params.view) {
      this.setState({
        open: nextProps.match.params.view,
      });
    }
  }

  onDateChange = ({ from, to, resolution }) => {
    this.setState({
      start: dayjs(from),
      end: dayjs(to),
      resolution,
    });
  };

  openView = (view) => {
    this.props.history.push(
      `/devices/${this.props.match.params.id}/${view ? view : ''}`
    );
  };

  calculateDatausageValue = (value, vnstat, sensor) => {
    const datausageValue = convertDatausageValue(sensor, value);
    return Math.max(0, Math.round(datausageValue - vnstat));
  };
  getPointId = (sensor, name) => {
    let pointId;
    if (sensor) {
      const datapoint = _filter(_get(sensor, 'datapoints', []), {
        name,
      })[0];
      pointId = datapoint && datapoint.id ? datapoint.id : null;
    }

    return pointId;
  };

  formatX = (date, resolution) => {
    if (resolution === 'minute') return dayjs(date).format('HH:mm');
    if (resolution === 'hour') return dayjs(date).format('D.M HH:mm');
    if (resolution === 'day') return dayjs(date).format('D.M');
    if (resolution === 'month') return dayjs(date).format('MM/YYYY');
    if (resolution === 'year') return dayjs(date).format('YYYY');

    return date;
  };
  handleConnectionStatus = (queryData) => {
    const {
      actions: { connectDevice },
    } = this.props;
    this._deviceConnection = connectDevice(queryData.device)
      .then((v) => {
        this.setState({
          deviceStatus: 'connected',
        });
        return v;
      })
      .catch((err) => {
        this.setState({
          deviceStatus: 'disconnected',
        });
      });
  };
  render() {
    const {
      match: { params },
      history,
      state: { user, device, navigationItems },
      actions: { rebootDevice, logout, repeaterSetup },
      auth,
      tagObjects,
    } = this.props;

    const { open, deviceStatus, start, end, resolution } = this.state;
    const serviceDesk = _get(
      tagObjects,
      'organization.meta.public.serviceDesk',
      false
    );
    const serviceDeskOrganizationId = _get(
      tagObjects,
      'organization.meta.public.serviceDeskOrganizationId',
      ''
    );
    return (
      <SharedToasterConsumer>
        {({ addToast }) => (
          <Composed addToast={addToast} params={params} history={history}>
            {({
              Query: { queryLoading, queryError, queryData }, //loading and error states has own names
              AlarmQuery: {
                AlarmsQueryLoading,
                AlarmsQueryError,
                AlarmsQueryData,
              }, //loading and error states has own names
              AckAlarm: { ackAlarm, ackAlarmError, ackAlarmLoading }, //loading and error states has own names
            }) => {
              if (
                !queryLoading &&
                !queryError &&
                queryData.device &&
                !device &&
                !this._deviceConnection
              ) {
                this.handleConnectionStatus(queryData);
                this._interval = setInterval(() => {
                  this.handleConnectionStatus(queryData);
                }, 15 * 1000);
              }
              let _loading = queryLoading;

              const sensorDatausage = _filter(
                _get(queryData, 'device.sensors', []),
                {
                  type: _get(queryData, 'device.sensors', []).some(
                    (s) => s.type === 'Router'
                  )
                    ? 'Router'
                    : 'HuaweiRouter',
                }
              )[0];

              const sensorRoomMeasurement = _filter(
                _get(queryData, 'device.sensors', []),
                {
                  type: 'LansenCO2TemperatureHumidity',
                }
              )[0];

              const useBleSensorUnit = _filter(
                _get(queryData, 'device.sensors', []),
                {
                  type: 'BleSensorUnit',
                }
              )[0];

              const bleFilter = useBleSensorUnit
                ? 'BleSensorUnit'
                : 'Ble12VDCPowerControlUnit';

              const sensorTemperatureMeasurement = _filter(
                _get(queryData, 'device.sensors', []),
                (o) => o.type === bleFilter
              )[0];

              const sensorMovement = _filter(
                _get(queryData, 'device.sensors', []),
                (o) => o.type === bleFilter
              )[0];

              const sensorSoundMeasurement = _filter(
                _get(queryData, 'device.sensors', []),
                (o) => o.type === bleFilter
              )[0];

              const temperatureId = this.getPointId(
                sensorRoomMeasurement || sensorTemperatureMeasurement,
                'temperature'
              );

              const humidityId = this.getPointId(
                sensorRoomMeasurement || sensorTemperatureMeasurement,
                'humidity'
              );

              const movementId = this.getPointId(
                sensorMovement,
                'pirEventCount'
              );
              const co2Id = this.getPointId(
                sensorRoomMeasurement || sensorTemperatureMeasurement,
                'co2'
              );

              const datausageId = this.getPointId(sensorDatausage, 'datausage');
              const rssiId = this.getPointId(sensorDatausage, 'routerrssi');
              const speedId = this.getPointId(sensorDatausage, 'speed');
              const soundBgId = this.getPointId(
                sensorSoundMeasurement,
                'soundBackgroundLevel'
              );
              const soundLastId = this.getPointId(
                sensorSoundMeasurement,
                'soundAverageLevel'
              );
              const soundMaxId = this.getPointId(
                sensorSoundMeasurement,
                'soundMaxLevel'
              );
              const clientId = this.getPointId(sensorDatausage, 'clients');
              const vnStatId = this.getPointId(
                sensorDatausage,
                'vnstatdatausage'
              );

              const variables = {
                filter: {
                  resolution: resolution,
                  interpolation: 'linear',
                  decimals: 3,
                  fill: 'linear',
                  timestamp: {
                    gte: start,
                    lte: end,
                  },
                },
              };

              return (
                <DashboardDefaultPage
                  navigationItems={navigationItems}
                  /*onEditClick={
                        !open
                          ? () => history.push(`/devices/${params.id}/edit`)
                          : null
                      }*/
                  addTitle={'Set state'}
                  gqlData={queryData && queryData.device}
                  user={user}
                  {...this.props}
                  customActions={
                    <Flex
                      style={{
                        border: '1px solid gray',
                        paddingRight: '1em',
                        borderRadius: '1em',
                      }}
                      alignItems="center"
                    >
                      <StyledIcon icon={'plug'} size={'0.9em'} />
                      <StatusLabel className={deviceStatus}>
                        {deviceStatus}
                      </StatusLabel>
                    </Flex>
                  }
                  loading={queryLoading || _loading}
                  error={queryError}
                  title={queryData && queryData.device && queryData.device.name}
                  subtitle={
                    queryData && queryData.device && queryData.device.id
                  }
                  actions
                  onProfileClick={(v) => history.push(`/users/${v.uid}/edit`)}
                  onLogoutClick={logout}
                  onBack={history.goBack}
                >
                  <StyledFlex flexWrap="wrap">
                    <StyledBox p={'.2em'} flex="1 1 auto" width={[1]} s>
                      <InfoCard
                        deviceStatus={deviceStatus}
                        title="Device info"
                        rebootDevice={rebootDevice}
                        repeaterSetup={repeaterSetup}
                        info={queryData}
                        addToast={addToast}
                        tagObjects={tagObjects}
                        user={auth.user}
                      />
                      {serviceDesk && serviceDeskOrganizationId && (
                        <SupportCard
                          title="Support"
                          addToast={addToast}
                          tagObjects={tagObjects}
                          user={auth.user}
                        />
                      )}
                      {AlarmsQueryData &&
                        AlarmsQueryData.publicAlarmRules &&
                        AlarmsQueryData.publicAlarmRules.edges && (
                          <AlarmsCards
                            alarmRules={AlarmsQueryData.publicAlarmRules.edges}
                            onAck={ackAlarm}
                            alarmsLoading={AlarmsQueryLoading}
                            ackLoading={ackAlarmLoading}
                            user={auth.user && auth.user.email}
                          />
                        )}
                    </StyledBox>

                    {/* 
                    todo
                    <ReservationsCard
                      hidden={open && open !== 'reservations'}
                      onZoom={() =>
                        this.openView(
                          open ? null : 'reservations'
                        )
                      }
                      loading={queryLoading}
                      device={queryData && queryData.device}
                    /> */}

                    <MinedComposed
                      params={{
                        datausageVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: datausageId,
                            },
                          }
                        ),
                        speedVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: speedId,
                            },
                          }
                        ),
                        soundBgVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: soundBgId,
                            },
                          }
                        ),
                        soundLastVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: soundLastId,
                            },
                          }
                        ),
                        soundMaxVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: soundMaxId,
                            },
                          }
                        ),
                        clientsVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: clientId,
                            },
                          }
                        ),
                        vnstatVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: vnStatId,
                            },
                          }
                        ),
                        temperatureVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: temperatureId,
                            },
                          }
                        ),
                        humidityVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: humidityId,
                            },
                          }
                        ),
                        movementVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: movementId,
                              interpolation: 'flat',
                            },
                          }
                        ),
                        co2Variables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: co2Id,
                            },
                          }
                        ),
                        rssiVariables: Object.assign(
                          {},
                          {
                            filter: {
                              ...variables.filter,
                              pointId: rssiId,
                            },
                          }
                        ),
                      }}
                    >
                      {({
                        DataUsageQuery: { datausageData, datausageLoading },
                        SpeedQuery: { speedData, speedLoading },
                        SoundBackgroundQuery: {
                          soundBackgroundData,
                          soundBackgrondLoading,
                        },
                        SoundLastQuery: { soundLastData, soundLastLoading },
                        SoundMaxQuery: { soundMaxData, soundMaxLoading },
                        ClientsQuery: { clientsData, clientsLoading },
                        VnStatQuery: { vnstatData, vnstatLoading },
                        TemperatureQuery: {
                          temperatureData,
                          temperatureLoading,
                        },
                        HumidityQuery: { humidityData, humidityLoading },
                        MovementQuery: { movementData, movementLoading },
                        CO2Query: { co2Data, co2Loading },
                        RssiQuery: { rssiData, rssiLoading },
                      }) => {
                        let minedRoomMeasurements = [];
                        let minedPresence = [];
                        let minedSoundMeasurements = [];

                        const usageLabel = 'Data Usage';
                        const speedLabel = 'Average Speed Mb/s';
                        const clientsLabel = 'Clients';
                        const vnStatLabel = 'Vnstat';

                        let minedUsage,
                          minedSpeed,
                          minedRSSI,
                          minedBgSound,
                          minedLastSound,
                          minedMaxSound,
                          minedClients,
                          minedVnstat,
                          minedTemperature,
                          minedHumidity,
                          minedMovement,
                          minedCo2;

                        let minedData = [];
                        let loading =
                          datausageLoading ||
                          speedLoading ||
                          clientsLoading ||
                          vnstatLoading ||
                          temperatureLoading ||
                          humidityLoading ||
                          movementLoading ||
                          soundBackgrondLoading ||
                          soundLastLoading ||
                          soundMaxLoading ||
                          co2Loading ||
                          rssiLoading;

                        if (!loading) {
                          minedUsage =
                            _get(datausageData, 'mineSensorValues', []) || [];
                          minedSpeed =
                            _get(speedData, 'mineSensorValues', []) || [];
                          minedRSSI =
                            _get(rssiData, 'mineSensorValues', []) || [];
                          minedClients =
                            _get(clientsData, 'mineSensorValues', []) || [];

                          minedVnstat =
                            _get(vnstatData, 'mineSensorValues', []) || [];

                          minedData = minedUsage.map((o, i) => {
                            const clients =
                              minedClients[i] && minedClients[i].count > 0
                                ? Math.ceil(minedClients[i].max)
                                : null;

                            const vnstat = minedVnstat[i]
                              ? minedVnstat[i].run
                              : 0;

                            const datausage = this.calculateDatausageValue(
                              o.run,
                              vnstat,
                              sensorDatausage
                            );

                            const valid = dayjs(o.startTime) <= dayjs();
                            return {
                              ...o,
                              formattedDate: this.formatX(
                                o.startTime,
                                resolution
                              ),
                              [usageLabel]: clients > 0 ? datausage : 0,
                              [speedLabel]:
                                minedSpeed[i] && valid
                                  ? Math.round((minedSpeed[i].avg * 8) / 1000)
                                  : null,
                              [clientsLabel]: valid ? clients : null,
                              [vnStatLabel]: vnstat,
                            };
                          });

                          minedTemperature =
                            _get(temperatureData, 'mineSensorValues', []) || [];

                          minedHumidity =
                            _get(humidityData, 'mineSensorValues', []) || [];

                          minedRoomMeasurements = minedTemperature.map(
                            (o, i) => ({
                              formattedDate: this.formatX(
                                o.startTime,
                                resolution
                              ),
                              temperature: o.count ? o.avg : null,
                              humidity:
                                minedHumidity[i] && minedHumidity[i].count
                                  ? minedHumidity[i].startValue
                                  : null,
                            })
                          );

                          minedBgSound =
                            _get(soundBackgroundData, 'mineSensorValues', []) ||
                            [];
                          minedLastSound =
                            _get(soundLastData, 'mineSensorValues', []) || [];
                          minedMaxSound =
                            _get(soundMaxData, 'mineSensorValues', []) || [];

                          let dbmA = minedBgSound;
                          if (minedLastSound.length > dbmA.length)
                            dbmA = minedLastSound;
                          if (minedMaxSound.length > dbmA.length)
                            dbmA = minedMaxSound;

                          minedSoundMeasurements = dbmA.map((o, i) => {
                            return {
                              formattedDate: this.formatX(
                                o.startTime,
                                resolution
                              ),
                              soundBackgroundLevel:
                                minedBgSound[i] && minedBgSound[i].count
                                  ? minedBgSound[i].min
                                  : null,
                              soundAverage:
                                minedLastSound[i] && minedLastSound[i].count
                                  ? minedLastSound[i].avg
                                  : null,
                              soundMaxLevel:
                                minedMaxSound[i].count && minedMaxSound[i].count
                                  ? minedMaxSound[i].max
                                  : null,
                            };
                          });

                          minedMovement =
                            _get(movementData, 'mineSensorValues', []) || [];

                          minedCo2 =
                            _get(co2Data, 'mineSensorValues', []) || [];

                          const s =
                            minedMovement.length > minedCo2.length
                              ? minedMovement
                              : minedCo2;

                          minedPresence = s.map((o, i) => {
                            return {
                              date: this.formatX(o.startTime, resolution),
                              movement:
                                minedMovement[i] && minedMovement[i].count
                                  ? minedMovement[i].run
                                  : null,
                              co2:
                                minedCo2[i] && minedCo2[i].count
                                  ? minedCo2[i].startValue
                                  : null,
                            };
                          });
                        }

                        //  if(minedRSSI && minedRSSI && minedRSSI[minedRSSI.length -1])  console.log(minedRSSI[minedRSSI.length -1].max)

                        return (
                          <React.Fragment>
                            <SignalStrengthCard
                              loading={loading}
                              connected={deviceStatus === 'connected'}
                              mined={
                                minedRSSI &&
                                minedRSSI &&
                                minedRSSI[minedRSSI.length - 1] &&
                                minedRSSI[minedRSSI.length - 1].max
                              }
                            />
                            <SpeedCard
                              subtitle="Network speed"
                              loading={loading}
                              device={queryData && queryData.device}
                              deviceConnection={this.props.state.device}
                              toaster={{ addToast }}
                              connected={deviceStatus === 'connected'}
                              minedSpeed={_get(
                                queryData,
                                'device.meta.mining.24h.speed.avg',
                                null
                              )}
                            />
                            {!queryLoading && (
                              <SpeedTestCard
                                subtitle="Speed Test"
                                loading={loading}
                                device={queryData && queryData.device}
                                toaster={{ addToast }}
                                connected={deviceStatus === 'connected'}
                              />
                            )}

                            <PersonDetectCard
                              hidden={open && open !== 'presence'}
                              onZoom={() =>
                                this.openView(open ? null : 'presence')
                              }
                              loading={loading}
                              device={queryData && queryData.device}
                              mined={{
                                pirEventCount: minedMovement,
                                soundBackgroundLevel: minedBgSound,
                                soundLastLevel: minedLastSound,
                                soundMaxLevel: minedMaxSound,
                                datausage: minedUsage,
                                clients: minedClients,
                                temperature: minedTemperature,
                                humidity: minedHumidity,
                                co2: minedCo2,
                                vnstatdatausage: minedVnstat,
                              }}
                              start={start}
                              end={end}
                            />

                            {sensorDatausage && (
                              <DataUsageCard
                                onDateChange={this.onDateChange}
                                hidden={open && open !== 'datausage'}
                                onZoom={() =>
                                  this.openView(open ? null : 'datausage')
                                }
                                loading={loading}
                                device={queryData && queryData.device}
                                start={start}
                                end={end}
                                resolution={resolution}
                                mined={minedData}
                              />
                            )}

                            {(sensorRoomMeasurement || sensorMovement) && (
                              <PresenceGraphCard
                                onDateChange={this.onDateChange}
                                hidden={open && open !== 'presence'}
                                onZoom={() =>
                                  this.openView(open ? null : 'presence')
                                }
                                loading={loading}
                                device={queryData && queryData.device}
                                mined={minedPresence}
                                start={start}
                                end={end}
                                resolution={resolution}
                              />
                            )}

                            {(sensorTemperatureMeasurement ||
                              sensorRoomMeasurement) && (
                              <RoomMeasurementsCard
                                onDateChange={this.onDateChange}
                                hidden={open && open !== 'room'}
                                onZoom={() =>
                                  this.openView(open ? null : 'room')
                                }
                                loading={loading}
                                mined={minedRoomMeasurements}
                                device={queryData && queryData.device}
                                start={start}
                                end={end}
                                resolution={resolution}
                              />
                            )}
                            {sensorSoundMeasurement && (
                              <SoundGraphCard
                                onDateChange={this.onDateChange}
                                hidden={open && open !== 'room'}
                                onZoom={() =>
                                  this.openView(open ? null : 'room')
                                }
                                loading={loading}
                                mined={minedSoundMeasurements}
                                device={queryData && queryData.device}
                                start={start}
                                end={end}
                                resolution={resolution}
                              />
                            )}
                          </React.Fragment>
                        );
                      }}
                    </MinedComposed>
                  </StyledFlex>
                </DashboardDefaultPage>
              );
            }}
          </Composed>
        )}
      </SharedToasterConsumer>
    );
  }
}

export default withRouter(withLivionConnect(Device));
