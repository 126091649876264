import React, { useCallback, useContext, useState, useEffect } from 'react';
import _get from 'lodash/get';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { useQuery, useMutation } from '@apollo/react-hooks';
import createDecorator from 'final-form-calculate';

import { GET_TAG } from '../api/queries/tags.js';
import { UPDATE_DEVICE, UNINSTALL_DEVICE } from '../api/mutations/devices';
import { GET_DEVICE } from '../api/queries/devices';
import { apolloClient } from '@components/connect-react-lib';
import {
  validSSID,
  required,
  passwordLengthMax,
  passwordLengthMin,
} from '@components/utils';
import ToasterContext from '@components/contexts/ToasterContext';
import DashboardFlexibleFormPage from '@components/pages/DashboardFlexibleFormPage';
import Block from '@components/atoms/Block';
import Input from '@components/atoms/Input';
import Fieldset from '@components/atoms/Fieldset';
import TextField from '@components/fields/TextField';
import TagSelectSearch from '@components/molecules/TagSelectSearch';

import Modal from '@molecules/Modal';
import Button from '@components/atoms/Button';
import { countries } from '../defaults';
import { createSsid } from '../utils/ssid';

const ConfirmModal = styled(Modal)``;
const StyledBlock = styled(Block)`
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${({ theme }) =>
      _get(theme, 'breakpoints[0]', '40em')}) {
    padding: 0.4em;
  }
`;
const StyledHint = styled.div`
  font-size: 1.2em;
  text-align: center;
  color: #fff;
`;
const StyledLabel = styled.div`
  font-size: 1.2em;
  color: #fff;
`;

const StyledField = styled(Flex)`
  padding: 1em 1em;
`;
const StyledFieldLabel = styled(Box)`
  padding-right: 10px;
`;
const StyledInput = styled(Box)`
  padding-right: 1em;
`;
const StyledError = styled(Box)`
  color: ${({ theme }) => theme.internetDisabledColor};
`;
const timeout = 24 * 60 * 60;

const apartmentDecorator = createDecorator({
  field: 'tag', // when a field matching this pattern changes...
  updates: {
    address: async (ignoredValue, allValues) => {
      const res = await apolloClient.query({
        query: GET_TAG,
        variables: { id: allValues.tag },
        skip: !allValues.tag,
      });
      return _get(res, 'data.tag.meta.apartment.address', '');
    },
    country: async (ignoredValue, allValues) => {
      const res = await apolloClient.query({
        query: GET_TAG,
        variables: { id: allValues.tag },
        skip: !allValues.tag,
      });
      return countries[
        _get(res, 'data.tag.meta.apartment.countryCode', '').toUpperCase()
      ];
    },
    area: async (ignoredValue, allValues) => {
      const res = await apolloClient.query({
        query: GET_TAG,
        variables: { id: allValues.tag },
        skip: !allValues.tag,
      });
      return _get(res, 'data.tag.meta.apartment.officeName', '');
    },
  },
});

export const Apartment = ({
  history,
  match: {
    params,
    params: { id, apartment },
  },
  tagObjects: { organization },
}) => {
  const { addToast } = useContext(ToasterContext);
  const [apartmentId, setApartmentId] = useState(null);
  const [warning, setWarning] = useState(false);
  const [visible, setVisible] = useState(null);
  const [networkName, setNetworkName] = useState('');
  const [password, setPassword] = useState('');
  const [oldNetworkName, setOldNetworkName] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [networkNameError, setNetworkNameError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const ssidPrefix = _get(
    organization,
    'meta.public.defaultSsidPrefix',
    'RoomRobot'
  );
  const defaultWifiPassword = _get(
    organization,
    'meta.public.defaultWifiPassword',
    `RoomRobot${new Date().getFullYear()}`
  );

  const { data: tagData, error: tagError, loading: tagLoading } = useQuery(
    GET_TAG,
    {
      variables: { id: apartmentId || decodeURIComponent(apartment) },
      skip: !(apartmentId || decodeURIComponent(apartment)),
    }
  );
  const {
    data: deviceData,
    error: deviceError,
    loading: deviceLoading,
  } = useQuery(GET_DEVICE, {
    variables: { id },
    skip: !id,
  });

  const [updateDevice] = useMutation(UPDATE_DEVICE);
  const [uninstallDevice] = useMutation(UNINSTALL_DEVICE);

  const orgSettings = _get(deviceData, 'device.settings.data', {});
  const orgSettingsWlan = _get(
    deviceData,
    'device.settings.data.huawei-router.wlan',
    {}
  );
  useEffect(() => {
    const wlan = _get(
      deviceData,
      'device.settings.data.huawei-router.wlan',
      {}
    );
    setOldNetworkName(wlan.networkname);
    setOldPassword(wlan.password);
  }, [deviceData]);
  useEffect(() => {
    setNetworkName(
      `${ssidPrefix} ${createSsid(_get(tagData, 'tag.meta.apartment'))
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')}`
    );
    setPassword(defaultWifiPassword);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagData]);

  const submit = useCallback(() => {
    const { tag } = visible;
    updateDevice({
      variables: {
        id,
        input: {
          tag,
          name: _get(tagData, 'tag.name'),
          settings: {
            ...orgSettings,
            'huawei-router': {
              wlan: {
                ...orgSettingsWlan,
                state: true,
                networkname: networkName,
                password,
              },
            },
          },
        },
      },
    })
      .then(() => {
        setVisible(null);
        addToast({
          type: 'success',
          title: `Success`,
          message: `Device apartment updated successfully`,
        });
      })
      .catch((error) => {
        addToast({
          type: 'error',
          title: `Error`,
          message: `Device apartment update failed ${error.message}`,
          timeout,
        });
      });
  }, [
    addToast,
    id,
    networkName,
    orgSettings,
    orgSettingsWlan,
    password,
    tagData,
    updateDevice,
    visible,
  ]);
  const uninstall = useCallback(
    (e) => {
      e.preventDefault();
      uninstallDevice({
        variables: {
          id,
          input: {
            name: `Roomrobot`,
          },
        },
      })
        .then(() => {
          setWarning(null);
          addToast({
            type: 'success',
            title: `Success`,
            message: `Apartment successfully uninstalled from device`,
          });
          history.push('/');
        })
        .catch((error) => {
          setWarning(null);
          addToast({
            type: 'error',
            title: `Error`,
            message: `Apartment uninstallation failed ${error.message}`,
            timeout,
          });
        });
    },
    [addToast, history, id, uninstallDevice]
  );

  const handleUninstall = useCallback((e) => {
    e.preventDefault();
    setWarning(true);
  }, []);

  const handleSubmit = useCallback((values) => {
    setVisible(values);
  }, []);

  let title;

  if (tagError || deviceError) {
    addToast({
      type: 'error',
      title: `Error`,
      message: tagError.message || deviceError.message,
      timeout,
    });
  }

  if (tagData && tagData.tag) {
    title = `${_get(tagData, 'tag.name')}`;
  }

  return (
    <DashboardFlexibleFormPage
      initialValues={{
        tag: _get(tagData, 'tag.id'),
        name: _get(tagData, 'tag.name'),
        address: _get(tagData, 'tag.meta.apartment.address'),
        country: _get(tagData, 'tag.meta.apartment.country'),
        area: _get(tagData, 'tag.meta.apartment.area'),
      }}
      onSubmit={handleSubmit}
      title={title}
      subtitle=" "
      onBack={history.goBack}
      loading={tagLoading || deviceLoading}
      decorators={[apartmentDecorator]}
    >
      {({ form }) => (
        <StyledBlock>
          <Fieldset label="Change apartment">
            <StyledHint>
              Enter apartment id or use apartment tree to look for apartment in
              specific country/area.
            </StyledHint>
            <TagSelectSearch
              validate={required}
              name="tag"
              label="Apartment ID or Address"
              labelKey="name"
              valueKey="id"
              validateOnInit={true}
              initValue={_get(tagData, 'tag.id')}
              closeMenuOnSelect={true}
              onChange={(value) => {
                // console.log('updating apartment', value);
                setApartmentId(value);
              }}
            />
            <TextField
              name="address"
              label="Address"
              validateOnInit={true}
              disabled={true}
            />
            <TextField
              name="country"
              label="Country"
              validateOnInit={true}
              disabled={true}
            />
            <TextField
              name="area"
              label="Area"
              validateOnInit={true}
              disabled={true}
            />
          </Fieldset>
          <Fieldset label="Uninstall from apartment">
            <Flex alignItems="center" justifyContent="space-between">
              <Box width={[2 / 12]}>
                <Button onClick={handleUninstall} secondary>
                  Uninstall
                </Button>
              </Box>
              <Box width={[10 / 12]}>
                <StyledLabel>
                  Uninstalling roomrobot will deactivate temporarely its
                  internet subscription. Also roomrobot will not be accessible
                  from Devices list until it is installed again.
                </StyledLabel>
              </Box>
            </Flex>
          </Fieldset>
          {warning && (
            <ConfirmModal
              header={`Apartment uninstall warning`}
              submitText="Uninstall roomrobot"
              onClose={() => setWarning(null)}
              onSave={uninstall}
              closeColor="#00BCF1"
            >
              Uninstalling roomrobot will deactivate temporarely its internet
              subscription. Also roomrobot will not be accessible from Devices
              list until it is installed again.{'\n'} Are you sure you want to
              proceed ?
            </ConfirmModal>
          )}
          {visible && (
            <ConfirmModal
              header={`Apartment change confirmation`}
              submitText="Change Apartment"
              cancelText="Cancel"
              onClose={() => setVisible(null)}
              onSave={submit}
              submitDisabled={
                !networkName || !password || networkNameError || passwordError
              }
              closeColor="#00BCF1"
            >
              You are about to change this roomrobot appartment. If you wish to
              proceed, the roomrobot Wifi settings will be changed as follows.
              Feel free to modify the settings.
              <StyledField alignItems="center" justifyContent="start">
                <StyledFieldLabel width={[1 / 4]}>
                  Old Wifi SSID :{' '}
                </StyledFieldLabel>
                <StyledFieldLabel width={[1 / 4]}>
                  {oldNetworkName}
                </StyledFieldLabel>
                <StyledFieldLabel width={[1 / 4]}>
                  New Wifi SSID :{' '}
                </StyledFieldLabel>
                <StyledInput>
                  <Input
                    value={networkName}
                    onChange={(e) => {
                      setNetworkName(
                        e.target.value
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                      );
                      setNetworkNameError(
                        required(e.target.value) ||
                          validSSID(
                            e.target.value
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                          )
                      );
                    }}
                  />
                </StyledInput>
                {networkNameError && (
                  <StyledError>{networkNameError}</StyledError>
                )}
              </StyledField>
              <StyledField alignItems="center" justifyContent="start">
                <StyledFieldLabel width={[1 / 4]}>
                  Old Wifi SSID :{' '}
                </StyledFieldLabel>
                <StyledFieldLabel width={[1 / 4]}>
                  {oldPassword}
                </StyledFieldLabel>
                <StyledFieldLabel width={[1 / 4]}>
                  New Wifi Password :{' '}
                </StyledFieldLabel>
                <StyledInput>
                  <Input
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError(
                        required(e.target.value) ||
                          passwordLengthMin(
                            e.target.value || passwordLengthMax(e.target.value)
                          )
                      );
                    }}
                  />
                </StyledInput>
                {passwordError && <StyledError>{passwordError}</StyledError>}
              </StyledField>
            </ConfirmModal>
          )}
        </StyledBlock>
      )}
    </DashboardFlexibleFormPage>
  );
};

export default withRouter(Apartment);
