export const routerCommands = [
  {
    label: 'Reboot modem',
    command: 'modem-reboot',
    description: 'Restarts router only',
    loader: true,
  },
  {
    label: 'RF modem',
    command: 'modem-rf',
    description: 'Factory resets router',
    loader: false,
  },
  {
    label: 'Lte modem auto',
    command: 'modem-lte-auto-connect',
    description: 'Allows an automatic setup of LTE modem',
    loader: false,
  },
  {
    label: 'Lte modem reset',
    command: 'modem-lte-reset',
    loader: true,
    description: 'Resets LTE modem and allow an automatic setup of LTE modem',
  },
  {
    label: 'LTE AT command',
    command: 'modem-at-command',
    input: true,
    placeholder: 'Type custom AT command',
    description: 'Sends custom AT command',
    result: true,
    timeout: 5000,
    variable: 'Modem-AT-COMMAND',
  },
  {
    label: 'LTE band',
    command: 'modem-at-command',
    options: [
      {
        value: 'AT+QCFG="band"',
        label: 'Get band',
        loader: false,
      },
      {
        value: 'AT+COPS',
        label: 'Register',
        loader: false,
      },
      {
        value: 'AT+QCFG="band",d3,1a0000800d5,0',
        label: 'Set default band',
        loader: true,
      },
      {
        value: 'AT+QCFG="band",d3,1a000080000,0',
        label: 'B20 - 800 MHz',
        loader: true,
      },
      {
        value: 'AT+QCFG="band",d3,1a000000004,0',
        label: 'B7 - 1800 MHz',
        loader: true,
      },
      {
        value: 'AT+QCFG="band",d3,1a000000044,0',
        label: 'B7/B3 - 1800/2600 MHz',
        command: 'AT+QCFG="band",d3,1a000000044,0',
        loader: true,
      },
    ],
    placeholder: 'Select band setting',
    description:
      'Forces specific LTE band to be used, auto will let device select itself the best band',
    result: true,
    timeout: 5000,
    variable: 'Modem-AT-COMMAND',
  },
];
export const deviceCommands = [
  {
    label: 'Reboot device',
    command: 'reboot-device',
    description: 'Reboots device (Modem + Raspberry)',
  },
];
