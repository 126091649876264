import gql from 'graphql-tag';

export const UPDATE_DEVICE = gql`
  mutation Mutation($id: String!, $input: DeviceInput!) {
    updateDevice(id: $id, input: $input) {
      id
      tag
      name
      meta
    }
  }
`;