import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
0% { 
  transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
} 50% { 
  transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
} 100% { 
  transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
}
`;

/**
 * @render react
 * @name LoaderCube
 * @description LoaderCube component
 * @example
 * <LoaderCube />
 */
const LoaderCube = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.loaderBackground};

  margin: 100px auto;
  animation: ${rotate} 1.2s infinite ease-in-out;
`;

LoaderCube.propTypes = {};

LoaderCube.defaultProps = {
  theme: {
    loaderBackground: '#0789CC',
  },
};

export default LoaderCube;
