import PropTypes from 'prop-types';
import styled from 'styled-components';
import c from 'color';

import Button from '../Button';
/**
 * @render react
 * @description Styled ToggleButton component
 *
 * @example
 * <ToggleButton>Toggle</ToggleButton>
 */
const ToggleButton = styled(Button)`
  background: ${props =>
    props.selected
      ? props.theme.toggleButtonSelected
      : props.theme.toggleButtonNotSelected}
  border: ${props => props.theme.toggleButtonBorder};
  color: ${props =>
    props.selected
      ? props.theme.toggleButtonTextColorSelected
      : props.theme.toggleButtonTextColorNotSelected}
  font-weight: 500;
  //border-radius: 0;
  //border: 1px solid #cccccc;
  margin: 0;
  margin-right: 0.5em;
  &:hover {
    background: ${props => (props.selected ? '#cccccc' : '#fff')};
    color: ${props => (props.selected ? 'rgba(0,0,0,.54)' : '#cccccc')};
    //border: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

ToggleButton.propTypes = {
  /**
   * @property {propTypes.bool} disabled - button to disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * @property {propTypes.bool} small - small button.
   */
  small: PropTypes.bool,
  /**
   * @property {propTypes.bool} selected - button toggled.
   */
  selected: PropTypes.bool,
};

export default ToggleButton;
