import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import InputLabel from '../../atoms/InputLabel';
import Input from '../../atoms/Input';
import Error from '../../atoms/Error';
import InputWrapper from '../../atoms/InputWrapper';
import { composeValidators } from '../../utils';
import Hint from '../utils/Hint';

const TextField = ({
  name,
  validate,
  label,
  optional,
  onChange,
  wrapperStyles,
  validateOnInit,
  required,
  ...attributes
}) => (
  <Field name={name} validate={composeValidators(validate)}>
    {({ input, meta }) => (
      <InputWrapper style={wrapperStyles} data-cy={name}>
        {label && <InputLabel htmlFor={input.name}>{label}</InputLabel>}
        <Input
          {...attributes}
          {...input}
          onChange={event => {
            if (onChange) onChange(event);
            input.onChange(event);
          }}
          invalid={(meta.touched || validateOnInit) && meta.error}
        />

        {(meta.touched || validateOnInit) && meta.error && (
          <Error id={`${input.name}Error`} role="alert" palette="danger">
            {meta.error}
          </Error>
        )}
        {!((meta.touched || validateOnInit) && meta.error) && required && (
          <Hint role="info">Required</Hint>
        )}
        {!((meta.touched || validateOnInit) && meta.error) && optional && (
          <Hint role="info">Optional</Hint>
        )}
      </InputWrapper>
    )}
  </Field>
);

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  validateOnInit: PropTypes.bool,
};

TextField.defaultProps = {
  label: null,
  onChange: null,
  validate: () => {},
};

export default TextField;
