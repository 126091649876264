import React, { useState, useEffect } from 'react';
import DashboardDefaultPage from '@components/pages/DashboardDefaultPage';
import MdViewer from '@components/organisms/MdViewer';
import { withRouter } from 'react-router-dom';
import { withLivionConnect } from '@components/contexts/LivionConnectContext';

import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import CircleButton from '@components/atoms/CircleButton';
import Label from '@components/atoms/Label';
import axios from 'axios';

const Button = styled(Flex)`
  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
  label {
    cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
  }
`;

const StyledLabel = styled(Label)`
  text-transform: none;
  color: ${(props) =>
    props.offset ? props.theme.background : props.theme.subtitleColor};
  font-weight: bold;
  margin-right: 0.75em;
`;

export const Privacy = ({
  history,
  state: { user, navigationItems },
  actions: { logout },
}) => {
  const [lang, setlang] = useState('En');
  const [file, setFile] = useState('# Loading...');

  useEffect(() => {
    const fetchDocument = async () => {
      const res = await axios.get(
        'https://storage.googleapis.com/livionconnect-public/LivionPrivacyPolicy' +
          lang +
          '.md'
      );
      setFile(res.data);
    };
    fetchDocument();
  }, [lang]);

  return (
    <DashboardDefaultPage
      navigationItems={navigationItems}
      user={user}
      title="Privacy Policy"
      subtitle="Livion Oy"
      onProfileClick={(v) => history.push(`/users/${v.uid}/edit`)}
      onLogoutClick={logout}
      onBack={history.goBack}
      customActions={
        <Button
          alignItems="center"
          justifyContent="flex-end"
          onClick={() => setlang(lang === 'Fi' ? 'En' : 'Fi')}
        >
          <Box>
            <StyledLabel>Change language</StyledLabel>
          </Box>
          <Box>
            <CircleButton>{lang === 'Fi' ? 'EN' : 'FI'}</CircleButton>
          </Box>
        </Button>
      }
    >
      <MdViewer file={file} />
    </DashboardDefaultPage>
  );
};

export default withRouter(withLivionConnect(Privacy));
