import React, { useState } from 'react';

import styled from 'styled-components';

import { GET_USERS } from '../api/queries/users';
import { withQS } from '@components/contexts/QSContext';
import DashboardListPage from '@components/pages/DashboardListPage';

import Label from '@components/atoms/Label';
import Icon from '@components/atoms/Icon';
import Link from '@atoms/Link';
import get from 'lodash/get';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${(props) => props.theme.tertiaryThemeColor};
`;

const StyledLabel = styled(Label)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
`;
const MessageLabel = styled(StyledLabel)`
  text-transform: none;
  font-size: 0.8em;
  display: block;
`;

const StyledPermissionIcon = styled(Icon)`
  background: ${(props) => props.theme.tertiaryThemeColor};
  margin-right: 0.4em;
  fill: #fff;
  border-radius: 50px;
`;

export const Users = ({ auth, history, location, getQueryString }) => {
  const role = 'roomrobot-admin';
  const xTag = auth.getSelectedTag();
  const [tagSelected, setTagSelected] = useState(
    !!getQueryString(location).tag
  );

  let variables = {
    filter: { role, tagWithParents: getQueryString(location).tag },
    sort: { 'acl.path': 1 },
  };

  const onTagChanged = (tag) => setTagSelected(!!tag);

  return (
    <DashboardListPage
      useFlexibleSearch={false}
      variables={variables}
      fetchPolicy={'no-cache'}
      tagsSidebar={{
        isOpenedByDefault: true,
        showSidebar: true,
        onTagChanged,
        buttonText: 'Areas',
      }}
      context="users"
      query={GET_USERS}
      actions={[
        {
          order: 1,
          title: 'Create',
          icon: <Icon icon="plus" size="2em" />,
          onClick: () =>
            history.push({
              pathname: '/users/create',
              search: get(history, 'location.search'),
            }),
        },
      ]}
      columns={[
        {
          name: 'User',
          hide: ['sm', 'md', 'lg'],
          render: (data) => (
            <StyledLabel>
              {data.email}
              <MessageLabel>{data.role}</MessageLabel>
              <MessageLabel>{data.tag}</MessageLabel>
            </StyledLabel>
          ),
        },
        { key: 'displayName', name: 'Name', hide: ['xs'] },
        { key: 'email', name: 'Email', hide: ['xs'] },
        {
          key: 'acl',
          name: (
            <>
              {tagSelected && (
                <StyledPermissionIcon icon="check" size={'1.2em'} />
              )}
              <span>Areas</span>
            </>
          ),
          hide: ['xs', 'sm'],
          render: (acl) => (
            <StyledLabel>
              {acl &&
                acl.map((a, index) => (
                  <div key={`acl-${index}`}>
                    {`${a.path === xTag ? 'All' : a.name}`}{' '}
                  </div>
                ))}
            </StyledLabel>
          ),
        },
        {
          name: '',
          render: ({ id }) => (
            <Link to={`/users/${id}`}>
              <StyledIcon size={'2em'} icon={'chevron-right'} />
            </Link>
          ),
        },
      ]}
    />
  );
};

export default withQS(Users);
