import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactSelect, {Async as ReactSelectAsync} from 'react-select';

export const styles = css`
  #react-select-2-input {
    opacity: 1 !important;
  }
  .select--is-disabled {
    color: ${({ theme }) => theme.disabled};
  }
  .select__control {
    box-shadow: none;
    border-radius: ${({ theme }) => theme.inputBorderRadius};
    background: ${({ theme }) => theme.inputBackground};
    border-width: ${({ theme }) => theme.inputBorderWidth};
    min-height: ${({ theme }) => theme.inputHeight};
    padding: ${({ theme }) => theme.selectPadding};
    font-family: ${({ theme }) => theme.primaryFont};
    font-weight: ${({ theme }) => theme.inputFontWeight};
    border-color: ${({ theme, invalid }) =>
      invalid ? theme.inputBorderError : theme.inputBorder};
  }

  .select__control--is-disabled {
    border-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.disabled};
  }

  .select__single-value {
    font-size: ${({ theme }) => theme.inputFontSize};
    color: ${({ theme }) => theme.inputFontColor};
  }
  .select__single-value--is-disabled {
    font-size: ${({ theme }) => theme.inputFontSize};
    color: ${({ theme }) => theme.disabled};
  }

  .select__placeholder {
    font-size: ${({ theme }) => theme.inputFontSize};
    color: ${({ theme }) => theme.inputPlaceholderColor};
    font-weight: ${({ theme }) => theme.inputPlaceholderWeight};
  }

  .select__menu {
    padding: 0;
    margin: 0;
    box-shadow: ${({ theme }) => theme.dropdownShadow};
    background: ${({ theme }) => theme.inputBackground};
    border: ${({ theme }) => theme.selectMenuBorder};
    border-radius: ${({ theme }) => theme.selectMenuRadius};
  }

  .select__option {
    font-size: ${({ theme }) => theme.inputFontSize};
    color: ${({ theme }) => theme.inputFontColor};
    background: ${({ theme }) => theme.inputBackground};
    font-family: ${({ theme }) => theme.primaryFont};

    &:hover {
      color: ${({ theme }) => theme.inputBorderFocus};
    }

    &--is-focused {
      color: ${({ theme }) => theme.inputBorderFocus};
    }

    &:active {
      background: ${({ theme }) => theme.inputBackground};
    }
  }

  .select__menu-notice--no-options {
    color: ${({ theme }) => theme.inputPlaceholderColor};
    background: ${({ theme }) => theme.inputBackground};
    font-family: ${({ theme }) => theme.primaryFont};
    font-size: ${({ theme }) => theme.inputFontSize};
  }

  .select__multi-value {
    background: ${({ theme }) => theme.selectMultiBackground};
    border: ${({ theme }) => theme.selectMultiBorder};
    border-radius: ${({ theme }) => theme.inputBorderRadius};
  }

  .select__multi-value-label {
    color: ${({ theme }) => theme.inputFontColor};
  }

  .select__menu-list {
    max-height: ${props => (props.menuMaxHeight ? props.menuMaxHeight : '')};
  }

  .select__multi-value__remove {
    color: ${({ theme }) => theme.inputPlaceholderColor};
    background: none;

    &:hover {
      color: ${({ theme }) => theme.inputFontColor};
      background: none;
    }
  }
  .select__input {
    color: ${({ theme }) => theme.inputFontColor};
  }
`;

const Select = ({ select, controlled, ...props }) => {
  const {
    isMulti,
    options,
    valueKey = 'value',
    labelKey = 'label',
    loadOptions,
    menuPlacement,
  } = select;
  let value;
  if (options) {
    const propsValue =
      typeof props.value === 'object' && props.value
        ? props.value[valueKey]
        : props.value;
    value = Array.isArray(props.value)
      ? options.filter(({ [valueKey]: value }) =>
          props.value.some(v => v === value)
        )
      : options.find(({ [valueKey]: value }) => value === propsValue);
    if (!options.length && !value && propsValue)
      value = { [valueKey]: propsValue, [labelKey]: propsValue };
  }

  if (!value && props.value)
    value = { [valueKey]: props.value, [labelKey]: props.value };
  if (Array.isArray(value) && props.value)
    value = props.value.map(val => ({ [valueKey]: val, [labelKey]: val }));
  if (!value && controlled) value = '';

  const SelectComponent = loadOptions ? ReactSelectAsync : ReactSelect;
  
  return (
    <SelectComponent
      closeMenuOnSelect={!isMulti}
      getOptionLabel={data => data[labelKey]}
      getOptionValue={data => data[valueKey]}
      classNamePrefix="select"
      {...select}
      {...props}
      value={value}
      onChange={input => {
        const value = isMulti
          ? Object.values(input).map(({ [valueKey]: value }) => value)
          : input[valueKey];
        props.onChange(value, input);
      }}
      menuPlacement={menuPlacement || 'auto'}
    />
  );
};

Select.propTypes = {
  select: PropTypes.shape({
    options: PropTypes.array,
    isMulti: PropTypes.bool,
  }),
  theme: PropTypes.object,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  select: {
    options: [],
    isMulti: false,
  },
  onChange: () => {},
  theme: {},
};

const StyledSelect = styled(Select)`
  ${styles};
`;

export default StyledSelect;
