import React, { useState, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import _get from 'lodash/get';

import Input from '@atoms/Input';
import Icon from '@atoms/Icon';
import Label from '@atoms/Label';
import Button from '@atoms/Button';
import ProgressBar from '@organisms/Install/ProgressBar';
import LoaderCube from '@atoms/LoaderCube';
import Error from '@organisms/Install/pages/Error';

import ReplaceDevices from '../ReplaceDevices';

const LocationContainer = styled.div`
  text-align: center;
  max-width: 60em;
`;

const TextContainer = styled.div`
  color: #707070;
  font-size: 1.25em;
`;

const ImageContainer = styled.div`
  margin: 1em 0;
`;

const InputContainer = styled.div`
  padding: 0.4em;
  text-align: left;
`;

const FieldLabel = styled(Label)`
  text-transform: capitalize;
  padding-left: 15px;
  font-weight: bold;
  color: #707070;
`;

const ButtonContainer = styled.div`
  padding: 0.4em;
  display: flex;
  justify-content: space-between;
`;

const GET_APARTMENT = gql`
  query tags($limit: Int, $filter: TagFilter) {
    tags(limit: $limit, filter: $filter) {
      edges {
        id
        name
        type
        devices {
          id
          name
          secondary
        }
        meta
      }
    }
  }
`;

const Apartment = ({
  prevPage,
  nextPage,
  setDeviceData,
  device,
  maxSteps,
  step,
}) => {
  const [showReplaceDevices, setShowReplaceDevices] = useState(false);
  const [apartmentId, setApartmentId] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const fetchApartmentData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await client.query({
        query: GET_APARTMENT,
        variables: {
          filter: {
            type: 'apartment',
            meta: {
              apartment: {
                id: apartmentId,
              },
            },
          },
        },
        fetchPolicy: 'no-cache',
      });
      setLoading(false);
      const apartments = _get(result, 'data.tags.edges');
      if (apartments && apartments.length === 1) {
        const installedDevices = _get(apartments[0], 'devices', []);
        setError(null);
        setDeviceData({
          name: apartments[0].name,
          tag: apartments[0].id,
          meta: {
            apartmentId: apartments[0].meta.apartment.id,
            apartment: apartments[0].meta.apartment,
            location: apartments[0].meta.apartment.address,
            cellularState: true,
          },
          installedDevices,
          settings: device.settings,
          warning:
            apartments[0].id !== device.tag &&
            `You are about to change this device allocated billing area to ${apartments[0].meta.apartment.officeName}. If you do not wish to do proceed, click the "Previous" button and choose an apartment in the same area`,
        });

        if (installedDevices.length) {
          setShowReplaceDevices(true);
        } else {
          nextPage();
        }
      } else {
        // set error
        if (!apartments || apartments.length === 0)
          setError(() => (
            <React.Fragment>
              {' '}
              <div>{`Cannot find apartment with id: ${apartmentId}`}</div>
              <div style={{ paddingTop: '10px' }}>
                Apartment might not be synched yet, please try again later (30
                min)
              </div>{' '}
              <div style={{ paddingTop: '10px' }}>
                If Apartment is still not found after some time, you might not
                have proper permissions. Please contact support team.
              </div>
            </React.Fragment>
          ));
        if (apartments.length > 1)
          setError(`Found more than one apartment with id: ${apartmentId}`);
      }
    } catch (e) {
      setError(e);
    }
  }, [
    apartmentId,
    client,
    device.settings,
    device.tag,
    nextPage,
    setDeviceData,
  ]);

  return (
    <React.Fragment>
      {showReplaceDevices && (
        <ReplaceDevices
          device={device}
          onReturn={(data) => {
            if (data) setDeviceData(data);
            setShowReplaceDevices(false);
          }}
          onButtonClick={(data) => {
            setDeviceData(data);
            nextPage();
          }}
        />
      )}
      {loading && <LoaderCube />}
      {!loading && !error && !showReplaceDevices && (
        <LocationContainer>
          <ProgressBar maxSteps={maxSteps} currentStep={step} />
          <div>
            <TextContainer>
              Please enter apartmentId and we will fetch data for You
            </TextContainer>
            <ImageContainer>
              <Icon icon="map-marker" size={'150px'} color="#707070" />
            </ImageContainer>
            <InputContainer>
              <FieldLabel>Apartment Id</FieldLabel>
              <Input
                onChange={(e) => setApartmentId(e.target.value)}
                placeholder="Enter id"
                value={apartmentId}
              />
            </InputContainer>
            <ButtonContainer>
              <Button
                style={{ marginRight: '30px' }}
                secondary
                onClick={prevPage}
              >
                Previous
              </Button>
              <Button
                style={{ marginLeft: '30px' }}
                disabled={apartmentId.length > 0 ? false : true}
                onClick={() => fetchApartmentData()}
              >
                Next
              </Button>
            </ButtonContainer>
          </div>
        </LocationContainer>
      )}
      {error && (
        <Error
          errorMessage={error}
          onPrev={() => setError(null)}
          onDone={() => prevPage()}
        />
      )}
    </React.Fragment>
  );
};

export default Apartment;
