import styled from 'styled-components';

export default styled.div`
  margin-bottom: 0.5rem;

  input[type='checkbox'],
  input[type='radio'] {
    margin-right: 0.5rem;
    display: inline-block;
    width: auto;
    height: auto;
  }

  label {
    vertical-align: middle;
    margin-left: 0.5em;
    text-transform: none;
  }
`;
