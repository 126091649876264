import gql from 'graphql-tag';

import { userInfoFragment, roleInfoFragment } from '../fragments/users';
import { pageInfoFragment } from '../fragments/pageInfo';

export const GET_USER = gql`
  query user($id: String!) {
    user(id: $id) {
      ...userInfo
    }
  }
  ${userInfoFragment}
`;

export const GET_USER_CLAIMS = gql`
query user($id: String!) {
  user(id: $id) {
    claims {
      userTags
      role
    }
  }
}
${userInfoFragment}
`;

export const GET_USERS = gql`
  query users($limit: Int, $after: String, $filter: UserFilter) {
    users(limit: $limit, after: $after, filter: $filter) {
      edges {
        ...userInfo
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${userInfoFragment}
  ${pageInfoFragment}
`;
