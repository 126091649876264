import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const fontSize = ({ level }) => `${0.75 + 1 * (1 / level)}em`;

const styles = css`
  font-family: ${props => props.theme.primaryFont};
  font-weight: ${props => props.theme.headingFontWeight};
  font-size: ${fontSize};
  padding: ${props => props.theme.headingPadding};
  margin: 0;
  color: ${props => props.theme.headingColor};
`;

/**
 * @render react
 * @name Heading
 * @description Heading
 * @example
 * <Heading level={1}>Example</Heading>
 */
const Heading = styled(
  ({ level, children, reverse, palette, theme, ...props }) =>
    React.createElement(`h${level}`, props, children)
)`
  ${styles};
`;

Heading.propTypes = {
  /**
   * @property {propTypes.number} level - Level of heading.
   */
  level: PropTypes.number,
  children: PropTypes.node
};

Heading.defaultProps = {
  level: 1,
  theme: {
    headingFont: "Rubik, sans-serif",
    headingFontWeight: "500",
    headingColor: "#000000",
    headingPadding: "0 0 0 0"
  }
};

export default Heading;
