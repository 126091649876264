import React, { Component } from 'react';
import QSContext from './qs-context';

export class QSProvider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <QSContext.Provider
        value={{
          getQueryString: location => {
            const params = new URLSearchParams(location.search);
            const search = params.get('search');
            const tag = params.get('tag');
            const timestamp = JSON.parse(params.get('timestamp'));
            const sort = JSON.parse(params.get('sort'));
            const limit = Number(params.get('limit')) || 0;
            const filter = JSON.parse(params.get('filter')) || undefined;
            return { search, tag, timestamp, sort, limit, filter };
          },
          setQueryString: (query, history, location) => {
            const { search, tag, timestamp, sort, limit, filter } = query;
            const qs = [];
            if (!isNaN(limit)) qs.push(`limit=${limit}`);
            if (search && search !== '') qs.push(`search=${search}`);
            if (tag && tag !== '') qs.push(`tag=${tag}`);
            if (timestamp) qs.push(`timestamp=${JSON.stringify(timestamp)}`);
            if (sort) qs.push(`sort=${JSON.stringify(sort)}`);
            if (filter) qs.push(`filter=${JSON.stringify(filter)}`);
            history.replace(`${location.pathname}?${qs.join('&')}`);
          },
        }}
      >
        {this.props.children}
      </QSContext.Provider>
    );
  }
}

export const withQS = Component =>
  class withQS extends React.Component {
    render() {
      return (
        <QSContext.Consumer>
          {value => <Component {...this.props} {...value} />}
        </QSContext.Consumer>
      );
    }
  };
