import React, { useState } from 'react';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import Input from '../../../../atoms/Input';
import LoaderCube from '../../../../atoms/LoaderCube';
import Icon from '../../../../atoms/Icon';
import Label from '../../../../atoms/Label';

import Button from '../../../../atoms/Button';
import ProgressBar from '../../ProgressBar';

import Error from '../Error';

const IndentificationContainer = styled.div`
  max-width: 60em;
`;

const TextContainer = styled.div`
  color: #707070;
  font-size: 1.25em;
`;

const ImageContainer = styled.div`
  margin: 1em 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.4em;
`;

const InputContainer = styled.div`
  text-align: left;
  padding: 0.4em;
`;

const FieldLabel = styled(Label)`
  text-transform: capitalize;
  padding-left: 15px;
  font-weight: bold;
  color: #707070;
`;

const getDevice = gql`
  query getDevice($id: String!) {
    device(id: $id) {
      id
      lifecycleState
      lifecycleEvent {
        data {
          after
        }
      }
      meta
      settings {
        data
      }
      tag
    }
  }
`;

const Identification = ({
  device,
  setDeviceData,
  nextPage,
  maxSteps,
  step,
  prevPage,
  cancel,
}) => {
  const [id, setId] = useState(device.id);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const client = useApolloClient();

  const checkDevice = async () => {
    setLoading(true);
    try {
      const { data } = await client.query({
        query: getDevice,
        variables: { id: id },
        fetchPolicy: 'no-cache',
      });

      if (data) {
        setLoading(false);
        const isTerminated =
          data?.device?.lifecycleEvent?.data?.after?.reason === 'terminated';

        if (data.device === null) {
          setError(
            `Could not find a device with an id: ${id}. Please try again or contact RoomRobot support..`
          );
        }

        if (
          data.device !== null &&
          data.device.lifecycleState !== 'delivered' &&
          data.device.lifecycleState !== 'deactivated'
        ) {
          setError(
            `This device is already installed or has not been delivered. Please contact RoomRobot support.`
          );
        }
        if (data.device.lifecycleState === 'deactivated' && isTerminated) {
          setError(
            `This device subscription has been terminated. Please contact RoomRobot support for more information.`
          );
        }
        if (
          (data.device && data.device.lifecycleState === 'delivered') ||
          (data.device.lifecycleState === 'deactivated' && !isTerminated)
        ) {
          setDeviceData({
            id: id,
            name: '',
            meta: data.device.meta,
            settings: data.device.settings,
            tag: data.device.tag,
          });
          nextPage();
        }
      }
    } catch (e) {
      setLoading(false);
      setError(
        'An error has occured during installation, please contact RoomRobot support.' +
          e && e.message
      );
    }
  };
  return (
    <React.Fragment>
      {loading && <LoaderCube />}
      {!loading && (
        <IndentificationContainer>
          <ProgressBar maxSteps={maxSteps} currentStep={step} />
          {error && (
            <Error
              errorMessage={error}
              onPrev={() => setError(null)}
              onDone={() => prevPage()}
            />
          )}
          {!error && (
            <div>
              <TextContainer>
                Please find the device ID and enter it in the input below.
              </TextContainer>
              <ImageContainer>
                <Icon icon="id-icon" size={'150px'} color="#707070" />
              </ImageContainer>
              <InputContainer>
                <FieldLabel>Device ID</FieldLabel>
                <Input
                  onChange={(e) => setId(e.target.value)}
                  placeholder="Enter device ID.."
                  value={id}
                />
              </InputContainer>
              <ButtonContainer>
                <Button
                  style={{ marginRight: '30px' }}
                  onClick={() => cancel()}
                  secondary
                >
                  Cancel
                </Button>
                <Button
                  style={{ marginLeft: '30px' }}
                  disabled={id.length > 5 ? false : true}
                  onClick={() => checkDevice()}
                >
                  Next
                </Button>
              </ButtonContainer>
            </div>
          )}
        </IndentificationContainer>
      )}
    </React.Fragment>
  );
};

export default Identification;
