// const utils = require('./utils');

const items = [
  'time',
  'temperature',
  'pirEventCount',
  'soundBackgroundLevel',
  'soundAverageLevel',
  'soundMaxLevel',
  'soundMaxLevelVariance',
  'clients',
];

const MOVEMENT_INDICATOR = 1 * 1;
const SOUND_INDICATOR = 10 * 1;
const SOUND_VARIANCE_INDICATOR = 100 * 1;
const CLIENTS_INDICATOR = 1000 * 1;
/*
const CO2_INDICATOR = 10000 * 0;
const HUMIDITY_INDICATOR = 100000 * 0;
*/

function extractDeviceData(data) {
  var dataTables = [];
  var countTables = [];
  for (var i = 0; i < items.length; i++) {
    dataTables[i] = [];
    dataTables[i][0] = items[i];
    countTables[i] = [];
    countTables[i][0] = items[i];
  }

  for (var j = 0; j < data.temperature.length; j++) {
    var indx = 0;
    var d = new Date(data.temperature[j].startTime);
    var day = d.getDay();
    var month = d.getMonth();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var year = d.getFullYear();
    dataTables[indx].push(
      day + '.' + month + '.' + year + ' ' + hour + ':' + minute
    );
    countTables[indx++].push(
      day + '.' + month + '.' + year + ' ' + hour + ':' + minute
    );
    dataTables[indx].push(data.temperature[j].avg);
    countTables[indx++].push(data.temperature[j].count);
    var numberOfNewMoves = data.pirEventCount[j].run;
    if (numberOfNewMoves < 0) numberOfNewMoves = 0;
    dataTables[indx].push(numberOfNewMoves);
    countTables[indx++].push(data.pirEventCount[j].count);
    dataTables[indx].push(data.soundBackgroundLevel[j].min);
    countTables[indx++].push(data.soundBackgroundLevel[j].count);
    dataTables[indx].push(data.soundLastLevel[j].avg);
    countTables[indx++].push(data.soundLastLevel[j].count);
    dataTables[indx].push(data.soundMaxLevel[j].max);
    countTables[indx++].push(data.soundMaxLevel[j].count);
    dataTables[indx].push(data.soundMaxLevel[j].variance);
    countTables[indx++].push(data.soundMaxLevel[j].variance);

    if (data.clients && data.clients.length) {
      dataTables[indx].push(data.clients[j].max);
      countTables[indx++].push(data.clients[j].count);
    }
  }
  return {
    data: dataTables,
    counts: countTables,
  };
}

function extractItemData(data, item) {
  for (var i = 0; i < data.data.length; i++) {
    if (data.data[i][0] === item) {
      var result = {
        data: [],
        counts: [],
      };
      for (var j = 1; j < data.data[i].length; j++) {
        result.data.push(data.data[i][j]);
        result.counts.push(data.counts[i][j]);
      }
      return result;
    }
  }
  //    console.log('dataItem does not exist');
  return [];
}

// let co2Limit = 500;
/*
function defineCharacteristics(list) {
  var l = list.length;
  if (l === 0) {
    //        console.log('item datalist is empty');
    return list;
  }
  var deltaList = [];
  for (var j = 0; j < list.length - 1; j++)
    deltaList.push(list[j + 1] - list[j]);
  var sortedData = utils.sortAscending(list);
  var first = (l / 100) | 0;
  var last = ((99 * l) / 100) | 0;
  var newList = [];
  for (var k = first; k < last; k++) newList.push(sortedData[k]);
  var characteristics = {};
  characteristics.min = utils.minA(newList);
  characteristics.max = utils.maxA(newList);
  characteristics.average = utils.aveA(newList);
  characteristics.median = utils.median(newList);
  characteristics.std = utils.stdA(newList);
  characteristics.limit = (3 * characteristics.min + characteristics.max) / 4;
  characteristics.deltaMin = utils.minA(deltaList);
  characteristics.deltaMax = utils.maxA(deltaList);
  characteristics.deltaAverage = utils.aveA(deltaList);
  characteristics.deltaPlusLimit = characteristics.deltaMax / 2;
  characteristics.deltaMinusLimit = characteristics.deltaMin / 2;
  return characteristics;
}
*/
function addA(list1, list2) {
  if (list1 && list2) {
    var shorterList = list2;
    var longerList = list1;
    if (list1.length !== list2.length) {
      if (list2.length > list1.length) {
        shorterList = list1;
        longerList = list2;
        //                console.log('list1 length=', list1.length, 'list2 length=', list1.length)
      } else {
        //                console.log('list1 length=', list1.length, 'list2 length=', list2.length)
      }
    }
    var newList = [];
    for (var i = 0; i < shorterList.length; i++) {
      if (shorterList[i] < 0 && longerList[i] < 0) newList.push(-1);
      else if (shorterList[i] < 0) newList.push(longerList[i]);
      else if (longerList[i] < 0) newList.push(shorterList[i]);
      else newList.push(shorterList[i] + longerList[i]);
    }
    for (; i < longerList.length; i++) {
      newList.push(longerList[i]);
    }
    return newList;
  } else {
    if (list1) {
      //            console.log('list2 does not exist');
      return list1;
    } else {
      //            console.log('list1 does not exist');
      return list2;
    }
  }
}
/*
const fixedCo2Limit = 550;

function presenceByCo2(co2s) {
  //    var characteristics = defineCharacteristics(co2s)
  var presence = [];
  //    co2Limit = characteristics.limit
  co2Limit = fixedCo2Limit;
  for (var i = 0; i < co2s.length; i++) {
    if (co2s[i] > co2Limit) presence[i] = CO2_INDICATOR;
    else presence[i] = 0;
  }
  return presence;
}
*/
const fixedSoundLevelLimit = 65;

function presenceBySound(
  soundBackGroundLevels,
  soundAverageLevels,
  soundMaxLevelsData
) {
  var soundMaxLevels = soundMaxLevelsData.data;
  var counts = soundMaxLevelsData.counts;
  var presence = [];
  for (var i = 0; i < soundMaxLevels.length; i++) {
    if (soundMaxLevels[i] > fixedSoundLevelLimit) presence[i] = SOUND_INDICATOR;
    else presence[i] = 0;
    if (counts[i] === 0) presence[i] = -1;
  }
  return presence;
}
const fixedSoundLevelVarianceLimit = 10;
function presenceBySoundVariance(soundMaxLevelVariancesData) {
  var soundMaxLevelVariances = soundMaxLevelVariancesData.data;
  var counts = soundMaxLevelVariancesData.counts;
  var presence = [];
  for (var i = 0; i < soundMaxLevelVariances.length; i++) {
    if (soundMaxLevelVariances[i] > fixedSoundLevelVarianceLimit)
      presence[i] = SOUND_VARIANCE_INDICATOR;
    else presence[i] = 0;
    if (counts[i] === 0) presence[i] = -1;
  }
  return presence;
}
/*
function presenceByHumidity(humidities) {
  var characteristics = defineCharacteristics(humidities);
  var presence = [];
  var plusLimit = characteristics.deltaPlusLimit;
  var minusLimit = characteristics.deltaMinusLimit;
  presence[0] = 0;
  for (var i = 1; i < humidities.length; i++) {
    //        if (!humidities[i]) console.log('humidity undefined');
    //        if (!humidities[i - 1]) console.log('humidity undefined');
    var delta = humidities[i] - humidities[i - 1];
    if (delta > plusLimit || delta < minusLimit)
      presence[i] = HUMIDITY_INDICATOR;
    else presence[i] = 0;
  }
  return presence;
}
*/
function presenceByClients(data) {
  var clients = data.data;
  var counts = data.counts;
  if (clients) {
    var presence = [];
    for (var i = 0; i < clients.length; i++) {
      if (clients[i] > 0) presence[i] = CLIENTS_INDICATOR;
      else presence[i] = 0;
      if (counts[i] === 0) presence[i] = -1;
    }
    return presence;
  } else {
    return null;
  }
}

function presenceByMovement(data) {
  var movements = data.data;
  var counts = data.counts;
  var presence = [];
  for (var i = 0; i < movements.length; i++) {
    if (counts[i] === 0) {
      presence[i] = -1;
    } else {
      if (movements[i] > 1) presence[i] = MOVEMENT_INDICATOR;
      else presence[i] = 0;
    }
  }
  return presence;
}
function formPresenceData(data, presences) {
  var deviceData = data.temperature;
  var timedPresences = [];
  for (var i = 0; i < deviceData.length; i++) {
    var timedPresence = {};
    timedPresence.aggregatedPresence = presences[i];
    timedPresence.startTime = deviceData[i].startTime;
    timedPresence.endTime = deviceData[i].endTime;
    timedPresence.date = deviceData[i].date;
    timedPresence.__typename = deviceData[i].__typename;
    timedPresences.push(timedPresence);
  }
  return timedPresences;
}

function personInRoom(rawDeviceData) {
  var deviceData = extractDeviceData(rawDeviceData);
  var movementPresences = presenceByMovement(
    extractItemData(deviceData, 'pirEventCount')
  );
  var soundPresences = presenceBySound(
    {},
    {},
    extractItemData(deviceData, 'soundMaxLevel')
  );
  var soundVariancePresences = presenceBySoundVariance(
    extractItemData(deviceData, 'soundMaxLevelVariance')
  );
  var clientPresences = presenceByClients(
    extractItemData(deviceData, 'clients')
  );
  //console.log('clientPresences', clientPresences);
  var aggregatedPresences = addA(
    soundVariancePresences,
    addA(movementPresences, addA(soundPresences, clientPresences))
  );
  var presenceData = formPresenceData(rawDeviceData, aggregatedPresences);
  return presenceData;
}
module.exports = {
  personInRoom: function (data) {
    return personInRoom(data);
  },
  extractDeviceData: function (data) {
    return extractDeviceData(data);
  },
};
