import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../../atoms/Icon';
import CircleButton from '../../atoms/CircleButton';

const StyledCircleButton = styled(CircleButton)`
  background-color: ${props => props.theme.toastErrorColor};
  height: 1.5em;
  width: 1.5em;
`;

const DeleteButton = ({ onClick, disabled }) => (
  <StyledCircleButton
    type="button"
    small
    alert
    onClick={onClick}
    disabled={disabled}
  >
    <Icon icon="trash" size="1em" />
  </StyledCircleButton>
);

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DeleteButton;
