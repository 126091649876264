import gql from 'graphql-tag';
import { alarmInfoFragment } from '../fragments/alarms';
import { publicAlarmRuleInfoFragment } from '../fragments/alarm-rules';
import { pageInfoFragment } from '../fragments/pageInfo';

export const GET_ALARM_RULES_WITH_ALARMS = gql`
  query alarmRulesWithAlarms(
    $after: String
    $limit: Int
    $filter: AlarmRuleFilter
    $alarmsFilter: AlarmFilter
    $alarmsAfter: String
    $alarmsLimit: Int
  ) {
    publicAlarmRules(after: $after, limit: $limit, filter: $filter) {
      edges {
        ...publicAlarmRuleInfo
        alarms(
          limit: $alarmsLimit
          after: $alarmsAfter
          filter: $alarmsFilter
        ) {
          edges {
            ...alarmInfo
          }
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${alarmInfoFragment}
  ${publicAlarmRuleInfoFragment}
  ${pageInfoFragment}
`;
