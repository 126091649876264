import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Flex, Box } from '@rebass/grid';

import Button from '@atoms/Button';
import Label from '@atoms/Label';
import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import { showNewMessage } from '@intercom/messenger-js-sdk';
const StyledCaption = styled(Caption)`
  padding: 0.2em 0.8em 0.5em 0;
`;

const BoxWithRightRuler = styled(Box)`
  border-right: ${(props) => props.theme.cardBoxRuler};
  margin-right: 2em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    border-right: none;
    margin-right: inherit;
  }
`;
const StyledCard = styled(Card)`
  position: relative;
  padding: 2em;
  margin-top : 2em 
  border-left: ${(props) => props.theme.cardLeftBorder};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;
const StyledLabel = styled(Label)`
  color: ${(props) => props.theme.primaryThemeColor};
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${(props) => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;

const StyledSupportButton = styled(Button)`
  text-align: center;
  font-size: 12px;
  background-color: #ff5a13;
`;

const SupportCard = ({ title, match: { params } }) => {
  const handleCreateSupportRequest = () => {
    showNewMessage(
      `Hi Livion Team, I am facing an issue with my device ${params.id}`
    );
  };

  return (
    <StyledCard title={title} style={{ position: 'relative' }}>
      <StyledCaption>{title}</StyledCaption>
      <StyledFlex flexWrap={['wrap', null]}>
        <Box width={[1, 1, 2 / 3]}>
          <StyledLabel>
            Create a support request when a customer is facing issues with a
            roomrobot device.
          </StyledLabel>
        </Box>
        <BoxWithRightRuler flex="1 1 auto" />
        <Box width={'210px'}>
          <StyledFlex justifyContent="center" flexDirection="column">
            <StyledSupportButton onClick={handleCreateSupportRequest}>
              Create support request
            </StyledSupportButton>
          </StyledFlex>
        </Box>
      </StyledFlex>
    </StyledCard>
  );
};

export default withRouter(SupportCard);
