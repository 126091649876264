import gql from 'graphql-tag';

export const uiNotificationInfoFragment = gql`
  fragment uiNotificationInfo on UiNotification {
    id
    title
    products
    tags
    type
    content
    discardable
    updatedAt
  }
`;
