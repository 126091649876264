import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Masked from 'react-text-mask';
import { StyledInput as Input } from '../Input';
import Icon from '../Icon';
import {
  parseDate,
  formatDate,
  dateMask,
  StyledDayPicker,
} from '../DatePicker';

const StyledIcon = styled(Icon)`
  width: 2rem;
  fill: ${({ theme }) => theme.inputBorder};
`;

const StyledInput = styled(Input)`
  width: 8rem;
  border: 0;
  background: none;
  text-align: center;
`;

const StyledDayRange = styled(StyledDayPicker)`
  display: inline-block;
  border: ${({ theme }) => theme.inputBorderWidth} solid
    ${({ theme }) => theme.inputBorder};
  background: ${({ theme }) => theme.inputBackground};
  border-radius: 999px;

  .DayPickerInput:first-of-type .DayPickerInput-OverlayWrapper {
    left: 0;
    top: 1em;

    .DayPickerInput-Overlay {
      text-align: center;
    }
  }

  .DayPickerInput:last-of-type .DayPickerInput-OverlayWrapper {
    left: unset;
    right: 0;
    top: 1em;

    .DayPickerInput-Overlay {
      left: unset;
      right: 0;
      text-align: center;
    }
  }

  .DayPicker-Day {
    border-radius: 0 !important;
  }

  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`;

class DateRange extends Component {
  static propTypes = {
    format: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
    disabledDays: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    format: 'DD.MM.YYYY',
    onChange: () => {},
    start: null,
    end: null,
    disabledDays: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      from:
        typeof props.start === 'string'
          ? parseDate(props.start)
          : dayjs(props.start || undefined).toDate(),
      to:
        typeof props.end === 'string'
          ? parseDate(props.end)
          : dayjs(props.end || undefined).toDate(),
    };
    this.to = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (
      dayjs(nextProps.start).format('DDMMYYYY') !==
        dayjs(this.props.start).format('DDMMYYYY') ||
      dayjs(nextProps.end).format('DDMMYYYY') !==
        dayjs(this.props.end).format('DDMMYYYY')
    ) {
      this.setState({
        from: parseDate(dayjs(nextProps.start).format('DD.MM.YYYY')),
        to: parseDate(dayjs(nextProps.end).format('DD.MM.YYYY')),
      });
    }
  }

  handleFromChange = from => {
    if (from) {
      this.setState({ from });
      this.to.current.inputElement.focus();
      this.props.onChange({
        from: dayjs(from).startOf('day'),
        to: dayjs(this.state.to).endOf('day'),
      });
    }
  };

  handleToChange = to => {
    if (to) {
      this.setState({ to });
      this.to.current.inputElement.blur();
      this.props.onChange({
        to: dayjs(to).endOf('day'),
        from: dayjs(this.state.from).startOf('day'),
      });
    }
  };

  render() {
    const { format, disabledDays } = this.props;
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <StyledDayRange>
        <DayPickerInput
          value={from}
          placeholder="From"
          format={format}
          formatDate={formatDate}
          parseDate={parseDate}
          component={props => (
            <Masked
              mask={dateMask}
              guide
              keepCharPositions
              {...props}
              render={(ref, props) => (
                <div ref={ref}>
                  <StyledInput {...props} />
                </div>
              )}
            />
          )}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: [{ after: to }, ...disabledDays],
            toMonth: to,
            modifiers,
            firstDayOfWeek: 1,
          }}
          onDayChange={this.handleFromChange}
        />
        <StyledIcon icon="chevron-right" />
        <DayPickerInput
          value={to}
          placeholder="To"
          format={format}
          formatDate={formatDate}
          parseDate={parseDate}
          component={props => (
            <Masked
              mask={dateMask}
              guide
              ref={this.to}
              keepCharPositions
              {...props}
              render={(ref, props) => (
                <div ref={ref}>
                  <StyledInput {...props} />
                </div>
              )}
            />
          )}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: [{ before: from }, ...disabledDays],
            modifiers,
            month: from,
            fromMonth: from,
            firstDayOfWeek: 1,
          }}
          onDayChange={this.handleToChange}
        />
      </StyledDayRange>
    );
  }
}

export default DateRange;
