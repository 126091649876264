import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { Flex, Box } from '@rebass/grid';
import filter from 'lodash/filter';
import get from 'lodash/get';

import { withLivionConnect } from '../../contexts/LivionConnectContext';
import { withAuth } from '../../contexts/FirebaseContext';
import Header from '../../organisms/Header';
import Banner from '../../molecules/Banner';
import Icon from '../../atoms/Icon';
import Cookies from 'universal-cookie';
import { getLanguageForCommonApps } from '../../utils';
const cookies = new Cookies();

const StyledIcon = styled(Icon)`
  fill: black;
  vertical-align: inherit;
`;

const UI_NOTIFICATIONS = gql`
  query notifications($filter: UiNotificationFilter, $limit: Int, $sort: JSON) {
    uiNotifications(filter: $filter, limit: $limit, sort: $sort) {
      edges {
        id
        title
        products
        tags
        type
        content
        discardable
        updatedAt
      }
      pageInfo {
        start
        end
        hasNext
      }
    }
  }
`;
export const GET_ACL = gql`
  query {
    verifyLogin {
      id
      acl {
        path
        role
      }
    }
  }
`;

export const GET_TAG = gql`
  query tag($id: String!) {
    tag(id: $id) {
      id
      name
      type
      tagParents
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const StyledHeader = styled.div`
  flex: 0 0 auto;
`;
const StyledBanner = styled.div`
  flex: 0 0 auto;
  &:hover {
    cursor: pointer;
  }
`;

const StyledActions = styled.div`
  flex: 0 0 auto;
`;

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  flex: 1 1 auto;
  position: relative;
  /*@media screen and (min-width: ${(props) =>
    props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }*/
`;
let queryData = null;
let queryError = null;
let queryLoading = false;
function PageTemplate(props) {
  const {
    history,
    auth,
    auth: { user, getSelectedTag, tagSelect, getOrganization, productFilter },
    state: { navigationItems },
    actions: {
      logout,
      selectTag,
      getUserTags,
      getUserNotification,
      setUserNotification,
    },
    actionsPane,
    children,
    hideBanner,
    i18n,
  } = props;

  const [customItems, setCustomItems] = useState([]);
  const [notification, setNotification] = useState();

  const apolloClient = useApolloClient();
  const { data, error, loading } = useQuery(UI_NOTIFICATIONS, {
    variables: {
      filter: { products: [productFilter] },
      limit: 1,
      sort: { updatedAt: -1 },
    },
    skip: hideBanner,
  });

  useEffect(() => {
    const checkNotification = async () => {
      const notifications = _get(data, 'uiNotifications.edges', []);
      if (notifications.length > 0 && notifications[0]) {
        const isNotification = await getUserNotification(notifications[0].id);
        setNotification(!isNotification && notifications[0]);
      }
    };

    checkNotification();
  }, [data]);

  let tagId = getSelectedTag();

  const organizationClick = useCallback(() => {
    if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
      const lng = getLanguageForCommonApps(i18n);
      localStorage.setItem('selectedOrganizationUnit', null);
      cookies.remove('x-tag', {
        path: '/',
      });
      window.location = `/apps/${lng}/auth/select-organization`;
    } else {
      history.push(`/selectorganization`);
    }
  });

  const onHandleDiscard = async () => {
    const isNotification = await setUserNotification(notification.id);
    console.log('isNotification', isNotification);
  };

  useEffect(() => {
    if (tagId && !tagSelect && auth.user) {
      async function getData() {
        const acl = await apolloClient.query({
          query: GET_ACL,
          context: {
            headers: {
              'x-tag': '',
            },
          },
        });
        const { data } = await apolloClient.query({
          query: GET_TAG,
          variables: {
            id: tagId,
          },
        });

        const filteredAcl = filter(
          get(acl.data, 'verifyLogin.acl', []),
          (o) => o.role.indexOf(productFilter) === 0
        );

        if (filteredAcl.length > 1 && data && data.tag) {
          const organizationTag = getOrganization(_get(data, 'tag'));
          organizationTag &&
            customItems.push(
              <Flex
                alignItems="center"
                justifyContent="space-between"
                onClick={organizationClick}
              >
                <Box>{`${organizationTag.name}`}</Box>
                <Box>
                  <Flex>
                    <StyledIcon icon="chevron-down" />
                  </Flex>
                </Box>
              </Flex>
            );
          setCustomItems(customItems);
        }
      }
      getData();
    }
  }, [tagId, auth]);

  const profileClick = useCallback(() => {
    if (process.env.REACT_APP_COMMONAPPS_IN_USE) {
      const lng = getLanguageForCommonApps(i18n);
      window.location = `/apps/${lng}/users/me`;
    } else {
      history.push(`/users/${_get(auth, 'user.uid')}/edit`);
    }
  }, [i18n, auth, history]);

  const onPrivacyClick = useCallback(() => {
    history.push(`/privacy/`);
  });

  const userDropDownItem = user
    ? [
        {
          text: _get(user, 'email'),
          onClick: profileClick,
          hideMobile: true,
        },
      ]
    : [];

  const privacyItem = [
    { text: 'Privacy policy', onClick: onPrivacyClick, hideMobile: true },
  ];
  return (
    <Wrapper>
      {notification && !hideBanner && (
        <StyledBanner>
          <Banner
            type={notification.type}
            title={notification.title}
            discardable={notification.discardable}
            onDiscard={onHandleDiscard}
            onNavigate={() => history.push('/notifications')}
          />
        </StyledBanner>
      )}
      <StyledHeader>
        <Header
          user={user}
          navigationItems={navigationItems}
          dropdownItems={
            process.env.REACT_APP_LEGACYAPPS_IN_USE === 'true'
              ? userDropDownItem
                  .concat(customItems)
                  .concat(
                    <Box
                      onClick={() => {
                        localStorage.setItem('selectedOrganizationUnit', null);
                        window.location.href = '/en/auth/select-organization';
                      }}
                    >
                      Change organization
                    </Box>
                  )
                  .concat(privacyItem)
                  .concat([
                    { text: 'Logout', onClick: logout, hideMobile: true },
                  ])
              : userDropDownItem
                  .concat(customItems)
                  .concat(privacyItem)
                  .concat([
                    { text: 'Logout', onClick: logout, hideMobile: true },
                  ])
          }
        />
      </StyledHeader>
      <StyledActions>{actionsPane}</StyledActions>
      <Content>{children}</Content>
    </Wrapper>
  );
}

PageTemplate.propTypes = {
  actionsPane: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  hideBanner: PropTypes.bool,
};

export default withRouter(withLivionConnect(withAuth(PageTemplate)));
