import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Select from '../Select';
import CheckBox from '../CheckBox';

const styles = css`
  font-family: ${props => props.theme.primaryFont};
  display: block;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  opacity: 1;
  font-weight: ${props => props.theme.inputFontWeight};
  font-size: ${props => props.theme.inputFontSize};
  padding: ${props => props.theme.inputPadding};
  height: ${props => props.theme.inputHeight};
  color: ${props => props.theme.inputFontColor};
  background-color: ${props => props.theme.inputBackground};
  border-radius: ${props => props.theme.inputBorderRadius};
  border: 2px solid
    ${props =>
      props.invalid ? props.theme.inputBorderError : props.theme.inputBorder};
  border-width: ${props => props.theme.inputBorderWidth};
  outline: none;

  ::placeholder {
    font-weight: ${props => props.theme.inputPlaceholderWeight};
    color: ${props => props.theme.inputPlaceholderColor};
  }

  &:focus {
    border: 2px solid ${props => props.theme.inputBorderFocus};
  }

  &.error {
    border: 2px solid ${props => props.theme.inputBorderError};
  }
  &:disabled {
    border: 1px solid ${props => props.theme.disabled};
    color: ${props => props.theme.disabled};
  }
`;

const StyledTextarea = styled.textarea`
  ${styles};
`;
const StyledSelect = styled.select`
  ${styles};
`;
export const StyledInput = styled.input`
  ${styles};
`;

const defaultProps = {
  theme: {
    inputPlaceholderColor: '#A4A4A4',
    inputPlaceholderWeight: '200',
    inputFontWeight: '300',
    inputHeight: '2.75em',
    inputFontSize: '1em',
    inputFontColor: '#282828',
    inputBackground: '#fff',
    inputBorderRadius: '1.250em',
    inputBorderFocus: '#43BDFC',
    inputBorder: '#0789CC',
    inputBorderError: '#C54747',
    inputPadding: '0 1.25em',
    inputBorderWidth: '2px',
  },
};

StyledTextarea.defaultProps = defaultProps;
StyledSelect.defaultProps = defaultProps;
StyledInput.defaultProps = defaultProps;

/**
 * @render react
 * @name Input
 * @description Input
 * @example
 * <Input type="text" />
 */

const Input = props => {
  if (props.type === 'textarea') {
    return <StyledTextarea {...props} />;
  } else if (props.type === 'native-select') {
    return <StyledSelect {...props}>{props.children}</StyledSelect>;
  } else if (props.type === 'select') {
    return <Select {...props} />;
  } else if (props.type === 'checkbox') {
    return <CheckBox {...props} />;
  }

  return <StyledInput {...props} />;
};

Input.propTypes = {
  /**
   * @property {propTypes.string} type - type of input.
   */
  type: PropTypes.string,
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
