import React, { useRef, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';
import DropdownNavigation from '../../molecules/DropdownNavigation';
import Link from '../../atoms/Link';
import c from 'color';
import { withRouter } from 'react-router';
import Icon from '../../atoms/Icon';
import { Flex, Box } from '@rebass/grid';

const Nav = styled.nav`
  display: flex;
  list-style: none;
  padding-top: 1.5em;
  justify-content: center;
`;

const StyledLink = styled((props) => <Link {...props} />)`
  text-decoration: none;
  font-weight: ${(props) => props.theme.navigationFontWeigt};
  color: ${(props) => props.theme.navigationFontColor};
  font-size: ${(props) => props.theme.navigationFontSize};
  white-space: nowrap;
  &.active {
    color: ${(props) => props.theme.navigationActiveColor};
  }
  :after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-top: 0.2em;
    border-bottom: 2px solid transparent;
  }

  &.selected:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-top: 0.2em;
    border-bottom: 3px solid ${(props) => props.theme.navigationActiveColor};
  }

  &:hover:after {
    content: '';
    display: block;
    margin: 0 auto;
    width: 100%;
    padding-top: 0.2em;
    border-bottom: 2px solid
      ${(props) => c(props.theme.navigationColor).lighten(0.2).toString()};
  }
`;

/**
 * @render react
 * @name Navigation
 * @description Navigation component.
 * @example
 *   <Navigation items={ [
    { to: "/home", label: "Home" },
    { to: "/users", label: "Users" }
  ];} />
 */

StyledLink.defaultProps = {
  theme: {
    navigationFontWeigt: '700',
    navigationPadding: '0',
    navigationFontSize: '2em',
    navigationColor: '#5b5b5b',
    navigationFontColor: '#5b5b5b',
    navigationActiveColor: '#43BDFC',
  },
};

export const NavigationFC = ({ items, location }) => {
  const [navigationItems, setNavigationItems] = useState(items);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [widths, setWidths] = useState([]);

  const navContainerRev = useRef();
  const navRef = useRef();

  const itemsMemo = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        active:
          item.to === location.pathname.match(/^\/[^/]*/)[0] ? true : false,
      })),
    [items, location.pathname]
  );

  const calculateWidth = () => {
    if (
      navRef &&
      navRef.current &&
      navContainerRev &&
      navContainerRev.current
    ) {
      const containerWidth = navContainerRev.current.getBoundingClientRect()
        .width;
      const index = widths.findIndex((i) => i + 200 > containerWidth);

      setNavigationItems(index === -1 ? itemsMemo : itemsMemo.slice(0, index));
      setDropdownItems(index === -1 ? [] : itemsMemo.slice(index));
    }
  };

  const calculateWidthDebounced = _debounce(calculateWidth, 50);

  useEffect(() => {
    calculateWidth();
  }, [itemsMemo]);

  useEffect(() => {
    if (
      navRef &&
      navRef.current &&
      navContainerRev &&
      navContainerRev.current &&
      !widths.length
    ) {
      setWidths(
        Array.from(navRef.current.children).reduce(
          (acc, cur, i) => [...acc, (acc[i - 1] || 0) + cur.clientWidth],
          []
        )
      );
    }
  }, [navContainerRev, navRef, widths]);

  useEffect(() => {
    calculateWidth();
    window.addEventListener('resize', calculateWidthDebounced);
    return () => {
      window.removeEventListener('resize', calculateWidthDebounced);
    };
  }, [widths]);

  return (
    <div ref={navContainerRev} style={{ flex: '1 0 auto' }}>
      <Nav ref={navRef}>
        {navigationItems.map((l, i) => (
          <li key={`navi-${i}`} style={{ padding: '0 1em' }}>
            <StyledLink
              to={l.to ? l.to : null}
              onClick={l.onClick ? l.onClick : null}
              activeClassName="selected"
            >
              {l.label}
            </StyledLink>
          </li>
        ))}
        {!!dropdownItems.length && (
          <li>
            <DropdownNavigation options={dropdownItems}>
              <StyledLink>
                <Flex>
                  More
                  <Box>
                    <Icon style={{ height: 18 }} icon="chevron-down" />
                  </Box>
                </Flex>
              </StyledLink>
            </DropdownNavigation>
          </li>
        )}
      </Nav>
    </div>
  );
};

NavigationFC.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default withRouter(NavigationFC);
