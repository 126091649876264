import React, { useState, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import merge from 'lodash.merge';

import Card from '../../atoms/Card';

const StyledCard = styled(Card)`
  margin: 2em;
  padding: 2em 0px;
  display: flex;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: ${props =>
      _get(props, 'theme.breakpoints[0]', '40em')}) {
    padding: 0.1em;
    margin: 0.1em;
  }
`;

const Install = props => {
  const {
    match: { url },
    location,
    match,
    history,
    pages,
  } = props;
  const [step, setStep] = useState(1);
  const [device, setDevice] = useState({
    id: '',
    name: '',
    meta: {},
    settings: {},
  });

  //reload install when accessing directly advanced steps
  if (
    pages.findIndex(p => location.pathname.includes(p.location)) > 0 &&
    pages.findIndex(p => location.pathname.includes(p.location)) > step - 1
  )
    window.location.replace(`${url}${pages[0].location}`);

  useEffect(() => {
    // always forward to right place if step and location is mismatching
    const pathParts = location.pathname.split('/');
    const pathName = `/${pathParts[pathParts.length - 1]}`;
    if (pathName !== pages[step - 1].location)
      history.push(`${url}${pages[step - 1].location}`);
  }, [location]);

  const maxSteps = pages.length;

  const nextPage = () => {
    if (step < maxSteps) {
      const nextStep = step + 1;
      setStep(nextStep);
      history.push(`${url}${pages[nextStep - 1].location}`);
    }
  };

  const prevPage = () => {
    if (step > 1) {
      const prevStep = step - 1;
      setStep(prevStep);
      history.push(`${url}${pages[prevStep - 1].location}`);
    }
  };

  const setDeviceData = data => {
    console.log('data', data);
    if (
      data.id !== undefined &&
      data.tag !== undefined &&
      data.meta !== undefined &&
      data.settings !== undefined
    ) {
      setDevice({
        id: data.id,
        tag: data.tag,
        name: '',
        meta: data.meta !== null ? data.meta : {},
        settings: data.settings !== null ? data.settings : {},
        warning: data.warning,
      });
    }
    if (data.id)
      setDevice(device => {
        device.id = data.id;
        return device;
      });
    if (data.tag)
      setDevice(device => {
        device.tag = data.tag;
        return device;
      });

    if (data.replaceDevices) {
      setDevice(device => {
        device.replaceDevices = data.replaceDevices;
        return device;
      });
    }

    if (data.secondary) {
      setDevice(device => {
        device.meta = merge(device.meta, { secondary: data.secondary });
        return device;
      });
    }

    if (data.installedDevices) {
      setDevice(device => {
        device.installedDevices = data.installedDevices;
        return device;
      });
    }

    if (data.meta)
      setDevice(device => {
        device.meta = merge(device.meta, data.meta);
        return device;
      });
    if (data.settings)
      setDevice(device => {
        device.settings = merge(device.settings, data.settings);
        return device;
      });

    if (data.name)
      setDevice(device => {
        device.name = data.name;
        return device;
      });
    if (data.location)
      setDevice(device => {
        device.meta.location = data.location;
        return device;
      });
    if (data.warning)
      setDevice(device => {
        device.warning = data.warning;
        return device;
      });
  };

  return (
    <StyledCard>
      <Switch>
        {pages.map((page, i) => {
          return (
            <Route
              path={`${url}${page.location}`}
              key={i}
              component={() =>
                React.cloneElement(page.component, {
                  ...props,
                  step,
                  setDeviceData,
                  nextPage,
                  prevPage,
                  device,
                  maxSteps,
                  cancel: () => {
                    history.push('/');
                  },
                  onDone: () => {
                    history.push('/');
                  },
                })
              }
            />
          );
        })}
        <Redirect from={url} to={`${url}${pages[0].location}`} />
      </Switch>
    </StyledCard>
  );
};

export default withRouter(Install);
