import React, {useState} from 'react';
import styled from 'styled-components';
import {Flex, Box} from '@rebass/grid';
import Button from '../Button';
import Icon from '../Icon';


const StyledSidebar = styled(Box)`
height: 100%
min-width: 12em;
display: ${props => props.isOpened ? 'block' : 'none'}
transition-duration: .3s;
font-family: ${props => props.theme.primaryFont};
background-color: ${props => props.theme.cardBackground};
color: ${props => props.theme.cardColor};
padding: ${props => props.theme.cardPadding};
box-shadow: ${props => props.theme.cardBoxShadow};
border-right: 3px solid ${props => props.theme.tertiaryThemeColor}
`;

const Sidebar = ({children, isOpened = false}) => <StyledSidebar isOpened={isOpened}>{children}</StyledSidebar>;

export default Sidebar;

