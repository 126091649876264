import gql from 'graphql-tag';
import {
  orderInfoFragment,
  productsInfoFragment,
  accessoriesInfoFragment,
  customerInfoFragment,
  notificationReceiversFragment,
  devicesInfoFragment,
  detailsInfoFragment,
} from '../fragments/orders';

export const CREATE_ORDER = gql`
  mutation Mutation($input: OrderCreateInput!) {
    createOrder(input: $input) {
      ...orderInfo
      ...productsInfo
      ...accessoriesInfo
      ...customerInfo
      ...notificationReceivers
      ...devices
      ...detailsInfo
    }
  }
  ${orderInfoFragment}
  ${productsInfoFragment}

  ${accessoriesInfoFragment}
  ${customerInfoFragment}
  ${notificationReceiversFragment}
  ${devicesInfoFragment}
  ${detailsInfoFragment}
`;

export const UPDATE_ORDER = gql`
  mutation Mutation($id: ID!, $input: OrderUpdateInput!) {
    updateOrder(id: $id, input: $input) {
      ...orderInfo
      ...productsInfo
      ...accessoriesInfo
      ...customerInfo
      ...notificationReceivers
      ...devices
      ...detailsInfo
    }
  }
  ${orderInfoFragment}
  ${productsInfoFragment}

  ${accessoriesInfoFragment}
  ${customerInfoFragment}
  ${notificationReceiversFragment}
  ${devicesInfoFragment}
  ${detailsInfoFragment}
`;

export const DELETE_ORDER = gql`
  mutation Mutation($id: ID!) {
    deleteOrder(id: $id)
  }
`;
