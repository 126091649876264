import gql from 'graphql-tag';
import { uiNotificationInfoFragment } from '../fragments/ui-notifications';
import { pageInfoFragment } from '../fragments/pageInfo';

export const UI_NOTIFICATIONS = gql`
  query notifications($filter: UiNotificationFilter) {
    uiNotifications(filter: $filter) {
      edges {
        ...uiNotificationInfo
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${uiNotificationInfoFragment}
  ${pageInfoFragment}
`;
