import React, { Component } from 'react';
import {
  logout,
  selectTag,
  getSelectedTag,
  getUserTags,
  getUserNotification,
  setUserNotification,
  getIdToken,
  getCurrentUser,
} from '../../connect-react-lib';

import connectComm from '@livion/connect2-communication';

const { Provider, Consumer } = React.createContext(null);

const devices = {};

// TODO refactor to be DeviceRealTimeProvider
export class LivionConnectProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      navigationItems: props.navigationItems, // TODO move somewhere else
      device: null,
    };
  }

  render() {
    return (
      <Provider
        value={{
          state: { ...this.state, ...this.props.state },
          actions: {
            ...this.props.actions,
            refreshUser: () => this.setState({ user: getCurrentUser() }),
            logout: logout,
            selectTag: selectTag,
            getSelectedTag: getSelectedTag,
            getUserTags: getUserTags,
            getUserNotification: getUserNotification,
            setUserNotification: setUserNotification,
            connectDevice: async (device, hooks) => {
              const token = await getIdToken();
              const getToken = async () => {
                return await getIdToken();
              };
              if (!devices[device.id]) {
                devices[device.id] = new connectComm(
                  token,
                  device.id,
                  device.socketServer,
                  getToken,
                  process.env.REACT_APP_APPNAME
                );
              } else {
                devices[device.id].token = token;
              }
              await devices[device.id].connect();
              const v = await devices[device.id].version();
              devices[device.id].appName = v.appName;
              devices[device.id].appVersion = v.appVersion;
              if (!hooks) this.setState({ device: devices[device.id] });
              return devices[device.id];
            },
            rebootDevice: async (id) => {
              if (devices[id]) {
                console.log('rebooting');
                await devices[id].sendCustom('reboot-device');
              }
            },
            disconnectDevice: async (id, hooks) => {
              if (devices[id]) {
                devices[id].destroy();
                devices[id] = null;
              }
              if (!hooks) this.setState({ device: null });
            },
            repeaterSetup: async (id) => {
              if (devices[id]) {
                console.log('repeater-setup');
                return await devices[id].sendCustom(
                  'repeater-setup',
                  {},
                  180000
                );
              }
            },
          },
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export const withLivionConnect = (Component) =>
  class AppProvider extends React.Component {
    render() {
      return (
        <Consumer>
          {(value) => <Component {...this.props} {...value} />}
        </Consumer>
      );
    }
  };
