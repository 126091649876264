import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import styled from 'styled-components';

import InputLabel from '../../atoms/InputLabel';
import Error from '../../atoms/Error';
import Input from '../../atoms/Input';
import InputWrapper from '../../atoms/InputWrapper';
import Icon from '../../atoms/Icon';
import { composeValidators } from '../../utils';
import Hint from '../utils/Hint';
const VisibilityToggleIcon = styled(Icon)`
  position: absolute;
  right: 1em;
  bottom: 0;
  margin-bottom: 0.6rem;
  fill: ${({ hidden }) => (hidden ? '#999' : '#666')};
  transform-origin: 50% 50% 0;
  transition: transform ease 150ms;
  transform: ${({ hidden }) => (hidden ? 'scaleY(.95)' : 'scaleY(1.05)')};
`;

class PasswordField extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    hide: PropTypes.bool,
    toggle: PropTypes.bool,
    onChange: PropTypes.func,
    validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  };

  static defaultProps = {
    label: null,
    hide: true,
    toggle: false,
    onChange: null,
    validate: () => {},
  };

  state = {
    hide: this.props.hide,
  };

  componentDidUpdate({ hide }) {
    if (this.props.hide !== hide) {
      this.setState({ hide });
    }
  }

  render() {
    const {
      name,
      validate,
      label,
      toggle,
      onChange,
      validateOnInit,
      required,
      optional,
      ...attributes
    } = this.props;
    const { hide } = this.state;

    return (
      <Field name={name} validate={composeValidators(validate)}>
        {({ input, meta }) => (
          <InputWrapper style={{ position: 'relative' }} data-cy={name}>
            {label && <InputLabel htmlFor={input.name}>{label}</InputLabel>}
            <Input
              {...attributes}
              {...input}
              onChange={event => {
                if (onChange) onChange(event);
                input.onChange(event);
              }}
              type={hide ? 'password' : 'text'}
              invalid={(meta.touched || validateOnInit) && meta.error}
            />
            {toggle && (
              <VisibilityToggleIcon
                hidden={hide}
                onClick={() => this.setState({ hide: !hide })}
                icon="eye"
              />
            )}

            {(meta.touched || validateOnInit) && meta.error && (
              <Error id={`${input.name}Error`} role="alert" palette="danger">
                {meta.error}
              </Error>
            )}
            {!((meta.touched || validateOnInit) && meta.error) && required && (
              <Hint role="info">Required</Hint>
            )}
            {!((meta.touched || validateOnInit) && meta.error) && optional && (
              <Hint role="info">Optional</Hint>
            )}
          </InputWrapper>
        )}
      </Field>
    );
  }
}

export default PasswordField;
