import React from 'react';
import Button from '../Button';
import styled from 'styled-components';

import Icon from '../Icon';


const StyledIcon = styled(Icon)`
fill: ${props => props.theme.primaryButtonTextColor};
`;

const SidebarButton = ({onTagsSidebarClick, buttonText = 'Areas', isOpened}) => 
<Button onClick={onTagsSidebarClick}>
 {buttonText}
<StyledIcon
  icon={isOpened ? 'chevron-left' : 'chevron-right'}
  size={'1.2em'}
/>
</Button>

export default SidebarButton;