import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const styles = css`
  font-family: ${props => props.theme.primaryFont};
  color: ${props => props.theme.listItemColor};
  padding: ${props => props.theme.listItemPadding};
  list-style-type: ${props => props.theme.listItemStyleType};
`;

/**
 * @render react
 * @name ListItem
 * @description ListItem component
 * @example
 *  <ul>
      <ListItem>Item 1</ListItem>
    </ul>
 */
const ListItem = styled.li`
  ${styles};
`;

ListItem.defaultProps = {
  theme: {
    listItemColor: "#000000",
    listItemPadding: "0",
    listItemStyleType: "disc"
  }
};

ListItem.propTypes = {
  children: PropTypes.any
};

export default ListItem;
