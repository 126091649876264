import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: any;
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};













export type Accessory = {
  __typename?: 'Accessory';
  id?: Maybe<Scalars['ID']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  imgUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  deliveredQuantity?: Maybe<Scalars['Int']>;
};

export type AccessoryFilter = {
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AccessoryInput = {
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  imgUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AccessoryList = {
  __typename?: 'AccessoryList';
  edges?: Maybe<Array<Maybe<Accessory>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum ActionCategoryEnum {
  Daily = 'daily',
  Administrative = 'administrative',
  Exception = 'exception',
  Debug = 'debug'
}

export type ActionCount = {
  __typename?: 'ActionCount';
  action?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type ActivateIloqKeyResponse = {
  __typename?: 'ActivateIloqKeyResponse';
  canProgram?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export type ActivationInput = {
  action?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  js?: Maybe<Scalars['String']>;
};

export type ActivationType = {
  __typename?: 'ActivationType';
  action?: Maybe<Scalars['String']>;
  delay?: Maybe<Scalars['Int']>;
  js?: Maybe<Scalars['String']>;
};

export type AddIloqLocksToBuildingInput = {
  buildingId: Scalars['String'];
  serialNumber: Scalars['Int'];
};

export type Agreement = {
  __typename?: 'Agreement';
  id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};

export type Alarm = {
  __typename?: 'Alarm';
  id: Scalars['ID'];
  deviceName?: Maybe<Scalars['ID']>;
  ruleId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  ack?: Maybe<AlarmAckType>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  eventHistory?: Maybe<Array<Maybe<EventLogType>>>;
  routingHistory?: Maybe<Array<Maybe<RoutingHistoryType>>>;
  updatedAt?: Maybe<Scalars['Date']>;
  rootCause?: Maybe<Scalars['String']>;
  solutions?: Maybe<Array<Maybe<SolutionType>>>;
  product?: Maybe<Scalars['String']>;
  publicAlarm?: Maybe<Scalars['Boolean']>;
};

export type AlarmAckInput = {
  comment?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type AlarmAckType = {
  __typename?: 'AlarmAckType';
  comment?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type AlarmCountFilter = {
  timestamp?: Maybe<Timerange>;
  tag?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Date']>;
  priority?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicAlarm?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AlarmDelays = {
  __typename?: 'AlarmDelays';
  keyNotReturned?: Maybe<Scalars['Int']>;
};

export type AlarmDelaysInput = {
  keyNotReturned?: Maybe<Scalars['Int']>;
};

export type AlarmFilter = {
  timestamp?: Maybe<Timerange>;
  id?: Maybe<Scalars['ID']>;
  tag?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Date']>;
  ruleId?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  publicAlarm?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
};

export type AlarmInput = {
  ruleId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  ack?: Maybe<AlarmAckInput>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  publicAlarm?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  eventHistory?: Maybe<Array<Maybe<EventLogInput>>>;
};

export type AlarmList = {
  __typename?: 'AlarmList';
  edges?: Maybe<Array<Maybe<Alarm>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AlarmReceiverInput = {
  mail?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  activation?: Maybe<Scalars['Boolean']>;
  deactivation?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
};

export type AlarmReceiverType = {
  __typename?: 'AlarmReceiverType';
  mail?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  activation?: Maybe<Scalars['Boolean']>;
  deactivation?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
};

export type AlarmRoutingUpdateInput = {
  routingId: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Date']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
};

export type AlarmRule = {
  __typename?: 'AlarmRule';
  id: Scalars['ID'];
  source?: Maybe<Scalars['String']>;
  alarmName?: Maybe<Scalars['String']>;
  isNotification?: Maybe<Scalars['Boolean']>;
  delivery?: Maybe<Scalars['String']>;
  emailLimit?: Maybe<Scalars['Int']>;
  smsLimit?: Maybe<Scalars['Int']>;
  limitTimeInterval?: Maybe<Scalars['Int']>;
  activation?: Maybe<ActivationType>;
  deactivation?: Maybe<ActivationType>;
  reactivation?: Maybe<ActivationType>;
  tag?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  messages?: Maybe<Array<Maybe<MessagesType>>>;
  enabled?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  updated?: Maybe<Scalars['Date']>;
  receivers?: Maybe<Array<Maybe<AlarmReceiverType>>>;
  priority?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  rootCause?: Maybe<Scalars['String']>;
  solutions?: Maybe<Array<Maybe<SolutionType>>>;
  chatHook?: Maybe<Scalars['String']>;
};

export type AlarmRuleFilter = {
  timestamp?: Maybe<Timerange>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  alarmName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  fullText?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  isNotification?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['String']>;
};

export type AlarmRuleInput = {
  source?: Maybe<Scalars['String']>;
  alarmName: Scalars['String'];
  isNotification?: Maybe<Scalars['Boolean']>;
  delivery?: Maybe<Scalars['String']>;
  emailLimit?: Maybe<Scalars['Int']>;
  smsLimit?: Maybe<Scalars['Int']>;
  limitTimeInterval?: Maybe<Scalars['Int']>;
  activation?: Maybe<ActivationInput>;
  deactivation?: Maybe<ActivationInput>;
  reactivation?: Maybe<ActivationInput>;
  tag: Scalars['String'];
  products?: Maybe<Array<Scalars['String']>>;
  categories?: Maybe<Array<Scalars['String']>>;
  messages?: Maybe<Array<Maybe<MessagesInput>>>;
  enabled?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  updated?: Maybe<Scalars['Date']>;
  receivers?: Maybe<Array<Maybe<AlarmReceiverInput>>>;
  priority: Scalars['String'];
  rootCause?: Maybe<Scalars['String']>;
  solutions?: Maybe<Array<Maybe<SolutionInput>>>;
  chatHook?: Maybe<Scalars['String']>;
};

export type AlarmRuleList = {
  __typename?: 'AlarmRuleList';
  edges?: Maybe<Array<Maybe<AlarmRule>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AlarmRuleUpdateInput = {
  source?: Maybe<Scalars['String']>;
  alarmName?: Maybe<Scalars['String']>;
  isNotification?: Maybe<Scalars['Boolean']>;
  delivery?: Maybe<Scalars['String']>;
  emailLimit?: Maybe<Scalars['Int']>;
  smsLimit?: Maybe<Scalars['Int']>;
  limitTimeInterval?: Maybe<Scalars['Int']>;
  activation?: Maybe<ActivationInput>;
  deactivation?: Maybe<ActivationInput>;
  reactivation?: Maybe<ActivationInput>;
  tag?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  messages?: Maybe<Array<Maybe<MessagesInput>>>;
  enabled?: Maybe<Scalars['Boolean']>;
  created?: Maybe<Scalars['Date']>;
  receivers?: Maybe<Array<Maybe<AlarmReceiverInput>>>;
  priority?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  rootCause?: Maybe<Scalars['String']>;
  solutions?: Maybe<Array<Maybe<SolutionInput>>>;
  chatHook?: Maybe<Scalars['String']>;
};

export type AlarmSearchList = {
  __typename?: 'AlarmSearchList';
  alarmRules?: Maybe<AlarmRuleList>;
  keys?: Maybe<KeyListV2>;
  contracts?: Maybe<ContractList>;
  buildings?: Maybe<BuildingList>;
};

export type AlarmSettings = {
  __typename?: 'AlarmSettings';
  delays?: Maybe<AlarmDelays>;
};

export type AlarmSettingsInput = {
  delays?: Maybe<AlarmDelaysInput>;
};

export type AlarmUpdateFilter = {
  ruleId: Scalars['String'];
  sourceId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Date']>;
};

export type AlarmUpdateInput = {
  end?: Maybe<Scalars['Date']>;
  eventLog?: Maybe<EventLogInput>;
  routingHistory?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type AlarmsCount = {
  __typename?: 'AlarmsCount';
  total?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
};

export type AnonymizationSettings = {
  __typename?: 'AnonymizationSettings';
  privateDataMaxAge?: Maybe<Scalars['Int']>;
  frontCameraDataMaxAge?: Maybe<Scalars['Int']>;
};

export type AnonymizationSettingsInput = {
  privateDataMaxAge?: Maybe<Scalars['Int']>;
  frontCameraDataMaxAge?: Maybe<Scalars['Int']>;
};


export type Apartment = {
  __typename?: 'Apartment';
  id: Scalars['String'];
  address: Scalars['String'];
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
  officeName: Scalars['String'];
  devices?: Maybe<Array<Maybe<TagDevices>>>;
};

export type ApartmentCreateInput = {
  id: Scalars['String'];
  address: Scalars['String'];
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  timezone?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
  officeName: Scalars['String'];
  device?: Maybe<ApartmentDeviceInput>;
};

export type ApartmentDeviceInput = {
  id: Scalars['String'];
  secondary?: Maybe<Scalars['Boolean']>;
  replaceDevices?: Maybe<Array<Scalars['String']>>;
};

export type ApartmentFilter = {
  id?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['String']>;
  officeName?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type ApartmentList = {
  __typename?: 'ApartmentList';
  edges?: Maybe<Array<Maybe<Apartment>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ApartmentUpdateInput = {
  id?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['String']>;
  officeName?: Maybe<Scalars['String']>;
  costCenter?: Maybe<Scalars['String']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  url?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  lockerIndex?: Maybe<Scalars['String']>;
  attachmentId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type AttachmentList = {
  __typename?: 'AttachmentList';
  edges?: Maybe<Array<Maybe<Attachment>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};

export type Bill = {
  __typename?: 'Bill';
  id: Scalars['ID'];
  timestamp?: Maybe<Scalars['Date']>;
  totalPrice?: Maybe<Scalars['Float']>;
  totalExtrasPrice?: Maybe<Scalars['Float']>;
  totalUnitPrice?: Maybe<Scalars['Float']>;
  totalAccessoriesPrice?: Maybe<Scalars['Float']>;
  totalServicePrice?: Maybe<Scalars['Float']>;
  totalSmsPrice?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Maybe<BillTags>>>;
  devices?: Maybe<Array<Maybe<BillDevice>>>;
  accessories?: Maybe<Array<Maybe<BillAccessory>>>;
};

export type BillAccessory = {
  __typename?: 'BillAccessory';
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type BillDevice = {
  __typename?: 'BillDevice';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  unitFee?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  serviceFee?: Maybe<Scalars['Float']>;
  smsFee?: Maybe<Scalars['Float']>;
  smsSegments?: Maybe<Scalars['JSON']>;
  prices?: Maybe<Scalars['JSON']>;
  smsQuantity?: Maybe<Scalars['Int']>;
  activeDays?: Maybe<Scalars['Int']>;
  extrasFee?: Maybe<Scalars['Float']>;
  extras?: Maybe<Array<Maybe<BillExtras>>>;
  isInGroup?: Maybe<Scalars['Boolean']>;
  costCenter?: Maybe<Scalars['String']>;
};

export type BillExtras = {
  __typename?: 'BillExtras';
  name?: Maybe<Scalars['String']>;
  billingInfo?: Maybe<Scalars['JSON']>;
  monthlyFee?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  activeDays?: Maybe<Scalars['Int']>;
};

export type BillFilter = {
  tag?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Timerange>;
};

export type BillList = {
  __typename?: 'BillList';
  edges?: Maybe<Array<Maybe<Bill>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BillPeriod = {
  __typename?: 'BillPeriod';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type BillTags = {
  __typename?: 'BillTags';
  name?: Maybe<Scalars['String']>;
  billingInfo?: Maybe<Scalars['JSON']>;
  deliveryInfo?: Maybe<Scalars['JSON']>;
};

export enum BillinConfigurationServiceType {
  DeviceMonthlySubscription = 'deviceMonthlySubscription',
  DeviceDailySubscription = 'deviceDailySubscription',
  SmsOne = 'smsOne',
  ActiveBuildings = 'activeBuildings',
  InactiveBuildings = 'inactiveBuildings',
  KeysTotal = 'keysTotal',
  StrongAuthentication = 'strongAuthentication',
  Buildings = 'buildings'
}

export type BillingConfiguration = {
  __typename?: 'BillingConfiguration';
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  activeStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  services?: Maybe<Array<Maybe<BillingConfigurationService>>>;
  extras?: Maybe<Array<Maybe<Extra>>>;
};

export type BillingConfigurationInput = {
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  activeStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  services?: Maybe<Array<Maybe<BillingConfigurationServiceInput>>>;
  unit?: Maybe<Array<Maybe<BillingConfigurationServiceInput>>>;
  extras?: Maybe<Array<Maybe<ExtraInput>>>;
};

export type BillingConfigurationService = {
  __typename?: 'BillingConfigurationService';
  articleId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  groupPrice?: Maybe<Scalars['Float']>;
  type?: Maybe<BillinConfigurationServiceType>;
  info?: Maybe<Array<Maybe<BillingConfigurationServiceInfo>>>;
};

export type BillingConfigurationServiceInfo = {
  __typename?: 'BillingConfigurationServiceInfo';
  language?: Maybe<BillingConfigurationServiceInfoLanguage>;
  name?: Maybe<Scalars['String']>;
  freeText?: Maybe<Scalars['String']>;
};

export type BillingConfigurationServiceInfoInput = {
  language?: Maybe<BillingConfigurationServiceInfoLanguage>;
  name?: Maybe<Scalars['String']>;
  freeText?: Maybe<Scalars['String']>;
};

export enum BillingConfigurationServiceInfoLanguage {
  En = 'en',
  Fi = 'fi',
  Se = 'se',
  Dk = 'dk'
}

export type BillingConfigurationServiceInput = {
  articleId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  groupPrice?: Maybe<Scalars['Float']>;
  type?: Maybe<BillinConfigurationServiceType>;
  info?: Maybe<Array<Maybe<BillingConfigurationServiceInfoInput>>>;
};

export type BillingData = {
  __typename?: 'BillingData';
  configurations?: Maybe<Array<Maybe<BillingConfiguration>>>;
};

export type BillingInput = {
  configurations?: Maybe<Array<Maybe<BillingConfigurationInput>>>;
};

export type BillingServicePriceInput = {
  monthlyFee: FeeInput;
  smsFee: FeeInput;
};

export type Building = {
  __typename?: 'Building';
  id: Scalars['ID'];
  name: Scalars['String'];
  parents: Array<Maybe<TagParents>>;
  parent?: Maybe<Scalars['String']>;
  type?: Maybe<BuildingTypeEnum>;
  meta?: Maybe<Scalars['JSON']>;
  syncedData?: Maybe<BuildingSyncedData>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
};

export type BuildingCreateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BuildingTypeEnum>;
  customerId?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  postalOffice: Scalars['String'];
};

export type BuildingFilter = {
  id?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<BuildingTypeEnum>>>;
  connectedLock?: Maybe<Scalars['Int']>;
  hasIloqLocks?: Maybe<Scalars['Boolean']>;
};

export type BuildingIloqSyncedData = {
  __typename?: 'BuildingIloqSyncedData';
  connectedRealEstate?: Maybe<IloqRealEstate>;
  connectedLocks?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type BuildingList = {
  __typename?: 'BuildingList';
  edges?: Maybe<Array<Maybe<Building>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BuildingSyncedData = {
  __typename?: 'BuildingSyncedData';
  iloq?: Maybe<BuildingIloqSyncedData>;
  snt?: Maybe<SntSyncedData>;
};

export enum BuildingTypeEnum {
  Building = 'building',
  BuildingSection = 'buildingSection'
}

export type BuildingUpdateInput = {
  name?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  postalCode: Scalars['String'];
  postalOffice: Scalars['String'];
};

export type BuildingsUsageFilter = {
  organizationUnit?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  timerange: Timerange;
};

export enum Calculation {
  Daily = 'daily',
  Monthly = 'monthly'
}

export type Comment = {
  __typename?: 'Comment';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  tag?: Maybe<Scalars['String']>;
  commentThreadId?: Maybe<Scalars['String']>;
  originator?: Maybe<Originator>;
  photoUrl?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
};

export type CommentFilter = {
  sourceId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
};

export type CommentInput = {
  text: Scalars['String'];
  subtype?: Maybe<Scalars['String']>;
  commentThreadId?: Maybe<Scalars['String']>;
  source: Scalars['String'];
  sourceId: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
};

export type CommentList = {
  __typename?: 'CommentList';
  edges?: Maybe<Array<Maybe<Comment>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CommentThread = {
  __typename?: 'CommentThread';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
  subtype?: Maybe<Scalars['String']>;
  originator?: Maybe<Originator>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
};

export type CommentThreadInput = {
  name: Scalars['String'];
  subtype: Scalars['String'];
  source: Scalars['String'];
  sourceId?: Maybe<Scalars['String']>;
};

export type CommentThreadUpdateInput = {
  name: Scalars['String'];
};

export type CommentUpdateInput = {
  text?: Maybe<Scalars['String']>;
};

export type ConnectVpnOrganizationUnitUsage = {
  __typename?: 'ConnectVpnOrganizationUnitUsage';
  rootUnit?: Maybe<ConnectVpnUsage>;
  childrenUnits?: Maybe<Array<Maybe<ConnectVpnUsage>>>;
};

export type ConnectVpnUsage = {
  __typename?: 'ConnectVpnUsage';
  unit?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  buildings?: Maybe<Scalars['Int']>;
};

export type ConnectionInput = {
  state: Scalars['String'];
  app?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  fixedPhoneNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};

export type ContactPersonDetailsInput = {
  ContactPersonID?: Maybe<Scalars['String']>;
  ContactPersonFirstName?: Maybe<Scalars['String']>;
  ContactPersonLastName: Scalars['String'];
  ContactPersonPhoneNumber?: Maybe<Scalars['String']>;
  ContactPersonEmail?: Maybe<Scalars['String']>;
  ContactPersonOfficeNetvisorKey?: Maybe<Scalars['String']>;
};

export type ContactPersonDetailsType = {
  __typename?: 'ContactPersonDetailsType';
  ContactPersonID?: Maybe<Scalars['String']>;
  ContactPersonFirstName?: Maybe<Scalars['String']>;
  ContactPersonLastName?: Maybe<Scalars['String']>;
  ContactPersonPhoneNumber?: Maybe<Scalars['String']>;
  ContactPersonEmail?: Maybe<Scalars['String']>;
  ContactPersonOfficeNetvisorKey?: Maybe<Scalars['String']>;
};

export type ContactsList = {
  __typename?: 'ContactsList';
  edges?: Maybe<Array<Maybe<Contact>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Contract = {
  __typename?: 'Contract';
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractId?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Maybe<ContractDevices>>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  recurrent?: Maybe<Array<Maybe<Timeslot>>>;
  perm?: Maybe<Scalars['Boolean']>;
  pincode?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<ContractContact>>>;
  freeText?: Maybe<Scalars['String']>;
  keys?: Maybe<Array<Maybe<ContractKey>>>;
  verification?: Maybe<ContractVerification>;
  verified?: Maybe<Scalars['Boolean']>;
  ongoing?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ContractStatusEnum>;
  waitingVerification?: Maybe<Scalars['Boolean']>;
  v2?: Maybe<Scalars['Boolean']>;
  attachments?: Maybe<Array<Maybe<ContractAttachment>>>;
  type?: Maybe<ContractTypeEnum>;
  agreements?: Maybe<Array<Maybe<ContractAgreement>>>;
  comments?: Maybe<CommentList>;
  isPlaceholderContract?: Maybe<Scalars['Boolean']>;
};


export type ContractCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type ContractAgreement = {
  __typename?: 'ContractAgreement';
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
  forceAllAgreements?: Maybe<Scalars['Boolean']>;
};

export type ContractAgreementInput = {
  url: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
  lang: Scalars['String'];
  parent?: Maybe<Scalars['String']>;
  forceAllAgreements?: Maybe<Scalars['Boolean']>;
};

export type ContractAttachment = {
  __typename?: 'ContractAttachment';
  url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type ContractAttachmentInput = {
  url: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type ContractContact = {
  __typename?: 'ContractContact';
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  fixedPhoneNumber?: Maybe<Scalars['String']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
};

export type ContractContactInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  fixedPhoneNumber?: Maybe<Scalars['String']>;
};

export type ContractCreateInput = {
  contractId: Scalars['String'];
  devices: Array<Maybe<ContractDevicesInput>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  perm?: Maybe<Scalars['Boolean']>;
  recurrent?: Maybe<Array<Maybe<TimeslotInput>>>;
  pincode?: Maybe<Scalars['String']>;
  person: Scalars['String'];
  contacts: Array<Maybe<ContractContactInput>>;
  freeText?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<ContractAttachmentInput>>>;
  type?: Maybe<ContractTypeEnum>;
  defaultStartTime?: Maybe<Scalars['String']>;
  defaultEndTime?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
  agreements?: Maybe<Array<Maybe<ContractAgreementInput>>>;
};

export type ContractCreateInputV2 = {
  contractId?: Maybe<Scalars['String']>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  keys?: Maybe<Array<Maybe<ContractKeyInput>>>;
  start: Scalars['Date'];
  end?: Maybe<Scalars['Date']>;
  perm?: Maybe<Scalars['Boolean']>;
  recurrent?: Maybe<Array<Maybe<TimeslotInput>>>;
  pincode?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<ContractContactInput>>>;
  verification: ContractVerificationInput;
  host?: Maybe<Scalars['String']>;
  attachments?: Maybe<UploadAttachmentInput>;
  agreements?: Maybe<Array<Maybe<ContractAgreementInput>>>;
  isPlaceholderContract?: Maybe<Scalars['Boolean']>;
};

export type ContractDevices = {
  __typename?: 'ContractDevices';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
};

export type ContractDevicesInput = {
  id: Scalars['String'];
  keyId: Scalars['String'];
  keyName?: Maybe<Scalars['String']>;
};

export type ContractFilter = {
  devicesIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  keyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  devices?: Maybe<Array<Maybe<ContractDevicesInput>>>;
  contractId?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  startTime?: Maybe<Timerange>;
  endTime?: Maybe<Timerange>;
};

export type ContractFilterV2 = {
  devicesIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
  contractId?: Maybe<Scalars['String']>;
  keyStatus?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  buildings?: Maybe<Array<Maybe<Scalars['String']>>>;
  building?: Maybe<Scalars['String']>;
  verifications?: Maybe<Array<Maybe<ContractVerificationEnum>>>;
  history?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ContractStatusEnum>;
  startTime?: Maybe<Timerange>;
  endTime?: Maybe<Timerange>;
  search?: Maybe<Scalars['String']>;
  timerange?: Maybe<Timerange>;
  excludePlaceholderContracts?: Maybe<Scalars['Boolean']>;
  onGoing?: Maybe<Scalars['Boolean']>;
  future?: Maybe<Scalars['Boolean']>;
  ended?: Maybe<Scalars['Boolean']>;
};

export type ContractKey = {
  __typename?: 'ContractKey';
  id?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
  keyName?: Maybe<Scalars['String']>;
  shared?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
  tagName?: Maybe<Scalars['String']>;
  tagAddress?: Maybe<Scalars['String']>;
  status?: Maybe<KeyStatus>;
  location?: Maybe<KeyLocationEnum>;
  selectedStorage?: Maybe<Storage>;
  contractKeyEvents?: Maybe<Array<Maybe<KeyAction>>>;
  lastContractKeyEvent?: Maybe<KeyAction>;
  contractKeyStatus?: Maybe<ContractKeyStatusEnum>;
  iloqAccessRights?: Maybe<Array<Maybe<IloqAccessRight>>>;
  iloq?: Maybe<KeyIloq>;
  nameOfAccess?: Maybe<Scalars['String']>;
};

export type ContractKeyInput = {
  keyId?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  status?: Maybe<KeyStatusInput>;
  location?: Maybe<KeyLocationEnum>;
  selectedStorage?: Maybe<StorageInput>;
  shared?: Maybe<Scalars['Boolean']>;
  iloqAccessRights?: Maybe<Array<Maybe<IloqAccessRightInput>>>;
  nameOfAccess?: Maybe<Scalars['String']>;
};

export enum ContractKeyStatusEnum {
  KeyFetched = 'keyFetched',
  KeyReturned = 'keyReturned',
  KeyNotFetched = 'keyNotFetched',
  KeyNotReturned = 'keyNotReturned'
}

export type ContractKeyStatusInput = {
  keyId: Scalars['String'];
  keyStatus: KeyActionInput;
};

export type ContractKeysReturnInput = {
  updateContractEndTime?: Maybe<Scalars['Boolean']>;
  returnSignature?: Maybe<Scalars['JSON']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  keys?: Maybe<Array<Maybe<ContractKeyInput>>>;
  host?: Maybe<Scalars['String']>;
};

export type ContractList = {
  __typename?: 'ContractList';
  edges?: Maybe<Array<Maybe<Contract>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ContractSignatureInput = {
  data: Scalars['JSON'];
  host?: Maybe<Scalars['String']>;
  updateContractStartTime?: Maybe<Scalars['Boolean']>;
};

export enum ContractStatusEnum {
  WaitingVerification = 'waitingVerification',
  WaitingStart = 'waitingStart',
  WaitingCustomer = 'waitingCustomer',
  Active = 'active',
  KeyReturned = 'keyReturned',
  KeyNotReturned = 'keyNotReturned',
  KeyNeverFetched = 'keyNeverFetched',
  AllowsKeyReceive = 'allowsKeyReceive',
  NotDefined = 'notDefined'
}

export enum ContractTypeEnum {
  Fetch = 'fetch',
  Return = 'return',
  Default = 'default'
}

export type ContractUpdateInput = {
  devices?: Maybe<Array<Maybe<ContractDevicesInput>>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  perm?: Maybe<Scalars['Boolean']>;
  recurrent?: Maybe<Array<Maybe<TimeslotInput>>>;
  pincode?: Maybe<Scalars['String']>;
  person?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<ContractContactInput>>>;
  freeText?: Maybe<Scalars['String']>;
  attachments?: Maybe<Array<Maybe<ContractAttachmentInput>>>;
  defaultStartTime?: Maybe<Scalars['String']>;
  defaultEndTime?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  agreements?: Maybe<Array<Maybe<ContractAgreementInput>>>;
};

export type ContractUpdateInputV2 = {
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  keys?: Maybe<Array<Maybe<ContractKeyInput>>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  perm?: Maybe<Scalars['Boolean']>;
  recurrent?: Maybe<Array<Maybe<TimeslotInput>>>;
  pincode?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<ContractContactInput>>>;
  verification?: Maybe<ContractVerificationInput>;
  host?: Maybe<Scalars['String']>;
  attachments?: Maybe<UploadAttachmentUpdateInput>;
  agreements?: Maybe<Array<Maybe<ContractAgreementInput>>>;
  isPlaceholderContract?: Maybe<Scalars['Boolean']>;
};

export type ContractVerification = {
  __typename?: 'ContractVerification';
  type?: Maybe<ContractVerificationEnum>;
  data?: Maybe<Scalars['JSON']>;
  returnData?: Maybe<Scalars['JSON']>;
};

export enum ContractVerificationEnum {
  None = 'none',
  Signature = 'signature',
  SignatureLink = 'signatureLink',
  StrongAuthentication = 'strongAuthentication'
}

export type ContractVerificationInput = {
  type?: Maybe<ContractVerificationEnum>;
  data?: Maybe<Scalars['JSON']>;
};

export type CountriesAndAreasTree = {
  __typename?: 'CountriesAndAreasTree';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  areas?: Maybe<Array<Maybe<TagNameAndId>>>;
};

export type CreateContactInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  fixedPhoneNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};

export type CreateOrganizationUnitInput = {
  tag: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
};

export type CreateProductionDeviceInput = {
  settings?: Maybe<Scalars['JSON']>;
  name: Scalars['String'];
  tag: Scalars['String'];
  product: Scalars['String'];
  configuration?: Maybe<Scalars['String']>;
  env?: Maybe<Scalars['JSON']>;
  sensors?: Maybe<Array<Maybe<SensorCreateInput>>>;
};

export type CreatorType = {
  __typename?: 'CreatorType';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CustomerAdditionalInformationInput = {
  InvoicingLanguage?: Maybe<Scalars['String']>;
};

export type CustomerAdditionalInformationType = {
  __typename?: 'CustomerAdditionalInformationType';
  InvoicingLanguage?: Maybe<Scalars['String']>;
  DefaultPaymentTerm?: Maybe<Scalars['String']>;
};

export type CustomerBaseInformationInput = {
  InternalIdentifier?: Maybe<Scalars['String']>;
  ExternalIdentifier: Scalars['String'];
  OrganizationUnitNumber?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  StreetAddress: Scalars['String'];
  City: Scalars['String'];
  PostNumber: Scalars['String'];
  Country: Scalars['String'];
  PhoneNumber?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  EmailInvoicingAddress?: Maybe<Scalars['String']>;
};

export type CustomerBaseInformationType = {
  __typename?: 'CustomerBaseInformationType';
  NetvisorKey?: Maybe<Scalars['String']>;
  InternalIdentifier?: Maybe<Scalars['String']>;
  ExternalIdentifier?: Maybe<Scalars['String']>;
  OrganizationUnitNumber?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  StreetAddress?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  PostNumber?: Maybe<Scalars['String']>;
  Country?: Maybe<Scalars['String']>;
  PhoneNumber?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  EmailInvoicingAddress?: Maybe<Scalars['String']>;
};

export type CustomerContactDetailsInput = {
  ContactPerson: Scalars['String'];
  ContactPersonEmail: Scalars['String'];
  ContactPersonPhone: Scalars['String'];
};

export type CustomerContactDetailsType = {
  __typename?: 'CustomerContactDetailsType';
  ContactId?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  ContactPersonEmail?: Maybe<Scalars['String']>;
  ContactPersonPhone?: Maybe<Scalars['String']>;
};

export type CustomerContactPersonDetailsInput = {
  CustomerContactPerson?: Maybe<ContactPersonDetailsInput>;
};

export type CustomerContactPersonDetailsType = {
  __typename?: 'CustomerContactPersonDetailsType';
  CustomerContactPerson?: Maybe<ContactPersonDetailsType>;
};

export type CustomerData = {
  __typename?: 'CustomerData';
  billYearly?: Maybe<Scalars['Boolean']>;
  billServicesSeparately?: Maybe<Scalars['Boolean']>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  billSubscriptionSeparately?: Maybe<Scalars['Boolean']>;
  syncToNetvisor?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<CustomerDetailedData>;
  unit?: Maybe<CustomerDetailedData>;
};

export enum CustomerDataStatusEnum {
  Ok = 'ok',
  Missing = 'missing'
}

export type CustomerDataUpsertInput = {
  billYearly?: Maybe<Scalars['Boolean']>;
  billSubscriptionSeparately?: Maybe<Scalars['Boolean']>;
  billServicesSeparately?: Maybe<Scalars['Boolean']>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  syncToNetvisor?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<CustomerDetailedDataInput>;
  unit: CustomerDetailedDataInput;
};

export type CustomerDeliveryDetailsInput = {
  DeliveryName: Scalars['String'];
  DeliveryStreetAddress: Scalars['String'];
  DeliveryCity: Scalars['String'];
  DeliveryPostNumber: Scalars['String'];
  DeliveryCountry: Scalars['String'];
};

export type CustomerDeliveryDetailsType = {
  __typename?: 'CustomerDeliveryDetailsType';
  DeliveryName?: Maybe<Scalars['String']>;
  DeliveryStreetAddress?: Maybe<Scalars['String']>;
  DeliveryCity?: Maybe<Scalars['String']>;
  DeliveryPostNumber?: Maybe<Scalars['String']>;
  DeliveryCountry?: Maybe<Scalars['String']>;
};

export type CustomerDetailedData = {
  __typename?: 'CustomerDetailedData';
  netvisorKey?: Maybe<Scalars['Int']>;
  differentDeliveryAddress?: Maybe<Scalars['Boolean']>;
  CustomerBaseInformation?: Maybe<CustomerBaseInformationType>;
  CustomerFinvoiceDetails?: Maybe<CustomerFinvoiceDetailsType>;
  CustomerDeliveryDetails?: Maybe<CustomerDeliveryDetailsType>;
  CustomerContactDetails?: Maybe<CustomerContactDetailsType>;
  CustomerAdditionalInformation?: Maybe<CustomerAdditionalInformationType>;
  CustomerInvoiceData?: Maybe<CustomerInvoiceDataType>;
  CustomerContactPersons?: Maybe<CustomerContactPersonDetailsType>;
};

export type CustomerDetailedDataInput = {
  netvisorKey?: Maybe<Scalars['Int']>;
  differentDeliveryAddress?: Maybe<Scalars['Boolean']>;
  CustomerBaseInformation?: Maybe<CustomerBaseInformationInput>;
  CustomerFinvoiceDetails?: Maybe<CustomerFinvoiceDetailsInput>;
  CustomerDeliveryDetails?: Maybe<CustomerDeliveryDetailsInput>;
  CustomerContactDetails?: Maybe<CustomerContactDetailsInput>;
  CustomerAdditionalInformation?: Maybe<CustomerAdditionalInformationInput>;
  CustomerInvoiceData?: Maybe<CustomerInvoiceDataInput>;
  CustomerContactPersons?: Maybe<CustomerContactPersonDetailsInput>;
};

export type CustomerFilter = {
  tag?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['Int']>;
  customerCodeList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  billable?: Maybe<Scalars['Boolean']>;
  billYearly?: Maybe<Scalars['Boolean']>;
  customerDataStatus?: Maybe<CustomerDataStatusEnum>;
};

export type CustomerFinvoiceDetailsInput = {
  FinvoiceAddress?: Maybe<Scalars['String']>;
  FinvoiceRouterCode?: Maybe<Scalars['String']>;
};

export type CustomerFinvoiceDetailsType = {
  __typename?: 'CustomerFinvoiceDetailsType';
  FinvoiceAddress?: Maybe<Scalars['String']>;
  FinvoiceRouterCode?: Maybe<Scalars['String']>;
};

export type CustomerInvoiceDataInput = {
  vatRate?: Maybe<Scalars['Float']>;
  vatCode?: Maybe<Scalars['String']>;
  sellerAccount?: Maybe<Scalars['Int']>;
  paymentTerms?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  disableTaxClause?: Maybe<Scalars['Boolean']>;
};

export type CustomerInvoiceDataType = {
  __typename?: 'CustomerInvoiceDataType';
  vatRate?: Maybe<Scalars['Float']>;
  vatCode?: Maybe<Scalars['String']>;
  sellerAccount?: Maybe<Scalars['Int']>;
  paymentTerms?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  disableTaxClause?: Maybe<Scalars['Boolean']>;
};

export type CustomerUpsertInput = {
  billYearly?: Maybe<Scalars['Boolean']>;
  billSubscriptionSeparately?: Maybe<Scalars['Boolean']>;
  billServicesSeparately?: Maybe<Scalars['Boolean']>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  syncToNetvisor?: Maybe<Scalars['Boolean']>;
  subscription?: Maybe<CustomerDetailedDataInput>;
  unit: CustomerDetailedDataInput;
};


export type DeactivateIloqKeyResponse = {
  __typename?: 'DeactivateIloqKeyResponse';
  canProgram?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export type DelayedEvent = {
  __typename?: 'DelayedEvent';
  id: Scalars['ID'];
  timerId?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  event?: Maybe<DelayedEventData>;
  schedule?: Maybe<Scalars['Date']>;
  state?: Maybe<Scalars['Int']>;
  ruleId?: Maybe<Scalars['String']>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type DelayedEventData = {
  __typename?: 'DelayedEventData';
  action?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceId?: Maybe<Scalars['String']>;
  data?: Maybe<EventData>;
};

export type DelayedEventDataInput = {
  action: Scalars['String'];
  source: Scalars['String'];
  sourceId: Scalars['String'];
  originator?: Maybe<OriginatorInput>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceId?: Maybe<Scalars['String']>;
  data: EventDataInput;
  group?: Maybe<EventGroup>;
  system?: Maybe<Scalars['Boolean']>;
};

export type DelayedEventFilter = {
  sourceId?: Maybe<Scalars['String']>;
  ruleId?: Maybe<Scalars['String']>;
  timerId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  event?: Maybe<Scalars['JSON']>;
  scheduled?: Maybe<Timerange>;
};

export type DelayedEventInput = {
  timerId: Scalars['String'];
  sourceId: Scalars['String'];
  event: DelayedEventDataInput;
  schedule: Scalars['Date'];
  state: Scalars['Int'];
  ruleId: Scalars['String'];
};

export type DelayedEventUpdateInput = {
  timerId: Scalars['String'];
};

export type DelayedEventsList = {
  __typename?: 'DelayedEventsList';
  edges?: Maybe<Array<Maybe<DelayedEvent>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DeleteAgreementInput = {
  agreementId: Scalars['ID'];
  name: Scalars['String'];
  lang: Scalars['String'];
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  privateKey?: Maybe<Scalars['String']>;
  socketServer?: Maybe<Scalars['String']>;
  settings?: Maybe<DeviceSettings>;
  meta?: Maybe<Scalars['JSON']>;
  alarmReceivers?: Maybe<Array<Maybe<ReceiversType>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversType>>>;
  lifecycleState?: Maybe<Scalars['String']>;
  connectionState?: Maybe<Scalars['String']>;
  sensors?: Maybe<Array<Maybe<Sensor>>>;
  connectionEvent?: Maybe<Event>;
  product?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  productionTests?: Maybe<DeviceTests>;
  lifecycleEvent?: Maybe<Event>;
  connectionEvents?: Maybe<EventList>;
  lifecycleEvents?: Maybe<EventList>;
  events?: Maybe<EventList>;
  productionStatus?: Maybe<ProductionStatus>;
  state?: Maybe<DeviceState>;
  productDetails?: Maybe<Product>;
  updatedAt?: Maybe<Scalars['Date']>;
  extras?: Maybe<Array<Maybe<DeviceExtras>>>;
  hwConfiguration?: Maybe<HwConfiguration>;
  activePublicAlarms?: Maybe<AlarmList>;
  hasReservationSupport?: Maybe<Scalars['Boolean']>;
  hasReservationSyncSupport?: Maybe<Scalars['Boolean']>;
  hasSmartLockerSupport?: Maybe<Scalars['Boolean']>;
  supportedLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  anonymizationSettings?: Maybe<Scalars['JSON']>;
  virtual?: Maybe<Scalars['Boolean']>;
  synced?: Maybe<Scalars['JSON']>;
  deviceSw?: Maybe<Array<Maybe<DeviceSw>>>;
  lockers?: Maybe<Array<Maybe<Locker>>>;
  deviceGroup?: Maybe<DeviceGroup>;
  syncGroup?: Maybe<DeviceGroup>;
  iloq?: Maybe<DeviceIloqSettings>;
  location?: Maybe<DeviceLocation>;
};


export type DeviceConnectionEventsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<EventFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type DeviceLifecycleEventsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<EventFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type DeviceEventsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<EventFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type DeviceActivePublicAlarmsArgs = {
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type DeviceAlive = {
  __typename?: 'DeviceAlive';
  timestamp?: Maybe<Scalars['Date']>;
  socketServer?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['JSON']>;
  state?: Maybe<Scalars['JSON']>;
  sw?: Maybe<Scalars['String']>;
};

export type DeviceCommand = {
  __typename?: 'DeviceCommand';
  name?: Maybe<Scalars['String']>;
  command?: Maybe<Scalars['String']>;
};

export type DeviceCommandInput = {
  name: Scalars['String'];
  command: Scalars['String'];
};

export type DeviceDetailsInput = {
  name?: Maybe<Scalars['String']>;
};

export type DeviceExtraInput = {
  name?: Maybe<Scalars['String']>;
  state?: Maybe<ExtraStateEnum>;
};

export type DeviceExtras = {
  __typename?: 'DeviceExtras';
  name?: Maybe<Scalars['String']>;
  state?: Maybe<ExtraStateEnum>;
  description?: Maybe<Scalars['String']>;
  info?: Maybe<Array<Maybe<BillingConfigurationServiceInfo>>>;
  monthlyFee?: Maybe<Scalars['Float']>;
};

export type DeviceFilter = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  tagExact?: Maybe<Scalars['String']>;
  socketServer?: Maybe<Scalars['String']>;
  lifecycleState?: Maybe<Scalars['String']>;
  lifecycleStates?: Maybe<Array<Maybe<Scalars['String']>>>;
  connectionState?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  configuration?: Maybe<Scalars['String']>;
  algoliaFilters?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalOffice?: Maybe<Scalars['String']>;
};

export type DeviceGroup = {
  __typename?: 'DeviceGroup';
  id?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Maybe<GroupDevice>>>;
  wlan?: Maybe<GroupWlan>;
  config?: Maybe<GroupSyncConfig>;
};

export type DeviceGroupInput = {
  devices: Array<Maybe<GroupDeviceInput>>;
  wlan?: Maybe<GroupWlanInput>;
  config?: Maybe<GroupSyncConfigInput>;
};

export type DeviceIloqInput = {
  n50xSerial?: Maybe<Scalars['String']>;
};

export type DeviceIloqSettings = {
  __typename?: 'DeviceIloqSettings';
  n50xSerial?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  product?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  socketServer?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  alarmReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  settings?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
  virtual?: Maybe<Scalars['Boolean']>;
  iloq?: Maybe<DeviceIloqInput>;
  location?: Maybe<DeviceLocationInput>;
};

export type DeviceList = {
  __typename?: 'DeviceList';
  edges?: Maybe<Array<Maybe<Device>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DeviceLocation = {
  __typename?: 'DeviceLocation';
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalOffice?: Maybe<Scalars['String']>;
};

export type DeviceLocationInput = {
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  postalOffice?: Maybe<Scalars['String']>;
};

export type DeviceReport = {
  __typename?: 'DeviceReport';
  deviceId?: Maybe<Scalars['String']>;
  actions?: Maybe<Array<Maybe<ActionCount>>>;
};

export type DeviceSettings = {
  __typename?: 'DeviceSettings';
  data?: Maybe<Scalars['JSON']>;
  sensors?: Maybe<Scalars['JSON']>;
};


export type DeviceSettingsSensorsArgs = {
  filter?: Maybe<SensorFilter>;
  sort?: Maybe<Scalars['JSON']>;
};

export type DeviceState = {
  __typename?: 'DeviceState';
  data?: Maybe<Scalars['JSON']>;
  deviceSw?: Maybe<DeviceStateSw>;
  sw?: Maybe<Scalars['JSON']>;
};

export type DeviceStateSw = {
  __typename?: 'DeviceStateSW';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type DeviceStorageDetails = {
  __typename?: 'DeviceStorageDetails';
  deviceId?: Maybe<Scalars['String']>;
  deviceName?: Maybe<Scalars['String']>;
  deviceLabel?: Maybe<Scalars['String']>;
  lockerIndex?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
};

export type DeviceStorageDetailsInput = {
  deviceId?: Maybe<Scalars['String']>;
  lockerIndex?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
};

export type DeviceSw = {
  __typename?: 'DeviceSw';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  servicePath?: Maybe<Scalars['String']>;
  serviceRestartCommand?: Maybe<Scalars['String']>;
  servicePingUrl?: Maybe<Scalars['String']>;
  servicePingDelay?: Maybe<Scalars['Int']>;
  readyForUpdateUrl?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  autoUpdate?: Maybe<Scalars['Boolean']>;
};

export type DeviceSwInput = {
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  servicePath?: Maybe<Scalars['String']>;
  serviceRestartCommand?: Maybe<Scalars['String']>;
  servicePingUrl?: Maybe<Scalars['String']>;
  servicePingDelay?: Maybe<Scalars['Int']>;
  readyForUpdateUrl?: Maybe<Scalars['String']>;
  schedule?: Maybe<Scalars['String']>;
  autoUpdate?: Maybe<Scalars['Boolean']>;
};

export type DeviceTest = {
  __typename?: 'DeviceTest';
  name?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  sequentially?: Maybe<Scalars['Boolean']>;
  details?: Maybe<DeviceTestDetails>;
  deviceCommands?: Maybe<Array<Maybe<DeviceCommand>>>;
};

export type DeviceTestDetails = {
  __typename?: 'DeviceTestDetails';
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  rate?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['JSON']>;
};

export type DeviceTestFilter = {
  state?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
};

export type DeviceTestInput = {
  name: Scalars['String'];
  state: Scalars['String'];
  details?: Maybe<Scalars['JSON']>;
};

export type DeviceTests = {
  __typename?: 'DeviceTests';
  timestamp?: Maybe<Scalars['Date']>;
  state?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  deviceId?: Maybe<Scalars['String']>;
  tests?: Maybe<Array<Maybe<DeviceTest>>>;
};

export type DeviceTestsList = {
  __typename?: 'DeviceTestsList';
  edges?: Maybe<Array<Maybe<DeviceTests>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum DeviceTypeEnum {
  Main = 'main',
  Secondary = 'secondary',
  Default = 'default'
}

export type DeviceVpnInput = {
  lastConfiguredAt?: Maybe<Scalars['Date']>;
  country?: Maybe<Scalars['String']>;
  serverIp?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  configurate?: Maybe<Scalars['Boolean']>;
};

export type DevicesCount = {
  __typename?: 'DevicesCount';
  total?: Maybe<Scalars['Int']>;
  connected?: Maybe<Scalars['Int']>;
  disconnected?: Maybe<Scalars['Int']>;
  stored?: Maybe<Scalars['Int']>;
  delivered?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Int']>;
  deactivated?: Maybe<Scalars['Int']>;
  returned?: Maybe<Scalars['Int']>;
};

export type DevicesReport = {
  __typename?: 'DevicesReport';
  _id?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Maybe<DeviceReport>>>;
};

export type ElinaReservationDataInput = {
  bookingId?: Maybe<Scalars['String']>;
};

export type ElinaReservationDataType = {
  __typename?: 'ElinaReservationDataType';
  bookingId?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['String']>;
  data?: Maybe<EventData>;
  schedule?: Maybe<Scalars['Date']>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  originator?: Maybe<Originator>;
  anonymized?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<CommentList>;
  group?: Maybe<EventGroupOutput>;
};


export type EventCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type EventCreation = {
  group?: Maybe<EventGroup>;
  system?: Maybe<Scalars['Boolean']>;
  byPass?: Maybe<Scalars['Boolean']>;
};

export type EventData = {
  __typename?: 'EventData';
  text?: Maybe<Scalars['String']>;
  sourceTime?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['String']>;
  attachment?: Maybe<Array<Maybe<Attachment>>>;
  attachmentId?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['JSON']>;
  after?: Maybe<Scalars['JSON']>;
};

export type EventDataInput = {
  text?: Maybe<Scalars['String']>;
  sourceTime?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['JSON']>;
  after?: Maybe<Scalars['JSON']>;
};

export type EventFilter = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  timerange?: Maybe<Timerange>;
  timestamp?: Maybe<Timerange>;
  sourceTime?: Maybe<Timerange>;
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['JSON']>;
  data?: Maybe<Scalars['JSON']>;
  fullText?: Maybe<Scalars['String']>;
  actionCategory?: Maybe<ActionCategoryEnum>;
  building?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  anonymized?: Maybe<Scalars['Boolean']>;
  groupIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupLevel?: Maybe<EventGroupLevelFilter>;
};

export type EventGroup = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  level?: Maybe<Scalars['Int']>;
};

export type EventGroupLevelFilter = {
  gte?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
};

export type EventGroupOutput = {
  __typename?: 'EventGroupOutput';
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  level?: Maybe<Scalars['Int']>;
  childrenCount?: Maybe<Scalars['Int']>;
};

export type EventInput = {
  source?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  action: Scalars['String'];
  data: EventDataInput;
  schedule?: Maybe<Scalars['Date']>;
  tag?: Maybe<Array<Maybe<Scalars['String']>>>;
  originator?: Maybe<OriginatorInput>;
  group?: Maybe<EventGroup>;
};

export type EventList = {
  __typename?: 'EventList';
  edges?: Maybe<Array<Maybe<Event>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type EventLogInput = {
  time?: Maybe<Scalars['Date']>;
  event?: Maybe<EventInput>;
};

export type EventLogType = {
  __typename?: 'EventLogType';
  time?: Maybe<Scalars['Date']>;
  event?: Maybe<Event>;
};

export type EventSearchList = {
  __typename?: 'EventSearchList';
  events?: Maybe<EventList>;
  keys?: Maybe<KeyListV2>;
  contracts?: Maybe<ContractList>;
  buildings?: Maybe<BuildingList>;
  users?: Maybe<UserList>;
  devices?: Maybe<DeviceList>;
};

export type Extra = {
  __typename?: 'Extra';
  name: Scalars['String'];
  articleId: Scalars['String'];
  price: Scalars['Float'];
  info: Array<Maybe<BillingConfigurationServiceInfo>>;
  calculation?: Maybe<Calculation>;
};

export type ExtraInput = {
  name: Scalars['String'];
  articleId: Scalars['String'];
  price: Scalars['Float'];
  info: Array<Maybe<BillingConfigurationServiceInfoInput>>;
  calculation?: Maybe<Calculation>;
};

export enum ExtraStateEnum {
  Enabled = 'enabled',
  Disabled = 'disabled'
}

export type Fee = {
  __typename?: 'Fee';
  groupPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  info?: Maybe<Scalars['JSON']>;
};

export type FeeInput = {
  groupPrice: Scalars['Float'];
  price: Scalars['Float'];
  info?: Maybe<Scalars['JSON']>;
};

export enum Fills {
  Flat = 'flat',
  Linear = 'linear'
}

export type FiltersContactsInput = {
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};

export type GroupDevice = {
  __typename?: 'GroupDevice';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceTypeEnum>;
  calendar?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  relays?: Maybe<Array<Maybe<IloqOnlineReaderRelay>>>;
};

export type GroupDeviceInput = {
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  product: Scalars['String'];
  serial?: Maybe<Scalars['String']>;
  relays?: Maybe<Array<Maybe<IloqOnlineReaderRelayInput>>>;
  type: DeviceTypeEnum;
};

export type GroupSyncConfig = {
  __typename?: 'GroupSyncConfig';
  keypadStartEarlyAccess?: Maybe<Scalars['Int']>;
  keypadEndLateAccess?: Maybe<Scalars['Int']>;
};

export type GroupSyncConfigInput = {
  keypadStartEarlyAccess?: Maybe<Scalars['Int']>;
  keypadEndLateAccess?: Maybe<Scalars['Int']>;
};

export type GroupWlan = {
  __typename?: 'GroupWlan';
  password?: Maybe<Scalars['String']>;
  router?: Maybe<Scalars['JSON']>;
};

export type GroupWlanInput = {
  password: Scalars['String'];
  router?: Maybe<Scalars['JSON']>;
};

export type HwConfiguration = {
  __typename?: 'HwConfiguration';
  router?: Maybe<Scalars['String']>;
};

export type IloqAccessRight = {
  __typename?: 'IloqAccessRight';
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type IloqAccessRightInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type IloqAccessRights = {
  __typename?: 'IloqAccessRights';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type IloqAccessRightsResponse = {
  __typename?: 'IloqAccessRightsResponse';
  accessRights?: Maybe<Array<Maybe<IloqAccessRights>>>;
};

export type IloqApiCredentialsInput = {
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  serviceAddress?: Maybe<Scalars['String']>;
};

export type IloqConnectedRealEstate = {
  __typename?: 'IloqConnectedRealEstate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IloqIntegration = {
  __typename?: 'IloqIntegration';
  inherited?: Maybe<Scalars['Boolean']>;
  credentials?: Maybe<IloqIntegrationCredentials>;
  webhook?: Maybe<IloqIntegrationWebhook>;
};

export type IloqIntegrationCredentials = {
  __typename?: 'IloqIntegrationCredentials';
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  serviceAddress?: Maybe<Scalars['String']>;
};

export type IloqIntegrationWebhook = {
  __typename?: 'IloqIntegrationWebhook';
  settings?: Maybe<IloqWebhookSettings>;
  lockEventsSubscription?: Maybe<IloqIntegrationWebhookSubscription>;
};

export enum IloqIntegrationWebhookEventType {
  LockEvent = 'lockEvent'
}

export type IloqIntegrationWebhookInput = {
  enabled?: Maybe<Scalars['Boolean']>;
};

export type IloqIntegrationWebhookSettingsInput = {
  lockEventsEnabled?: Maybe<Scalars['Boolean']>;
};

export type IloqIntegrationWebhookSubscription = {
  __typename?: 'IloqIntegrationWebhookSubscription';
  subscriptionId?: Maybe<Scalars['String']>;
  signKey?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type IloqIntegrationWebhookSubscriptionInput = {
  subscriptionId?: Maybe<Scalars['String']>;
  signKey?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};

export type IloqKeyDataResponse = {
  __typename?: 'IloqKeyDataResponse';
  programmingState?: Maybe<Scalars['Int']>;
};

export type IloqKeys = {
  __typename?: 'IloqKeys';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IloqKeysResponse = {
  __typename?: 'IloqKeysResponse';
  keys?: Maybe<Array<Maybe<IloqKeys>>>;
};

export type IloqOnlineReader = {
  __typename?: 'IloqOnlineReader';
  id?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IloqOnlineReaderRelay = {
  __typename?: 'IloqOnlineReaderRelay';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IloqOnlineReaderRelayInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IloqOnlineReaderRelayResponse = {
  __typename?: 'IloqOnlineReaderRelayResponse';
  relays?: Maybe<Array<Maybe<IloqOnlineReaderRelay>>>;
};

export type IloqOnlineReadersResponse = {
  __typename?: 'IloqOnlineReadersResponse';
  onlineReaders?: Maybe<Array<Maybe<IloqOnlineReader>>>;
};

export type IloqRealEstate = {
  __typename?: 'IloqRealEstate';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type IloqRealEstatesResponse = {
  __typename?: 'IloqRealEstatesResponse';
  realEstates?: Maybe<Array<Maybe<IloqRealEstate>>>;
};

export type IloqSyncedData = {
  __typename?: 'IloqSyncedData';
  connectedRealEstate?: Maybe<IloqConnectedRealEstate>;
};

export type IloqWebhookSettings = {
  __typename?: 'IloqWebhookSettings';
  lockEventsEnabled?: Maybe<Scalars['Boolean']>;
};

export type InsertIloqKeyInput = {
  lockerIndex?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type InsertIloqKeyResponse = {
  __typename?: 'InsertIloqKeyResponse';
  inserted?: Maybe<Scalars['Boolean']>;
  programmingNeeded?: Maybe<Scalars['Boolean']>;
  canProgram?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
};

export type InstallDeviceInput = {
  tag?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  replaceDevices?: Maybe<Array<Scalars['String']>>;
  location?: Maybe<DeviceLocationInput>;
};

export type Integration = {
  __typename?: 'Integration';
  name?: Maybe<Scalars['String']>;
  inherited?: Maybe<Scalars['Boolean']>;
  credentials?: Maybe<IntegrationCredentials>;
  webhook?: Maybe<IntegrationWebhookSettings>;
};

export type IntegrationCredentials = {
  __typename?: 'IntegrationCredentials';
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  customerCode?: Maybe<Scalars['String']>;
  serviceAddress?: Maybe<Scalars['String']>;
};

export type IntegrationWebhookSettings = {
  __typename?: 'IntegrationWebhookSettings';
  enabled?: Maybe<Scalars['Boolean']>;
};


export type Key = {
  __typename?: 'Key';
  keyId: Scalars['String'];
  keyName: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
  keyCode?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<Contract>>>;
  backupCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  lockerIndex?: Maybe<Scalars['Int']>;
  shared?: Maybe<Scalars['Boolean']>;
};

export type KeyAction = {
  __typename?: 'KeyAction';
  action?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  contractId?: Maybe<Scalars['String']>;
  originator?: Maybe<Originator>;
};

export type KeyActionInput = {
  action: Scalars['String'];
  timestamp: Scalars['Date'];
  contractId?: Maybe<Scalars['String']>;
  originator?: Maybe<OriginatorInput>;
};

export type KeyCreateInput = {
  keyId: Scalars['String'];
  keyName: Scalars['String'];
  keyCode?: Maybe<Scalars['String']>;
  shared?: Maybe<Scalars['Boolean']>;
  apartmentId?: Maybe<Scalars['String']>;
};

export type KeyCreateInputV2 = {
  keyId: Scalars['String'];
  keyName?: Maybe<Scalars['String']>;
  shared?: Maybe<Scalars['Boolean']>;
  selectedStorage?: Maybe<StorageInput>;
  tag?: Maybe<Scalars['String']>;
  rfid?: Maybe<RfidInput>;
  iloq?: Maybe<KeyIloqInput>;
};

export type KeyFilter = {
  keyId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  keyName?: Maybe<Scalars['String']>;
};

export type KeyFilterV2 = {
  id?: Maybe<Scalars['ID']>;
  locations?: Maybe<Array<Maybe<KeyLocationEnum>>>;
  location?: Maybe<KeyLocationEnum>;
  tag?: Maybe<Scalars['String']>;
  building?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
  keyName?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<Scalars['String']>>>;
  storageTypes?: Maybe<Array<Maybe<KeyStorageEnum>>>;
  storageType?: Maybe<KeyStorageEnum>;
  deviceIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  deviceId?: Maybe<Scalars['String']>;
  lockerIndexes?: Maybe<Array<Maybe<Scalars['String']>>>;
  lockerIndex?: Maybe<Scalars['String']>;
  manualStorages?: Maybe<Array<Maybe<Scalars['String']>>>;
  manualStorage?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  inactive?: Maybe<Scalars['Boolean']>;
  available?: Maybe<Scalars['Boolean']>;
  status?: Maybe<KeyStatusEnum>;
  shared?: Maybe<Scalars['Boolean']>;
};

export type KeyIloq = {
  __typename?: 'KeyIloq';
  keyType?: Maybe<Scalars['String']>;
  iloqId?: Maybe<Scalars['String']>;
  accessRights?: Maybe<Array<Maybe<IloqAccessRight>>>;
  serial?: Maybe<Scalars['String']>;
  stamp?: Maybe<Scalars['String']>;
  nameOfAccess?: Maybe<Scalars['String']>;
};

export type KeyIloqAccessRightsInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type KeyIloqInput = {
  keyType?: Maybe<Scalars['String']>;
  iloqId?: Maybe<Scalars['String']>;
  accessRights?: Maybe<Array<Maybe<KeyIloqAccessRightsInput>>>;
};

export type KeyList = {
  __typename?: 'KeyList';
  edges?: Maybe<Array<Maybe<Key>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type KeyListV2 = {
  __typename?: 'KeyListV2';
  edges?: Maybe<Array<Maybe<KeyV2>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum KeyLocationEnum {
  Away = 'away',
  ManualStorage = 'manualStorage',
  Device = 'device',
  Coords = 'coords'
}

export type KeyRegisterBuildingsUsage = {
  __typename?: 'KeyRegisterBuildingsUsage';
  edges?: Maybe<Array<Maybe<KeyRegisterUsage>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type KeyRegisterOrganizationUnitUsage = {
  __typename?: 'KeyRegisterOrganizationUnitUsage';
  rootUnit?: Maybe<KeyRegisterUsage>;
  childrenUnits?: Maybe<Array<Maybe<KeyRegisterUsage>>>;
};

export type KeyRegisterSearchList = {
  __typename?: 'KeyRegisterSearchList';
  alarmRules?: Maybe<AlarmRuleList>;
  keys?: Maybe<KeyListV2>;
  contracts?: Maybe<ContractList>;
  buildings?: Maybe<BuildingList>;
  users?: Maybe<UserList>;
  devices?: Maybe<DeviceList>;
};

export type KeyRegisterUsage = {
  __typename?: 'KeyRegisterUsage';
  unit?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unitName?: Maybe<Scalars['String']>;
  keysTotal?: Maybe<Scalars['Int']>;
  keyFetched?: Maybe<Scalars['Int']>;
  keyReturned?: Maybe<Scalars['Int']>;
  keyNotReturned?: Maybe<Scalars['Int']>;
  keyDeactivated?: Maybe<Scalars['Int']>;
  keyAdded?: Maybe<Scalars['Int']>;
  strongAuthentication?: Maybe<Scalars['Int']>;
  contractAdded?: Maybe<Scalars['Int']>;
  smsSent?: Maybe<Scalars['Int']>;
  smsSegments?: Maybe<Scalars['Int']>;
  activeBuildings?: Maybe<Scalars['Int']>;
  inactiveBuildings?: Maybe<Scalars['Int']>;
};

export type KeyRfid = {
  __typename?: 'KeyRfid';
  hex?: Maybe<Scalars['String']>;
  decimal?: Maybe<Scalars['String']>;
};

export type KeyStatus = {
  __typename?: 'KeyStatus';
  available?: Maybe<Scalars['Boolean']>;
  keyStatus?: Maybe<KeyAction>;
  inactive?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
};

export enum KeyStatusEnum {
  Available = 'available',
  NotAvailable = 'notAvailable',
  Active = 'active',
  Inactive = 'inactive'
}

export type KeyStatusInput = {
  inactive?: Maybe<Scalars['Boolean']>;
  available?: Maybe<Scalars['Boolean']>;
  keyStatus?: Maybe<KeyActionInput>;
};

export type KeyStatusUpdateInputV2 = {
  lockerIndex?: Maybe<Scalars['String']>;
  status?: Maybe<KeyStatusInput>;
};

export enum KeyStorageEnum {
  ManualStorage = 'manualStorage',
  Device = 'device'
}

export type KeyUpdateInput = {
  keyName?: Maybe<Scalars['String']>;
  keyCode?: Maybe<Scalars['String']>;
  shared?: Maybe<Scalars['Boolean']>;
  apartmentId?: Maybe<Scalars['String']>;
};

export type KeyUpdateInputV2 = {
  keyName?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  location?: Maybe<KeyLocationEnum>;
  selectedStorage?: Maybe<StorageInput>;
  activeContract?: Maybe<Scalars['JSON']>;
  contracts?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  status?: Maybe<KeyStatusInput>;
  rfid?: Maybe<RfidInput>;
  shared?: Maybe<Scalars['Boolean']>;
  iloq?: Maybe<KeyIloqInput>;
};

export type KeyV2 = {
  __typename?: 'KeyV2';
  id: Scalars['ID'];
  keyId: Scalars['String'];
  keyName?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  tagName?: Maybe<Scalars['String']>;
  tagAddress?: Maybe<Scalars['String']>;
  location?: Maybe<KeyLocationEnum>;
  activeContract?: Maybe<Scalars['JSON']>;
  keyLocation?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<Scalars['JSON']>>>;
  selectedStorage?: Maybe<Storage>;
  status?: Maybe<KeyStatus>;
  comments?: Maybe<CommentList>;
  shared?: Maybe<Scalars['Boolean']>;
  rfid?: Maybe<KeyRfid>;
  iloq?: Maybe<KeyIloq>;
};


export type KeyV2CommentsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type Link = {
  __typename?: 'Link';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<LinkType>;
};

export type LinkCreateInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<LinkType>;
};

export type LinkEventInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type LinkFilter = {
  building?: Maybe<Scalars['ID']>;
  location?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type LinkFull = {
  __typename?: 'LinkFull';
  parents?: Maybe<Array<Maybe<TagParents>>>;
  buildingName?: Maybe<Scalars['String']>;
  buildingAddress?: Maybe<Scalars['String']>;
  buildingId?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<LinkType>;
};

export type LinkList = {
  __typename?: 'LinkList';
  edges?: Maybe<Array<Maybe<LinkFull>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum LinkType {
  Default = 'default',
  Vpn = 'vpn'
}

export type LinkUpdateInput = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  type?: Maybe<LinkType>;
};

export type Location = {
  __typename?: 'Location';
  tag?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  devices?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Locker = {
  __typename?: 'Locker';
  lockerIndex?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
  keyName?: Maybe<Scalars['String']>;
};

export type MailReceiver = {
  __typename?: 'MailReceiver';
  mail?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  activation?: Maybe<Scalars['Boolean']>;
  deactivation?: Maybe<Scalars['Boolean']>;
};

export type ManualStorageDetails = {
  __typename?: 'ManualStorageDetails';
  description?: Maybe<Scalars['String']>;
};

export type ManualStorageDetailsInput = {
  description?: Maybe<Scalars['String']>;
};

export type MessagesInput = {
  language?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type MessagesType = {
  __typename?: 'MessagesType';
  language?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  fromName?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type MineDeviceFilter = {
  timestamp?: Maybe<Timerange>;
  deviceId: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  resolution: Resolutions;
  fill?: Maybe<Fills>;
  field?: Maybe<Values>;
  decimals?: Maybe<Scalars['Int']>;
  periods?: Maybe<Array<Maybe<MinerPeriodInput>>>;
};

export type MineFilter = {
  timestamp?: Maybe<Timerange>;
  pointId: Scalars['String'];
  offset?: Maybe<Scalars['Int']>;
  resolution: Resolutions;
  interpolation: Fills;
  fill?: Maybe<Fills>;
  field?: Maybe<Values>;
  decimals?: Maybe<Scalars['Int']>;
  periods?: Maybe<Array<Maybe<MinerPeriodInput>>>;
};

export type MineValue = {
  __typename?: 'MineValue';
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  startValue?: Maybe<Scalars['Float']>;
  runStartValue?: Maybe<Scalars['Float']>;
  endValue?: Maybe<Scalars['Float']>;
  runEndValue?: Maybe<Scalars['Float']>;
  highFlowLimit?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  spins?: Maybe<Scalars['Int']>;
  spinLoss?: Maybe<Scalars['Int']>;
  syncDelta?: Maybe<Scalars['Int']>;
  syncs?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  avg?: Maybe<Scalars['Float']>;
  avgtf?: Maybe<Scalars['Float']>;
  avgtl?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Float']>;
  firstInTime?: Maybe<Scalars['String']>;
  firstOutTime?: Maybe<Scalars['String']>;
  firstOutValue?: Maybe<Scalars['Float']>;
  histogram?: Maybe<Array<Maybe<Scalars['Int']>>>;
  last?: Maybe<Scalars['Float']>;
  lastInTime?: Maybe<Scalars['String']>;
  lastInValue?: Maybe<Scalars['Float']>;
  lastOutTime?: Maybe<Scalars['String']>;
  lastOutValue?: Maybe<Scalars['Float']>;
  longestStableTime?: Maybe<Scalars['Int']>;
  longestToggleTime?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Float']>;
  maxrun?: Maybe<Scalars['Float']>;
  maxrunEnd?: Maybe<Scalars['Float']>;
  maxrunStart?: Maybe<Scalars['Float']>;
  maxrun3?: Maybe<Scalars['Float']>;
  maxrun3End?: Maybe<Scalars['Float']>;
  maxrun3Start?: Maybe<Scalars['Float']>;
  maxrun4?: Maybe<Scalars['Float']>;
  maxrun4End?: Maybe<Scalars['Float']>;
  maxrun4Start?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  minrun?: Maybe<Scalars['Float']>;
  minrun10min?: Maybe<Scalars['Float']>;
  minrunh?: Maybe<Scalars['Float']>;
  minrun3?: Maybe<Scalars['Float']>;
  minrun4?: Maybe<Scalars['Float']>;
  reliability?: Maybe<Scalars['Int']>;
  run?: Maybe<Scalars['Float']>;
  s?: Maybe<Scalars['Int']>;
  stableTime?: Maybe<Scalars['Int']>;
  sum?: Maybe<Scalars['Float']>;
  toggles?: Maybe<Scalars['Int']>;
  toggleTime?: Maybe<Scalars['Int']>;
  variance?: Maybe<Scalars['Float']>;
};

export type MinerPeriodInput = {
  name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Timerange>;
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteAccessory?: Maybe<Scalars['Boolean']>;
  updateAccessory?: Maybe<Accessory>;
  createAccessory?: Maybe<Accessory>;
  addAlarmRule?: Maybe<AlarmRule>;
  updateAlarmRule?: Maybe<AlarmRule>;
  deleteAlarmRule?: Maybe<Scalars['Boolean']>;
  addAlarm?: Maybe<Alarm>;
  updateAlarm?: Maybe<Alarm>;
  updateAlarmRouting?: Maybe<Alarm>;
  ackAlarm?: Maybe<Alarm>;
  deactivateAlarm?: Maybe<Alarm>;
  deleteAlarm?: Maybe<Scalars['Boolean']>;
  syncIndexes?: Maybe<Scalars['Boolean']>;
  createApartment?: Maybe<Apartment>;
  updateApartment?: Maybe<Apartment>;
  upsertApartment?: Maybe<Apartment>;
  deleteApartment?: Maybe<Scalars['Boolean']>;
  addAttachment?: Maybe<Attachment>;
  deleteAttachment?: Maybe<Scalars['Boolean']>;
  deleteAttachments?: Maybe<Scalars['Boolean']>;
  loginWithEmailAndPassword: User;
  loginWithEmailLink: User;
  refreshIdToken: Scalars['String'];
  sendTwoFactorCode?: Maybe<Scalars['Boolean']>;
  logout?: Maybe<Scalars['Boolean']>;
  logoutFromAllSessions?: Maybe<Scalars['Boolean']>;
  createOrderBill?: Maybe<Bill>;
  createTagBill?: Maybe<Array<Maybe<Bill>>>;
  createSubscriptionBill?: Maybe<SubscriptionBill>;
  deleteBill?: Maybe<Scalars['Boolean']>;
  createBuildingLink?: Maybe<Link>;
  updateBuildingLink?: Maybe<Link>;
  deleteBuildingLink?: Maybe<Scalars['Boolean']>;
  vpnProxyConnectionOpened?: Maybe<Scalars['Boolean']>;
  uploadBuildingAttachments?: Maybe<Scalars['Boolean']>;
  moveBuilding?: Maybe<Scalars['Boolean']>;
  deleteBuildingAttachment?: Maybe<Scalars['Boolean']>;
  deleteBuilding?: Maybe<Scalars['Boolean']>;
  createBuilding?: Maybe<Building>;
  updateBuilding?: Maybe<Building>;
  createThread?: Maybe<CommentThread>;
  updateThread?: Maybe<CommentThread>;
  removeThread?: Maybe<CommentThread>;
  addComment?: Maybe<Comment>;
  deleteComment?: Maybe<Comment>;
  editComment?: Maybe<Comment>;
  createContact?: Maybe<Contact>;
  updateContact?: Maybe<Contact>;
  deleteContact?: Maybe<Contact>;
  sendSignatureLinkViaSMS?: Maybe<Scalars['Boolean']>;
  signPublicContract?: Maybe<Scalars['Boolean']>;
  createContract?: Maybe<Contract>;
  updateContract?: Maybe<Contract>;
  upsertContract?: Maybe<Contract>;
  deleteContract?: Maybe<Scalars['Boolean']>;
  deleteContracts?: Maybe<Scalars['Boolean']>;
  deleteOldContracts?: Maybe<Scalars['Boolean']>;
  createContractV2?: Maybe<Contract>;
  updateContractV2?: Maybe<Contract>;
  updateContractKeyStatusV2?: Maybe<Contract>;
  updateContractKeysStatusV2?: Maybe<Contract>;
  deleteContractV2?: Maybe<Scalars['Boolean']>;
  deleteOldContractsV2?: Maybe<Scalars['Boolean']>;
  signContract?: Maybe<Contract>;
  returnKeysFromContract?: Maybe<Contract>;
  verifyContract?: Maybe<Scalars['Boolean']>;
  uploadContractAttachments?: Maybe<Array<Maybe<ContractAttachment>>>;
  deleteContractAttachment?: Maybe<Scalars['Boolean']>;
  createCustomer?: Maybe<Tag>;
  updateCustomer?: Maybe<Tag>;
  deleteCustomer?: Maybe<Scalars['Boolean']>;
  updateDelayedEvent?: Maybe<DelayedEvent>;
  addDelayedEvent?: Maybe<Array<Maybe<DelayedEvent>>>;
  removeDelayedEvent?: Maybe<Scalars['Boolean']>;
  startDeviceTests?: Maybe<DeviceTests>;
  changeTestState?: Maybe<DeviceTests>;
  createDevicesFromProduction?: Maybe<Array<Maybe<Scalars['String']>>>;
  createDevice?: Maybe<Device>;
  createDeviceWithId?: Maybe<Device>;
  updateDevice?: Maybe<Device>;
  updateDeviceMiningData?: Maybe<Scalars['Boolean']>;
  updateDeviceSettings?: Maybe<Device>;
  updateDeviceState?: Maybe<Device>;
  updateDeviceExtras?: Maybe<Array<Maybe<DeviceExtras>>>;
  updateDeviceEnv?: Maybe<Scalars['JSON']>;
  deleteDevice?: Maybe<Scalars['Boolean']>;
  installDevice?: Maybe<Device>;
  uninstallDevice?: Maybe<Device>;
  updateConnectionState?: Maybe<Scalars['Boolean']>;
  changeLifecycleState?: Maybe<Scalars['Boolean']>;
  deleteLastLifecycleState?: Maybe<Device>;
  updateDeviceKeys?: Maybe<Device>;
  updateLockerStatus?: Maybe<Scalars['Boolean']>;
  updateDeviceGroup?: Maybe<Scalars['Boolean']>;
  updateSyncGroup?: Maybe<Scalars['Boolean']>;
  updateDeviceDetails?: Maybe<Device>;
  addEvent?: Maybe<Array<Maybe<Event>>>;
  anonymizeEvents?: Maybe<Scalars['Boolean']>;
  syncIloqKeysToBuilding?: Maybe<Scalars['Boolean']>;
  syncIloqRealEstateToBuilding?: Maybe<Tag>;
  updateIloqApiCredentials?: Maybe<Scalars['Boolean']>;
  deleteIloqApiCredentials?: Maybe<Scalars['Boolean']>;
  verifyIloqApiCredentials?: Maybe<Scalars['Boolean']>;
  activateIloqKey?: Maybe<ActivateIloqKeyResponse>;
  deactivateIloqKey?: Maybe<DeactivateIloqKeyResponse>;
  addIloqLockToBuilding?: Maybe<Building>;
  removeIloqLockFromBuilding?: Maybe<Building>;
  prepareIloqKey?: Maybe<PrepareIloqKeyResponse>;
  createSntOrganizationUnit?: Maybe<Tag>;
  updateSntOrganizationUnit?: Maybe<Tag>;
  syncSntBuildings?: Maybe<Scalars['Boolean']>;
  updateIloqIntegrationWebhookSettings?: Maybe<OrganizationUnit>;
  saveIloqIntegrationWebhookSubscription?: Maybe<Scalars['Boolean']>;
  removeIloqIntegrationWebhookSubscription?: Maybe<Scalars['Boolean']>;
  orderIloqKey?: Maybe<OrderIloqKeyResponse>;
  insertIloqKey?: Maybe<InsertIloqKeyResponse>;
  switchAndDeactivateIloqKey?: Maybe<SwitchAndDeactivateIloqKeyResponse>;
  createKey?: Maybe<Key>;
  updateKey?: Maybe<Key>;
  deleteKey?: Maybe<Scalars['Boolean']>;
  createKeyV2?: Maybe<KeyV2>;
  updateKeyV2?: Maybe<KeyV2>;
  updateKeyStatusV2?: Maybe<KeyV2>;
  deactivateKeyV2?: Maybe<KeyV2>;
  deleteKeyV2?: Maybe<Scalars['Boolean']>;
  transferKey?: Maybe<KeyV2>;
  importKeys?: Maybe<Scalars['Boolean']>;
  createOrder?: Maybe<Order>;
  updateOrder?: Maybe<Order>;
  confirmOrder?: Maybe<Order>;
  deliverOrder?: Maybe<Order>;
  deleteOrder?: Maybe<Scalars['Boolean']>;
  createOrganizationUnit?: Maybe<OrganizationUnit>;
  updateOrganizationUnit?: Maybe<OrganizationUnit>;
  moveOrganizationUnit?: Maybe<Scalars['Boolean']>;
  uploadOrganizationUnitAgreements?: Maybe<Array<Maybe<Agreement>>>;
  deleteOrganizationUnitAgreement?: Maybe<Scalars['Boolean']>;
  uploadOrganizationUnitAttachments?: Maybe<Scalars['Boolean']>;
  uploadOrganizationUnitLogo?: Maybe<Scalars['Boolean']>;
  deleteOrganizationUnitAttachment?: Maybe<Scalars['Boolean']>;
  deleteOrganizationUnitLogo?: Maybe<Scalars['Boolean']>;
  updateOrganizationUnitAlarmSettings?: Maybe<OrganizationUnit>;
  updatePowerBiAccountConfiguration?: Maybe<Scalars['Boolean']>;
  updatePowerBiReportConfiguration?: Maybe<Scalars['Boolean']>;
  setPowerBiReportShareState?: Maybe<Scalars['String']>;
  deleteProduct?: Maybe<Scalars['Boolean']>;
  updateProduct?: Maybe<Product>;
  createProduct?: Maybe<Product>;
  createProductionTestSet?: Maybe<Product>;
  createProperty?: Maybe<Property>;
  updateProperty?: Maybe<Property>;
  deleteProperty?: Maybe<Scalars['Boolean']>;
  createReservation?: Maybe<Reservation>;
  updateReservation?: Maybe<Reservation>;
  upsertReservation?: Maybe<Reservation>;
  upsertReservationV2?: Maybe<Reservation>;
  syncReservation?: Maybe<Reservation>;
  unSyncReservation?: Maybe<Scalars['Boolean']>;
  reSyncReservation?: Maybe<Reservation>;
  deleteReservation?: Maybe<Scalars['Boolean']>;
  createRole?: Maybe<Role>;
  updateRole?: Maybe<Role>;
  deleteRole?: Maybe<Scalars['Boolean']>;
  addSensorValue?: Maybe<Array<Maybe<SensorValue>>>;
  createSensor?: Maybe<Sensor>;
  updateSensor?: Maybe<Sensor>;
  deleteSensor?: Maybe<Scalars['Boolean']>;
  setActiveSensorDevice?: Maybe<Sensor>;
  createServiceAccount?: Maybe<ServiceAccount>;
  updateServiceAccount?: Maybe<ServiceAccount>;
  deleteServiceAccount?: Maybe<Scalars['Boolean']>;
  moveTag?: Maybe<Scalars['Boolean']>;
  deleteTag?: Maybe<Scalars['Boolean']>;
  updateTag?: Maybe<Tag>;
  createTag?: Maybe<Tag>;
  createTimeline?: Maybe<Timeline>;
  editTimeline?: Maybe<Timeline>;
  addEventToTimeline?: Maybe<Timeline>;
  anonymizeTimeline?: Maybe<Scalars['Boolean']>;
  anonymizeTimelines?: Maybe<Scalars['Boolean']>;
  anonymizeFrontCameraTimeline?: Maybe<Scalars['Boolean']>;
  deleteUiNotification?: Maybe<Scalars['Boolean']>;
  updateUiNotification?: Maybe<UiNotification>;
  createUiNotification?: Maybe<UiNotification>;
  changePassword?: Maybe<User>;
  changePasswordWithToken?: Maybe<User>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  verifyUser?: Maybe<User>;
  sendSignInEmail?: Maybe<Scalars['Boolean']>;
  removeAccess?: Maybe<User>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  updateVpnConfig?: Maybe<VpnConfig>;
  createVpnConfig?: Maybe<VpnConfig>;
  deleteVpnConfig?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteAccessoryArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateAccessoryArgs = {
  id: Scalars['ID'];
  input: AccessoryInput;
};


export type MutationCreateAccessoryArgs = {
  input: AccessoryInput;
};


export type MutationAddAlarmRuleArgs = {
  input: AlarmRuleInput;
};


export type MutationUpdateAlarmRuleArgs = {
  id: Scalars['ID'];
  input: AlarmRuleUpdateInput;
};


export type MutationDeleteAlarmRuleArgs = {
  id: Scalars['ID'];
};


export type MutationAddAlarmArgs = {
  input: AlarmInput;
};


export type MutationUpdateAlarmArgs = {
  filter: AlarmUpdateFilter;
  input: AlarmUpdateInput;
};


export type MutationUpdateAlarmRoutingArgs = {
  filter: AlarmFilter;
  input: AlarmRoutingUpdateInput;
};


export type MutationAckAlarmArgs = {
  filter: AlarmFilter;
  input: AlarmAckInput;
};


export type MutationDeactivateAlarmArgs = {
  filter: AlarmFilter;
  input: AlarmAckInput;
};


export type MutationDeleteAlarmArgs = {
  id: Scalars['ID'];
};


export type MutationCreateApartmentArgs = {
  input: ApartmentCreateInput;
};


export type MutationUpdateApartmentArgs = {
  id: Scalars['String'];
  input?: Maybe<ApartmentUpdateInput>;
};


export type MutationUpsertApartmentArgs = {
  id: Scalars['String'];
  input?: Maybe<ApartmentCreateInput>;
};


export type MutationDeleteApartmentArgs = {
  id: Scalars['String'];
};


export type MutationAddAttachmentArgs = {
  input?: Maybe<AddAttachmentInput>;
};


export type MutationDeleteAttachmentArgs = {
  id: Scalars['String'];
  filename: Scalars['String'];
  bucketName: Scalars['String'];
  removeFromStorage?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteAttachmentsArgs = {
  attachments: Array<Maybe<DeleteAttachmentInput>>;
  removeFromStorage?: Maybe<Scalars['Boolean']>;
};


export type MutationLoginWithEmailAndPasswordArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  twoFactorCode?: Maybe<Scalars['String']>;
};


export type MutationLoginWithEmailLinkArgs = {
  email: Scalars['String'];
  signInToken: Scalars['String'];
};


export type MutationRefreshIdTokenArgs = {
  refreshToken: Scalars['String'];
};


export type MutationSendTwoFactorCodeArgs = {
  twoFactorAuthSessionToken: Scalars['String'];
  selectedOption: Scalars['String'];
};


export type MutationLogoutFromAllSessionsArgs = {
  userId: Scalars['String'];
  revokeActiveSession?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateOrderBillArgs = {
  orderId: Scalars['ID'];
};


export type MutationCreateTagBillArgs = {
  tag: Array<Scalars['ID']>;
  timerange: Timerange;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationCreateSubscriptionBillArgs = {
  tag: Scalars['ID'];
  timerange: Timerange;
};


export type MutationDeleteBillArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBuildingLinkArgs = {
  id: Scalars['ID'];
  input: LinkCreateInput;
};


export type MutationUpdateBuildingLinkArgs = {
  id: Scalars['ID'];
  input: LinkUpdateInput;
};


export type MutationDeleteBuildingLinkArgs = {
  id: Scalars['ID'];
  linkId: Scalars['ID'];
};


export type MutationVpnProxyConnectionOpenedArgs = {
  id: Scalars['ID'];
  link: LinkEventInput;
};


export type MutationUploadBuildingAttachmentsArgs = {
  id: Scalars['ID'];
  input: UploadAttachmentInput;
};


export type MutationMoveBuildingArgs = {
  id: Scalars['ID'];
  parent: Scalars['String'];
  waitForResult?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteBuildingAttachmentArgs = {
  id: Scalars['ID'];
  attachmentId: Scalars['ID'];
  name: Scalars['String'];
};


export type MutationDeleteBuildingArgs = {
  id: Scalars['ID'];
};


export type MutationCreateBuildingArgs = {
  input: BuildingCreateInput;
};


export type MutationUpdateBuildingArgs = {
  id: Scalars['ID'];
  input: BuildingUpdateInput;
};


export type MutationCreateThreadArgs = {
  input: CommentThreadInput;
};


export type MutationUpdateThreadArgs = {
  id: Scalars['String'];
  input: CommentThreadUpdateInput;
};


export type MutationRemoveThreadArgs = {
  id: Scalars['String'];
};


export type MutationAddCommentArgs = {
  input: CommentInput;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['String'];
};


export type MutationEditCommentArgs = {
  id: Scalars['String'];
  input?: Maybe<CommentUpdateInput>;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
  id: Scalars['String'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['String'];
};


export type MutationSendSignatureLinkViaSmsArgs = {
  contractId: Scalars['String'];
  input: SendPublicSignatureInput;
};


export type MutationSignPublicContractArgs = {
  id: Scalars['ID'];
  hash: Scalars['String'];
  input: ContractSignatureInput;
};


export type MutationCreateContractArgs = {
  input: ContractCreateInput;
};


export type MutationUpdateContractArgs = {
  id: Scalars['ID'];
  input: ContractUpdateInput;
  refreshPincode?: Maybe<Scalars['Boolean']>;
};


export type MutationUpsertContractArgs = {
  id: Scalars['ID'];
  input: ContractUpdateInput;
  refreshPincode?: Maybe<Scalars['Boolean']>;
  validate?: Maybe<Scalars['JSON']>;
};


export type MutationDeleteContractArgs = {
  id: Scalars['ID'];
  cancellation?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteContractsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  cancellation?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateContractV2Args = {
  input: ContractCreateInputV2;
  imported?: Maybe<Scalars['Boolean']>;
  eventCreation?: Maybe<EventCreation>;
};


export type MutationUpdateContractV2Args = {
  contractId: Scalars['String'];
  input: ContractUpdateInputV2;
  eventCreation?: Maybe<EventCreation>;
};


export type MutationUpdateContractKeyStatusV2Args = {
  contractId: Scalars['String'];
  input: ContractKeyStatusInput;
};


export type MutationUpdateContractKeysStatusV2Args = {
  contractId: Scalars['String'];
  input?: Maybe<Array<ContractKeyStatusInput>>;
  createEvent?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteContractV2Args = {
  contractId: Scalars['String'];
  eventCreation?: Maybe<EventCreation>;
};


export type MutationSignContractArgs = {
  contractId: Scalars['String'];
  input: ContractSignatureInput;
};


export type MutationReturnKeysFromContractArgs = {
  contractId: Scalars['String'];
  input: ContractKeysReturnInput;
  eventCreation?: Maybe<EventCreation>;
};


export type MutationVerifyContractArgs = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};


export type MutationUploadContractAttachmentsArgs = {
  contractId: Scalars['ID'];
  input: UploadAttachmentInput;
  updateContract?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteContractAttachmentArgs = {
  contractId: Scalars['ID'];
  attachmentId: Scalars['ID'];
  filename: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  tag: Scalars['String'];
  input: CustomerUpsertInput;
};


export type MutationUpdateCustomerArgs = {
  tag: Scalars['String'];
  input?: Maybe<CustomerUpsertInput>;
};


export type MutationDeleteCustomerArgs = {
  tag: Scalars['String'];
};


export type MutationUpdateDelayedEventArgs = {
  id: Scalars['ID'];
  input: DelayedEventUpdateInput;
};


export type MutationAddDelayedEventArgs = {
  input: DelayedEventInput;
};


export type MutationRemoveDelayedEventArgs = {
  filter: DelayedEventFilter;
};


export type MutationStartDeviceTestsArgs = {
  id: Scalars['String'];
};


export type MutationChangeTestStateArgs = {
  deviceId: Scalars['String'];
  input?: Maybe<Array<DeviceTestInput>>;
};


export type MutationCreateDevicesFromProductionArgs = {
  input: CreateProductionDeviceInput;
  quantity: Scalars['Int'];
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
};


export type MutationCreateDeviceWithIdArgs = {
  id: Scalars['String'];
  input?: Maybe<DeviceInput>;
};


export type MutationUpdateDeviceArgs = {
  id: Scalars['String'];
  input: DeviceInput;
  sensorFollow?: Maybe<Scalars['Boolean']>;
  overwriteMeta?: Maybe<Scalars['Boolean']>;
  bypassEventCreation?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateDeviceMiningDataArgs = {
  id?: Maybe<Scalars['String']>;
  input: Scalars['JSON'];
};


export type MutationUpdateDeviceSettingsArgs = {
  id: Scalars['String'];
  input: Scalars['JSON'];
  partialUpdate?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type MutationUpdateDeviceStateArgs = {
  id: Scalars['ID'];
  input: Scalars['JSON'];
};


export type MutationUpdateDeviceExtrasArgs = {
  id: Scalars['ID'];
  input: Array<Maybe<DeviceExtraInput>>;
};


export type MutationUpdateDeviceEnvArgs = {
  id: Scalars['ID'];
  input: Scalars['JSON'];
};


export type MutationDeleteDeviceArgs = {
  id: Scalars['String'];
};


export type MutationInstallDeviceArgs = {
  id: Scalars['String'];
  input?: Maybe<InstallDeviceInput>;
};


export type MutationUninstallDeviceArgs = {
  id: Scalars['String'];
  input?: Maybe<UninstallDeviceInput>;
};


export type MutationUpdateConnectionStateArgs = {
  id: Scalars['String'];
  input: ConnectionInput;
};


export type MutationChangeLifecycleStateArgs = {
  id: Scalars['String'];
  newState: Scalars['String'];
  data?: Maybe<Scalars['JSON']>;
};


export type MutationDeleteLastLifecycleStateArgs = {
  id: Scalars['String'];
};


export type MutationUpdateDeviceKeysArgs = {
  id: Scalars['String'];
};


export type MutationUpdateLockerStatusArgs = {
  id?: Maybe<Scalars['String']>;
  input: UpdateLockerStatusInput;
};


export type MutationUpdateDeviceGroupArgs = {
  id: Scalars['String'];
  input: DeviceGroupInput;
};


export type MutationUpdateSyncGroupArgs = {
  id: Scalars['String'];
  input: DeviceGroupInput;
};


export type MutationUpdateDeviceDetailsArgs = {
  id: Scalars['String'];
  input: DeviceDetailsInput;
};


export type MutationAddEventArgs = {
  input: Array<Maybe<EventInput>>;
};


export type MutationAnonymizeEventsArgs = {
  filter: EventFilter;
  input?: Maybe<Scalars['JSON']>;
};


export type MutationSyncIloqKeysToBuildingArgs = {
  input: SyncIloqKeysToBuilding;
};


export type MutationSyncIloqRealEstateToBuildingArgs = {
  buildingId: Scalars['String'];
  iloqRealEstateId: Scalars['String'];
};


export type MutationUpdateIloqApiCredentialsArgs = {
  tag: Scalars['String'];
  input: IloqApiCredentialsInput;
};


export type MutationDeleteIloqApiCredentialsArgs = {
  tag: Scalars['String'];
};


export type MutationVerifyIloqApiCredentialsArgs = {
  credentials: IloqApiCredentialsInput;
};


export type MutationActivateIloqKeyArgs = {
  keyId: Scalars['String'];
};


export type MutationDeactivateIloqKeyArgs = {
  keyId: Scalars['String'];
};


export type MutationAddIloqLockToBuildingArgs = {
  input?: Maybe<AddIloqLocksToBuildingInput>;
};


export type MutationRemoveIloqLockFromBuildingArgs = {
  input?: Maybe<RemoveIloqLocksFromBuildingInput>;
};


export type MutationPrepareIloqKeyArgs = {
  input?: Maybe<PrepareIloqKeyInput>;
};


export type MutationCreateSntOrganizationUnitArgs = {
  input: SntOrganizationUnitCreateInput;
};


export type MutationUpdateSntOrganizationUnitArgs = {
  id: Scalars['String'];
  input: SntOrganizationUnitUpdateInput;
};


export type MutationSyncSntBuildingsArgs = {
  sntBuildings: Array<Maybe<SntBuildingInput>>;
};


export type MutationUpdateIloqIntegrationWebhookSettingsArgs = {
  tagId: Scalars['String'];
  input: IloqIntegrationWebhookSettingsInput;
};


export type MutationSaveIloqIntegrationWebhookSubscriptionArgs = {
  tagId: Scalars['String'];
  input: IloqIntegrationWebhookSubscriptionInput;
  subscriptionType?: Maybe<IloqIntegrationWebhookEventType>;
};


export type MutationRemoveIloqIntegrationWebhookSubscriptionArgs = {
  tagId: Scalars['String'];
  subscriptionId: Scalars['String'];
  subscriptionType: IloqIntegrationWebhookEventType;
};


export type MutationOrderIloqKeyArgs = {
  keyId: Scalars['String'];
  contractId: Scalars['String'];
};


export type MutationInsertIloqKeyArgs = {
  input?: Maybe<InsertIloqKeyInput>;
};


export type MutationSwitchAndDeactivateIloqKeyArgs = {
  input?: Maybe<SwitchAndDeactivateIloqKeyInput>;
};


export type MutationCreateKeyArgs = {
  deviceId: Scalars['String'];
  key: KeyCreateInput;
  lockerIndex?: Maybe<Scalars['Int']>;
};


export type MutationUpdateKeyArgs = {
  deviceId: Scalars['String'];
  keyId: Scalars['String'];
  input?: Maybe<KeyUpdateInput>;
  lockerIndex?: Maybe<Scalars['Int']>;
  newBackupCodes?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteKeyArgs = {
  deviceId: Scalars['String'];
  keyId: Scalars['String'];
};


export type MutationCreateKeyV2Args = {
  key: KeyCreateInputV2;
};


export type MutationUpdateKeyV2Args = {
  keyId: Scalars['String'];
  input?: Maybe<KeyUpdateInputV2>;
};


export type MutationUpdateKeyStatusV2Args = {
  keyId: Scalars['String'];
  input?: Maybe<KeyStatusUpdateInputV2>;
  imported?: Maybe<Scalars['Boolean']>;
};


export type MutationDeactivateKeyV2Args = {
  keyId: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationDeleteKeyV2Args = {
  keyId: Scalars['String'];
};


export type MutationTransferKeyArgs = {
  keyId: Scalars['String'];
  targetStorage: StorageInput;
};


export type MutationImportKeysArgs = {
  buildingId: Scalars['String'];
  keys: Array<Maybe<Scalars['JSON']>>;
};


export type MutationCreateOrderArgs = {
  input: OrderCreateInput;
};


export type MutationUpdateOrderArgs = {
  id: Scalars['ID'];
  input?: Maybe<OrderUpdateInput>;
};


export type MutationConfirmOrderArgs = {
  id: Scalars['ID'];
  details: OrderConfirmDetailsInput;
};


export type MutationDeliverOrderArgs = {
  id: Scalars['ID'];
  deviceIds?: Maybe<Array<Scalars['ID']>>;
  accessories?: Maybe<Array<Maybe<OrderAccessoriesInput>>>;
  details: OrderDeliverDetailsInput;
  partial?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateOrganizationUnitArgs = {
  input: CreateOrganizationUnitInput;
};


export type MutationUpdateOrganizationUnitArgs = {
  id: Scalars['ID'];
  input: UpdateOrganizationUnitInput;
};


export type MutationMoveOrganizationUnitArgs = {
  id: Scalars['ID'];
  parent: Scalars['String'];
  waitForResult?: Maybe<Scalars['Boolean']>;
};


export type MutationUploadOrganizationUnitAgreementsArgs = {
  id: Scalars['ID'];
  input: UploadAgreementInput;
};


export type MutationDeleteOrganizationUnitAgreementArgs = {
  id: Scalars['ID'];
  input: DeleteAgreementInput;
};


export type MutationUploadOrganizationUnitAttachmentsArgs = {
  id: Scalars['ID'];
  input: UploadAttachmentInput;
};


export type MutationUploadOrganizationUnitLogoArgs = {
  id: Scalars['ID'];
  input: UploadInput;
};


export type MutationDeleteOrganizationUnitAttachmentArgs = {
  id: Scalars['ID'];
  attachmentId: Scalars['ID'];
  filename: Scalars['String'];
};


export type MutationDeleteOrganizationUnitLogoArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateOrganizationUnitAlarmSettingsArgs = {
  id: Scalars['ID'];
  input: AlarmSettingsInput;
};


export type MutationUpdatePowerBiAccountConfigurationArgs = {
  tag: Scalars['String'];
  input: PowerBiAccountConfigurationInput;
};


export type MutationUpdatePowerBiReportConfigurationArgs = {
  tag: Scalars['String'];
  input: PowerBiReportConfigurationInput;
};


export type MutationSetPowerBiReportShareStateArgs = {
  tag: Scalars['String'];
  isPublic: Scalars['Boolean'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateProductArgs = {
  id: Scalars['ID'];
  input: ProductUpdateInput;
};


export type MutationCreateProductArgs = {
  input: ProductCreateInput;
};


export type MutationCreateProductionTestSetArgs = {
  id: Scalars['ID'];
  input: ProductionTestSetInput;
};


export type MutationCreatePropertyArgs = {
  input: PropertyCreateInput;
};


export type MutationUpdatePropertyArgs = {
  id: Scalars['String'];
  input?: Maybe<PropertyUpdateInput>;
};


export type MutationDeletePropertyArgs = {
  id: Scalars['String'];
};


export type MutationCreateReservationArgs = {
  input: ReservationCreateInput;
  upsertEntities?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateReservationArgs = {
  id: Scalars['ID'];
  input: ReservationUpdateInput;
  cancel?: Maybe<Scalars['Boolean']>;
};


export type MutationUpsertReservationArgs = {
  id: Scalars['ID'];
  input: ReservationUpdateInput;
  cancel?: Maybe<Scalars['Boolean']>;
  upsertEntities?: Maybe<Scalars['Boolean']>;
};


export type MutationUpsertReservationV2Args = {
  id: Scalars['ID'];
  input: ReservationUpdateInput;
  cancel?: Maybe<Scalars['Boolean']>;
  upsertEntities?: Maybe<Scalars['Boolean']>;
};


export type MutationSyncReservationArgs = {
  id: Scalars['ID'];
  contract: ContractCreateInput;
  reserveLocker?: Maybe<Scalars['Boolean']>;
};


export type MutationUnSyncReservationArgs = {
  id: Scalars['ID'];
  contractId: Scalars['ID'];
  contractType: Scalars['String'];
};


export type MutationReSyncReservationArgs = {
  id: Scalars['ID'];
  contractId: Scalars['ID'];
  contract: ContractUpdateInput;
};


export type MutationDeleteReservationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateRoleArgs = {
  input: RoleInput;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['String'];
  input: RoleInput;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['String'];
};


export type MutationAddSensorValueArgs = {
  input: Array<Maybe<SensorValueInput>>;
};


export type MutationCreateSensorArgs = {
  input: SensorCreateInput;
};


export type MutationUpdateSensorArgs = {
  id: Scalars['ID'];
  input: SensorUpdateInput;
};


export type MutationDeleteSensorArgs = {
  id: Scalars['ID'];
};


export type MutationSetActiveSensorDeviceArgs = {
  id: Scalars['ID'];
  deviceId: Scalars['ID'];
};


export type MutationCreateServiceAccountArgs = {
  input: ServiceAccountInput;
};


export type MutationUpdateServiceAccountArgs = {
  id: Scalars['String'];
  input: ServiceAccountInput;
};


export type MutationDeleteServiceAccountArgs = {
  id: Scalars['String'];
};


export type MutationMoveTagArgs = {
  id: Scalars['ID'];
  parent: Scalars['String'];
  waitForResult?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteTagArgs = {
  id: Scalars['String'];
};


export type MutationUpdateTagArgs = {
  id: Scalars['String'];
  input: TagUpdateInput;
};


export type MutationCreateTagArgs = {
  input: TagCreateInput;
};


export type MutationCreateTimelineArgs = {
  input?: Maybe<TimelineCreateInput>;
};


export type MutationEditTimelineArgs = {
  id: Scalars['ID'];
  input?: Maybe<TimelineEditInput>;
};


export type MutationAddEventToTimelineArgs = {
  id: Scalars['ID'];
  input: TimelineAddEventInput;
  deviceId: Scalars['String'];
};


export type MutationAnonymizeTimelineArgs = {
  id: Scalars['String'];
};


export type MutationAnonymizeTimelinesArgs = {
  filter: TimelineFilter;
};


export type MutationAnonymizeFrontCameraTimelineArgs = {
  id: Scalars['String'];
  anonymize?: Maybe<Scalars['Boolean']>;
};


export type MutationDeleteUiNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateUiNotificationArgs = {
  id: Scalars['ID'];
  input: UiNotificationInput;
};


export type MutationCreateUiNotificationArgs = {
  input: UiNotificationInput;
};


export type MutationChangePasswordArgs = {
  id: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangePasswordWithTokenArgs = {
  token: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserCreateInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  input: UserUpdateInput;
};


export type MutationVerifyUserArgs = {
  id: Scalars['String'];
  input: UserVerifyInput;
};


export type MutationSendSignInEmailArgs = {
  id: Scalars['String'];
};


export type MutationRemoveAccessArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateVpnConfigArgs = {
  id: Scalars['ID'];
  input: VpnConfigInput;
};


export type MutationCreateVpnConfigArgs = {
  input: VpnConfigInput;
};


export type MutationDeleteVpnConfigArgs = {
  id: Scalars['ID'];
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<OrderProduct>>>;
  customer?: Maybe<OrderCustomer>;
  devices?: Maybe<Array<Maybe<OrderDeviceType>>>;
  accessories?: Maybe<Array<Maybe<OrderAccessory>>>;
  timestamp?: Maybe<Scalars['Date']>;
  area?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversType>>>;
  creator?: Maybe<CreatorType>;
  details?: Maybe<OrderDetailsType>;
};

export type OrderAccessoriesInput = {
  name?: Maybe<Scalars['String']>;
  deliveredQuantity?: Maybe<Scalars['Int']>;
};

export type OrderAccessory = {
  __typename?: 'OrderAccessory';
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  imgUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  deliveredQuantity?: Maybe<Scalars['Int']>;
};

export type OrderAccessoryInput = {
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type OrderBill = {
  __typename?: 'OrderBill';
  id: Scalars['ID'];
};

export type OrderConfirmDetailsInput = {
  message?: Maybe<Scalars['String']>;
  deliveryEstimation: Scalars['Date'];
};

export type OrderCreateInput = {
  products?: Maybe<Array<Maybe<OrderProductInput>>>;
  customer: OrderCustomerInput;
  accessories?: Maybe<Array<Maybe<OrderAccessoryInput>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
};

export type OrderCustomer = {
  __typename?: 'OrderCustomer';
  tag?: Maybe<Scalars['String']>;
  billingInfo?: Maybe<Scalars['JSON']>;
  deliveryInfo?: Maybe<Scalars['JSON']>;
  customData?: Maybe<Scalars['Boolean']>;
};

export type OrderCustomerInput = {
  tag?: Maybe<Scalars['String']>;
  billingInfo?: Maybe<Scalars['JSON']>;
  deliveryInfo?: Maybe<Scalars['JSON']>;
  customData?: Maybe<Scalars['Boolean']>;
};

export type OrderDeliverDetailsInput = {
  message?: Maybe<Scalars['String']>;
  trackingId?: Maybe<Scalars['String']>;
};

export type OrderDetailsInput = {
  message?: Maybe<Scalars['String']>;
  deliveryEstimation?: Maybe<Scalars['Date']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  trackingId?: Maybe<Scalars['String']>;
};

export type OrderDetailsType = {
  __typename?: 'OrderDetailsType';
  messages?: Maybe<Array<Maybe<OrderMessageType>>>;
  deliveryEstimation?: Maybe<Scalars['Date']>;
  deliveryDate?: Maybe<Scalars['Date']>;
  trackingId?: Maybe<Scalars['String']>;
};

export type OrderDeviceType = {
  __typename?: 'OrderDeviceType';
  id?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  lifecycleState?: Maybe<Scalars['String']>;
};

export type OrderFilter = {
  state?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Timerange>;
  tag?: Maybe<Scalars['String']>;
  tagExact?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
};

export type OrderIloqKeyResponse = {
  __typename?: 'OrderIloqKeyResponse';
  canProgram?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  iloqState?: Maybe<Scalars['String']>;
  readyToHandover?: Maybe<Scalars['Boolean']>;
};

export type OrderList = {
  __typename?: 'OrderList';
  edges?: Maybe<Array<Maybe<Order>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrderMessageType = {
  __typename?: 'OrderMessageType';
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  name?: Maybe<Scalars['String']>;
  configuration?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type OrderProductInput = {
  name: Scalars['String'];
  configuration: Scalars['String'];
  quantity: Scalars['Int'];
};

export type OrderUpdateInput = {
  state?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<OrderProductInput>>>;
  customer?: Maybe<OrderCustomerInput>;
  accessories?: Maybe<Array<Maybe<OrderAccessoryInput>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  details?: Maybe<OrderDetailsInput>;
};

export type OrgUnit = {
  __typename?: 'OrgUnit';
  country?: Maybe<OrgUnitData>;
  area?: Maybe<OrgUnitData>;
  organization?: Maybe<OrgUnitData>;
};

export type OrgUnitData = {
  __typename?: 'OrgUnitData';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type OrganizationTreeUnitsFilter = {
  unitsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  parentId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};

export type OrganizationUnit = {
  __typename?: 'OrganizationUnit';
  name: Scalars['String'];
  type: Scalars['String'];
  id: Scalars['String'];
  highlighted?: Maybe<Scalars['Boolean']>;
  parent?: Maybe<Scalars['String']>;
  parents: Array<OrganizationUnitParents>;
  alarmReceivers?: Maybe<Array<Maybe<ReceiversType>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversType>>>;
  meta?: Maybe<Scalars['JSON']>;
  parentsAgreements?: Maybe<Array<Maybe<ParentAgreements>>>;
  language?: Maybe<Scalars['String']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  childrenInfo?: Maybe<PageInfo>;
  powerBiAccountConfiguration?: Maybe<PowerBiAccountConfiguration>;
  powerBiReportConfiguration?: Maybe<PowerBiReportConfiguration>;
  customizationData?: Maybe<OrganizationUnitCustomizationData>;
  customerData?: Maybe<CustomerData>;
  enforceTwoFactorAuth?: Maybe<Scalars['Boolean']>;
  integrations?: Maybe<Array<Maybe<Integration>>>;
  syncedData?: Maybe<SyncedData>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  iloq?: Maybe<IloqIntegration>;
  alarmSettings?: Maybe<AlarmSettings>;
};

export type OrganizationUnitCustomizationData = {
  __typename?: 'OrganizationUnitCustomizationData';
  backgroundColor?: Maybe<Scalars['String']>;
  tz?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  sender?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  organizationAddress?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
};

export type OrganizationUnitList = {
  __typename?: 'OrganizationUnitList';
  edges?: Maybe<Array<Maybe<OrganizationUnit>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrganizationUnitParents = {
  __typename?: 'OrganizationUnitParents';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type Originator = {
  __typename?: 'Originator';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OriginatorInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  hasNext?: Maybe<Scalars['Boolean']>;
};

export type PaginationContactInput = {
  limit?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type ParentAgreements = {
  __typename?: 'ParentAgreements';
  parent?: Maybe<Scalars['String']>;
  forceAllAgreements?: Maybe<Scalars['Boolean']>;
  agreements?: Maybe<Array<Maybe<Agreement>>>;
};

export type PowerBiAccountConfiguration = {
  __typename?: 'PowerBiAccountConfiguration';
  clientId?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type PowerBiAccountConfigurationInput = {
  clientId?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['String']>;
};

export type PowerBiEmbedParams = {
  __typename?: 'PowerBiEmbedParams';
  accessToken: Scalars['String'];
  embedUrl: Scalars['String'];
  expiry: Scalars['String'];
  reportId: Scalars['String'];
  publicHash?: Maybe<Scalars['String']>;
};

export type PowerBiEmbedParamsList = {
  __typename?: 'PowerBiEmbedParamsList';
  edges?: Maybe<Array<Maybe<PowerBiEmbedParams>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PowerBiReportConfiguration = {
  __typename?: 'PowerBiReportConfiguration';
  reportId?: Maybe<Scalars['String']>;
  rls?: Maybe<Scalars['String']>;
  publicHash?: Maybe<Scalars['String']>;
};

export type PowerBiReportConfigurationInput = {
  reportId?: Maybe<Scalars['String']>;
  rls?: Maybe<Scalars['String']>;
  isPublic?: Maybe<Scalars['Boolean']>;
};

export type PowerBiReportFilter = {
  tag: Scalars['String'];
  reportId?: Maybe<Scalars['String']>;
};

export type PrepareIloqKeyInput = {
  keyId: Scalars['String'];
  securityAccessIds: Array<Maybe<Scalars['String']>>;
  personId: Scalars['String'];
};

export type PrepareIloqKeyResponse = {
  __typename?: 'PrepareIloqKeyResponse';
  prepared?: Maybe<Scalars['Boolean']>;
};

export type Product = {
  __typename?: 'Product';
  id?: Maybe<Scalars['ID']>;
  billing?: Maybe<BillingData>;
  deviceSw?: Maybe<Array<Maybe<DeviceSw>>>;
  productionTestSet?: Maybe<ProductionTestSet>;
  swAutoUpdate?: Maybe<Scalars['Boolean']>;
  production?: Maybe<ProductProduction>;
};

export type ProductCreateInput = {
  name: Scalars['String'];
  deviceSw?: Maybe<Array<Maybe<DeviceSwInput>>>;
  billing?: Maybe<BillingInput>;
  productionTestSet?: Maybe<ProductionTestSetInput>;
  swAutoUpdate?: Maybe<Scalars['Boolean']>;
  production?: Maybe<ProductProductionInput>;
};

export type ProductFilter = {
  id?: Maybe<Scalars['String']>;
};

export type ProductList = {
  __typename?: 'ProductList';
  edges?: Maybe<Array<Maybe<Product>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ProductProduction = {
  __typename?: 'ProductProduction';
  envs?: Maybe<Array<Maybe<ProductProductionEnv>>>;
  sensors?: Maybe<Array<Maybe<Sensor>>>;
  settings?: Maybe<Scalars['JSON']>;
};

export type ProductProductionEnv = {
  __typename?: 'ProductProductionEnv';
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
};

export type ProductProductionEnvInput = {
  name?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['JSON']>;
};

export type ProductProductionInput = {
  envs: Array<Maybe<ProductProductionEnvInput>>;
  sensors?: Maybe<Array<Maybe<SensorCreateInput>>>;
  settings?: Maybe<Scalars['JSON']>;
};

export type ProductUpdateInput = {
  deviceSw?: Maybe<Array<Maybe<DeviceSwInput>>>;
  billing?: Maybe<BillingInput>;
  productionTestSet?: Maybe<ProductionTestSetInput>;
  swAutoUpdate?: Maybe<Scalars['Boolean']>;
  production?: Maybe<ProductProductionInput>;
};

export type ProductionStatus = {
  __typename?: 'ProductionStatus';
  status?: Maybe<Scalars['String']>;
  nbrOfSteps?: Maybe<Scalars['String']>;
  step?: Maybe<Scalars['String']>;
  err?: Maybe<Scalars['Boolean']>;
  mac?: Maybe<Scalars['String']>;
};

export type ProductionTestSet = {
  __typename?: 'ProductionTestSet';
  timeout?: Maybe<Scalars['Int']>;
  tests?: Maybe<Array<Maybe<TestSetTest>>>;
};

export type ProductionTestSetInput = {
  timeout?: Maybe<Scalars['Int']>;
  tests?: Maybe<Array<Maybe<TestSetTestInput>>>;
};

export type Property = {
  __typename?: 'Property';
  id: Scalars['String'];
  name: Scalars['String'];
  devices?: Maybe<Array<Maybe<Scalars['String']>>>;
  activePublicAlarms?: Maybe<AlarmList>;
};


export type PropertyDevicesArgs = {
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type PropertyActivePublicAlarmsArgs = {
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type PropertyCreateInput = {
  name: Scalars['String'];
};

export type PropertyDeviceInput = {
  name: Scalars['String'];
};

export type PropertyFilter = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};

export type PropertyList = {
  __typename?: 'PropertyList';
  edges?: Maybe<Array<Maybe<Property>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PropertyUpdateInput = {
  name: Scalars['String'];
};

export type PublicAlarmRule = {
  __typename?: 'PublicAlarmRule';
  id?: Maybe<Scalars['ID']>;
  alarmName?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  solutions?: Maybe<Array<Maybe<SolutionType>>>;
  rootCause?: Maybe<Scalars['String']>;
  alarms?: Maybe<AlarmList>;
};


export type PublicAlarmRuleAlarmsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<AlarmFilter>;
  sort?: Maybe<Scalars['JSON']>;
};

export type PublicAlarmRuleList = {
  __typename?: 'PublicAlarmRuleList';
  edges?: Maybe<Array<Maybe<PublicAlarmRule>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type PublicContract = {
  __typename?: 'PublicContract';
  id?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  person?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<ContractContact>>>;
  keys?: Maybe<Array<Maybe<ContractKey>>>;
  verified?: Maybe<Scalars['Boolean']>;
  agreements?: Maybe<Array<Maybe<ContractAgreement>>>;
};

export type PublicPowerBiEmbedParams = {
  __typename?: 'PublicPowerBiEmbedParams';
  accessToken: Scalars['String'];
  embedUrl: Scalars['String'];
  expiry: Scalars['String'];
  reportId: Scalars['String'];
  publicHash?: Maybe<Scalars['String']>;
  unitName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  accessories?: Maybe<AccessoryList>;
  accessory?: Maybe<Accessory>;
  verifyLogin: User;
  verifyUserToken?: Maybe<Scalars['Boolean']>;
  verifyDeviceToken?: Maybe<DeviceAlive>;
  checkDeviceAccess?: Maybe<Scalars['Boolean']>;
  checkMethodAccess?: Maybe<Scalars['Boolean']>;
  publicAlarmRules?: Maybe<PublicAlarmRuleList>;
  publicAlarmRule?: Maybe<AlarmRule>;
  alarmRules?: Maybe<AlarmRuleList>;
  alarmRule?: Maybe<AlarmRule>;
  receivers?: Maybe<Receivers>;
  alarms?: Maybe<AlarmList>;
  alarm?: Maybe<Alarm>;
  activeAlarmsCount?: Maybe<Scalars['Int']>;
  allAlarms?: Maybe<Array<Maybe<Alarm>>>;
  countSentSmsEmailForAlarm?: Maybe<SentSmsEmailCount>;
  apartments?: Maybe<ApartmentList>;
  apartment?: Maybe<Apartment>;
  attachments?: Maybe<AttachmentList>;
  verifyIdToken: User;
  bills?: Maybe<BillList>;
  bill?: Maybe<Bill>;
  buildings?: Maybe<BuildingList>;
  building?: Maybe<Building>;
  buildingsLinks?: Maybe<LinkList>;
  comments?: Maybe<CommentList>;
  commentThreads?: Maybe<ThreadList>;
  contact?: Maybe<Contact>;
  contacts?: Maybe<ContactsList>;
  contracts?: Maybe<ContractList>;
  contractByHash?: Maybe<PublicContract>;
  contract?: Maybe<Contract>;
  contractsV2?: Maybe<ContractList>;
  contractsNotVerified?: Maybe<ContractList>;
  contractsFuture?: Maybe<ContractList>;
  contractsOngoing?: Maybe<ContractList>;
  contractsNotReturned?: Maybe<ContractList>;
  contractV2?: Maybe<Contract>;
  customers?: Maybe<TagList>;
  customer?: Maybe<Tag>;
  keyRegisterOrganizationUnitUsage?: Maybe<KeyRegisterOrganizationUnitUsage>;
  keyRegisterBuildingsUsage?: Maybe<KeyRegisterBuildingsUsage>;
  connectVpnOrganizationUnitUsage?: Maybe<ConnectVpnOrganizationUnitUsage>;
  delayedEvents?: Maybe<DelayedEventsList>;
  devicesProductionTests?: Maybe<DeviceTestsList>;
  deviceProductionTests?: Maybe<DeviceTests>;
  devices?: Maybe<DeviceList>;
  devicesCount?: Maybe<DevicesCount>;
  device?: Maybe<Device>;
  deviceEnv?: Maybe<Scalars['JSON']>;
  alive?: Maybe<DeviceAlive>;
  getDefaultSocketServer?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  mineDeviceSensorValues?: Maybe<Array<Maybe<SensorValues>>>;
  events?: Maybe<EventList>;
  event?: Maybe<Event>;
  connectionEvents?: Maybe<EventList>;
  lifecycleEvents?: Maybe<EventList>;
  keyUsageReport?: Maybe<Array<Maybe<DevicesReport>>>;
  countSentSmsEmailForNotification?: Maybe<SentSmsEmailCount>;
  iloqRealEstates?: Maybe<IloqRealEstatesResponse>;
  iloqRealEstateKeys?: Maybe<IloqKeysResponse>;
  getSntOrganizationUnit?: Maybe<Tag>;
  getSntOrganizationUnits?: Maybe<TagList>;
  getSntAreaBuildings?: Maybe<Array<Maybe<SntBuildingsType>>>;
  iloqKey?: Maybe<IloqKeyDataResponse>;
  expiringIloqWebhookSubscriptions?: Maybe<Array<Maybe<OrganizationUnit>>>;
  availableIloqAccessRights?: Maybe<IloqAccessRightsResponse>;
  iloqOnlineReaders?: Maybe<IloqOnlineReadersResponse>;
  iloqOnlineReaderRelays?: Maybe<IloqOnlineReaderRelayResponse>;
  iloqCredentialPermissions?: Maybe<IloqCredentialPermissionsResponse>;
  keys?: Maybe<KeyList>;
  keyStatus?: Maybe<Scalars['JSON']>;
  key?: Maybe<Key>;
  keyV2?: Maybe<KeyV2>;
  keysV2?: Maybe<KeyListV2>;
  activeKeysV2?: Maybe<KeyListV2>;
  orders?: Maybe<OrderList>;
  order?: Maybe<Order>;
  organizationTreeUnits?: Maybe<OrganizationUnitList>;
  organizationUnits?: Maybe<Array<Maybe<OrganizationUnit>>>;
  organizationUnit?: Maybe<OrganizationUnit>;
  organizationStructure?: Maybe<Array<TagStructureType>>;
  organizationUnitLogoByHash?: Maybe<Scalars['String']>;
  powerbiEmbendedLink?: Maybe<PowerBiEmbedParams>;
  publicPowerbiEmbendedLink?: Maybe<PublicPowerBiEmbedParams>;
  products?: Maybe<ProductList>;
  product?: Maybe<Product>;
  properties?: Maybe<PropertyList>;
  property?: Maybe<Property>;
  reservations?: Maybe<ReservationList>;
  reservation?: Maybe<Reservation>;
  reservationFromContract?: Maybe<Reservation>;
  reservationSettings?: Maybe<ReservationSettings>;
  roles?: Maybe<RoleList>;
  role?: Maybe<Role>;
  roleAllAllows?: Maybe<Array<Maybe<RoleAllowsType>>>;
  rolePermissions?: Maybe<Scalars['JSON']>;
  eventsSearch?: Maybe<EventSearchList>;
  alarmsSearch?: Maybe<AlarmSearchList>;
  keyRegisterSearch?: Maybe<KeyRegisterSearchList>;
  sensorValues?: Maybe<SensorValueList>;
  mineSensorValues?: Maybe<Array<Maybe<MineValue>>>;
  sensors?: Maybe<SensorList>;
  sensor?: Maybe<Sensor>;
  serviceAccounts?: Maybe<ServiceAccountList>;
  serviceAccount?: Maybe<ServiceAccount>;
  tagsTree?: Maybe<TagTree>;
  tags?: Maybe<TagList>;
  tag?: Maybe<Tag>;
  findTagMeta?: Maybe<Scalars['JSON']>;
  /** @deprecated Use Tag.organization.meta instead */
  findOrganizationMeta?: Maybe<Scalars['JSON']>;
  /** @deprecated Use Tag.organization instead */
  getOrganizationFromTag?: Maybe<Tag>;
  countriesAndAreasTree?: Maybe<Array<Maybe<CountriesAndAreasTree>>>;
  timelines?: Maybe<TimelineList>;
  timeline?: Maybe<Timeline>;
  timelineByUuid?: Maybe<Timeline>;
  uiNotifications?: Maybe<UiNotificationList>;
  uiNotification?: Maybe<UiNotification>;
  users?: Maybe<UserList>;
  user?: Maybe<User>;
  userExistsInApp?: Maybe<Scalars['Boolean']>;
  vpnConfig?: Maybe<VpnConfig>;
  vpnConfigs?: Maybe<Array<Maybe<VpnConfig>>>;
};


export type QueryAccessoriesArgs = {
  filter?: Maybe<AccessoryFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryAccessoryArgs = {
  id: Scalars['ID'];
};


export type QueryVerifyUserTokenArgs = {
  token: Scalars['String'];
};


export type QueryVerifyDeviceTokenArgs = {
  token: Scalars['String'];
  id: Scalars['String'];
  sw?: Maybe<Scalars['String']>;
};


export type QueryCheckDeviceAccessArgs = {
  userId: Scalars['String'];
  deviceId: Scalars['String'];
  checkRealtimeConnectionAccess?: Maybe<Scalars['Boolean']>;
};


export type QueryCheckMethodAccessArgs = {
  userId: Scalars['String'];
  resource: Scalars['String'];
  action: Scalars['String'];
  deviceId?: Maybe<Scalars['String']>;
};


export type QueryPublicAlarmRulesArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<AlarmRuleFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryPublicAlarmRuleArgs = {
  id: Scalars['ID'];
};


export type QueryAlarmRulesArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<AlarmRuleFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryAlarmRuleArgs = {
  id: Scalars['ID'];
};


export type QueryReceiversArgs = {
  ruleId: Scalars['ID'];
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


export type QueryAlarmsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<AlarmFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryAlarmArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryActiveAlarmsCountArgs = {
  filter?: Maybe<AlarmCountFilter>;
};


export type QueryAllAlarmsArgs = {
  filter?: Maybe<AlarmFilter>;
};


export type QueryCountSentSmsEmailForAlarmArgs = {
  ruleId: Scalars['ID'];
  timerange: Timerange;
};


export type QueryApartmentsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ApartmentFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryApartmentArgs = {
  id: Scalars['String'];
};


export type QueryBillsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<BillFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryBillArgs = {
  id: Scalars['ID'];
};


export type QueryBuildingsArgs = {
  filter?: Maybe<BuildingFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryBuildingArgs = {
  buildingId: Scalars['ID'];
};


export type QueryBuildingsLinksArgs = {
  filter?: Maybe<LinkFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryCommentsArgs = {
  filter: CommentFilter;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryCommentThreadsArgs = {
  filter: CommentFilter;
};


export type QueryContactArgs = {
  id: Scalars['String'];
};


export type QueryContactsArgs = {
  search?: Maybe<Scalars['String']>;
  filters?: Maybe<FiltersContactsInput>;
  pagination?: Maybe<PaginationContactInput>;
};


export type QueryContractsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ContractFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryContractByHashArgs = {
  id: Scalars['ID'];
  hash: Scalars['String'];
};


export type QueryContractArgs = {
  id: Scalars['ID'];
};


export type QueryContractsV2Args = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ContractFilterV2>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryContractsNotVerifiedArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ContractFilterV2>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryContractsFutureArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ContractFilterV2>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryContractsOngoingArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ContractFilterV2>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryContractsNotReturnedArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ContractFilterV2>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryContractV2Args = {
  contractId: Scalars['String'];
};


export type QueryCustomersArgs = {
  filter?: Maybe<CustomerFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryCustomerArgs = {
  tag: Scalars['String'];
};


export type QueryKeyRegisterOrganizationUnitUsageArgs = {
  unitIds?: Maybe<Array<Scalars['ID']>>;
  timerange: Timerange;
};


export type QueryKeyRegisterBuildingsUsageArgs = {
  filter: BuildingsUsageFilter;
  after: Scalars['String'];
  limit: Scalars['Int'];
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryConnectVpnOrganizationUnitUsageArgs = {
  unit: Scalars['ID'];
  timerange: Timerange;
};


export type QueryDelayedEventsArgs = {
  filter?: Maybe<DelayedEventFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryDevicesProductionTestsArgs = {
  filter?: Maybe<DeviceTestFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryDeviceProductionTestsArgs = {
  deviceId: Scalars['String'];
};


export type QueryDevicesArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<DeviceFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryDevicesCountArgs = {
  tag?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryDeviceArgs = {
  id: Scalars['String'];
};


export type QueryDeviceEnvArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  lifecycleState?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryMineDeviceSensorValuesArgs = {
  filter: MineDeviceFilter;
};


export type QueryEventsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<EventFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryConnectionEventsArgs = {
  deviceId: Scalars['String'];
  filter?: Maybe<EventFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryLifecycleEventsArgs = {
  deviceId: Scalars['String'];
  filter?: Maybe<EventFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryKeyUsageReportArgs = {
  devices: Array<Maybe<Scalars['String']>>;
  actions: Array<Maybe<Scalars['String']>>;
  timerange?: Maybe<Timerange>;
};


export type QueryCountSentSmsEmailForNotificationArgs = {
  notificationId: Scalars['ID'];
  timerange: Timerange;
};


export type QueryIloqRealEstateKeysArgs = {
  buildingId: Scalars['String'];
};


export type QueryGetSntOrganizationUnitArgs = {
  sntPremiseId: Scalars['String'];
  type: Scalars['String'];
};


export type QueryGetSntOrganizationUnitsArgs = {
  filter?: Maybe<SntOrganizationUnitsFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryGetSntAreaBuildingsArgs = {
  sntAreaId: Scalars['String'];
};


export type QueryIloqKeyArgs = {
  keyId: Scalars['String'];
};


export type QueryExpiringIloqWebhookSubscriptionsArgs = {
  subscriptionType: IloqIntegrationWebhookEventType;
};


export type QueryIloqOnlineReaderRelaysArgs = {
  onlineReaderId: Scalars['String'];
};


export type QueryIloqCredentialPermissionsArgs = {
  credentials: IloqApiCredentialsInput;
};


export type QueryKeysArgs = {
  filter?: Maybe<KeyFilter>;
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryKeyStatusArgs = {
  deviceId: Scalars['String'];
  keyId: Scalars['String'];
  contractObjectId?: Maybe<Scalars['String']>;
};


export type QueryKeyArgs = {
  keyId: Scalars['String'];
};


export type QueryKeyV2Args = {
  keyId: Scalars['String'];
};


export type QueryKeysV2Args = {
  filter?: Maybe<KeyFilterV2>;
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryActiveKeysV2Args = {
  filter?: Maybe<KeyFilterV2>;
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryOrdersArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<OrderFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationTreeUnitsArgs = {
  filter?: Maybe<OrganizationTreeUnitsFilter>;
  limit?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  includeBuildings?: Maybe<Scalars['Boolean']>;
};


export type QueryOrganizationUnitArgs = {
  unitId: Scalars['ID'];
};


export type QueryOrganizationUnitLogoByHashArgs = {
  id: Scalars['ID'];
  hash: Scalars['String'];
};


export type QueryPowerbiEmbendedLinkArgs = {
  filter?: Maybe<PowerBiReportFilter>;
};


export type QueryPublicPowerbiEmbendedLinkArgs = {
  tag: Scalars['String'];
  publicHash: Scalars['String'];
};


export type QueryProductsArgs = {
  filter?: Maybe<ProductFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryPropertiesArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<PropertyFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryPropertyArgs = {
  id: Scalars['String'];
};


export type QueryReservationsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<ReservationFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryReservationArgs = {
  id: Scalars['ID'];
};


export type QueryReservationFromContractArgs = {
  contractObjectId: Scalars['String'];
};


export type QueryReservationSettingsArgs = {
  id: Scalars['ID'];
};


export type QueryRolesArgs = {
  filter?: Maybe<RoleFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRoleArgs = {
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
};


export type QueryRolePermissionsArgs = {
  role: Scalars['String'];
};


export type QueryEventsSearchArgs = {
  search: Scalars['String'];
};


export type QueryAlarmsSearchArgs = {
  search: Scalars['String'];
};


export type QueryKeyRegisterSearchArgs = {
  search: Scalars['String'];
};


export type QuerySensorValuesArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<SensorValueFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryMineSensorValuesArgs = {
  filter: MineFilter;
};


export type QuerySensorsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<SensorFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QuerySensorArgs = {
  id?: Maybe<Scalars['String']>;
  hwId?: Maybe<Scalars['String']>;
};


export type QueryServiceAccountsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryServiceAccountArgs = {
  id: Scalars['String'];
};


export type QueryTagsTreeArgs = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryTagsArgs = {
  filter?: Maybe<TagFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryTagArgs = {
  id: Scalars['String'];
};


export type QueryFindTagMetaArgs = {
  id: Scalars['String'];
};


export type QueryFindOrganizationMetaArgs = {
  id: Scalars['String'];
};


export type QueryGetOrganizationFromTagArgs = {
  id: Scalars['String'];
};


export type QueryCountriesAndAreasTreeArgs = {
  id: Scalars['String'];
};


export type QueryTimelinesArgs = {
  filter?: Maybe<TimelineFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryTimelineArgs = {
  id: Scalars['ID'];
};


export type QueryTimelineByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryUiNotificationsArgs = {
  filter?: Maybe<UiNotificationFilter>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryUiNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<UserFilter>;
  sort?: Maybe<Scalars['JSON']>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUserExistsInAppArgs = {
  email: Scalars['String'];
};


export type QueryVpnConfigArgs = {
  id: Scalars['ID'];
};

export type ReceiverRuleInput = {
  name?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
};

export type ReceiverRuleType = {
  __typename?: 'ReceiverRuleType';
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  sendSms?: Maybe<Scalars['Boolean']>;
};

export type Receivers = {
  __typename?: 'Receivers';
  mail?: Maybe<Array<Maybe<MailReceiver>>>;
  sms?: Maybe<Array<Maybe<SmsReceiver>>>;
};

export type ReceiversInput = {
  mail?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  activation?: Maybe<Scalars['Boolean']>;
  deactivation?: Maybe<Scalars['Boolean']>;
  rules?: Maybe<Array<Maybe<ReceiverRuleInput>>>;
};

export type ReceiversType = {
  __typename?: 'ReceiversType';
  mail?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  activation?: Maybe<Scalars['Boolean']>;
  deactivation?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  rules?: Maybe<Array<Maybe<ReceiverRuleType>>>;
};

export type RemoveIloqLocksFromBuildingInput = {
  buildingId: Scalars['String'];
  serialNumber: Scalars['Int'];
};

export type Reservation = {
  __typename?: 'Reservation';
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  apartmentId?: Maybe<Scalars['String']>;
  apartmentName?: Maybe<Scalars['String']>;
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  contacts?: Maybe<Array<Maybe<ReservationContact>>>;
  freeText?: Maybe<Scalars['String']>;
  contracts?: Maybe<Array<Maybe<Scalars['String']>>>;
  pincode?: Maybe<Scalars['String']>;
  syncError?: Maybe<Scalars['String']>;
  cancelled?: Maybe<Scalars['Boolean']>;
  type?: Maybe<ContractTypeEnum>;
  fetchSync?: Maybe<ReservationKeySync>;
  returnSync?: Maybe<ReservationKeySync>;
  defaultSync?: Maybe<ReservationKeySync>;
  elinaReservationData?: Maybe<ElinaReservationDataType>;
};

export type ReservationContact = {
  __typename?: 'ReservationContact';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  fixedPhoneNumber?: Maybe<Scalars['String']>;
};

export type ReservationContactInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  sendSms?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  fixedPhoneNumber?: Maybe<Scalars['String']>;
};

export type ReservationCreateInput = {
  id?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  apartmentId: Scalars['String'];
  apartmentName?: Maybe<Scalars['String']>;
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
  start: Scalars['Date'];
  end: Scalars['Date'];
  contacts: Array<Maybe<ReservationContactInput>>;
  freeText?: Maybe<Scalars['String']>;
  type?: Maybe<ContractTypeEnum>;
  elinaReservationData?: Maybe<ElinaReservationDataInput>;
};

export type ReservationFilter = {
  apartmentId?: Maybe<Scalars['String']>;
  keyIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  startTime?: Maybe<Timerange>;
  endTime?: Maybe<Timerange>;
  notSynced?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  tag?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
};

export type ReservationKeySync = {
  __typename?: 'ReservationKeySync';
  deviceId?: Maybe<Scalars['String']>;
  contract?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
  keyName?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
};

export type ReservationList = {
  __typename?: 'ReservationList';
  edges?: Maybe<Array<Maybe<Reservation>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type ReservationSettings = {
  __typename?: 'ReservationSettings';
  organization?: Maybe<Scalars['String']>;
  fetchDuration?: Maybe<Scalars['Int']>;
  returnDuration?: Maybe<Scalars['Int']>;
  defaultDuration?: Maybe<Scalars['Int']>;
  defaultSyncTime?: Maybe<Scalars['Int']>;
  fetchSyncTime?: Maybe<Scalars['Int']>;
  returnSyncTime?: Maybe<Scalars['Int']>;
  freeLockersLimit?: Maybe<Scalars['Int']>;
  smartSync?: Maybe<Scalars['Boolean']>;
};

export type ReservationUpdateInput = {
  apartmentId?: Maybe<Scalars['String']>;
  apartmentName?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  keys?: Maybe<Array<Maybe<Scalars['String']>>>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  contacts?: Maybe<Array<Maybe<ReservationContactInput>>>;
  freeText?: Maybe<Scalars['String']>;
  type?: Maybe<ContractTypeEnum>;
  elinaReservationData?: Maybe<ElinaReservationDataInput>;
};

export enum Resolutions {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
  All = 'all'
}

export type RfidInput = {
  hex?: Maybe<Scalars['String']>;
  decimal?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename?: 'Role';
  id?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  allowedRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  allows?: Maybe<Array<Maybe<RoleAllowsType>>>;
  restrictSettings?: Maybe<Scalars['Boolean']>;
  whiteList?: Maybe<Array<Maybe<WhiteListType>>>;
};

export type RoleAllowsInput = {
  resources?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoleAllowsType = {
  __typename?: 'RoleAllowsType';
  resources?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type RoleFilter = {
  role?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  role?: Maybe<Scalars['String']>;
  app?: Maybe<Scalars['String']>;
  allowedRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  allows?: Maybe<Array<Maybe<RoleAllowsInput>>>;
  restrictSettings?: Maybe<Scalars['Boolean']>;
  whiteList?: Maybe<Array<Maybe<WhiteListInput>>>;
};

export type RoleList = {
  __typename?: 'RoleList';
  edges?: Maybe<Array<Maybe<Role>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type RoutingHistoryType = {
  __typename?: 'RoutingHistoryType';
  routingId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Date']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorCode?: Maybe<Scalars['String']>;
};

export enum SearchEntitiesEnum {
  Keys = 'keys',
  Contracts = 'contracts',
  Users = 'users',
  Buildings = 'buildings',
  Devices = 'devices',
  AlarmRules = 'alarmRules'
}

export type SendPublicSignatureInput = {
  host: Scalars['String'];
};

export type Sensor = {
  __typename?: 'Sensor';
  id?: Maybe<Scalars['String']>;
  hwId?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  datapoints?: Maybe<Array<Maybe<SensorDatapoint>>>;
  devices?: Maybe<Array<Maybe<SensorDevice>>>;
  data?: Maybe<Scalars['JSON']>;
  location?: Maybe<Event>;
  locations?: Maybe<Array<Maybe<Event>>>;
  locationEvents?: Maybe<EventList>;
  updatedAt?: Maybe<Scalars['Date']>;
};


export type SensorLocationEventsArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<EventFilter>;
  sort?: Maybe<Scalars['JSON']>;
};

export type SensorCreateInput = {
  hwId?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Maybe<SensorDeviceInput>>>;
  datapoints?: Maybe<Array<Maybe<SensorDatapointInput>>>;
  data?: Maybe<Scalars['JSON']>;
};

export type SensorDatapoint = {
  __typename?: 'SensorDatapoint';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['Int']>;
  minInterval?: Maybe<Scalars['Int']>;
  maxInterval?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['Int']>;
  interpolation?: Maybe<Fills>;
  offset?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['JSON']>;
  values?: Maybe<SensorValueList>;
};


export type SensorDatapointValuesArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['JSON']>;
};

export type SensorDatapointInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  interval?: Maybe<Scalars['Int']>;
  minInterval?: Maybe<Scalars['Int']>;
  maxInterval?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['Int']>;
  interpolation?: Maybe<Fills>;
  offset?: Maybe<Scalars['Float']>;
  data?: Maybe<Scalars['JSON']>;
};

export type SensorDevice = {
  __typename?: 'SensorDevice';
  id?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
};

export type SensorDeviceInput = {
  id: Scalars['String'];
  state: Scalars['String'];
};

export type SensorFilter = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  algoliaFilters?: Maybe<Scalars['String']>;
};

export type SensorList = {
  __typename?: 'SensorList';
  edges?: Maybe<Array<Maybe<Sensor>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SensorUpdateInput = {
  hwId?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  tag: Scalars['String'];
  devices?: Maybe<Array<Maybe<SensorDeviceInput>>>;
  datapoints?: Maybe<Array<Maybe<SensorDatapointInput>>>;
  data?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type SensorValue = {
  __typename?: 'SensorValue';
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  pointId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Any']>;
};

export type SensorValueFilter = {
  timestamp?: Maybe<Timerange>;
  pointId?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type SensorValueInput = {
  pointId: Scalars['String'];
  value: Scalars['Any'];
  timestamp?: Maybe<Scalars['Date']>;
};

export type SensorValueList = {
  __typename?: 'SensorValueList';
  edges?: Maybe<Array<Maybe<SensorValue>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SensorValues = {
  __typename?: 'SensorValues';
  datapointId?: Maybe<Scalars['ID']>;
  datapointName?: Maybe<Scalars['String']>;
  sensorId?: Maybe<Scalars['String']>;
  sensorName?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<MineValue>>>;
};

export type SentSmsEmailCount = {
  __typename?: 'SentSmsEmailCount';
  sms?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['Int']>;
};

export type ServiceAccount = {
  __typename?: 'ServiceAccount';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  acl?: Maybe<Array<Maybe<UserAcl>>>;
  password?: Maybe<Scalars['String']>;
};

export type ServiceAccountInput = {
  name?: Maybe<Scalars['String']>;
  acl?: Maybe<Array<Maybe<UserAclInput>>>;
  role?: Maybe<Scalars['String']>;
};

export type ServiceAccountList = {
  __typename?: 'ServiceAccountList';
  edges?: Maybe<Array<Maybe<ServiceAccount>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SmsReceiver = {
  __typename?: 'SmsReceiver';
  sms?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  activation?: Maybe<Scalars['Boolean']>;
  deactivation?: Maybe<Scalars['Boolean']>;
};

export type SntBuildingCreateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BuildingTypeEnum>;
  customerId?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  address: Scalars['String'];
  postalCode: Scalars['String'];
  postalOffice: Scalars['String'];
  syncedData?: Maybe<SyncedDataInput>;
};

export type SntBuildingInput = {
  tunnus?: Maybe<Scalars['String']>;
  lisaysAikaleima?: Maybe<Scalars['String']>;
  muutosAikaleima?: Maybe<Scalars['String']>;
  nimi?: Maybe<Scalars['String']>;
  yritysID?: Maybe<Scalars['String']>;
  voimassaolo?: Maybe<VoimassaOloEnum>;
  hallinnollisenKiinteistonTunnus?: Maybe<Scalars['String']>;
  rakennusNumero?: Maybe<Scalars['String']>;
  isannointialue?: Maybe<Scalars['String']>;
  isannoitsija?: Maybe<Scalars['String']>;
  katuosoite?: Maybe<Scalars['String']>;
  postinumero?: Maybe<Scalars['String']>;
  postitoimipaikka?: Maybe<Scalars['String']>;
};

export type SntBuildingUpdateInput = {
  name?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  address: Scalars['String'];
  postalCode: Scalars['String'];
  postalOffice: Scalars['String'];
  syncedData?: Maybe<SyncedDataInput>;
};

export type SntBuildingsType = SntRakennelmaType | SntRakennusType;

export type SntHallinnollinenKiinteistoType = {
  __typename?: 'SntHallinnollinenKiinteistoType';
  tunnus?: Maybe<Scalars['String']>;
  lisaysAikaleima?: Maybe<Scalars['String']>;
  muutosAikaleima?: Maybe<Scalars['String']>;
  yritysID?: Maybe<Scalars['String']>;
  yritysNimi?: Maybe<Scalars['String']>;
  nimi?: Maybe<Scalars['String']>;
  voimassaolo?: Maybe<VoimassaOloEnum>;
  alue?: Maybe<Scalars['String']>;
  toimiala?: Maybe<Scalars['String']>;
  alaryhma?: Maybe<Scalars['String']>;
  isannointialue?: Maybe<Scalars['String']>;
  isannoitsija?: Maybe<Scalars['String']>;
  unitId?: Maybe<Tag>;
};

export type SntOrganizationUnitCreateInput = {
  tag: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  syncedData?: Maybe<SyncedDataInput>;
};

export type SntOrganizationUnitUpdateInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  enforceTwoFactorAuth?: Maybe<Scalars['Boolean']>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  syncedData?: Maybe<SyncedDataInput>;
};

export type SntOrganizationUnitsFilter = {
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type?: Maybe<Scalars['String']>;
};

export type SntRakennelmaType = {
  __typename?: 'SntRakennelmaType';
  tunnus?: Maybe<Scalars['String']>;
  lisaysAikaleima?: Maybe<Scalars['String']>;
  muutosAikaleima?: Maybe<Scalars['String']>;
  nimi?: Maybe<Scalars['String']>;
  voimassaolo?: Maybe<VoimassaOloEnum>;
  hallinnollisenKiinteistonTunnus?: Maybe<Scalars['String']>;
  isannointialue?: Maybe<Scalars['String']>;
  isannoitsija?: Maybe<Scalars['String']>;
  unitId?: Maybe<Tag>;
};

export type SntRakennusType = {
  __typename?: 'SntRakennusType';
  tunnus?: Maybe<Scalars['String']>;
  lisaysAikaleima?: Maybe<Scalars['String']>;
  muutosAikaleima?: Maybe<Scalars['String']>;
  nimi?: Maybe<Scalars['String']>;
  yritysID?: Maybe<Scalars['String']>;
  voimassaolo?: Maybe<VoimassaOloEnum>;
  hallinnollisenKiinteistonTunnus?: Maybe<Scalars['String']>;
  rakennusNumero?: Maybe<Scalars['String']>;
  isannointialue?: Maybe<Scalars['String']>;
  isannoitsija?: Maybe<Scalars['String']>;
  katuosoite?: Maybe<Scalars['String']>;
  postinumero?: Maybe<Scalars['String']>;
  postitoimipaikka?: Maybe<Scalars['String']>;
  unitId?: Maybe<Tag>;
};

export type SntSyncedData = {
  __typename?: 'SntSyncedData';
  syncedPremise?: Maybe<Scalars['JSON']>;
};

export type SntSyncedDataInput = {
  syncedPremise?: Maybe<Scalars['JSON']>;
};

export type SolutionInput = {
  language?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type SolutionType = {
  __typename?: 'SolutionType';
  language?: Maybe<Scalars['String']>;
  steps?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum State {
  Created = 'created',
  Assembled = 'assembled',
  StoredExt = 'stored_ext',
  Delivered = 'delivered',
  Active = 'active',
  Returned = 'returned'
}

export type Storage = {
  __typename?: 'Storage';
  keyName?: Maybe<Scalars['String']>;
  keyId?: Maybe<Scalars['String']>;
  type?: Maybe<KeyStorageEnum>;
  details?: Maybe<StorageDetails>;
};

export type StorageDetails = {
  __typename?: 'StorageDetails';
  device?: Maybe<DeviceStorageDetails>;
  manualStorage?: Maybe<ManualStorageDetails>;
};

export type StorageDetailsInput = {
  device?: Maybe<DeviceStorageDetailsInput>;
  manualStorage?: Maybe<ManualStorageDetailsInput>;
};

export type StorageInput = {
  type: KeyStorageEnum;
  details: StorageDetailsInput;
};

export type SubscriptionBill = {
  __typename?: 'SubscriptionBill';
  id: Scalars['ID'];
  period?: Maybe<BillPeriod>;
  tag?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<Maybe<SubscriptionBillProduct>>>;
};

export type SubscriptionBillProduct = {
  __typename?: 'SubscriptionBillProduct';
  product?: Maybe<Scalars['String']>;
  stat?: Maybe<Scalars['JSON']>;
  totalAmount?: Maybe<Scalars['Float']>;
  netvisorInvoiceId?: Maybe<Scalars['Int']>;
  units?: Maybe<Array<Maybe<SubscriptionBillProductUnit>>>;
};

export type SubscriptionBillProductService = {
  __typename?: 'SubscriptionBillProductService';
  totalAmount?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Int']>;
  serviceName?: Maybe<Scalars['String']>;
  serviceFreeText?: Maybe<Scalars['String']>;
};

export type SubscriptionBillProductUnit = {
  __typename?: 'SubscriptionBillProductUnit';
  unitName?: Maybe<Scalars['String']>;
  unitId?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
  services?: Maybe<Array<Maybe<SubscriptionBillProductService>>>;
  extras?: Maybe<Array<Maybe<SubsctiptionBillProductExtra>>>;
};

export type SubsctiptionBillProductExtra = {
  __typename?: 'SubsctiptionBillProductExtra';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  serviceId?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Float']>;
  serviceName?: Maybe<Scalars['String']>;
  serviceFreeText?: Maybe<Scalars['String']>;
};

export type SwitchAndDeactivateIloqKeyInput = {
  keyId?: Maybe<Scalars['String']>;
  otherKeyId?: Maybe<Scalars['String']>;
};

export type SwitchAndDeactivateIloqKeyResponse = {
  __typename?: 'SwitchAndDeactivateIloqKeyResponse';
  canProgram?: Maybe<Scalars['Boolean']>;
  reason?: Maybe<Scalars['String']>;
  keySwitched?: Maybe<Scalars['Boolean']>;
};

export type SyncedData = {
  __typename?: 'SyncedData';
  iloq?: Maybe<IloqSyncedData>;
  snt?: Maybe<SntSyncedData>;
};

export type SyncedDataInput = {
  snt?: Maybe<SntSyncedDataInput>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  structure?: Maybe<Array<Maybe<TagStructureType>>>;
  parents?: Maybe<Array<Maybe<TagParents>>>;
  parent?: Maybe<Scalars['ID']>;
  billing?: Maybe<BillingData>;
  products?: Maybe<TagProducts>;
  accessories?: Maybe<Array<Maybe<Accessory>>>;
  billingInfo?: Maybe<Scalars['JSON']>;
  deliveryInfo?: Maybe<Scalars['JSON']>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  alarmReceivers?: Maybe<Array<Maybe<ReceiversType>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversType>>>;
  children?: Maybe<TagTreeList>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
  tagParents?: Maybe<Scalars['JSON']>;
  devices?: Maybe<Array<Maybe<TagDevices>>>;
  customerData?: Maybe<CustomerData>;
  customerDataMissing?: Maybe<Scalars['Boolean']>;
  enforceTwoFactorAuth?: Maybe<Scalars['Boolean']>;
  groupSyncConfig?: Maybe<GroupSyncConfig>;
  syncedData?: Maybe<SyncedData>;
  alarmSettings?: Maybe<AlarmSettings>;
  organization?: Maybe<TagOrganization>;
};


export type TagChildrenArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<TagFilter>;
};

export type TagCreateInput = {
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  meta?: Maybe<Scalars['JSON']>;
  tag: Scalars['String'];
  products?: Maybe<Array<Maybe<TagProductInput>>>;
  alarmReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  billing?: Maybe<BillingInput>;
  accessories?: Maybe<Array<Maybe<AccessoryInput>>>;
  billingInfo?: Maybe<Scalars['JSON']>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  deliveryInfo?: Maybe<Scalars['JSON']>;
  customerData?: Maybe<CustomerDataUpsertInput>;
  enforceTwoFactorAuth?: Maybe<Scalars['Boolean']>;
  groupSyncConfig?: Maybe<GroupSyncConfigInput>;
};

export type TagDevices = {
  __typename?: 'TagDevices';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['Boolean']>;
};

export type TagFilter = {
  id?: Maybe<Scalars['JSON']>;
  type?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  name?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  algoliaFilters?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  customerCode?: Maybe<Scalars['Int']>;
  customerCodeList?: Maybe<Array<Maybe<Scalars['Int']>>>;
  buyerCode?: Maybe<Scalars['String']>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  customerDataMissing?: Maybe<Scalars['Boolean']>;
};

export type TagList = {
  __typename?: 'TagList';
  edges?: Maybe<Array<Maybe<Tag>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type TagNameAndId = {
  __typename?: 'TagNameAndId';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TagOrganization = {
  __typename?: 'TagOrganization';
  features?: Maybe<Scalars['JSON']>;
};

export type TagParents = {
  __typename?: 'TagParents';
  id: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type TagProduct = {
  __typename?: 'TagProduct';
  id?: Maybe<Scalars['String']>;
  billing?: Maybe<BillingData>;
};

export type TagProductInput = {
  id?: Maybe<Scalars['String']>;
  billing?: Maybe<BillingInput>;
};

export type TagProducts = {
  __typename?: 'TagProducts';
  inheritedFrom?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<TagProduct>>>;
};

export type TagStructureType = {
  __typename?: 'TagStructureType';
  type: Scalars['String'];
  required: Scalars['Boolean'];
};

export type TagTree = {
  __typename?: 'TagTree';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  billing?: Maybe<BillingData>;
  billingInfo?: Maybe<Scalars['JSON']>;
  deliveryInfo?: Maybe<Scalars['JSON']>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  hasChildren?: Maybe<Scalars['Boolean']>;
  children?: Maybe<TagTreeList>;
};


export type TagTreeHasChildrenArgs = {
  type?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type TagTreeChildrenArgs = {
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  filter?: Maybe<TagFilter>;
};

export type TagTreeList = {
  __typename?: 'TagTreeList';
  edges?: Maybe<Array<Maybe<TagTree>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum TagType {
  Bulding = 'bulding',
  Area = 'area',
  Country = 'country',
  Organization = 'organization'
}

export type TagUpdateInput = {
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  alarmReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
  billing?: Maybe<BillingInput>;
  accessories?: Maybe<Array<Maybe<AccessoryInput>>>;
  billingInfo?: Maybe<Scalars['JSON']>;
  products?: Maybe<Array<Maybe<TagProductInput>>>;
  billSeparately?: Maybe<Scalars['Boolean']>;
  deliveryInfo?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['Date']>;
  customerData?: Maybe<CustomerDataUpsertInput>;
  enforceTwoFactorAuth?: Maybe<Scalars['Boolean']>;
  groupSyncConfig?: Maybe<GroupSyncConfigInput>;
};

export type TestSetTest = {
  __typename?: 'TestSetTest';
  name?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  sequentially?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  deviceCommands?: Maybe<Array<Maybe<DeviceCommand>>>;
};

export type TestSetTestInput = {
  name: Scalars['String'];
  timeout?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  sequentially?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  deviceCommands?: Maybe<Array<Maybe<DeviceCommandInput>>>;
};

export type ThreadList = {
  __typename?: 'ThreadList';
  edges?: Maybe<Array<Maybe<CommentThread>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Timeline = {
  __typename?: 'Timeline';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Date']>;
  end?: Maybe<Scalars['Date']>;
  deviceId?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<EventData>>>;
  uuid?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  anonymized?: Maybe<Scalars['Boolean']>;
};

export type TimelineAddEventInput = {
  text: Scalars['String'];
  sourceTime: Scalars['Date'];
  value: Scalars['String'];
  file?: Maybe<Scalars['Upload']>;
  filename?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  lockerIndex?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['JSON']>;
};

export type TimelineCreateInput = {
  deviceId?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  events?: Maybe<Array<Maybe<TimelineAddEventInput>>>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type TimelineEditInput = {
  deviceId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type TimelineEvent = {
  __typename?: 'TimelineEvent';
  name?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export type TimelineFilter = {
  fullText?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  start?: Maybe<Timerange>;
  anonymized?: Maybe<Scalars['Boolean']>;
  eventTexts?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasAttachment?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type TimelineList = {
  __typename?: 'TimelineList';
  edges?: Maybe<Array<Maybe<Timeline>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type Timerange = {
  gte?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
};

export type Timeslot = {
  __typename?: 'Timeslot';
  startTime?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  days?: Maybe<Scalars['JSON']>;
};

export type TimeslotInput = {
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  days: Scalars['JSON'];
};

export type TwoFactorAuthOptions = {
  __typename?: 'TwoFactorAuthOptions';
  email?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  app?: Maybe<Scalars['Boolean']>;
};

export type TwoFactorAuthOptionsInput = {
  email?: Maybe<Scalars['Boolean']>;
  sms?: Maybe<Scalars['Boolean']>;
  app?: Maybe<Scalars['Boolean']>;
};

export type UiNotification = {
  __typename?: 'UiNotification';
  id?: Maybe<Scalars['ID']>;
  creator?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  discardable?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type UiNotificationFilter = {
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
};

export type UiNotificationInput = {
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  discardable?: Maybe<Scalars['Boolean']>;
};

export type UiNotificationList = {
  __typename?: 'UiNotificationList';
  edges?: Maybe<Array<Maybe<UiNotification>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type UninstallDeviceInput = {
  tag?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
};

export type UpdateContactInput = {
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  fixedPhoneNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};

export type UpdateLockerStatusInput = {
  keyId: Scalars['String'];
  contractId?: Maybe<Scalars['String']>;
  lockerIndex?: Maybe<Scalars['String']>;
  lockerStatus: Scalars['JSON'];
  originator?: Maybe<OriginatorInput>;
};

export type UpdateOrganizationUnitInput = {
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['JSON']>;
  enforceTwoFactorAuth?: Maybe<Scalars['Boolean']>;
  notificationReceivers?: Maybe<Array<Maybe<ReceiversInput>>>;
};


export type UploadAgreementInput = {
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  customFileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Scalars['String']>;
};

export type UploadAttachmentInput = {
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  customFileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UploadAttachmentUpdateInput = {
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
  customFileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  attachments?: Maybe<Array<Maybe<ContractAttachmentInput>>>;
};

export type UploadInput = {
  files?: Maybe<Array<Maybe<Scalars['Upload']>>>;
};

export type User = {
  __typename?: 'User';
  idToken?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  redirectLink?: Maybe<Scalars['String']>;
  twoFactorAuthSessionToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  email: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  acl?: Maybe<Array<Maybe<UserAcl>>>;
  uiNotifications?: Maybe<Scalars['Boolean']>;
  claims?: Maybe<UserClaims>;
  verified?: Maybe<Scalars['Boolean']>;
  passwordChangedAt?: Maybe<Scalars['Date']>;
  twoFactorAuth?: Maybe<UserTwoFactorAuth>;
};

export type UserAcl = {
  __typename?: 'UserAcl';
  role?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  units?: Maybe<OrgUnit>;
  rolePermissions?: Maybe<Scalars['JSON']>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export type UserAclInput = {
  role?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  expiresAt?: Maybe<Scalars['Date']>;
};

export type UserClaims = {
  __typename?: 'UserClaims';
  userTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  role?: Maybe<Scalars['String']>;
};

export type UserCreateInput = {
  email: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  acl: Array<Maybe<UserAclInput>>;
  role: Scalars['String'];
  uiNotifications?: Maybe<Scalars['Boolean']>;
  twoFactorAuth?: Maybe<UserTwoFactorAuthInput>;
};

export type UserFilter = {
  tagExact?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  fullText?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  algoliaFilters?: Maybe<Scalars['String']>;
  tagWithParents?: Maybe<Scalars['String']>;
  uiNotifications?: Maybe<Scalars['Boolean']>;
};

export type UserList = {
  __typename?: 'UserList';
  edges?: Maybe<Array<Maybe<User>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type UserTwoFactorAuth = {
  __typename?: 'UserTwoFactorAuth';
  setupRequired?: Maybe<Scalars['Boolean']>;
  isEnforced?: Maybe<Scalars['Boolean']>;
  options?: Maybe<TwoFactorAuthOptions>;
};

export type UserTwoFactorAuthInput = {
  options?: Maybe<TwoFactorAuthOptionsInput>;
};

export type UserUpdateInput = {
  displayName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  acl?: Maybe<Array<Maybe<UserAclInput>>>;
  role?: Maybe<Scalars['String']>;
  uiNotifications?: Maybe<Scalars['Boolean']>;
  twoFactorAuth?: Maybe<UserTwoFactorAuthInput>;
};

export type UserVerifyInput = {
  displayName: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export enum Values {
  Value = 'value',
  MeterValue = 'meterValue',
  StateValue = 'stateValue'
}

export enum VoimassaOloEnum {
  PaattynytKohde = 'PAATTYNYT_KOHDE',
  VoimassaolevaKohde = 'VOIMASSAOLEVA_KOHDE',
  VoimassaolevaTulevaisuudenKohde = 'VOIMASSAOLEVA_TULEVAISUUDEN_KOHDE'
}

export type VpnConfig = {
  __typename?: 'VpnConfig';
  id?: Maybe<Scalars['ID']>;
  enable?: Maybe<Scalars['Boolean']>;
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  server?: Maybe<Scalars['String']>;
};

export type VpnConfigInput = {
  country?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['String']>;
  server?: Maybe<Scalars['String']>;
};

export type AddAttachmentInput = {
  bucketName?: Maybe<Scalars['String']>;
  savePath?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  deviceId?: Maybe<Scalars['String']>;
  lockerIndex?: Maybe<Scalars['String']>;
};

export type DeleteAttachmentInput = {
  id: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
};

export type IloqCredentialPermissionsResponse = {
  __typename?: 'iloqCredentialPermissionsResponse';
  livionKeyAccessForIloqS50?: Maybe<Scalars['Boolean']>;
  emEdition?: Maybe<Scalars['Boolean']>;
};

export type SyncIloqKeysToBuilding = {
  buildingId: Scalars['String'];
  iloqKeyIds: Array<Maybe<Scalars['String']>>;
};

export type SyncSntOrganizationUnit = {
  tag: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  syncedData?: Maybe<Scalars['JSON']>;
};

export type WhiteListInput = {
  path?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type WhiteListType = {
  __typename?: 'whiteListType';
  path?: Maybe<Scalars['String']>;
  read?: Maybe<Scalars['Boolean']>;
  write?: Maybe<Scalars['Boolean']>;
};

export type VerifyLoginQueryVariables = Exact<{ [key: string]: never; }>;


export type VerifyLoginQuery = (
  { __typename?: 'Query' }
  & { verifyLogin: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName' | 'phoneNumber' | 'email'>
    & { acl?: Maybe<Array<Maybe<(
      { __typename?: 'UserAcl' }
      & Pick<UserAcl, 'path' | 'role' | 'name' | 'rolePermissions'>
      & { units?: Maybe<(
        { __typename?: 'OrgUnit' }
        & { organization?: Maybe<(
          { __typename?: 'OrgUnitData' }
          & Pick<OrgUnitData, 'name' | 'id'>
        )>, country?: Maybe<(
          { __typename?: 'OrgUnitData' }
          & Pick<OrgUnitData, 'name' | 'id'>
        )>, area?: Maybe<(
          { __typename?: 'OrgUnitData' }
          & Pick<OrgUnitData, 'name' | 'id'>
        )> }
      )> }
    )>>> }
  ) }
);

export type RolePermissionsQueryVariables = Exact<{
  role: Scalars['String'];
}>;


export type RolePermissionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'rolePermissions'>
);

export type LoginWithEmailAndPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginWithEmailAndPasswordMutation = (
  { __typename?: 'Mutation' }
  & { loginWithEmailAndPassword: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'displayName' | 'phoneNumber' | 'idToken' | 'refreshToken'>
  ) }
);

export type RefreshIdTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshIdTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'refreshIdToken'>
);

export type VerifyIdTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type VerifyIdTokenQuery = (
  { __typename?: 'Query' }
  & { verifyIdToken: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'displayName'>
  ) }
);

export type LoginWithEmailLinkMutationVariables = Exact<{
  email: Scalars['String'];
  signInToken: Scalars['String'];
}>;


export type LoginWithEmailLinkMutation = (
  { __typename?: 'Mutation' }
  & { loginWithEmailLink: (
    { __typename?: 'User' }
    & Pick<User, 'idToken' | 'refreshToken'>
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  id: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { changePassword?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'language' | 'displayName' | 'phoneNumber' | 'role' | 'uiNotifications'>
    & { acl?: Maybe<Array<Maybe<(
      { __typename?: 'UserAcl' }
      & Pick<UserAcl, 'role' | 'path'>
    )>>> }
  )> }
);


export const VerifyLoginDocument = gql`
    query verifyLogin {
  verifyLogin {
    id
    displayName
    phoneNumber
    email
    acl {
      path
      role
      name
      rolePermissions
      units {
        organization {
          name
          id
        }
        country {
          name
          id
        }
        area {
          name
          id
        }
      }
    }
  }
}
    `;

/**
 * __useVerifyLoginQuery__
 *
 * To run a query within a React component, call `useVerifyLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifyLoginQuery(baseOptions?: Apollo.QueryHookOptions<VerifyLoginQuery, VerifyLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyLoginQuery, VerifyLoginQueryVariables>(VerifyLoginDocument, options);
      }
export function useVerifyLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyLoginQuery, VerifyLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyLoginQuery, VerifyLoginQueryVariables>(VerifyLoginDocument, options);
        }
export type VerifyLoginQueryHookResult = ReturnType<typeof useVerifyLoginQuery>;
export type VerifyLoginLazyQueryHookResult = ReturnType<typeof useVerifyLoginLazyQuery>;
export type VerifyLoginQueryResult = Apollo.QueryResult<VerifyLoginQuery, VerifyLoginQueryVariables>;
export const RolePermissionsDocument = gql`
    query rolePermissions($role: String!) {
  rolePermissions(role: $role)
}
    `;

/**
 * __useRolePermissionsQuery__
 *
 * To run a query within a React component, call `useRolePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolePermissionsQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useRolePermissionsQuery(baseOptions: Apollo.QueryHookOptions<RolePermissionsQuery, RolePermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolePermissionsQuery, RolePermissionsQueryVariables>(RolePermissionsDocument, options);
      }
export function useRolePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolePermissionsQuery, RolePermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolePermissionsQuery, RolePermissionsQueryVariables>(RolePermissionsDocument, options);
        }
export type RolePermissionsQueryHookResult = ReturnType<typeof useRolePermissionsQuery>;
export type RolePermissionsLazyQueryHookResult = ReturnType<typeof useRolePermissionsLazyQuery>;
export type RolePermissionsQueryResult = Apollo.QueryResult<RolePermissionsQuery, RolePermissionsQueryVariables>;
export const LoginWithEmailAndPasswordDocument = gql`
    mutation loginWithEmailAndPassword($email: String!, $password: String!) {
  loginWithEmailAndPassword(email: $email, password: $password) {
    id
    email
    displayName
    phoneNumber
    idToken
    refreshToken
  }
}
    `;
export type LoginWithEmailAndPasswordMutationFn = Apollo.MutationFunction<LoginWithEmailAndPasswordMutation, LoginWithEmailAndPasswordMutationVariables>;

/**
 * __useLoginWithEmailAndPasswordMutation__
 *
 * To run a mutation, you first call `useLoginWithEmailAndPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithEmailAndPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithEmailAndPasswordMutation, { data, loading, error }] = useLoginWithEmailAndPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginWithEmailAndPasswordMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithEmailAndPasswordMutation, LoginWithEmailAndPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithEmailAndPasswordMutation, LoginWithEmailAndPasswordMutationVariables>(LoginWithEmailAndPasswordDocument, options);
      }
export type LoginWithEmailAndPasswordMutationHookResult = ReturnType<typeof useLoginWithEmailAndPasswordMutation>;
export type LoginWithEmailAndPasswordMutationResult = Apollo.MutationResult<LoginWithEmailAndPasswordMutation>;
export type LoginWithEmailAndPasswordMutationOptions = Apollo.BaseMutationOptions<LoginWithEmailAndPasswordMutation, LoginWithEmailAndPasswordMutationVariables>;
export const RefreshIdTokenDocument = gql`
    mutation refreshIdToken($refreshToken: String!) {
  refreshIdToken(refreshToken: $refreshToken)
}
    `;
export type RefreshIdTokenMutationFn = Apollo.MutationFunction<RefreshIdTokenMutation, RefreshIdTokenMutationVariables>;

/**
 * __useRefreshIdTokenMutation__
 *
 * To run a mutation, you first call `useRefreshIdTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshIdTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshIdTokenMutation, { data, loading, error }] = useRefreshIdTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshIdTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshIdTokenMutation, RefreshIdTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshIdTokenMutation, RefreshIdTokenMutationVariables>(RefreshIdTokenDocument, options);
      }
export type RefreshIdTokenMutationHookResult = ReturnType<typeof useRefreshIdTokenMutation>;
export type RefreshIdTokenMutationResult = Apollo.MutationResult<RefreshIdTokenMutation>;
export type RefreshIdTokenMutationOptions = Apollo.BaseMutationOptions<RefreshIdTokenMutation, RefreshIdTokenMutationVariables>;
export const VerifyIdTokenDocument = gql`
    query verifyIdToken {
  verifyIdToken {
    id
    displayName
  }
}
    `;

/**
 * __useVerifyIdTokenQuery__
 *
 * To run a query within a React component, call `useVerifyIdTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyIdTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyIdTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useVerifyIdTokenQuery(baseOptions?: Apollo.QueryHookOptions<VerifyIdTokenQuery, VerifyIdTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyIdTokenQuery, VerifyIdTokenQueryVariables>(VerifyIdTokenDocument, options);
      }
export function useVerifyIdTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyIdTokenQuery, VerifyIdTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyIdTokenQuery, VerifyIdTokenQueryVariables>(VerifyIdTokenDocument, options);
        }
export type VerifyIdTokenQueryHookResult = ReturnType<typeof useVerifyIdTokenQuery>;
export type VerifyIdTokenLazyQueryHookResult = ReturnType<typeof useVerifyIdTokenLazyQuery>;
export type VerifyIdTokenQueryResult = Apollo.QueryResult<VerifyIdTokenQuery, VerifyIdTokenQueryVariables>;
export const LoginWithEmailLinkDocument = gql`
    mutation loginWithEmailLink($email: String!, $signInToken: String!) {
  loginWithEmailLink(email: $email, signInToken: $signInToken) {
    idToken
    refreshToken
  }
}
    `;
export type LoginWithEmailLinkMutationFn = Apollo.MutationFunction<LoginWithEmailLinkMutation, LoginWithEmailLinkMutationVariables>;

/**
 * __useLoginWithEmailLinkMutation__
 *
 * To run a mutation, you first call `useLoginWithEmailLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithEmailLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithEmailLinkMutation, { data, loading, error }] = useLoginWithEmailLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *      signInToken: // value for 'signInToken'
 *   },
 * });
 */
export function useLoginWithEmailLinkMutation(baseOptions?: Apollo.MutationHookOptions<LoginWithEmailLinkMutation, LoginWithEmailLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginWithEmailLinkMutation, LoginWithEmailLinkMutationVariables>(LoginWithEmailLinkDocument, options);
      }
export type LoginWithEmailLinkMutationHookResult = ReturnType<typeof useLoginWithEmailLinkMutation>;
export type LoginWithEmailLinkMutationResult = Apollo.MutationResult<LoginWithEmailLinkMutation>;
export type LoginWithEmailLinkMutationOptions = Apollo.BaseMutationOptions<LoginWithEmailLinkMutation, LoginWithEmailLinkMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($id: String!, $password: String!) {
  changePassword(id: $id, password: $password) {
    email
    language
    displayName
    phoneNumber
    role
    uiNotifications
    acl {
      role
      path
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "SntBuildingsType": [
      "SntRakennelmaType",
      "SntRakennusType"
    ]
  }
};
      export default result;
    