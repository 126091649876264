import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const styles = css`
  margin: 1rem 0;
  padding-left: 1.6rem;
  line-height: 1.7rem;
`;

const Ol = styled.ol`
  ${styles};
`;
const Ul = styled.ul`
  ${styles};
`;

/**
 * @render react
 * @name List
 * @description List component.
 * @example
 *  <List>
      <li>Item 1</li>
      <li>Item 2</li>
      <li>Item 3</li>
    </List>
 */
const List = ({ ordered, children, ...props }) => {
  return React.createElement(ordered ? Ol : Ul, props, children);
};

List.propTypes = {
  /**
   * @property {propTypes.bool} ordered - ordered list.
   */
  ordered: PropTypes.bool,
  children: PropTypes.any
};

List.defaultProps = {
  palette: "grayscale"
};

export default List;
