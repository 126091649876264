const validElement = (element) => {
  const valid =
    ' 01234567890ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvxyz_-';
  const elementCharacters = element.split('');
  let validElement = '';
  for (let i = 0; i < elementCharacters.length; i++) {
    if (valid.includes(elementCharacters[i]))
      validElement += elementCharacters[i];
  }
  return validElement;
};
const hasNumberAt = (name) => {
  const numbers = '0123456789'.split('');
  const characters = name.split('');
  for (let i = 0; i < characters.length; i++) {
    for (let j = 0; j < numbers.length; j++) {
      if (characters[i] === numbers[j]) return i;
    }
  }
  return null;
};
const removeEmptyElements = (list) => {
  const newList = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i] !== '') newList.push(list[i]);
  }
  return newList;
};
const cleanAddress = (text) => {
  const characters = text.split('');
  let newText = '';
  for (let i = 0; i < characters.length; i++) {
    if (characters[i] === ',') newText += ' ';
    else if (characters[i] === '.') newText += ' ';
    else if (characters[i] === 'é') newText += 'e';
    else newText += characters[i];
  }
  return newText;
};
const ssidIdentifier = (streetAddress) => {
  const elements = removeEmptyElements(
    validElement(cleanAddress(streetAddress)).split(' ')
  );
  if (elements.length < 2) return '';
  let identifier = '';
  let numberFound = false;
  let numberIndex;
  for (let i = 0; i < elements.length; i++) {
    if (hasNumberAt(elements[i]) !== null) {
      numberIndex = i;
      numberFound = true;
    }
  }
  //    console.log(numberIndex,numberFound)
  if (numberFound) {
    if (numberIndex > 2) {
      identifier += elements[numberIndex - 1].substr(-5);
    }
    for (let i = numberIndex; i < numberIndex + 2 && i < elements.length; i++) {
      if (identifier.length < 12) {
        identifier += elements[i];
      }
    }
  } else {
    for (let i = 2; i < elements.length; i++) {
      if (identifier.length < 12) {
        identifier += elements[i];
      }
    }
  }
  return identifier;
};
export const createSsid = (apartment) => {
  let createdSsid = '';
  createdSsid += apartment && ssidIdentifier(apartment.address);
  return createdSsid;
};
/*
module.exports = {
  createSSID: function (apartment) { return ssidIdentifier(apartment.address) }
}
*/
