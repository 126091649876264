import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
2;
import Caption from '../../atoms/Caption';

const StyledSpeed = styled.div`
  font-size: 2em;
  font-weight: 800;
`;
const VeryBadSpeed = styled(StyledSpeed)`
  color: #f46060;
`;
const BadSpeed = styled(StyledSpeed)`
  color: #f7743c;
`;
const ModerateSpeed = styled(StyledSpeed)`
  color: #f0c534;
`;
const GoodSpeed = styled(StyledSpeed)`
  color: #9bde7e;
`;
const NoSpeed = styled(StyledSpeed)`
  color: #5a5a5a;
`;
const SpeedIndicator = ({ speed }) => {
  const isVeryBadSpeed = speed > 0 && speed < 5;
  const isBadSpeed = speed >= 5 && speed < 7.5;
  const isModerateSpeed = speed >= 7.5 && speed < 10;
  const isGoodSpeed = speed >= 10;

  return isVeryBadSpeed ? (
    <VeryBadSpeed>{speed} Mbps</VeryBadSpeed>
  ) : isBadSpeed ? (
    <BadSpeed>{speed} Mbps</BadSpeed>
  ) : isModerateSpeed ? (
    <ModerateSpeed>{speed} Mbps</ModerateSpeed>
  ) : isGoodSpeed ? (
    <GoodSpeed>{speed} Mbps</GoodSpeed>
  ) : (
    <NoSpeed>{speed} Mbps</NoSpeed>
  );
};

SpeedIndicator.propTypes = {
  speed: PropTypes.number.isRequired,
};

export default SpeedIndicator;
