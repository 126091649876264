import React, { Component } from 'react';
import styled from 'styled-components';
import Icon from '../../atoms/Icon';

const Container = styled.div`
  font-size: 1.2vw;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  @media screen and (min-width: 64em), (min-height: 850px) {
    font-size: 18px;
  }
  @media screen and (max-width: 40em), (max-height: 850px) {
    font-size: 14px;
  }
  @media screen and (max-width: 52em), (max-height: 700px) {
    font-size: 10px;
  }
  @media screen and (max-width: 40em), (max-height: 600px) {
    font-size: 6px;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  margin: 2%;
  width: 96%;
  height: 50%;
  min-height: 200px;
`;

const StyleBarsContainer = styled.div`
  width: 100%;
  height: 100%;
`;
const StyleBarTop = styled.div`
  background: ${props => props.theme.primaryThemeColor};
  height: 33.33%;
`;
const StyleBarBottom = styled.div`
  background: ${props => props.theme.panelBackground};
  height: 66.66%;
`;

const InnerWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const MessageHeader = styled.h1`
  color: #fff;
  font-size: 8em;
  margin: 0;
`;
const Message = styled.div`
  background: #fff;
  padding: 0.5em;
  border-radius: 0.5em;
  font-size: 2em;
`;

const GhostImg = styled(Icon)`
  z-index: 1;
  opacity: 0.5;
`;

const GhostShadow = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20em;
  height: 20em;
  border-radius: 10em;
  transform: translateY(47.5%) scale(0.7, 0.1);
  background: #000;
  opacity: 0.05;
`;

export default class ErrorPage extends Component {
  // static propTypes = {}
  // static defaultProps = {}
  // state = {}
  static isPrivate = false;

  render() {
    const { code, message, ...props } = this.props;
    return (
      <Container>
        <InnerContainer {...props}>
          <StyleBarsContainer>
            <StyleBarTop />
            <StyleBarBottom />
          </StyleBarsContainer>
          <InnerWrapper>
            <MessageWrapper>
              <MessageHeader>{code || '404'}</MessageHeader>
              <Message>{message || 'Not Found :('}</Message>
            </MessageWrapper>
            <GhostImg icon={'ghost'} fill={'#fff'} size={'20em'} />
            <GhostShadow />
          </InnerWrapper>
        </InnerContainer>
      </Container>
    );
  }
}
