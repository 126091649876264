import { useEffect } from 'react';
import Cookies from 'universal-cookie';
import Intercom from '@intercom/messenger-js-sdk';

import { withAuth } from '@components/contexts/FirebaseContext';
import { withApollo } from 'react-apollo';
const cookies = new Cookies();

const IntercomSupport = (props) => {
  const {
    client,
    auth: { user, getSelectedTag },
    extractTags,
  } = props;

  useEffect(() => {
    const intercomData = cookies.get('x-intercom');
    console.log('intercomData', intercomData);
    console.log('intercomData selectedTag', getSelectedTag());
    const selectedTag = getSelectedTag();
    const initIntercom = async () => {
      if (
        intercomData &&
        selectedTag &&
        user &&
        process.env.REACT_APP_INTERCOM_APP_ID
      ) {
        console.log(
          'setting intercom',
          user?.uid,
          process.env.REACT_APP_INTERCOM_APP_ID
        );
        const tags = await extractTags(client, selectedTag);
        const organization = tags?.organization;
        console.log('intercom organization', organization);
        Intercom({
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          region: 'eu',
          email: user?.email,
          user_id: user?.uid,
          user_hash: intercomData?.hash,
          name: user?.displayName || '',
          language_override: 'en',
          company: {
            id: organization?.id,
            name: organization?.name,
          },
          hide_default_launcher: false,
        });
      }
    };
    initIntercom();
  }, [user, extractTags, getSelectedTag, client]);
  console.log('IntercomSupport', props);
  return null;
};

export default withApollo(withAuth(IntercomSupport));
