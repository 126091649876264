import React, { useCallback, useContext, useState, useEffect } from 'react';
import _get from 'lodash/get';
import { withRouter } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { GET_USER } from '../api/queries/users';
import { UPDATE_USER, CREATE_USER } from '../api/mutations/users';
import { withLivionConnect } from '@components/contexts/LivionConnectContext';
import { withQS } from '@components/contexts/QSContext';
import ToasterContext from '@components/contexts/ToasterContext';

import DashboardFlexibleFormPage from '@components/pages/DashboardFlexibleFormPage';
import Block from '@components/atoms/Block';
import TextField from '@components/fields/TextField';
import SelectField from '@components/fields/SelectField';
import PasswordField from '@components/fields/PasswordField';
import TagSelectSearch from '@components/molecules/TagSelectSearch';

import Password from './Password';

import {
  mustBeValidPhoneNumber,
  mustBeEmail,
  required,
} from '@components/utils';

const minLength = (min) => (value) =>
  value && value.length >= min
    ? undefined
    : `The password must be a string with a least 6 characters.`;

const StyledBlock = styled(Block)`
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${({ theme }) =>
      _get(theme, 'breakpoints[0]', '40em')}) {
    padding: 0.4em;
  }
`;

const timeout = 24 * 60 * 60;

/* multiple apollo graphql components causes easily callback hell, adopt is like Promise.All for react components */

export const UserEdit = ({
  history,
  getQueryString,
  auth = {},
  match: {
    params,
    params: { id },
  },
}) => {
  const tag = getQueryString(history.location).tag;
  const { logout, user } = auth;
  const { addToast } = useContext(ToasterContext);
  const [isLoading, setIsLoading] = useState(false);

  const role = 'roomrobot-admin';
  const variables = id ? { id } : {};
  const { data, loading, error } = useQuery(GET_USER, { variables, skip: !id });
  const [updateUser] = useMutation(UPDATE_USER);
  const [createUser] = useMutation(CREATE_USER);

  if (error)
    addToast({
      type: 'error',
      title: 'Error',
      message: error.message,
    });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const onSubmit = useCallback(
    (input) => {
      setIsLoading(true);
      const query = id ? updateUser : createUser;
      const variables = id ? { id, input } : { input };
      const userAcl = _get(data, 'user.acl');
      if (input.path) {
        if (userAcl) {
          userAcl.forEach((acl) => {
            delete acl.__typename;
            delete acl.name;

            if (acl.role === role) acl.path = input.path;
          });
          input.acl = userAcl;
        } else {
          input.acl = [{ role, path: input.path }];
        }
        delete input.path;
      }
      input.role = role;
      query({
        variables,
      })
        .then(({ data }) => {
          setIsLoading(false);
          const email = _get(
            data,
            id ? 'updateUser.email' : 'createUser.email'
          );
          addToast({
            type: 'success',
            title: `Success`,
            message: `User ${email} ${id ? 'updated' : 'created'} successfully`,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          addToast({
            type: 'error',
            title: `Error`,
            message: error.message,
            timeout,
          });
        });
    },
    [addToast, createUser, data, id, updateUser]
  );

  const initValues = {
    displayName: _get(data, 'user.displayName'),
    phoneNumber: _get(data, 'user.phoneNumber'),
    language: _get(data, 'user.language'),
    path: !id ? tag : _get(data, 'user.acl[0].path'),
  };

  const minTextLength = minLength(6);

  return (
    <>
      <DashboardFlexibleFormPage
        initialValues={initValues}
        title={_get(data, 'user.email')}
        subtitle={_get(data, 'user.displayName')}
        onSubmit={onSubmit}
        onBack={history.goBack}
        loading={isLoading}
      >
        {() => (
          <StyledBlock>
            {!params.id && (
              <>
                <TextField name="email" validate={mustBeEmail} label="Email" />

                <PasswordField
                  name="password"
                  validate={minTextLength}
                  label="Password"
                  display="false"
                />
              </>
            )}

            <TextField name="displayName" label="Name" />

            <TextField
              name="phoneNumber"
              label="Phonenumber"
              placeholder="+358401234567"
              validate={mustBeValidPhoneNumber}
            />

            <SelectField
              name="language"
              label="Language"
              // wrapperStyles={{ paddingRight: '20px' }}
              options={[
                {
                  value: 'fi-fi',
                  label: 'Finnish',
                },
                {
                  value: 'en-gb',
                  label: 'English',
                },
                {
                  value: 'sv-se',
                  label: 'Swedish',
                },
              ]}
              valueKey="value"
              labelKey="label"
            />

            <TagSelectSearch
              key="path"
              validate={required}
              labelKey={'name'}
              initValue={initValues.path}
              name={'path'}
              label="Permissions"
            />
          </StyledBlock>
        )}
      </DashboardFlexibleFormPage>

      {id && user.uid === id && <Password id={id} logout={logout} />}
    </>
  );
};

export default withRouter(withLivionConnect(withQS(UserEdit)));
