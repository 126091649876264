import React, { Component } from 'react';
import Toaster from '../../organisms/Toaster';

const SharedToasterContext = React.createContext();

export default SharedToasterContext;
export class SharedToasterProvider extends Component {
  addToast = data => {
    const toaster = this.refs.toasterRef;
    setTimeout(() => toaster.addToast(data));
  };

  render() {
    const { children } = this.props;

    return (
      <SharedToasterContext.Provider
        value={{
          addToast: this.addToast,
        }}
      >
        <Toaster ref={'toasterRef'} />

        {children}
      </SharedToasterContext.Provider>
    );
  }
}

export const SharedToasterConsumer = SharedToasterContext.Consumer;
