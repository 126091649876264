import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Form as FForm, Field as FField } from 'react-final-form';

import { Auth } from '../../contexts/FirebaseContext';

import LoginTemplate from '../../templates/LoginTemplate';

import Button from '../../atoms/Button';
import Block from '../../atoms/Block';
import LogoImage from '../../atoms/LogoImage';
import Link from '../../atoms/Link';
import LoaderCube from '../../atoms/LoaderCube';

import Field from '../../molecules/Field';

export const Error = styled(Block)`
  margin: 0.5em 0.4em 0;
  text-align: left;
  color: ${props => props.theme.fieldError};
`;

const StyledImage = styled(LogoImage)`
  width: 11em;
  height: auto;
`;

const StyledButton = styled(Button)`
  width: 100%;
  text-align: center;
`;

const Center = styled.div`
  text-align: center;
`;

const StyledLink = styled(Link)`
  text-transform: inherit;
  text-align: left;
  cursor: pointer;
`;

const StyledCube = styled(LoaderCube)`
  margin: 0 auto;
`;

const FormFlex = styled(Flex)`
  min-height: 16em;
  padding: 1em 0;
`;

const LoginForm = ({ onSubmit, validate }) => (
  <FForm
    onSubmit={onSubmit}
    render={({ handleSubmit, pristine, invalid }) => (
      <form onSubmit={handleSubmit}>
        <FField
          name="email"
          render={({ input, meta }) => (
            <Field
              {...input}
              label="Email"
              type="email"
              invalid={meta.error && meta.touched}
              error={meta.error && meta.touched ? meta.error : ''}
            />
          )}
        />

        <FField
          name="password"
          render={({ input, meta }) => (
            <Field
              {...input}
              label="Password"
              type="password"
              invalid={meta.error && meta.touched}
              error={meta.error && meta.touched ? meta.error : ''}
            />
          )}
        />
        <Center>
          <StyledButton type="submit" block disabled={pristine || invalid}>
            Login
          </StyledButton>
        </Center>
      </form>
    )}
  />
);

class LoginPage extends Component {
  render() {
    const { history, toRoute } = this.props;

    return (
      <Auth>
        {({ loginWithGoogle, login, logout, loading, error, ready }) => {
          return (
            <LoginTemplate>
              <div style={{ width: '100%' }}>
                <Flex flexWrap="wrap" justifyContent="space-around">
                  <Box width={[1, 1 / 2, 1 / 3]}>
                    <div>
                      <StyledImage version={process.env.REACT_APP_VERSION} />
                    </div>
                    <FormFlex alignItems="center">
                      <Box width={1}>
                        {loading && <StyledCube />}

                        {!loading && (
                          <React.Fragment>
                            <div>
                              <LoginForm
                                onSubmit={async v => {
                                  console.log('Signing in with email');
                                  await login(v);
                                  history.replace(toRoute || '/');
                                }}
                              />
                              <Center>
                                {error && <Error>{error.message}</Error>}
                                <StyledButton
                                  onClick={async v => {
                                    console.log('Signing in with google');
                                    await loginWithGoogle();
                                    history.replace(toRoute || '/');
                                  }}
                                >
                                  Sign in with Google
                                </StyledButton>
                              </Center>
                            </div>
                            <Flex justifyContent="space-between">
                              <Box mt={'1em'}>
                                <StyledLink to="/forgotpassword">
                                  Forgot your password?
                                </StyledLink>
                              </Box>
                              <Box mt={'1em'}>
                                <StyledLink
                                  onClick={() => history.push('/register')}
                                >
                                  Don't have account? Get Started
                                </StyledLink>
                              </Box>
                            </Flex>
                          </React.Fragment>
                        )}
                      </Box>
                    </FormFlex>
                  </Box>
                </Flex>
              </div>
            </LoginTemplate>
          );
        }}
      </Auth>
    );
  }
}

export default withRouter(LoginPage);
