import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

/**
 * @render react
 * @description Skeleton component
 *
 * @example
 * <Skeleton />
 */

const progress = keyframes`
  0% {
      background-position: -200px 0;
  }
  100% {
      background-position: calc(200px + 100%) 0;
  }
}`;

const Span = styled.span`
  /*
  font-family: ${props => props.theme.primaryFont};
  background-color: ${props => props.theme.blockBackground};
  color: ${props => props.theme.blockColor};
  padding: ${props => props.theme.blockPadding};
  */
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  margin: 2px;
  display: ${props => (props.block ? 'block' : 'inline-block')};
  line-height: 1;
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || '100%'};
  animation: ${progress} ${props =>
  props.duration || '1.2s'} infinite ease-in-out;
`;

const Skeleton = props => {
  const elements = [];
  for (let i = 0; i < props.count; i++) {
    const width = props.width
      ? Array.isArray(props.width)
        ? props.width[i]
        : props.width
      : null;
    const height = props.height
      ? Array.isArray(props.height)
        ? props.height[i]
        : props.height
      : null;
    elements.push(
      <Span
        block={props.block}
        width={width}
        height={height}
        {...props.duration}
        key={i}
      >
        &zwnj;
      </Span>
    );
  }

  const Wrapper = props.wrapper;
  return (
    <span>
      {Wrapper
        ? elements.map((element, i) => (
            <Wrapper key={i}>
              {element}
              &zwnj;
            </Wrapper>
          ))
        : elements}
    </span>
  );
};

Skeleton.propTypes = {
  count: PropTypes.number,
  duration: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  wrapper: PropTypes.node,
};

Skeleton.defaultProps = {
  count: 1,
  duration: 1.2,
  width: null,
  height: null,
  wrapper: null,
  theme: {
    blockBackground: 'none',
    blockColor: '#000000',
    blockPadding: '0 0 0 0',
  },
};

export default Skeleton;
