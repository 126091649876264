import React from 'react';

import PageTemplate from '../../templates/PageTemplate';

import ItemPane from '../../organisms/ItemPane';

/**
 * @render react
 * @name DashboardDefaultPage
 * @description DashboardDefaultPage component
 * @example
 * <DashboardDefaultPage />
 */
const DashboardDefaultPage = ({
  title,
  subtitle,
  onBack,
  loading,
  saving,
  children,
  onEditClick,
  onAddClick,
  addTitle,
  onRemoveClick,
  onSaveClick,
  customActions,
  status,
  synced,
  hideBanner,
}) => {
  return (
    <PageTemplate
      actionsPane={
        <ItemPane
          title={title}
          subtitle={subtitle}
          onEditClick={onEditClick}
          onBackClick={onBack}
          onAddClick={onAddClick}
          onSaveClick={onSaveClick}
          addTitle={addTitle}
          onRemoveClick={onRemoveClick}
          loading={saving || loading}
          status={status}
          synced={synced}
        >
          {customActions}
        </ItemPane>
      }
      hideBanner={hideBanner}
    >
      {children}
    </PageTemplate>
  );
};

export default DashboardDefaultPage;
