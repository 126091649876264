import _get from 'lodash/get';
import _filter from 'lodash/filter';

export const convertDatausageValue = (
  sensor,
  value,
  datapointName = 'datausage'
) => {
  const units = {
    // eslint-disable-next-line prettier/prettier
    TB: (v) => v * 1e6,
    GB: (v) => v * 1000,
    MB: (v) => v,
    KB: (v) => v / 1000,
    // eslint-disable-next-line prettier/prettier
    B: (v) => v / 1e6,
  };

  const datapoint = _filter(_get(sensor, 'datapoints', []), {
    name: datapointName,
  })[0];

  if (datapoint && datapoint.unit) {
    const unit = datapoint.unit;
    return Number(units[unit](value));
  } else return value;
};
