import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import Label from '../../atoms/Label';
import Block from '../../atoms/Block';

const toastIn = keyframes`
from {
  transform: translatex(100%);

}
to {
  transform: translatex(0);
}
`;

const toastOut = keyframes`
from {
  transform: translatex(0);

}
to {
  transform: translatex(100%);
  opacity: 0;
}
`;

const StyledBlock = styled(props => <Block {...props} />)`
  font-family: ${props => props.theme.primaryFont};
  background: ${props => props.theme.toastBackground};
  color: '#000000';
  min-height: 80px;
  border: ${props => props.theme.toastBorder};
  display: flex;
  align-items: center;
  box-shadow: ${props => props.theme.toastShadow};

  padding: ${props => props.theme.toastPadding};

  transition: transform 0.6s ease-in-out;

  &.toast-in {
    animation: ${toastIn} 0.4s;
  }

  user-select: none;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &.toast-out {
    animation: ${toastOut} 0.6s forwards;
  }

  &.success {
    border-left: solid 7px;
    border-color: ${props => props.theme.toastSuccessColor};
  }

  &.error {
    border-left: solid 7px;
    border-color: ${props => props.theme.toastErrorColor};
  }

  &.warning {
    border-left: solid 7px;
    border-color: ${props => props.theme.toastWarningColor};
  }
`;

const ToastContent = styled(props => <Block {...props} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(props => <Label {...props} />)`
  font-weight: 700;
  font-size: 1.2em;
  color: ${props => props.theme.toastTitleColor};
`;

const Message = styled(props => <Label {...props} />)`
  font-weight: 400;
  font-size: 1em;
  color: ${props => props.theme.toastMessageColor};
`;

/**
 * @render react
 * @name Toast
 * @description Toast component.
 * @example
 * <Toast title="Title" message="Message" />
 */
const Toast = ({
  index,
  type,
  toastEnter,
  isDeleted,
  title,
  message,
  onClick,
}) => {
  return (
    <StyledBlock
      className={`${toastEnter && 'toast-in'} ${isDeleted &&
        'toast-out'} ${type}`}
      onClick={() => onClick(index)}
    >
      <ToastContent>
        <Title>{title}</Title>
        <Message>{message}</Message>
      </ToastContent>
    </StyledBlock>
  );
};

StyledBlock.defaultProps = {
  theme: {
    toastBackground: '#ffffff',
    toastBorder: '1px solid #595959',
    toastPadding: '0.5em 0.5em 1em 1em',
    toastShadow: '0px 14px 37px -16px rgba(0,0,0,0.75);',
    toastSuccessColor: 'green',
    toastErrorColor: 'red',
    toastWarningColor: 'yellow',
  },
};

Title.defaultProps = {
  theme: {
    toastTitleColor: '#000000',
  },
};

Message.defaultProps = {
  theme: {
    toastMessageColor: '#000000',
  },
};

Toast.propTypes = {
  /**
   * @property {propTypes.string} title - Toast title.
   */
  title: PropTypes.string,
  /**
   * @property {propTypes.string} message - Toast message.
   */
  message: PropTypes.string,
  /**
   * @property {propTypes.bool} toastEnter - Toast enters.
   */
  toastEnter: PropTypes.bool,
  /**
   * @property {propTypes.bool} isDeleted - Marks toasts to deleted.
   */
  isDeleted: PropTypes.bool,
  /**
   * @property {propTypes.func} onClick - Click function.
   */
  onClick: PropTypes.func,
};

Toast.defaultProps = {};

export default Toast;
