import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { hidden } from '../Hide';

const styles = css`
  font-family: ${props => props.theme.primaryFont};
  color: ${props => props.theme.tableCellColor};
  font-weight: ${props => props.theme.tableCellFontWeight};
  line-height: ${props => props.theme.tableCellLineHeight};
  font-size: ${props => props.theme.tableCellFontSize};
  text-transform: ${props => props.theme.tableCellTextTransform};
  text-align: ${props => props.theme.tableCellTextAlign};
  padding: ${props => props.theme.tableCellPadding};
  margin: ${props => props.theme.tableCellMargin};
  border-top: ${props => props.theme.tableCellTopBorder};
`;

const xs = hidden('xs');
const sm = hidden('sm');
const md = hidden('md');
const lg = hidden('lg');

/**
 * @render react
 * @name TableCell
 * @description TableCell component.
 * @example
 *   <table>
    <thead>
      <tr>
        <th heading />
        <th heading />
        <th heading />
      </tr>
    </thead>
    <tbody>
      <tr>
        <TableCell>Cell 1</TableCell>
        <TableCell>Cell 2</TableCell>
        <TableCell>Cell 3</TableCell>
      </tr>
    </tbody>
  </table>
 */
const TableCell = styled.td([], styles, xs, sm, md, lg);

TableCell.defaultProps = {
  theme: {
    //breakpoints: ["40em", "52em", "64em"],
    tableCellColor: '#565656',
    tableCellFontWeight: '400',
    tableCellLineHeight: 'normal',
    tableCellFontSize: '0.95em',
    tableCellTextTransform: 'none',
    tableCellTextAlign: 'left',
    tableCellPadding: '1.2em 1.8em 1.2em 1.8em',
    tableCellMargin: '0',
    tableCellTopBorder: 'solid 2px #E0E0E0',
  },
};

TableCell.propTypes = {
  children: PropTypes.any,
  /**
   * @property {propTypes.bool} xs - Hide in xtra small screens.
   */
  xs: PropTypes.bool,
  /**
   * @property {propTypes.bool} sm - Hide in small screens.
   */
  sm: PropTypes.bool,
  /**
   * @property {propTypes.bool} md - Hide in medium screens.
   */
  md: PropTypes.bool,
  /**
   * @property {propTypes.bool} lg - Hide in large screen.
   */
  lg: PropTypes.bool,
};

export default TableCell;
