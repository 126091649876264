import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * @render react
 * @name ToggleButtonGroup
 * @description ToggleButtonGroup component.
 * @example
 *  <ToggleButtonGroup onChange={(v) => {}}>
      <ToggleButton value={1}>Toggle1</ToggleButton>
      <ToggleButton value={2}>Toggle1</ToggleButton>
      <ToggleButton value={3}>Toggle1</ToggleButton>
    </ToggleButtonGroup>
 */

const ButtonGroup = styled.div``;

class ToggleButtonGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected)
      this.setState({ selected: this.props.selected });
  }

  handleItemClick = value => {
    this.setState({
      selected: value,
    });
    this.props.onChange && this.props.onChange(value);
  };

  render() {
    const { className } = this.props;
    const { selected } = this.state;

    const children = React.Children.map(this.props.children, c =>
      React.cloneElement(c, {
        ...c.props,
        selected: c.props.value === selected,
        onClick: () => this.handleItemClick(c.props.value),
      })
    );

    return <ButtonGroup className={className}>{children}</ButtonGroup>;
  }
}

ToggleButtonGroup.propTypes = {
  /**
   * @property {propTypes.string or propTypes.number} selected - Selected item
   */
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ToggleButtonGroup;
