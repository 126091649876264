import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import Button from '../../atoms/Button';

const PullRight = styled.div`
  text-align: right;
`;

const FlexibleForm = ({
  onSubmit,
  children,
  submitButton,
  submitText,
  initialValues,
  mutators,
  decorators,
  validate,
}) => (
  <Form
    subscription={{ submitting: true, values: true }}
    initialValues={initialValues}
    onSubmit={onSubmit}
    mutators={{
      ...arrayMutators,
      ...mutators,
    }}
    decorators={decorators}
    validate={validate}
  >
    {form => (
      <form onSubmit={form.handleSubmit}>
        {children(form)}
        {submitButton && (
          <PullRight>
            <Button disabled={form.submitting || form.pristine} type="submit">
              {submitText}
            </Button>
          </PullRight>
        )}
      </form>
    )}
  </Form>
);

FlexibleForm.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitButton: PropTypes.bool,
  submitText: PropTypes.string,
  initialValues: PropTypes.object,
  mutators: PropTypes.object,
};

FlexibleForm.defaultProps = {
  submitButton: false,
  submitText: 'Submit',
  initialValues: null,
};

export default FlexibleForm;
