import gql from 'graphql-tag';

import { accessoryInfoFragment } from '../fragments/accessories';
import { pageInfoFragment } from '../fragments/pageInfo';

export const GET_ACCESSORIES = gql`
  query accessories($after: String, $limit: Int) {
    accessories(after: $after, limit: $limit) {
      edges {
        ...accessoryInfo
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${accessoryInfoFragment}
  ${pageInfoFragment}
`;

export const GET_ACCESSORY = gql`
  query accessory($id: ID!) {
    accessory(id: $id) {
      ...accessoryInfo
    }
  }
  ${accessoryInfoFragment}
`;
