import React, { useState, useEffect, useMemo } from 'react';
import SelectField from '../../fields/SelectField';
import { useApolloClient } from '@apollo/react-hooks';
import { GET_TAGSTREE, GET_TAG } from '../../api/queries/tags';
import { useDebounce } from 'use-debounce';
import { components } from 'react-select';
import TagSelectChildren from '../TagSelectChildren';
import PropTypes from 'prop-types';
import get from 'lodash/get';
const TagSelectSearch = ({
  flexibleSearch = true,
  name,
  validate,
  onChange,
  label,
  valueKey = 'id',
  labelKey = 'id',
  placeholder,
  isMulti,
  organizationsOnly,
  wrapperStyles,
  validateOnInit,
  disabled,
  initValue,
  labelInputKey = labelKey,
  closeMenuOnSelect = false,
}) => {
  const client = useApolloClient();
  let [values, setValues] = useState([]);
  let [inputValue, setInputValue] = useState();
  let [searchValue, setSearchValue] = useState();
  let [value, setValue] = useState();
  let [id, setId] = useState();
  let [isLoading, setIsLoading] = useState(false);
  const [debouncedText] = useDebounce(searchValue, 600);

  useEffect(() => {
    if (initValue) {
      setValue('Select...');
      client
        .query({ query: GET_TAG, variables: { id: initValue } })
        .then(({ data }) => {
          const { tag } = data || {};
          if (tag) setValue(get(tag, labelInputKey));
        });
    }
  }, [initValue]);

  const Option = useMemo(
    () => props => {
      const { children, data } = props;

      const nestedOptions = (data.children && data.children.edges) || [];
      if (nestedOptions || data.hasChildren) {
        const nestedProps = {
          props,
          option: data,
          nestedOptions,
          labelKey,
          valueKey,
        };
        return <TagSelectChildren {...nestedProps} />;
      }

      return <components.Option {...props}>{children}</components.Option>;
    },
    []
  );

  const handleInputChange = (val, { action }) => {
    if (action !== 'menu-close') {
      !organizationsOnly && setSearchValue(val);
      setInputValue(val);
      setId(null);
    }
  };

  const onChangeInternal = (value, option) => {
    if (!Array.isArray(value)) {
      const label = get(option, labelInputKey);
      setInputValue(label);
      setValue(label);
      labelKey === labelInputKey && setId(value);
    }
    setSearchValue(null);
    onChange && onChange(value);
  };

  const generateTree = data => {
    const tags = data.edges;
    var idToNodeMap = {};
    var root = [];
    const getParent = parentId => tags.find(({ id }) => id === parentId);
    tags.forEach(tag => {
      const parentId = tag.id.substring(0, tag.id.lastIndexOf('/'));
      tag.children = { edges: [] };
      const parent = getParent(parentId);

      if (parent && !idToNodeMap[parentId])
        idToNodeMap[parentId] = { ...parent, children: { edges: [] } };
      if (!parent) tag.pageInfo = data.pageInfo;
      idToNodeMap[tag.id] = tag;
      !parentId || !parent
        ? root.push(tag)
        : idToNodeMap[parentId].children &&
          idToNodeMap[parentId].children.edges.push(tag);
    });
    return root;
  };

  const loadTags = () => {
    const type = organizationsOnly ? 'organization' : undefined;
    const searchFilter = flexibleSearch
      ? { search: searchValue }
      : { fullText: searchValue };
    const tagFilter = id && !isMulti ? { id, type } : { type };
    const filter = !!searchValue
      ? { ...searchFilter, ...tagFilter }
      : tagFilter;
    const limit = !!searchValue || !!organizationsOnly ? 0 : 10;

    setIsLoading(true);
    client
      .query({
        query: GET_TAGSTREE,
        variables: { rootLimit: limit, limit, filter, type },
      })
      .then(({ data }) => {
        const { tagsTree } = data || {};
        if (tagsTree && tagsTree.children)
          setValues(generateTree(tagsTree.children));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return loadTags();
  }, [debouncedText, organizationsOnly]);

  const properties = {
    name,
    validate,
    label,
    options: values,
    components: { Option },
    valueKey,
    labelKey,
    onChange: onChangeInternal,
    validateOnInit,
    onInputChange: handleInputChange,
    placeholder,
    inputValue,
    wrapperStyles,
    isMulti,
    isLoading,
    value,
    disabled,
    closeMenuOnSelect,
    styles: {
      input: provided => ({
        ...provided,
        opacity: '1 !important',
      }),
    },
  };

  return <SelectField {...properties} />;
};

TagSelectSearch.propTypes = {
  flexibleSearch: PropTypes.bool,
  onChange: PropTypes.func,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  validate: PropTypes.func,
  validateOnInit: PropTypes.bool,
  wrapperStyles: PropTypes.object,
};

export default TagSelectSearch;
