import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.svg`
  cursor: 'pointer';
  fill: ${props => props.theme.logoFill};
`;
/**
 * @render react
 * @name Logo
 * @description Logo component. Dashboard Logo with version
 * @example
 * <Logo />
 */
const Logo = ({ version, ...props }) => {
  return (
    <StyledLogo {...props} width="200px" height="100%" viewBox="0 0 443 91">
      <g>
        <g>
          <path
            d="M24.957,65.969l-5.668,0l0,-41.84l19.368,0c3.19,0 5.819,0.466 7.889,1.396c3.93,1.785 5.896,5.079 5.896,9.883c0,2.506 -0.518,4.557 -1.553,6.152c-1.034,1.595 -2.482,2.877 -4.343,3.845c1.633,0.665 2.862,1.538 3.688,2.62c0.826,1.083 1.287,2.839 1.382,5.27l0.199,5.61c0.057,1.595 0.19,8.085 0.399,8.863c0.342,1.329 -5.791,-4.866 -5.867,-6.499l-0.342,-6.978c-0.133,-2.734 -1.149,-4.566 -3.048,-5.497c-1.082,-0.512 -2.781,-0.769 -5.098,-0.769l-4.902,0l0,-4.728l5.101,0c2.659,0 4.762,-0.531 6.309,-1.594c1.548,-1.064 2.321,-2.982 2.321,-5.754c0,-2.981 -1.082,-5.013 -3.246,-6.095c-1.159,-0.569 -2.706,-0.854 -4.643,-0.854l-13.842,0l0,36.969Z
                     M75.535,24.493c2.44,-1.002 5.216,-1.503 8.327,-1.503c7.386,0 12.855,2.374 16.405,7.121c2.772,3.702 4.159,8.44 4.159,14.212c0,6.247 -1.586,11.44 -4.757,15.579c-3.721,4.861 -9.029,7.292 -15.921,7.292c-2.994,0 -5.689,-0.46 -8.087,-1.38l3.086,-4.476c1.655,0.524 3.55,0.786 5.685,0.786c4.993,0 8.606,-1.79 10.837,-5.369c2.231,-3.579 3.346,-7.695 3.346,-12.347c0,-4.918 -1.286,-8.876 -3.859,-11.877c-2.573,-3 -6.09,-4.5 -10.552,-4.5c-1.921,0 -3.685,0.293 -5.292,0.878l-3.377,-4.416Zm-8.099,6.868l3.745,4.898c-1.116,2.549 -1.674,5.674 -1.674,9.374c0,3.052 0.509,5.788 1.526,8.21l-3.665,5.314c-2.485,-3.812 -3.728,-8.444 -3.728,-13.894c0,-5.369 1.265,-10.003 3.796,-13.902Z
                     M134.308,22.99c7.386,0 12.854,2.374 16.405,7.121c2.772,3.702 4.158,8.44 4.158,14.212c0,6.247 -1.585,11.44 -4.756,15.579c-3.722,4.861 -9.029,7.292 -15.921,7.292c-6.437,0 -11.497,-2.127 -15.181,-6.38c-3.285,-4.102 -4.927,-9.285 -4.927,-15.551c0,-5.659 1.405,-10.5 4.215,-14.526c3.608,-5.165 8.943,-7.747 16.007,-7.747Zm0.569,39.134c4.994,0 8.607,-1.79 10.838,-5.369c2.231,-3.579 3.346,-7.695 3.346,-12.347c0,-4.918 -1.286,-8.876 -3.859,-11.877c-2.573,-3 -6.09,-4.5 -10.552,-4.5c-4.33,0 -7.861,1.486 -10.596,4.458c-2.734,2.971 -4.101,7.353 -4.101,13.144c0,4.633 1.173,8.54 3.518,11.72c2.345,3.181 6.147,4.771 11.406,4.771Z
                     M166.554,24.129l8.117,0l12.019,22.118l11.934,-22.118l8.032,0l0,41.84l-5.383,0l0,-24.694c0,-0.854 0.019,-2.269 0.057,-4.243c0.038,-1.975 0.057,-4.092 0.057,-6.352l-11.934,22.032l-5.611,0l-12.019,-22.032l0,1.282c0,1.025 0.024,2.587 0.071,4.685c0.048,2.098 0.071,3.641 0.071,4.628l0,24.694l-5.411,0l0,-41.84Z
                     M226.712,65.969l-5.668,0l0,-41.84l19.368,0c3.19,0 5.819,0.466 7.889,1.396c3.931,1.785 5.896,5.079 5.896,9.883c0,2.506 -0.518,4.557 -1.552,6.152c-1.035,1.595 -2.483,2.877 -4.344,3.845c1.633,0.665 2.862,1.538 3.688,2.62c0.826,1.083 1.287,2.839 1.382,5.27l0.199,5.61c0.057,1.595 0.19,8.085 0.399,8.863c0.342,1.329 -5.791,-4.866 -5.867,-6.499l-0.342,-6.978c-0.133,-2.734 -1.149,-4.566 -3.048,-5.497c-1.082,-0.512 -2.781,-0.769 -5.098,-0.769l-4.53,0l0,-4.728l4.73,0c2.658,0 4.761,-0.531 6.308,-1.594c1.548,-1.064 2.321,-2.982 2.321,-5.754c0,-2.981 -1.082,-5.013 -3.246,-6.095c-1.159,-0.569 -2.706,-0.854 -4.643,-0.854l-13.842,0l0,36.969Z
                     M285.617,22.99c7.386,0 12.855,2.374 16.405,7.121c2.773,3.702 4.159,8.44 4.159,14.212c0,6.247 -1.586,11.44 -4.757,15.579c-3.721,4.861 -9.028,7.292 -15.921,7.292c-6.437,0 -11.497,-2.127 -15.181,-6.38c-3.285,-4.102 -4.927,-9.285 -4.927,-15.551c0,-5.659 1.405,-10.5 4.215,-14.526c3.608,-5.165 8.944,-7.747 16.007,-7.747Zm0.57,39.134c4.993,0 8.606,-1.79 10.837,-5.369c2.231,-3.579 3.346,-7.695 3.346,-12.347c0,-4.918 -1.286,-8.876 -3.859,-11.877c-2.573,-3 -6.09,-4.5 -10.552,-4.5c-4.329,0 -7.861,1.486 -10.595,4.458c-2.735,2.971 -4.102,7.353 -4.102,13.144c0,4.633 1.173,8.54 3.518,11.72c2.345,3.181 6.147,4.771 11.407,4.771Z
                     M333.727,41.816c2.393,0 4.254,-0.332 5.583,-0.996c2.088,-1.045 3.133,-2.925 3.133,-5.64c0,-2.734 -1.111,-4.576 -3.333,-5.525c-1.253,-0.532 -3.114,-0.798 -5.582,-0.798l-10.111,0l0,12.959l10.31,0Zm1.909,19.311c3.474,0 5.952,-1.006 7.433,-3.019c0.931,-1.272 1.396,-2.81 1.396,-4.614c0,-3.038 -1.358,-5.108 -4.073,-6.209c-1.443,-0.589 -3.351,-0.883 -5.725,-0.883l-11.25,0l0,14.725l12.219,0Zm-17.773,-36.998l17.972,0c4.899,0 8.383,1.462 10.453,4.387c1.215,1.727 1.823,3.721 1.823,5.981c0,2.639 -0.75,4.804 -2.25,6.493c-0.779,0.893 -1.899,1.709 -3.361,2.45c2.145,0.816 3.75,1.737 4.813,2.763c1.88,1.822 2.82,4.338 2.82,7.547c0,2.696 -0.845,5.136 -2.535,7.32c-2.525,3.266 -6.541,4.899 -12.048,4.899l-17.687,0l0,-41.84Z
                     M380.043,22.99c7.387,0 12.855,2.374 16.406,7.121c2.772,3.702 4.158,8.44 4.158,14.212c0,6.247 -1.585,11.44 -4.756,15.579c-3.722,4.861 -9.029,7.292 -15.922,7.292c-6.436,0 -11.497,-2.127 -15.18,-6.38c-3.285,-4.102 -4.928,-9.285 -4.928,-15.551c0,-5.659 1.406,-10.5 4.216,-14.526c3.607,-5.165 8.943,-7.747 16.006,-7.747Zm0.57,39.134c4.994,0 8.606,-1.79 10.837,-5.369c2.231,-3.579 3.347,-7.695 3.347,-12.347c0,-4.918 -1.287,-8.876 -3.859,-11.877c-2.573,-3 -6.091,-4.5 -10.553,-4.5c-4.329,0 -7.861,1.486 -10.595,4.458c-2.734,2.971 -4.101,7.353 -4.101,13.144c0,4.633 1.172,8.54 3.517,11.72c2.345,3.181 6.147,4.771 11.407,4.771Z
                     M442.879,24.129l0,4.985l-14.099,0l0,36.855l-5.753,0l0,-36.855l-14.098,0l0,-4.985l33.95,0Z
                     M52.364,0.002c1.168,0.156 1.206,0.224 1.894,1.033l31.881,41.689c0.666,0.972 0.883,1.805 0.076,3.116l-29.797,43.204c-0.998,1.296 -1.774,1.317 -2.94,1.035l-50.297,-14.988c-1.541,-0.548 -1.801,-1.28 -1.893,-2.476l-1.288,-52.467c0.046,-1.635 0.661,-2.108 1.77,-2.565l49.501,-17.438c0.189,-0.056 0.387,-0.162 1.093,-0.143Zm-47.016,21.942l1.194,48.615l46.605,13.888l27.609,-40.032l-29.54,-38.629c-15.289,5.386 -30.579,10.772 -45.868,16.158Z"
          />
          <path
            d="M52.364,0.002c1.168,0.156 1.206,0.224 1.894,1.033l31.881,41.689c0.666,0.972 0.883,1.805 0.076,3.116l-29.797,43.204c-0.998,1.296 -1.774,1.317 -2.94,1.035l-50.297,-14.988c-1.541,-0.548 -1.801,-1.28 -1.893,-2.476l-1.288,-52.467c0.046,-1.635 0.661,-2.108 1.77,-2.565l49.501,-17.438c0.189,-0.056 0.387,-0.162 1.093,-0.143Zm-47.016,21.942l1.194,48.615l46.605,13.888l27.609,-40.032l-29.54,-38.629c-15.289,5.386 -30.579,10.772 -45.868,16.158Z"
            fill="#00BCF1"
          />
        </g>
      </g>
      <g transform="translate(380, 90)">
        <text fontSize="1.1em">
          v.
          {version}
        </text>
      </g>
    </StyledLogo>
  );
};

StyledLogo.defaultProps = {
  theme: {
    logoFill: '#00000',
  },
};

export default Logo;
