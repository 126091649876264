import React, {useState} from 'react';
import {useApolloClient, useQuery} from '@apollo/react-hooks';
import {GET_TAGSTREE} from "../../api/queries/tags";
import Icon from '../../atoms/Icon';
import {components} from 'react-select';
import styled, { css } from 'styled-components';
import Button from '../../atoms/Button';

const StyledNestedOptGroup = styled.div`
  padding-left: 16px;
  display: ${({ isVisible }) => isVisible ? 'block' : 'none'}
`;

const StyledIcon = styled(Icon)`
  fill: ${props => props.theme.dropdownIconColor};
  float: left;
  padding-top: 8px;
`;

const StyledNestedOption = styled.div`
  padding-left: 12px;
`;

const StyledLoadMoreButton = styled(Button)`
  margin-left: 2.5em;
  font-size: 12px;
`;

const TagSelectChildren = ({props,  nestedOptions: externalNestedOptions, labelKey, valueKey, option: externalOption}) => {
    let {
        cx,
        getStyles,
        innerProps,
        selectOption
    } = props;

    
  const [option, setOption] = useState(externalOption);
  const limit = 10;
  const isOverLimit = externalNestedOptions.length > limit;


  const [after, setAfter] = useState(0);
  const [hasNext, setHasNext] = useState(isOverLimit);
  const [nestedOptions, setNestedOptions] = useState(externalNestedOptions)
  const client = useApolloClient();


  const getIconName = isVisible => isVisible ? "chevron-down" : "chevron-right"
  const handleIconClick = async () => {
    if (isOverLimit) {
      setNestedOptions(externalNestedOptions.slice(after, after + limit));
      setAfter(after + limit);
    } else {
      if (!option.children || !option.children.edges.length) {
        setIconName('loading');
        const {data} = await client.query({
          query: GET_TAGSTREE,
          variables: {limit, after: String(after), id: option.id}
        });
        setOption(data.tagsTree);
        setHasNext(data.tagsTree.children.pageInfo.hasNext);
        setNestedOptions(data.tagsTree.children.edges);
        }
    }

      setIsVisible(!isVisible);
      setIconName(getIconName(!isVisible));

  };

  const handleLoadMore = async () => {
    if (isOverLimit) {
      setNestedOptions([...nestedOptions, ...externalNestedOptions.slice(after, limit+after)]);
      setHasNext(externalNestedOptions.length > limit + after);
    } else {
        const {data} = await client.query({
          query: GET_TAGSTREE,
          variables: {limit, after: String(after + limit), id: option.id}
        });
 
        setHasNext(data.tagsTree.children.pageInfo.hasNext);
        setNestedOptions([...nestedOptions, ...data.tagsTree.children.edges])
      }
 
      setAfter(after+limit);

  };
  let [isVisible, setIsVisible] = useState(false);
  let [iconName, setIconName] = useState(getIconName(isVisible))
    const nestedLabelClassName = cx(
      css(getStyles('groupHeading', props)),
      { option: true },
      'nested-optgroup-label',
    );

    const nestedProps = {...props, isFocused: false};
    const nestedInnerProps = {...innerProps};
    nestedInnerProps.onClick = () => selectOption(option);
    return (
        <div>
                  <div className={nestedLabelClassName}>
       {option.hasChildren && <StyledIcon onClick={handleIconClick} icon={iconName}/>}
         <components.Option  {...nestedProps} innerProps={nestedInnerProps}>
                {option[labelKey]}
              </components.Option>
        </div>
        <StyledNestedOptGroup className='nested-optgroup' isVisible={isVisible}>

        {nestedOptions.map((nestedOption, index) => {
          if (nestedOption.hasChildren)  {
            const nestedOptions = (nestedOption.children && nestedOption.children.edges) || []
              const nestedProps =  {props, option: nestedOption, nestedOptions, labelKey, valueKey}
            return <TagSelectChildren {...nestedProps} key={nestedOption[valueKey]}/>;
          }

          const nestedProps = {...props, isFocused: false};
          const nestedInnerProps = {...innerProps};
          nestedInnerProps.onClick = () => selectOption(nestedOption);
          return (
            <StyledNestedOption className="nested-optgroup-option" key={nestedOption[valueKey]}>
              <components.Option  {...nestedProps} innerProps={nestedInnerProps}>
                {nestedOption[labelKey]}
              </components.Option>

            </StyledNestedOption>
          );
        })}
                  {hasNext && (
              <StyledLoadMoreButton type="button" secondary small onClick={handleLoadMore}>
                Load more
              </StyledLoadMoreButton>
            )}
        </StyledNestedOptGroup>
      </div>
    );
};

export default TagSelectChildren;
