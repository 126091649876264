import styled from 'styled-components';
import Block from '../../../atoms/Block';

const Hint = styled(Block)`
  font-family: ${props => props.theme.primaryFont};
  margin: 0.5em 0.4em 0;
  text-align: right;
  color: ${props => props.theme.inputLabelColor};
`;

Hint.defaultProps = {
  theme: {
    inputLabelColor: '#0789CC',
  },
};

export default Hint;
