import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import _get from 'lodash/get';
import { Flex, Box } from '@rebass/grid';
import styled from 'styled-components';
import { withRouter } from 'react-router';

import { withLivionConnect } from '@components/contexts/LivionConnectContext';
import DashboardDefaultPage from '@pages/DashboardDefaultPage';
import Input from '@components/atoms/Input';
import Label from '@components/atoms/Label';
import Button from '@components/atoms/Button';
import Select from '@components/atoms/Select';
import Icon from '@components/atoms/Icon';
import Fieldset from '@components/atoms/Fieldset';
import { routerCommands, deviceCommands } from '../commands';
import { GET_DEVICE_AND_ENV } from '../api/queries/devices';
import ToasterContext from '@components/contexts/ToasterContext';
const StatusLabel = styled(Label)`
  font-size: ${(props) => props.theme.statusLabelSize};
  &.connected {
    color: ${(props) => props.theme.statusLabelConnectedColor};
  }
  &.disconnected {
    color: ${(props) => props.theme.statusLabelDisconnectedColor};
  }
`;

const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.plugColor};
  padding: 0.1em 0.5em;
`;

const StyledButton = styled(Button)`
  width: 80%;
  text-align: center;
`;

const Commands = ({ device, addToast, commands, ATVariable }) => {
  return (
    <Flex wrap="wrap" flexDirection="column" alignItems="center">
      {commands.map((c, i) => (
        <StyledCommand
          key={`device-command-${c.label}-${i}`}
          device={device}
          addToast={addToast}
          ATVariable={ATVariable}
          {...c}
        />
      ))}
    </Flex>
  );
};
const StyledCommand = ({
  addToast,
  device,
  ATVariable,
  command,
  label,
  timeout,
  options,
  input,
  placeholder,
  description,
  result,
  variable,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState('');
  const [resultValue, setResultValue] = useState('');

  const params = options ? selectValue : input ? inputValue : null;

  return (
    <Box width={[1, 1, 1]}>
      <Flex wrap="nowrap" flexDirection="row" alignItems="center">
        <Box width={[1, 1, 1 / 6]} style={{ marginRight: '20px' }}>
          {options && (
            <Select
              value={selectValue}
              onChange={(e) => setSelectValue(e)}
              options={options}
              placeholder={placeholder}
            />
          )}
          {input && (
            <Input
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={placeholder}
            />
          )}
        </Box>
        <Box width={[1, 1, 1 / 6]}>
          <StyledButton
            onClick={async () => {
              try {
                const res = await device.sendCustom(command, params, timeout);

                if (res.status === 'ok') {
                  if (result) setResultValue(res.message);
                  addToast({
                    type: 'success',
                    title: `Success`,
                    message: `${res.message}`,
                  });
                } else {
                  addToast({
                    type: 'error',
                    title: `Error`,
                    message: `Command ${command} failed - ${res.message}`,
                    timeout: '5',
                  });
                }
              } catch (e) {
                console.log(e);
                addToast({
                  type: 'error',
                  title: `Error`,
                  message: `Command ${command} failed - ${
                    (e && e.message) || e
                  }`,
                  timeout: '5',
                });
              }
            }}
            secondary
          >
            {label}
          </StyledButton>
        </Box>
        <Box width={[1, 1, 2 / 6]} style={{ marginRight: '20px' }}>
          {result && <Label>{variable ? ATVariable : resultValue}</Label>}
        </Box>
        <Box width={[1, 1, 2 / 6]}>{description}</Box>
      </Flex>
    </Box>
  );
};
export const Maintenance = (props) => {
  const {
    match: { params },
    actions: { connectDevice },
    history,
  } = props;
  const { addToast } = useContext(ToasterContext);
  let [deviceStatus, setDeviceStatus] = useState('connecting...');
  // let [logs, setLogs] = useState(null);
  let [deviceConnection, setDeviceConnection] = useState();
  let [customCmd, setCustomCmd] = useState('');
  let [customTimeout, setCustomTimeout] = useState(0);
  let [ATVariable, setATVariable] = useState('');

  const { data, loading, error } = useQuery(GET_DEVICE_AND_ENV, {
    variables: { id: params.id, envId: params.id },
    skip: !params || !params.id,
  });
  const isHuawei = !_get(data, 'deviceEnv', {})['ROUTER_TYPE'];
  const heading = `Device ${params.id}`;
  const subHeading = 'Maintenance';

  const handleExecCmd = async () => {
    try {
      await deviceConnection.sendCustom(
        'exec-command',
        customCmd,
        customTimeout
      );
      addToast({
        type: 'success',
        title: `Success`,
        message: `Command ${customCmd} sent`,
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: `Error`,
        message: `Command ${customCmd} failed - ${error.message}`,
        timeout: '5',
      });
    }
  };

  useEffect(() => {
    if (!loading && !error && data && data.device && !deviceConnection) {
      connectDevice(data.device, true)
        .then(async (v) => {
          if (v.connected) {
            setDeviceStatus('connected');
            setDeviceConnection(v);
          }
          return v;
        })
        .catch((err) => {
          if (err !== 'device-disconnected') {
            addToast({
              type: 'error',
              title: `Error`,
              message:
                typeof err === 'string'
                  ? err
                  : err.message || err.error.message,
            });
            setDeviceStatus('disconnected');
          } else {
            setDeviceStatus('disconnected');
          }
        });
    }
    return () => {
      if (deviceConnection) {
        deviceConnection.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, error, data, loading, params.id, deviceConnection]);

  useEffect(() => {
    async function getVariable() {
      const variable = await deviceConnection.getVariable('Modem-AT-COMMAND');

      setATVariable(variable);
    }
    const onVariableChanged = (v) => {
      if (v.name === 'Modem-AT-COMMAND') setATVariable(v.value);
    };
    const onConnectedChanged = async (c) => {
      // console.log('onConnected', c);
      await getVariable('Modem-AT-COMMAND');
    };

    if (deviceConnection) {
      getVariable('Modem-AT-COMMAND');

      deviceConnection.on('variable', onVariableChanged);
      deviceConnection.on('connected', onConnectedChanged);
    }
    return () => {
      if (deviceConnection)
        deviceConnection.removeListener('variable', onVariableChanged);
    };
  }, [deviceConnection]);

  useEffect(() => {
    // console.log('New ATVariable', ATVariable);
    ATVariable &&
      addToast({
        type: 'success',
        title: `AT COMMAND Response`,
        message: ATVariable,
        timeout: 5,
      });
  }, [ATVariable, addToast]);

  return (
    <React.Fragment>
      <DashboardDefaultPage
        title={heading}
        subtitle={subHeading}
        onBack={() => history.back()}
        loading={loading}
        error={error}
        status={
          <Flex
            style={{
              border: '1px solid gray',
              paddingRight: '1em',
              borderRadius: '1em',
              marginLeft: '1em',
            }}
            alignItems="center"
          >
            <StyledIcon icon={'plug'} size={'1em'} />
            <StatusLabel className={deviceStatus}>{deviceStatus}</StatusLabel>
          </Flex>
        }
      >
        <React.Fragment>
          {!loading && deviceConnection && (
            <Flex flexWrap="wrap" flexDirection="column" alignItems="center">
              <Box width={[1, 1, 10 / 12]}>
                <Fieldset label="Custom Exec commands">
                  <Flex alignItems="center">
                    <Box width={[1, 1, 3 / 6]} style={{ marginRight: '20px' }}>
                      <Input
                        value={customCmd}
                        onChange={(e) => setCustomCmd(e.target.value)}
                        placeholder="Enter exec command"
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 6]} style={{ marginRight: '20px' }}>
                      <Input
                        value={customTimeout}
                        type="number"
                        onChange={(e) =>
                          setCustomTimeout(parseInt(e.target.value, 10))
                        }
                        placeholder="Enter Timeout (ms)"
                      />
                    </Box>
                    <Box>
                      <Button
                        onClick={handleExecCmd}
                        secondary
                        disabled={!customCmd}
                      >
                        Send
                      </Button>
                    </Box>
                  </Flex>
                </Fieldset>
                {!isHuawei && (
                  <Fieldset label="Router commands">
                    <Commands
                      commands={routerCommands}
                      device={deviceConnection}
                      addToast={addToast}
                      ATVariable={ATVariable}
                    />

                    <Fieldset label="Lte Band definition">
                      <Flex flexDirection="column">
                        <Box>
                          If it is set to 0 or 0x40000000, it means not to
                          change LTE frequency band.
                        </Box>
                        <Box>
                          Example: (e.g.: 0x15=0x1(LTE B1)+0x4(LTE B3)+0x10(LTE
                          B5))
                        </Box>
                        <Box>0x1 - LTE B1</Box>
                        <Box>0x4 - LTE B3</Box>
                        <Box>0x10 - LTE B5</Box>
                        <Box>0x40 - LTE B7</Box>
                        <Box>0x80 - LTE B8</Box>
                        <Box>0x80000 - LTE B20</Box>
                      </Flex>
                    </Fieldset>
                  </Fieldset>
                )}

                <Fieldset label="Device commands">
                  <Commands
                    commands={deviceCommands}
                    device={deviceConnection}
                    addToast={addToast}
                  />
                </Fieldset>
              </Box>
              {/* {logs && (
                <Box width={[1, 1, 10 / 12]}>
                  <Fieldset label="Device Logs" />
                </Box>
              )} */}
            </Flex>
          )}
        </React.Fragment>
      </DashboardDefaultPage>
    </React.Fragment>
  );
};

export default withRouter(withLivionConnect(Maintenance));
