import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import styled from 'styled-components';
import Label from '@components/atoms/Label';

const ResponsiveLabel = styled(Label)`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.9em;
  }
`;

const ConnectionStateLabel = styled(ResponsiveLabel)`
  text-transform: none;
  cursor: pointer;
  line-height: 1em;
`;

const MessageLabel = styled(Label)`
  text-transform: none;
  font-size: 0.8em;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  display: block;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.6em;
  }
`;

function WifiState({ device, onClick }) {
  const wifi = get(device, 'settings.data.huawei-router.wlan', null);

  return wifi ? (
    <ConnectionStateLabel onClick={onClick}>
      {wifi.state ? 'Enabled' : 'Disabled'}
      <MessageLabel style={{ cursor: 'pointer' }}>
        ssid: {wifi.networkname}
      </MessageLabel>
      <MessageLabel style={{ cursor: 'pointer' }}>
        password: {wifi.password}
      </MessageLabel>
    </ConnectionStateLabel>
  ) : (
    '-'
  );
}

WifiState.propTypes = {
  device: PropTypes.object.isRequired,
};

export default WifiState;
