import React, { useEffect, useState, useCallback } from 'react';
import useRealtimeDevice from '../../hooks/useRealtimeDevice';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Button from '../../atoms/Button';
import Label from '../../atoms/Label';
import CaptionCard from '../../molecules/CaptionCard';
import Caption from '../../atoms/Caption';
import LoaderLine from '../../atoms/LoaderLine';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_DEVICE } from '../../api/mutations/devices';
import Icon from '../../atoms/Icon';
import dots from '../../images/3-dots.svg';

const StyledLoader = styled(LoaderLine)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
const StyledBox = styled(Box)`
  margin-top: 2em;
  font-size: 1em;
  ${props =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `};
  ${props =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledCard = styled(CaptionCard)`
  position: relative;
  padding: 0.5em 0.8em 0.5em 0.8em;
  border-left: ${props => props.theme.cardLeftBorder};
  background: ${props => props.theme.cardBackground};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  padding: 1em;
`;

const LabelHeading = styled.p`
  display: inline;
  font-weight: bold;
  color: ${props => props.theme.labelBoldColor};
`;

const StyledLabel = styled(Label)`
  color: ${props => props.theme.secondaryThemeColor};
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${props => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;

const BoxWithRightRuler = styled(Box)`
  border-right: ${props => props.theme.cardBoxRuler};
  margin-right: 2em;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    border-right: none;
    margin-right: inherit;
  }
`;

const SpeedTestCard = ({
  hidden,
  loading,
  title,
  subtitle,
  device,
  toaster,
}) => {
  const [updateDeviceMeta] = useMutation(UPDATE_DEVICE);
  const [lastSpeedTest, setLastSpeedTest] = useState();
  const [currentSpeedTest, setCurrentSpeedTest] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState();

  const {
    loading: loadingRealtimeDevice,
    connected: realtimeDeviceConnected,
    device: connectedDevice,
  } = useRealtimeDevice(device);

  const onButtonClick = useCallback(async () => {
    setIsButtonDisabled(true);
    currentSpeedTest && setLastSpeedTest(currentSpeedTest);
    setCurrentSpeedTest(null);
    try {
      const speed = await connectedDevice.sendCustom('speedtest', {}, 180000);
      setCurrentSpeedTest((speed / 125).toFixed(2));
      setIsButtonDisabled(false);
      updateDeviceMeta({
        variables: {
          id: device.id,
          input: {
            meta: { ...device.meta, lastSpeedTest: speed },
          },
        },
      });
    } catch (e) {
      toaster &&
        toaster.addToast({
          type: 'error',
          title: `Error`,
          message: `${e.message || e.error}. Please, try again later!`,
        });
      setIsButtonDisabled(false);
    }
  }, [connectedDevice]);

  useEffect(() => {
    if (
      device &&
      device.meta &&
      device.meta.lastSpeedTest &&
      !currentSpeedTest
    ) {
      setLastSpeedTest((device.meta.lastSpeedTest / 125).toFixed(2));
    }
  }, [device]);

  useEffect(() => {
    setIsButtonDisabled(!realtimeDeviceConnected);
  }, [realtimeDeviceConnected]);

  return (
    !hidden && (
      <StyledBox hidden={hidden} flex="1 1 auto" width={[1]}>
        <StyledCard title={title} style={{ position: 'relative' }}>
          {subtitle && <Caption>{subtitle}</Caption>}
          {loading && <StyledLoader loading />}
          <StyledFlex flexWrap={['wrap', null]}>
            <BoxWithRightRuler flex="1 1 auto" ml="0.3em">
              <StyledLabel>
                <LabelHeading>Previous Result: </LabelHeading>{' '}
                {lastSpeedTest || '- '} Mbps
              </StyledLabel>

              <StyledLabel>
                <LabelHeading>Current Result: </LabelHeading>
                {realtimeDeviceConnected && isButtonDisabled ? (
                  <Icon svg={dots} />
                ) : (
                  currentSpeedTest || '-'
                )}{' '}
                Mbps
              </StyledLabel>
              <StyledLabel>
                Notice: Speed test may take up to 2 min.
              </StyledLabel>
            </BoxWithRightRuler>
            <Box width="210px">
              <Flex
                style={{ height: '100%' }}
                justifyContent="center"
                flexDirection="column"
              >
                <Button
                  disabled={isButtonDisabled}
                  secondary
                  onClick={onButtonClick}
                >
                  {realtimeDeviceConnected && isButtonDisabled
                    ? 'Testing speed...'
                    : 'Start Test'}
                </Button>
              </Flex>
            </Box>
          </StyledFlex>
        </StyledCard>
      </StyledBox>
    )
  );
};

export default SpeedTestCard;
