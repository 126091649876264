import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import _get from 'lodash/get';
import gql from 'graphql-tag';
import { withRouter } from 'react-router';
import styled from 'styled-components';
import { useQuery, useMutation, useApolloClient } from '@apollo/react-hooks';

import { GET_DEVICE } from '../api/queries/devices';
import { UPDATE_DEVICE, UPDATE_DEVICE_EXTRAS } from '../api/mutations/devices';

import ToasterContext from '@components/contexts/ToasterContext';
import DashboardFlexibleFormPage from '@components/pages/DashboardFlexibleFormPage';
import Block from '@components/atoms/Block';
import Fieldset from '@components/atoms/Fieldset';
import TextField from '@components/fields/TextField';
import SelectField from '@components/fields/SelectField';
import CheckboxField from '@components/fields/CheckboxField';
import Modal from '@molecules/Modal';
// import Condition from '@components/fields/Condition';
import {
  composeValidators,
  validSSID,
  required,
  numeric,
  passwordLengthMax,
  passwordLengthMin,
  nospecialchars,
} from '@components/utils';
import dayjs from 'dayjs';
let stateChangeTimeout;
const StyledBlock = styled(Block)`
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${({ theme }) =>
      _get(theme, 'breakpoints[0]', '40em')}) {
    padding: 0.4em;
  }
`;
const StyledHint = styled.div`
  padding: 5px 10px;
  color: #c7c7c7;
`;
const ConfirmModal = styled(Modal)``;

const timeout = 24 * 60 * 60;

const GET_COUNTRY_TAGS = gql`
  query tag($id: String!, $filter: TagFilter) {
    tag(id: $id) {
      id
      name
      type
      meta
      children(filter: $filter) {
        edges {
          id
          name
          type
        }
      }
    }
  }
`;

export const DeviceEdit = ({
  history,
  match: {
    params,
    params: { id },
  },
  tagObjects: { organization, selected, country },
}) => {
  const { addToast } = useContext(ToasterContext);
  const [landingPage, setLandingPage] = useState(null);
  const [isRaspiAsAp, setIsRaspiAsAp] = useState(false);
  const [countries, setCountries] = useState([]);
  const [showWarning, setShowWarning] = useState(null);
  const [cellularStateChangeDiff, setCellularStateChangeDiff] = useState(null);
  const [cellularStateChangedAt, setCellularStateChangedAt] = useState(null);

  const client = useApolloClient();
  const {
    data: queryData,
    error: queryError,
    loading: queryLoading,
  } = useQuery(GET_DEVICE, {
    variables: params,
  });

  const [updateDevice] = useMutation(UPDATE_DEVICE);
  const [updateDeviceExtras] = useMutation(UPDATE_DEVICE_EXTRAS);

  const orgSettings = _get(queryData, 'device.settings.data', {});
  const orgSettingsAlarms = _get(queryData, 'device.settings.data.alarms', {});
  const orgSettingsWlan = _get(
    queryData,
    'device.settings.data.huawei-router.wlan',
    {}
  );
  const orgHiddenSettingsWlan = _get(
    queryData,
    'device.settings.data.hidden-network',
    {}
  );

  const orgMeta = _get(queryData, 'device.meta', {});

  useEffect(() => {
    setCellularStateChangedAt(
      _get(queryData, 'device.meta.cellularStateChangedAt')
    );
  }, [queryData]);
  useEffect(() => {
    const vpnType = _get(queryData, 'device.settings.data.vpn.type', '');
    const vpnEanble = _get(queryData, 'device.settings.data.vpn.enable', false);
    const vpnOpenVpn = _get(queryData, 'device.settings.data.vpn.openvpn', '');
    setIsRaspiAsAp(vpnType === 'openvpn' && vpnEanble && vpnOpenVpn);
  }, [queryData]);

  useEffect(() => {
    async function fetchCountries() {
      if (organization.id === selected.id) {
        const result = await client.query({
          query: GET_COUNTRY_TAGS,
          variables: {
            id: organization.id,
            filter: {
              type: 'country',
            },
          },
          fetchPolicy: 'no-cache',
        });

        const countryList = _get(result, 'data.tag.children.edges', []);

        setCountries(
          countryList.map((entry) => {
            const countryCode = entry.id.split('/')[
              entry.id.split('/').length - 1
            ];
            return { value: countryCode, label: entry.name };
          })
        );
      } else if (country) {
        const countryCode = country.id.split('/')[
          country.id.split('/').length - 1
        ];
        setCountries([{ value: countryCode, label: country.name }]);
      } else {
        // we should always have country info, Show error to user
      }
      //setLoadingCountries(false);
    }

    if (organization && selected) fetchCountries();
    return function cleanup() {};
  }, [organization, selected, country, client]);

  useEffect(() => {
    const checkDiff = () => {
      if (cellularStateChangedAt) {
        if (!cellularStateChangeDiff) {
          setCellularStateChangeDiff(
            cellularStateChangedAt &&
              dayjs().diff(dayjs(cellularStateChangedAt)) / 1000
          );
        } else if (cellularStateChangeDiff < 120) {
          stateChangeTimeout = setTimeout(() => {
            setCellularStateChangeDiff(
              cellularStateChangedAt &&
                dayjs().diff(dayjs(cellularStateChangedAt)) / 1000
            );
          }, 10 * 1000);
        }
      } else {
        clearTimeout(stateChangeTimeout);
      }
    };
    checkDiff();
    return () => {
      clearInterval(stateChangeTimeout);
    };
  }, [cellularStateChangedAt, cellularStateChangeDiff]);

  let title;
  let deviceExtras = useMemo(() => [], []);
  let installWithApartmentId = false;
  let extras = {};

  installWithApartmentId = _get(
    organization,
    'meta.public.installWithApartmentId',
    false
  );

  if (queryError) {
    addToast({
      type: 'error',
      title: `Error`,
      message: queryError.message,
      timeout,
    });
  }

  if (queryData && queryData.device) {
    title = `${_get(queryData, 'device.id')} ${_get(queryData, 'device.name')}`;
    deviceExtras = _get(queryData, 'device.extras', []);
    if (landingPage === null)
      setLandingPage(
        _get(queryData, 'device.settings.data.huawei-router.wlan.landingpage')
      );
  }

  const preFormmatExtras = useCallback(() => {
    let extras = {};
    deviceExtras.forEach((d) => (extras[d.name] = d.state === 'enabled'));
    return extras;
  }, [deviceExtras]);

  const postFormmatExtras = useCallback(
    (rest) => {
      let extras = [];
      Object.keys(rest).forEach((k) => {
        deviceExtras.forEach((d) => {
          delete d.__typename;
          if (d.name === k)
            extras.push({
              name: d.name,
              state: rest[k] ? 'enabled' : 'disabled',
            });
        });
      });
      return extras;
    },
    [deviceExtras]
  );

  const submit = useCallback(
    async ({
      cellularState,
      address,
      postCode,
      city,
      countryCode,
      wifistate,
      networkname,
      password,
      landingpage,
      temperatureHigh,
      temperatureLow,
      hiddenWifistate,
      hiddenNetworkname,
      hiddenPassword,
      secondaryDevice,
      extrasChanged,
      ...rest
    }) => {
      let tagUpdate = {};
      if (!installWithApartmentId) {
        tagUpdate = { tag: `${organization.id}/${countryCode}` };
      }
      const extras = postFormmatExtras(rest);
      const cellularStateChanged =
        _get(queryData, 'device.meta.cellularState') !== cellularState;
      try {
        await updateDevice({
          variables: {
            id,
            input: {
              ...tagUpdate,
              meta: {
                ...orgMeta,
                apartment: { address, postCode, city, countryCode },
                cellularState,
                cellularStateChangedAt:
                  cellularStateChanged || extrasChanged
                    ? dayjs().toString()
                    : orgMeta.cellularStateChangedAt,
              },
              settings: {
                ...orgSettings,
                alarms: {
                  ...orgSettingsAlarms,
                  temperature: {
                    high: temperatureHigh,
                    low: temperatureLow,
                  },
                },
                'huawei-router': {
                  wlan: {
                    ...orgSettingsWlan,
                    landingpage,
                    state: wifistate,
                    networkname: networkname
                      .normalize('NFD')
                      .replace(/[\u0300-\u036f]/g, ''),
                    password,
                  },
                },
                'hidden-network': {
                  ...orgHiddenSettingsWlan,
                  state: hiddenWifistate,
                  networkname: hiddenNetworkname
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, ''),
                  password: hiddenPassword,
                  wifibroadcast: false,
                },
              },
            },
          },
        });

        if (extrasChanged) {
          console.log('updating extras');
          await updateDeviceExtras({ variables: { id, input: extras } });
        }
        if (extrasChanged || cellularStateChanged) {
          setCellularStateChangedAt(dayjs().toString());
          setCellularStateChangeDiff(cellularStateChangeDiff || 1);
        }
        addToast({
          type: 'success',
          title: `Success`,
          message: `Device parameters updated successfully`,
        });
        setTimeout(() => window.location.reload(), 2000);
      } catch (error) {
        addToast({
          type: 'error',
          title: `Error`,
          message: `Device parameters update failed ${error.message}`,
          timeout,
        });
      }

      if (showWarning) setShowWarning(null);
    },
    [
      addToast,
      id,
      installWithApartmentId,
      orgHiddenSettingsWlan,
      orgMeta,
      orgSettings,
      orgSettingsAlarms,
      orgSettingsWlan,
      organization,
      postFormmatExtras,
      showWarning,
      updateDevice,
      updateDeviceExtras,
      queryData,
      cellularStateChangeDiff,
    ]
  );
  const handleSubmit = useCallback(
    (formProps) => {
      let extrasChanged = false;
      const cellularStateChanged =
        _get(queryData, 'device.meta.cellularState') !==
        formProps.cellularState;

      deviceExtras.forEach((e) => {
        if (formProps[e.name] !== (e.state === 'enabled')) {
          extrasChanged = true;
          formProps.extrasChanged = true;
        }
      });
      if (cellularStateChanged || extrasChanged) {
        setShowWarning(formProps);
      } else {
        submit(formProps);
      }
    },
    [deviceExtras, queryData, submit]
  );

  extras = preFormmatExtras();

  const ToggleDisabledLabel =
    cellularStateChangeDiff && cellularStateChangeDiff < 120
      ? ` (Toggles temporarily disabled - Update possible in ${Math.round(
          120 - cellularStateChangeDiff
        )} seconds )`
      : '';

  return (
    <DashboardFlexibleFormPage
      initialValues={{
        name: _get(queryData, 'device.name'),
        cellularState: _get(queryData, 'device.meta.cellularState'),
        ...extras,
        address: _get(queryData, 'device.meta.apartment.address'),
        postCode: _get(queryData, 'device.meta.apartment.postCode'),
        city: _get(queryData, 'device.meta.apartment.city'),
        countryCode: _get(queryData, 'device.meta.apartment.countryCode'),
        temperatureHigh: _get(
          queryData,
          'device.settings.data.alarms.temperature.high'
        ),
        temperatureLow: _get(
          queryData,
          'device.settings.data.alarms.temperature.low'
        ),
        wifistate: _get(
          queryData,
          'device.settings.data.huawei-router.wlan.state'
        ),
        landingpage: _get(
          queryData,
          'device.settings.data.huawei-router.wlan.landingpage'
        ),
        networkname: _get(
          queryData,
          'device.settings.data.huawei-router.wlan.networkname',
          ''
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
        password: _get(
          queryData,
          'device.settings.data.huawei-router.wlan.password'
        ),
        hiddenNetworkname: _get(
          queryData,
          'device.settings.data.hidden-network.networkname',
          ''
        )
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
        hiddenPassword: _get(
          queryData,
          'device.settings.data.hidden-network.password'
        ),
        hiddenWifistate: _get(
          queryData,
          'device.settings.data.hidden-network.state'
        ),
      }}
      onSubmit={handleSubmit}
      title={title}
      subtitle=" "
      onBack={history.goBack}
      loading={queryLoading}
      mutators={{
        landingPageChange: ([wifistate], state, { changeValue }) => {
          setLandingPage(wifistate);
          changeValue(state, 'wifistate', () => !wifistate);
        },
      }}
    >
      {({ form: { mutators } }) => (
        <React.Fragment>
          <StyledBlock>
            <Fieldset label="Internet connection">
              <StyledHint>
                {`As sim subscription operations may take some time, Internet
                connection status change is disabled 2 min after last change is
                requested. `}
                <br />
                {ToggleDisabledLabel && (
                  <div style={{ paddingTop: '0.5rem', fontStyle: 'italic' }}>
                    {ToggleDisabledLabel}
                  </div>
                )}
              </StyledHint>
              <CheckboxField
                big
                name="cellularState"
                label={`Active`}
                validateOnInit={true}
                disabled={
                  cellularStateChangeDiff && cellularStateChangeDiff < 120
                }
              />
              {/* {deviceExtras.map((d, i) => (
                <CheckboxField
                  key={`extra-${i}`}
                  big
                  name={d.name}
                  label={_get(
                    d.info.find((info) => info.language === 'en'),
                    'name'
                  )}
                  validateOnInit={true}
                  disabled={
                    cellularStateChangeDiff && cellularStateChangeDiff < 120
                  }
                />
              ))} */}
            </Fieldset>
            {!installWithApartmentId && (
              <Fieldset label="Location">
                <TextField
                  name="address"
                  validate={composeValidators(required)}
                  label="Address"
                  validateOnInit={true}
                />
                <TextField
                  name="postCode"
                  validate={composeValidators(required)}
                  label="Postal code"
                  validateOnInit={true}
                />
                <TextField
                  name="city"
                  validate={composeValidators(required)}
                  label="City"
                  validateOnInit={true}
                />
                <SelectField
                  name="countryCode"
                  validate={composeValidators(required)}
                  label="Country"
                  isMulti={false}
                  options={countries}
                  validateOnInit={true}
                />
              </Fieldset>
            )}
            <Fieldset label="Wifi Settings">
              {!isRaspiAsAp && (
                <CheckboxField
                  big
                  name="landingpage"
                  label="Landing Page enabled"
                  validateOnInit={true}
                  onChange={(evt) => {
                    mutators.landingPageChange(evt.target.checked);
                  }}
                />
              )}
              <CheckboxField
                big
                name="wifistate"
                label={'Wifi enabled'}
                validateOnInit={true}
                disabled={landingPage}
              />
              <TextField
                name="networkname"
                validate={composeValidators(required, validSSID)}
                label="Wifi Network Name"
                validateOnInit={true}
              />
              <TextField
                name="password"
                validate={composeValidators(
                  required,
                  passwordLengthMin,
                  passwordLengthMax,
                  nospecialchars
                )}
                label="Wifi Password"
                validateOnInit={true}
              />
            </Fieldset>
            {!isRaspiAsAp && (
              <Fieldset label="Hidden Wifi Settings">
                <CheckboxField
                  big
                  name="hiddenWifistate"
                  label={'Hidden Wifi enabled'}
                  validateOnInit={false}
                />

                <TextField
                  name="hiddenNetworkname"
                  label="Hidden Wifi Network Name"
                  validateOnInit={false}
                  disabled={true}
                />
                <TextField
                  name="hiddenPassword"
                  label="Hidden Wifi Password"
                  validateOnInit={false}
                  disabled={true}
                />
              </Fieldset>
            )}
            <Fieldset label="Alarm limits">
              <TextField
                name="temperatureHigh"
                validate={composeValidators(numeric)}
                label="Temperature high limit (default 30 degrees)"
                validateOnInit={true}
              />
              <TextField
                name="temperatureLow"
                validate={composeValidators(numeric)}
                label="Temperature low limit (default 15 degrees)"
                validateOnInit={true}
              />
            </Fieldset>
          </StyledBlock>
          {showWarning && (
            <ConfirmModal
              header={`Subscription change warning`}
              submitText="Update subscription"
              onClose={() => setShowWarning(null)}
              onSave={() => submit(showWarning)}
              closeColor="#00BCF1"
            >
              You are about to change your roomrobot internet subscription.
              Activation and Upgrade will take few minutes to be effective. This
              change affects the billing for whole month.
            </ConfirmModal>
          )}
        </React.Fragment>
      )}
    </DashboardFlexibleFormPage>
  );
};

export default withRouter(DeviceEdit);
