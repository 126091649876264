import styled from 'styled-components';
import Block from '../Block';

const Error = styled(Block)`
  font-family: ${props => props.theme.primaryFont};
  margin: 0.5em 0.4em 0;
  text-align: right;
  color: ${props => props.theme.fieldError};
`;

Error.defaultProps = {
  theme: {
    fieldError: '#C54747',
    inputLabelColor: '#0789CC',
  },
};

export default Error;
