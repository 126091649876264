import React from 'react';
import PropTypes from 'prop-types';
import c from 'color';
import styled from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import dayjs from 'dayjs';
import Masked from 'react-text-mask';
import Input from '../Input';

export const StyledDayPicker = styled.div`
  .DayPicker {
    font-family: ${({ theme }) => theme.primaryFont};
    background: ${({ theme }) => theme.background};
    color: ${({ theme }) => theme.primaryThemeColor};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) => theme.primaryThemeColor};
    color: ${({ theme }) => theme.background};
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${({ theme }) =>
      c(theme.primaryThemeColor, 'hex')
        .alpha(0.1)
        .string()};
    color: ${({ theme }) => theme.primaryThemeColor};
  }
`;

export const parseDate = date => {
  const [day, month, year] = date.replace(/\_/g, '').split('.');
  if (!!day && !!month && !!year && year.length === 4) {
    const parsed = dayjs(`${year}-${month}-${day}`);

    return parsed.toDate();
  }

  return false;
};

export const dateMask = [
  /[0-3]/,
  /\d/,
  '.',
  /[0-1]/,
  /\d/,
  '.',
  /[1-2]/,
  /\d/,
  /\d/,
  /\d/,
];

export const formatDate = (date, format) => dayjs(date).format(format);

const DatePicker = ({
  value,
  format,
  onChange,
  invalid,
  disabledDays,
  ...props
}) => (
  <StyledDayPicker>
    <DayPickerInput
      value={value}
      onDayChange={onChange}
      format={format}
      formatDate={formatDate}
      parseDate={parseDate}
      placeholder={format}
      component={props => (
        <Masked
          mask={dateMask}
          guide
          keepCharPositions
          {...props}
          render={(ref, props) => (
            <div ref={ref}>
              <Input invalid={invalid} {...props} />
            </div>
          )}
        />
      )}
      dayPickerProps={{
        firstDayOfWeek: 1,
        disabledDays,
      }}
      {...props}
    />
  </StyledDayPicker>
);

DatePicker.propTypes = {
  value: PropTypes.string,
  format: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
  disabledDays: PropTypes.arrayOf(PropTypes.object),
};

DatePicker.defaultProps = {
  value: '',
  format: 'DD.MM.YYYY',
  onChange: () => {},
  invalid: false,
  disabledDays: [],
};

export default DatePicker;
