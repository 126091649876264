import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

const DropdownStyled = styled.div`
  position: relative;

  &:focus {
    outline: none;
  }
  cursor: pointer;
  svg {
    fill: ${props => props.theme.dropdownIconColor};
  }
`;

const DropdownMenu = styled.div`
  font-family: ${props => props.theme.primaryFont};
  display: ${props => (props.active ? 'block' : 'none')};
  background: ${props => props.theme.dropdownBackground};
  color: ${props => props.theme.dropdownTextColor};
  box-shadow: ${props => props.theme.dropdownShadow};
  border-radius: ${props => props.theme.dropdownBorderRadius};
  //padding: ${props => props.theme.dropdownPadding};
  position: absolute;
  right: 0;
  //bottom: 0;
  width: ${props => props.theme.dropdownWidth};
  text-align: left;
  z-index: 10;
`;

const Item = styled.div`
  padding: ${props => props.theme.dropdownItemPadding};
  transition: background 0.1s linear;
  &:hover {
    background: ${props => props.theme.dropdownMenuHover};
    cursor: pointer;
  }
  &.active {
    color: ${props => props.theme.navigationActiveColor};
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`;

/**
 * @render react
 * @name DropdownNavigation
 * @description DropdownNavigation component.
 * @example
 *  <DropdownNavigation
      options={[
        { label: "Devices", to: "/devices" },
        { label: "Tags", to: "/tags" }
      ]}
    >Menu</DropdownNavigation>
 */
class DropdownNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  expand = () => {
    this.setState({ expanded: true });
  };

  collapse = () => {
    this.setState({ expanded: false });
  };

  handleItemClick = e => {
    this.props.history.push(e.target.getAttribute('to'));
    this.setState({
      expanded: false,
      value: e.target.innerText,
    });
  };

  handleTriggerClick = e => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const props = this.props;
    let dropdownMenu = undefined;

    dropdownMenu = (
      <DropdownMenu active={this.state.expanded} {...props}>
        {this.props.options.map((item, i) => {
          return (
            <Item
              className={item.active ? 'active' : ''}
              {...props}
              key={`dditem-${i}`}
              to={item.to}
              onClick={this.handleItemClick}
            >
              {item.label}
            </Item>
          );
        })}
      </DropdownMenu>
    );

    return (
      <DropdownStyled
        {...props}
        active={this.state.expanded}
        tabIndex="0"
        onBlur={this.collapse}
        onClick={this.handleTriggerClick}
      >
        <StyledDiv>{this.props.children}</StyledDiv>
        {dropdownMenu}
      </DropdownStyled>
    );
  }
}

DropdownMenu.defaultProps = {
  theme: {
    dropdownBackground: '#ffffff',
    dropdownTextColor: '#595959',
    dropdownIconColor: '#595959',
    dropdownShadow: '0 1px 1px 0 rgba(0,0,0,0.17)',
    dropdownBorderRadius: '0 0 5px 5px',
    dropdownPadding: '0.75em',
    dropdownWidth: 'auto',
    dropdownItemPadding: '0.5em',
    dropdownMenuHover: '#f8f8f8',
  },
};

Item.defaultProps = {
  theme: {
    dropdownItemPadding: '0.5em',
    dropdownMenuHover: '#f8f8f8',
  },
};

DropdownNavigation.defaultProps = {
  options: [],
};

DropdownNavigation.propTypes = {
  /**
   * @property {propTypes.array} options - Menu options
   */
  options: PropTypes.array,
};

export default withRouter(DropdownNavigation);
