import jwt_decode from 'jwt-decode';
import { apolloClient } from './server-api';
import { RefreshIdTokenDocument } from '../generated/graphql';

type localAuthTokens = {
  accessToken: string;
  refreshToken: string;
};

function getAuthTokensFromLocalStorage(): localAuthTokens | undefined {
  const accessToken = localStorage.getItem('accessToken') as string;
  const refreshToken = localStorage.getItem('refreshToken') as string;
  return accessToken && refreshToken
    ? { accessToken, refreshToken }
    : undefined;
}
let tokens: localAuthTokens | undefined = getAuthTokensFromLocalStorage();

function saveAuthTokensToLocalStorage(
  tokens: localAuthTokens | undefined
): void {
  if (tokens) {
    localStorage.setItem('accessToken', tokens!.accessToken);
    localStorage.setItem('refreshToken', tokens!.refreshToken);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }
}

const TEN_MINUTES = 10 * 60; // as seconds

// deltaToExp means how long token has to be valid from now
function notExpired(token: string, deltaToExp: number) {
  try {
    var decoded: any = jwt_decode(token);
    if (!decoded && !decoded.exp) return false;
    return (
      decoded.exp - Math.floor(new Date().valueOf() / 1000) - deltaToExp >= 0
    );
  } catch (e) {
    return false;
  }
}

export function setTokens(newTokens: localAuthTokens | undefined): void {
  if (
    newTokens === undefined ||
    newTokens?.accessToken === undefined ||
    newTokens?.refreshToken === undefined
  ) {
    tokens = undefined;
  } else tokens = newTokens;

  saveAuthTokensToLocalStorage(tokens);
}

let tokenFetchPromise: Promise<string | undefined> | undefined = undefined;

async function fetchNewIdToken(): Promise<string | undefined> {
  if (!tokens) return undefined;
  // if fetch not ongoing, start new fetch
  if (tokenFetchPromise === undefined) {
    tokenFetchPromise = new Promise<string | undefined>(
      async (resolve, reject) => {
        let accessToken: string | undefined = undefined;
        try {
          //@ts-ignore // apolloClient here is redux store and will return client when calling it as a function
          const res: any = await apolloClient().mutate({
            mutation: RefreshIdTokenDocument,
            variables: { refreshToken: tokens!.refreshToken },
          });
          accessToken = res?.data?.refreshIdToken;
          if (accessToken && notExpired(accessToken, 0)) {
            setTokens({ refreshToken: tokens!.refreshToken, accessToken });
          }
        } catch (error) {
          setTokens(undefined);
        }
        // important to set undefined to trigger featch again in next expiration
        tokenFetchPromise = undefined;
        resolve(accessToken); // will be string or undefined
      }
    );
  }
  return tokenFetchPromise;
}

export async function getAccessToken(): Promise<string | undefined> {
  if (!tokens) return undefined;

  if (notExpired(tokens.accessToken, TEN_MINUTES)) {
    return tokens.accessToken;
  } else {
    return await fetchNewIdToken();
  }
}

export function hasTokens(): boolean {
  return !!tokens;
}
