import gql from 'graphql-tag';

export const productsInfoFragment = gql`
  fragment productsInfo on Order {
    products {
      name
      quantity
      unitPrice
      configuration
    }
  }
`;
export const accessoriesInfoFragment = gql`
  fragment accessoriesInfo on Order {
    accessories {
      products
      name
      unitPrice
      imgUrl
      description
      quantity
      deliveredQuantity
    }
  }
`;
export const detailsInfoFragment = gql`
  fragment detailsInfo on Order {
    details {
      messages {
        message
        timestamp
      }
      deliveryEstimation
      deliveryDate
      trackingId
    }
  }
`;
export const customerInfoFragment = gql`
  fragment customerInfo on Order {
    customer {
      tag
      billingInfo
      deliveryInfo
      customData
    }
  }
`;
export const orderInfoFragment = gql`
  fragment orderInfo on Order {
    id
    creator {
      name
      email
    }
    state
    timestamp
    area
    country
  }
`;
export const devicesInfoFragment = gql`
  fragment devices on Order {
    devices {
      id
      configuration
    }
  }
`;
export const notificationReceiversFragment = gql`
  fragment notificationReceivers on Order {
    notificationReceivers {
      mail
      sms
      language
      activation
      deactivation
      rules {
        id
        sendEmail
        sendSms
      }
    }
  }
`;
