import React, { useEffect, useState } from 'react';
import { GET_DEVICES } from '../api/queries/devices';

import styled from 'styled-components';
import Label from '@components/atoms/Label';
import Icon from '@components/atoms/Icon';
import Link from '@atoms/Link';
import { Flex } from '@rebass/grid';
import DashboardListPage from '@components/pages/DashboardListPage';
import CountryAreaTagSearch from '@components/molecules/CountryAreaTagSearch';
import ConnectionState from '@components/atoms/ConnectionState';
import { getSelectedTag } from '@components/connect-react-lib';
import { hws } from '../defaults';
import WifiState from './WifiState';
import _get from 'lodash/get';
import _filter from 'lodash/filter';
import { convertDatausageValue } from '../utils/util';
import dayjs from 'dayjs';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${(props) => props.theme.tertiaryThemeColor};
`;

const ResponsiveLabel = styled(Label)`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.9em;
  }
`;

const DeviceLabel = styled(ResponsiveLabel)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  max-width: 12em;
  display: block;
  overflow: hidden;
`;

const MessageLabel = styled(Label)`
  text-transform: none;
  font-size: 0.8em;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  display: block;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.6em;
  }
`;

const TsMessageLabel = styled(MessageLabel)`
  color: red;
`;

const SyncTime = ({ data }) => {
  const ts = _get(data, 'meta.mining.24h.time', null);

  const tsDiff = ts ? dayjs().diff(ts, 'hours') : 0;
  if (ts && tsDiff > 12) {
    return <TsMessageLabel>updated {dayjs(ts).fromNow()}</TsMessageLabel>;
  } else return <></>;
};
const Configuration = ({ config }) => {
  const configuration = hws.filter((h) => h.value === config);
  return <div>{(configuration[0] && configuration[0].label) || config}</div>;
};

function Debounce({ children }) {
  const [debouncing, setDebounce] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setDebounce(false), 200);
    return () => {
      clearTimeout(timer);
    };
  });

  if (debouncing) {
    return null;
  }
  return children;
}
export const Devices = ({ history }) => {
  let [tag] = useState(getSelectedTag());
  const q = {
    filter: {
      product: 'roomrobot',
      lifecycleState: 'active',
      tag,
    },
    after: '0',
    limit: 20,
  };

  return (
    <DashboardListPage
      useFlexibleSearch={true}
      searchComponents={({ onChange }) => (
        <Flex width={[1, 1 / 3]}>
          <CountryAreaTagSearch onChange={onChange} />
        </Flex>
      )}
      context="devices"
      actions={[
        {
          order: 1,
          title: 'Install',
          icon: <Icon icon="plus" size="2em" />,
          onClick: () => history.push(`/install`),
        },
      ]}
      query={GET_DEVICES}
      variables={q}
      skip={'tag'}
      columns={[
        {
          name: 'ID',
          sortBy: '_id',
          render: (data) => {
            return <DeviceLabel>{data.id}</DeviceLabel>;
          },
        },
        {
          name: 'Address',
          sortBy: 'name',
          render: (data) => {
            const tagParts = data.tag.split('/');
            let label = data.name;
            if (tagParts[3]) label = `${tagParts[3]} / ${label}`;
            if (tagParts[2]) label = `${tagParts[2]} / ${label}`;

            return <DeviceLabel>{`${label}`}</DeviceLabel>;
          },
        },
        {
          name: 'Type',
          hide: ['xs'],
          render: ({ configuration }) => (
            <Configuration config={configuration} />
          ),
        },
        {
          name: 'Subscription',
          hide: ['xs'],
          render: ({ extras }) => {
            const highSpeed = extras.some(
              (e) => e.name === '50mb' && e.state === 'enabled'
            );
            const superHighSpeed = extras.some(
              (e) => e.name === '150mb' && e.state === 'enabled'
            );
            return superHighSpeed ? '150Mb/s' : highSpeed ? '50Mb/s' : '50Mb/s';
          },
        },
        {
          name: 'Internet',
          hide: ['xs'],
          render: (data) => (
            <ConnectionState
              key={data.id}
              device={data}
              onClick={() => history.push(`/devices/${data.id}/edit`)}
              history={history}
            />
          ),
        },

        {
          name: 'Wifi',
          hide: ['xs', 'sm'],
          render: (data) => (
            <Debounce>
              <WifiState
                device={data}
                onClick={() => history.push(`/devices/${data.id}/edit`)}
              />
            </Debounce>
          ),
        },
        {
          name: 'Data Usage 24h',
          hide: ['xs'],
          sortBy: 'meta.mining.24h.datausage.run',
          extraSort: { connectionState: 1 },
          render: (data) => {
            let routerStat = _get(data, 'meta.mining.24h.datausage.run', null);
            const clients = _get(data, 'meta.mining.24h.clients.max', 0);

            //const vnstat = _get(data, 'meta.mining.24h.vnstatdatausage.run', 0);
            if (routerStat === null || routerStat < 0) return '-';

            const sensor = _filter(_get(data, 'sensors', []), {
              type: _get(data, 'sensors', []).some((s) => s.type === 'Router')
                ? 'Router'
                : 'HuaweiRouter',
            })[0];

            routerStat = convertDatausageValue(sensor, routerStat);

            const datausage = clients > 0 ? Math.round(routerStat) : 0;

            return (
              <div>
                <div>{`${datausage} MB`}</div>
                <MessageLabel>{`${clients} Clients`}</MessageLabel>
                <SyncTime data={data} />
              </div>
            );
          },
        },
        {
          name: 'Average Speed 24h',
          sortBy: 'meta.mining.24h.speed.avg',
          hide: ['xs'],
          extraSort: { connectionState: 1 },
          render: (data) => {
            const speed = _get(data, 'meta.mining.24h.speed.avg', null);

            if (speed === null) return '-';
            return (
              <div>
                <div>{speed} Mbps</div>
                <SyncTime data={data} />
              </div>
            );
          },
        },
        {
          name: '',
          render: ({ id }) => (
            <Link to={`/devices/${id}`}>
              <StyledIcon size={'2em'} icon={'chevron-right'} />
            </Link>
          ),
        },
      ]}
    />
  );
};

export default Devices;
