import React, { useEffect } from 'react';
import MonthPicker from '../../atoms/MonthPicker';
import dayjs from 'dayjs';
const MonthPickerSearch = ({ onChange, value }) => {
  const onHandleChange = date => {
    onChange({
      timestamp: {
        gte: date
          ? dayjs(date)
              .startOf('month')
              .toISOString()
          : dayjs()
              .startOf('month')
              .toISOString(),
        lte: date
          ? dayjs(date)
              .endOf('month')
              .toISOString()
          : dayjs()
              .endOf('month')
              .toISOString(),
      },
    });
  };
  return <MonthPicker value={value} onChange={onHandleChange} />;
};

export default MonthPickerSearch;
