import React, { useState, useCallback } from 'react';
import DashboardListPage from '@components/pages/DashboardListPage';
import { useQuery } from '@apollo/react-hooks';
import Label from '@components/atoms/Label';
import Icon from '@components/atoms/Icon';
import Link from '@atoms/Link';
import { ORDERS } from '../api/queries/orders';
import styled from 'styled-components';
import dayjs from 'dayjs';
import MonthPickerSearch from '@components/molecules/MonthPickerSearch';
import { Flex } from '@rebass/grid';
import { hws } from '../defaults';
import { GET_ACCESSORIES } from '../api/queries/accessories';
const StyledDate = styled(Label)`
  text-transform: none;
  line-height: 1em;
  max-width: 12em;
  display: block;
  overflow: hidden;
`;

const StyledState = styled(Label)`
  text-transform: none;
  line-height: 1em;
  color: ${({ state, theme }) => {
    switch (state) {
      case 'billed':
        return theme.tertiaryThemeColor;
      case 'delivered':
        return theme.connectedColor;
      case 'waiting for delivery':
        return theme.internetDisabledColor;
      default:
        break;
    }
  }};
  max-width: 12em;
  display: block;
  overflow: hidden;
`;
const StyledId = styled(Label)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  max-width: 2em;
  display: block;
  overflow: hidden;
`;
const StyledCreator = styled(Label)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  max-width: 20em;
  display: block;
  overflow: hidden;
`;

const StyledCountry = styled(Label)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  max-width: 5em;
  display: block;
  overflow: hidden;
`;
const StyledArea = styled(Label)`
  text-transform: none;
  line-height: 1em;
  color: ${(props) => props.theme.secondaryThemeColor};
  max-width: 5em;
  display: block;
  overflow: hidden;
`;
const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${(props) => props.theme.tertiaryThemeColor};
`;

const StyledProducts = styled.div`
  max-width: 15em;
  display: block;
  overflow: hidden;
`;

export const Orders = ({ history }) => {
  const q = {
    filter: {
      product: 'roomrobot',
    },
    after: '0',
    limit: 20,
    sort: { timestamp: -1 },
  };

  const [accessoriesList, setAccessoriesList] = useState([]);
  const onCompleted = useCallback(({ accessories: { edges: data } = [] }) => {
    setAccessoriesList(
      data.map(({ name, imgUrl }) => ({ value: name, label: name, imgUrl }))
    );
  }, []);
  useQuery(GET_ACCESSORIES, {
    onCompleted,
  });

  return (
    <DashboardListPage
      useFlexibleSearch={false}
      context="orders"
      searchComponents={({ onChange, timestamp }) => {
        return (
          <Flex width={[1, 1 / 4]}>
            <MonthPickerSearch
              onChange={onChange}
              value={
                timestamp
                  ? dayjs(timestamp.lte).toISOString()
                  : dayjs().toISOString()
              }
            />{' '}
          </Flex>
        );
      }}
      skip={'timestamp'}
      actions={[
        {
          order: 1,
          title: 'Create order',
          icon: <Icon icon="plus" size="2em" />,
          onClick: () => history.push(`/orders/create`),
        },
      ]}
      query={ORDERS}
      variables={q}
      columns={[
        {
          name: 'Order ID',
          sortBy: 'id',
          render: ({ id }) => {
            return <StyledId> {id} </StyledId>;
          },
        },
        {
          name: 'Creator',
          sortBy: 'creator.email',
          width: '400px',
          render: ({ creator: { email } }) => {
            return <StyledCreator> {email} </StyledCreator>;
          },
        },
        {
          name: 'Country',
          sortBy: 'country',
          render: ({ country }) => {
            return <StyledCountry> {country} </StyledCountry>;
          },
        },
        {
          name: 'Area',
          sortBy: 'area',
          render: ({ area }) => {
            return <StyledArea> {area} </StyledArea>;
          },
        },
        {
          sortBy: 'products',
          name: 'Products',
          render: ({ products, accessories }) => (
            <React.Fragment>
              {' '}
              {products &&
                products.map((p, i) => (
                  <StyledProducts key={`product-${i}`}>
                    {' '}
                    {(hws.filter((h) => h.value === p.configuration)[0] &&
                      hws.filter((h) => h.value === p.configuration)[0]
                        .label) ||
                      p.configuration}{' '}
                    : {p.quantity}{' '}
                  </StyledProducts>
                ))}{' '}
              {accessories &&
                accessories.map((p, i) => (
                  <StyledProducts key={`accessory-${i}`}>
                    {' '}
                    {(accessoriesList.filter((h) => h.value === p.name)[0] &&
                      accessoriesList.filter((h) => h.value === p.name)[0]
                        .label) ||
                      p.name}{' '}
                    : {p.quantity}{' '}
                  </StyledProducts>
                ))}
            </React.Fragment>
          ),
        },
        {
          name: 'Order Date',
          sortBy: 'timestamp',
          render: ({ timestamp }) => (
            <StyledDate> {dayjs(timestamp).format('DD MMM YYYY')} </StyledDate>
          ),
        },
        {
          name: 'Estimated Delivery',
          sortBy: 'details.deliveryEstimation',
          render: ({ details }) => (
            <StyledDate>
              {' '}
              {details && details.deliveryEstimation
                ? dayjs(details.deliveryEstimation).format('DD MMM YYYY')
                : ''}{' '}
            </StyledDate>
          ),
        },
        {
          name: 'State',
          sortBy: 'state',
          render: ({ state }) => (
            <StyledState state={state}> {state} </StyledState>
          ),
        },
        {
          name: '',
          render: ({ id }) => (
            <Link to={`/orders/${id}/edit`}>
              <StyledIcon size={'2em'} icon={'chevron-right'} />{' '}
            </Link>
          ),
        },
      ]}
    />
  );
};
export default Orders;
