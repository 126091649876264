import React, { Component } from 'react';
import Markdown from 'markdown-to-jsx';

import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Button from '../../atoms/Button';

const StyledDiv = styled.div`
  color: ${props => props.theme.secondaryThemeColor};
  font: ${props => props.theme.primaryFont};
  padding: 2% 10%;
`;
const StyledH1 = styled.h1`
  color: ${props => props.theme.primaryThemeColor};
`;
const StyledH2 = styled.h2`
  color: ${props => props.theme.primaryThemeColor};
`;
const StyledH3 = styled.h3`
  color: ${props => props.theme.primaryThemeColor};
`;
const StyledH4 = styled.h4`
  color: ${props => props.theme.primaryThemeColor};
`;
const StyledH5 = styled.h5`
  color: ${props => props.theme.primaryThemeColor};
`;
const StyledH6 = styled.h6`
  color: ${props => props.theme.primaryThemeColor};
`;
const StyledParagraph = styled.p`
  color: ${props => props.theme.paragraphColor};
  font-weight: ${props => props.theme.paragraphFontWeight};
  line-height: ${props => props.theme.paragraphLineHeight};
  font-size: ${props => props.theme.paragraphFontSize};
  text-transform: ${props => props.theme.paragraphTextTransform};
  text-align: ${props => props.theme.paragraphTextAlign};
  padding: ${props => props.theme.paragraphPadding};
  margin: ${props => props.theme.paragraphMargin};
`;
const StyledLi = styled.li`
  color: ${props => props.theme.liColor};
  font-weight: ${props => props.theme.liFontWeight};
  line-height: ${props => props.theme.liLineHeight};
  font-size: ${props => props.theme.liFontSize};
  text-transform: ${props => props.theme.liTextTransform};
  text-align: ${props => props.theme.liTextAlign};
  padding: ${props => props.theme.liPadding};
  margin: ${props => props.theme.liMargin};
`;
const MdViewer = ({ file }) => (
  <StyledDiv>
    <Markdown
      children={file}
      options={{
        overrides: {
          h1: {
            component: StyledH1,
          },
          h2: {
            component: StyledH2,
          },
          h3: {
            component: StyledH3,
          },
          h4: {
            component: StyledH4,
          },
          h5: {
            component: StyledH5,
          },
          h6: {
            component: StyledH6,
          },
          p: {
            component: StyledParagraph,
          },
          li: {
            component: StyledLi,
          },
        },
      }}
    />
  </StyledDiv>
);

export default MdViewer;
