import gql from 'graphql-tag';
import {
  alarmInfoFragment,
  alarmEventHistoryFragment,
} from '../fragments/alarms';

export const ACK_ALARM = gql`
  mutation ackAlarm($filter: AlarmFilter!, $input: AlarmAckInput!) {
    ackAlarm(filter: $filter, input: $input) {
      ...alarmInfo
      ...alarmEventHistory
    }
  }
  ${alarmInfoFragment}
  ${alarmEventHistoryFragment}
`;
