import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Label from '../../atoms/Label';
import Input from '../../atoms/Input';
import Error from '../../atoms/Error';

const StyledLabel = styled(Label)`
  color: ${(props) => props.theme.inputLabelColor};
`;

const StyledSeparator = styled(Label)`
  color: ${(props) => props.theme.labelColor};
  font-size: 1.5rem;
`;

const Wrapper = styled.div`
  margin-bottom: 1rem;
  input[type='checkbox'],
  input[type='radio'] {
    margin-right: 0.5rem;
    display: inline-block;
    width: auto;
    height: auto;
  }
  label {
    vertical-align: middle;
    margin-left: 0.5em;
    text-transform: none;
  }
`;

const SeparatorWrapper = styled.div`
  margin-bottom: 1rem;
  border-bottom-width: 0.1em;
  border-bottom-style: solid;
  border-bottom-color: ${(props) => props.theme.labelColor};
`;

/**
 * @render react
 * @name Field
 * @description Field component.
 * @example
 * <Field name="field" />
 */
const Field = ({ error, name, invalid, label, type, ...props }) => {
  const inputProps = {
    id: name,
    name,
    type,
    invalid,
    'aria-describedby': `${name}Error`,
    ...props,
  };

  const renderInputFirst = type === 'checkbox' || type === 'radio';
  const separator = type === 'separator';

  return (
    <React.Fragment>
      {separator && (
        <SeparatorWrapper {...props}>
          <StyledSeparator htmlFor={inputProps.id}>{label}</StyledSeparator>
        </SeparatorWrapper>
      )}
      {!separator && (
        <Wrapper {...props}>
          {renderInputFirst && <Input {...inputProps} />}
          {label && <StyledLabel htmlFor={inputProps.id}>{label}</StyledLabel>}
          {renderInputFirst || <Input {...inputProps} />}
          {invalid && error && (
            <Error id={`${name}Error`} role="alert" palette="danger">
              {error}
            </Error>
          )}
        </Wrapper>
      )}
    </React.Fragment>
  );
};

Field.propTypes = {
  /**
   * @property {propTypes.string.isRequired} name - Field name.
   */
  name: PropTypes.string.isRequired,
  /**
   * @property {propTypes.bool} invalid - Shows error note.
   */
  invalid: PropTypes.bool,
  /**
   * @property {propTypes.string} error - Error string for invalid input.
   */
  error: PropTypes.string,
  /**
   * @property {propTypes.string} label - Field label
   */
  label: PropTypes.string,
  /**
   * @property {propTypes.string} type - Field type
   */
  type: PropTypes.string,
};

Field.defaultProps = {
  type: 'text',
};

export default Field;
