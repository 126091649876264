import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import BackgroundImage from "./background.svg";

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100vh;
  box-sizing: border-box;
  background: ${props => props.theme.noLoginBg ? '' : `url(${BackgroundImage}) no-repeat center center fixed`};
  background-size: cover;
  align-items: center;
`;

const LoginTemplate = ({ children, ...props }) => {
  return <Wrapper {...props}>{children}</Wrapper>;
};

LoginTemplate.propTypes = {
  children: PropTypes.any.isRequired
};

export default LoginTemplate;
