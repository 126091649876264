import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import Creatable from 'react-select/lib/Creatable';

import Icon from '../../atoms/Icon';
import { styles } from '../../atoms/Select';
import Hide from '../../atoms/Hide';

const StyledLabel = styled.label`
  font-family: ${({ theme }) => theme.primaryFont};
  color: ${({ theme }) => theme.subtitleColor};
  font-size: 1.125em;
  font-weight: bold;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  left: 0.1em;
  height: 100%;
  margin-left: 1em;
  z-index: 1;
  color: ${({ theme }) => theme.inputIconFill};
`;

const StyledCreatable = styled(Creatable)`
  ${styles};

  .select__control {
    padding-left: 2.4rem;
    border: 0;
  }

  .select__input{
    color: ${props => props.theme.inputFontColor}
  }
`;

const components = {
  DropdownIndicator: null,
};

const createOption = label => ({
  label,
  value: label,
});

const concat = ({ value }) => value;

/**
 * @render react
 * @name Search
 * @description Search component.
 * @example
 * <Search onBlur={e => alert("search: " + e.target.value)} />
 */
class Search extends Component {
  static propTypes = {
    defaultValue: PropTypes.string,
    defaultFilters: PropTypes.array,
    filterable: PropTypes.array,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    defaultValue: '',
    defaultFilters: [],
    filterable: [],
    onChange: () => {},
  };

  state = {
    search: this.props.defaultValue || '',
    filter: this.props.defaultFilters.map(createOption),
  };

  get options() {
    const options = this.props.filterable
      .filter(option => option.includes(this.state.search))
      .map(createOption);

    return options;
  }

  handleChange = (filter, { action, option }) => {
    if (action === 'select-option') {
      this.setState({ search: `${option.value}:` });
      this.props.onChange({ search: '' });
    } else {
      this.props.onChange({
        filter: filter.length ? filter.map(concat) : null,
      });
      this.setState({ filter });
    }
  };

  handleInputChange = (search, { action }) => {
    if (action === 'input-change') {
      if (search.indexOf(':') === -1) {
        this.props.onChange({ search });
      }
      this.setState({ search });
    }
  };

  handleKeyDown = event => {
    const { search, filter } = this.state;

    if (!search) return;

    if (
      (event.key === 'Enter' || event.key === 'Tab') &&
      !this.options.length
    ) {
      const index = search.indexOf(':');

      if (index !== -1 && index !== search.length - 1) {
        const values = {
          filter: [...filter, createOption(search)],
          search: '',
        };

        this.props.onChange({
          search: '',
          filter: values.filter.map(concat),
        });

        this.setState(values);
      }

      event.preventDefault();
    }
  };

  render() {
    const { search, filter } = this.state;

    const options = this.options;

    return (
      <Flex alignItems="center">
        <Hide xs>
          <Box py={1}>
            <StyledLabel>Search</StyledLabel>
          </Box>
        </Hide>
        <Box px="1em" py={1} flex="1 1 auto">
          <div style={{ position: 'relative' }}>
            <StyledIcon size="1.5em" icon="search" />
            <StyledCreatable
              classNamePrefix="select"
              components={components}
              inputValue={search}
              isClearable
              isMulti
              isValidNewOption={() => false}
              menuIsOpen={
                search.length > 0 && options.length > 0 && !search.includes(':')
              }
              onChange={this.handleChange}
              onInputChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              options={options}
              placeholder="Search..."
              value={filter}
            />
          </div>
        </Box>
      </Flex>
    );
  }
}

export default Search;
