import gql from 'graphql-tag';

export const UPDATE_DEVICE = gql`
  mutation Mutation($id: String!, $input: DeviceInput!) {
    updateDevice(id: $id, input: $input) {
      id
      tag
      name
    }
  }
`;
export const UPDATE_DEVICE_EXTRAS = gql`
  mutation Mutation($id: ID!, $input: [DeviceExtraInput]!) {
    updateDeviceExtras(id: $id, input: $input) {
      name
      state
    }
  }
`;

export const UPDATE_WIFI = gql`
  mutation Mutation($id: String!, $input: JSON!) {
    updateDeviceSettings(id: $id, input: $input) {
      id
      settings {
        data
      }
    }
  }
`;

export const EDIT_SENSOR = gql`
  mutation Mutation($id: ID!, $input: SensorUpdateInput!) {
    updateSensor(id: $id, input: $input) {
      id
      type
      name
    }
  }
`;

export const CREATE_SENSOR = gql`
  mutation Mutation($input: SensorCreateInput!) {
    createSensor(input: $input) {
      id
      type
      name
    }
  }
`;

export const DELETE_SENSOR = gql`
  mutation Mutation($id: ID!) {
    deleteSensor(id: $id)
  }
`;
export const INSTALL_DEVICE = gql`
  mutation installDevice($id: String!, $input: InstallDeviceInput!) {
    installDevice(id: $id, input: $input) {
      id
    }
  }
`;
export const UNINSTALL_DEVICE = gql`
  mutation uninstallDevice($id: String!, $input: UninstallDeviceInput) {
    uninstallDevice(id: $id, input: $input) {
      id
    }
  }
`;
