import styled from 'styled-components';
import Label from '../Label';

const styledLabel = styled(Label)`
  color: ${({
    theme: { inputLabelColor, inputLabelDisabledColor = '#717171' },
    disabled,
  }) => {
    return disabled ? inputLabelDisabledColor : inputLabelColor;
  }};
  font-style: ${({ disabled }) => {
    return disabled ? 'italic' : 'normal';
  }};
`;

export default styledLabel;
