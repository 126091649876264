import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

const StyledFieldset = styled.fieldset`
  border: ${({ theme }) => theme.inputBorderWidth} solid
    ${({ borderless, invalid, theme }) =>
      borderless
        ? 'transparent'
        : invalid
        ? theme.inputBorderError
        : theme.inputBorder};
  border-radius: ${({ theme }) => theme.inputBorderRadius};
  padding: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
  margin-top: 1em;
`;

const StyledLegend = styled.legend`
  color: ${({ theme }) => theme.inputLabelColor};
  font-family: ${({ theme }) => theme.primaryFont};
`;

StyledLegend.displayName = 'Legend';

const Fieldset = ({
  children,
  label,
  leftPane,
  rightPane,
  borderless,
  invalid,
  disabled,
}) => (
  <StyledFieldset disabled={disabled} borderless={borderless} invalid={invalid}>
    {label && <StyledLegend>{label}</StyledLegend>}
    <Flex>
      <Box flex="0 1 auto">{leftPane}</Box>
      <Box flex="1 1 auto">{children}</Box>
      <Box flex="0 1 auto">{rightPane}</Box>
    </Flex>
  </StyledFieldset>
);

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  rightPane: PropTypes.node,
  leftPane: PropTypes.node,
  label: PropTypes.string,
  borderless: PropTypes.bool,
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
};

Fieldset.defaultProps = {
  rightPane: null,
  leftPane: null,
  label: null,
  borderless: false,
  invalid: false,
  disabled: false,

  theme: {
    inputLabelColor: '#666',
    primaryFont: 'system-ui',
    inputBorderRadius: '.5em',
  },
};

export default Fieldset;
