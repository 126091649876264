/*import this at beginning of main level index.tsx file
will check do we need to render app in spesific mode. Status can be queried from all over app
*/
import { getDomain } from './utils';

type IappMode = 'commonApps' | 'allApps';

const appMode: IappMode = (() => {
  const pathStart = window.location.pathname.split('/')[1] || '';
  switch (pathStart) {
    case 'apps':
      return 'commonApps';
    default:
      return 'allApps';
  }
})();

type IxApp =
  | 'keyregister'
  | 'roomrobot'
  | 'livionkey'
  | 'connect'
  | 'dashboard'
  | 'production'
  | 'contract';

/**
 * ```js
 * type IxApp =
 * | 'keyregister'
 * | 'roomrobot'
 * | 'livionkey'
 * | 'connect'
 * | 'dashboard'
 * | 'production'
 * | 'contract';
 *```
 */

export const xApp: IxApp = (() => {
  // 1.  in development mode, we allow to to force xApp via env variable
  if (!process.env.NODE_ENV?.includes('production')) {
    if (process.env.REACT_APP_XAPP) return process.env.REACT_APP_XAPP as IxApp;
  }

  // 2. Subdomains will determinate if we run connect VPN APP
  const connectVPNSubdomains = ['connect', 'staging-connect'];
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  if (connectVPNSubdomains.includes(subdomain)) return 'connect';

  // 3. Subdomains will determinate if we run dashboard  APP
  const dashboardSubdomains = ['dashboard', 'staging-dashboard', 'ssklivion01'];
  const dashboardHost = window.location.host;
  const dashboardSubdomain = dashboardHost.split('.')[0];
  if (dashboardSubdomains.includes(dashboardSubdomain)) return 'dashboard';

  // 3. Subdomains will determinate if we run dashboard  APP
  const contractSubdomains = ['contract', 'staging-contract'];
  const currentSubdomain = window.location.host.split('.')[0];
  if (contractSubdomains.includes(currentSubdomain)) return 'contract';

  // 4. Either run common apps for keyUI and roomrobot or plain keyregister
  const pathStart = window.location.pathname.split('/')[1] || '';
  switch (pathStart) {
    case 'apps':
      // Legacy apps will write x-app to localstorage when they start
      const app: IxApp | null = localStorage.getItem('x-app') as IxApp;
      return app ? app : 'livionkey'; // livionkey is default
    default:
      return 'keyregister';
  }
})();

// set document title according xApp
switch (xApp) {
  case 'connect':
    document.title = 'Connect';

    break;
  case 'roomrobot':
    document.title = 'Roomrobot';
    break;
  case 'dashboard':
    document.title = 'Dashboard';
    break;
  case 'production':
    document.title = 'Production';
    break;
  case 'contract':
    document.title = 'Contract';
    break;
  default:
    // by default, title is LivionKey so leave it
    break;
}

// Set also document meta information
// @ts-ignore
/*document
  .querySelector('meta[name="description"]')
  .setAttribute('content', document.title);
*/
export const isCommonApps: boolean = appMode === 'commonApps';

type IAuthMode = 'localAuth' | 'firebaseAuthOnly';

/**
 * Returns if UI support firebaseAuthOnly or both local and firebase.
 * localAndFirebaseAuth means that email password login is stored locally,
 * where as 3rd party auth vendors are handled by firebase auth (data stored worldwide)
 *
 * return value
 * ```js
 *    type IAuthMode = 'localAndFirebaseAuth' | 'firebaseAuthOnly';
 * ```
 */
export const xAuthMode: IAuthMode = (() => {
  // 0. in cypress mode xAuthMode is defined by cypress
  // @ts-ignore
  if (window.Cypress && window.xAuthMode) return window.xAuthMode;
  // 1.  in development mode, we allow to to force xAuthMode via env variable
  if (!process.env.NODE_ENV?.includes('production')) {
    if (process.env.REACT_APP_AUTH_MODE)
      return process.env.REACT_APP_AUTH_MODE as IAuthMode;
  }

  // 2. domain will determinate if we support local auth
  const localAuthDomains = ['livionkey.fi', 'senaatti.fi', 'senaatti.com'];
  const domain = getDomain();
  if (localAuthDomains.includes(domain)) return 'localAuth';

  return 'firebaseAuthOnly';
})();
