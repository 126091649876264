import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Bar, Line, YAxis } from 'recharts';
import { withTheme } from 'styled-components';

import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import LoaderLine from '@atoms/LoaderLine';
//import Button from '@atoms/Button';
import SkeletonLoadingCard from '@components/cards/SkeletonLoadingCard';
import ControlledChart from '@organisms/ControlledChart';

import { StyledTooltip } from '@atoms/Chart';

const renderTooltip = ({ payload, label, separator }) => (
  <StyledTooltip>
    <div>{label}</div>
    <ul>
      {payload &&
        payload.map(({ name, value, color }) => (
          <li key={name} style={{ color }}>
            {name}
            {separator}
            {value} {name !== 'Clients' && 'MB'}
          </li>
        ))}
    </ul>
  </StyledTooltip>
);

const StyledBox = styled(Box)`
  margin-top: 2em;
  ${(props) =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `};
  ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledLoader = styled(LoaderLine)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledCard = styled(Card)`
  position: relative;
  border-left: ${(props) => props.theme.cardLeftBorder};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

class NetworkCard extends React.Component {
  state = {
    resolutions: ['month', 'day', 'hour'],
  };
  render() {
    const { resolutions } = this.state;
    const {
      hidden,
      device,
      onDateChange,
      start,
      end,
      resolution,
      mined,
      loading,
      theme,
    } = this.props;

    const title = 'Data Usage';
    if (!device) return <SkeletonLoadingCard title={title} {...this.props} />;

    const usageLabel = 'Data Usage';
    const speedLabel = 'Average Speed Mb/s';
    const clientsLabel = 'Clients';
    // const vnStatLabel = 'Vnstat';
    //console.log(mined);

    return (
      <StyledBox hidden={hidden} p={'.2em'} flex="1 1 auto" width={[1]}>
        <StyledCard title={title} style={{ position: 'relative' }}>
          <Caption>{title}</Caption>
          {loading && <StyledLoader loading />}
          {/*!sensor && <Button onClick>Enable data collecting</Button>*/}
          <StyledFlex flexDirection="column" alignItems="flex-start">
            <ControlledChart
              data={mined}
              dataKey="formattedDate"
              start={start}
              end={end}
              onDateChange={onDateChange}
              // onResolutionChange={this.handleResolutionChange}
              resolution={resolution}
              resolutions={resolutions}
              tooltip={renderTooltip}
            >
              <YAxis
                isAnimationActive={false}
                yAxisId="clients"
                orientation="right"
                allowDecimals={false}
              />
              <Bar
                isAnimationActive={false}
                dataKey={usageLabel}
                fill={theme.chartBarFillColor}
              />
              <Line
                connectNulls={false}
                isAnimationActive={false}
                dataKey={speedLabel}
                stroke={theme.chartStrokeColor}
                fill="#2D2D2D"
              />
              <Line
                connectNulls={false}
                yAxisId="clients"
                isAnimationActive={false}
                dataKey={clientsLabel}
                stroke="#039be5"
                strokeWidth="1px"
              />
              {/*<Bar
                      isAnimationActive={false}
                      dataKey={vnStatLabel}
                      fill="#00FF"
                    />*/}
            </ControlledChart>
          </StyledFlex>
        </StyledCard>
      </StyledBox>
    );
  }
}

export default withTheme(NetworkCard);
