import gql from 'graphql-tag';

export const commonTagInfoFragment = gql`
  fragment commonTagInfo on Tag {
    id
    name
    type
    meta
    hasChildren
    tagParents
  }
`;

export const commonTagTreeInfoFragment = gql`
  fragment commonTagTreeInfo on TagTree {
    id
    name
    type
    hasChildren(type: $type)
  }
`;
