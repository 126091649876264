import styled from 'styled-components';

/**
 * @render react
 * @name Label
 * @description Label component
 * @example
 * <Label>Label</Label>
 */
const Label = styled.label`
  font-family: ${props => props.theme.primaryFont};
  color: ${props =>
    props.error ? props.theme.errorColor : props.theme.labelColor};
  text-transform: ${props => props.theme.labelTransform};
  font-size: 1em;
  line-height: 2em;
`;

Label.defaultProps = {
  theme: {
    labelColor: '#327EA7',
    labelTransform: 'uppercase',
  },
};

export default Label;
