import gql from 'graphql-tag';

export const userInfoFragment = gql`
  fragment userInfo on User {
    id
    email
    displayName
    language
    phoneNumber
    role
    uiNotifications
    acl {
      path
      role
      name
    }
    claims {
      userTags
      role
    }
  }
`;
