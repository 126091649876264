import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import styled from 'styled-components';
import { FormSpy } from 'react-final-form';

import Block from '../../atoms/Block';
import PageTemplate from '../../templates/PageTemplate';
import FlexibleForm from '../../organisms/FlexibleForm';
import { FormItemPane } from '../../organisms/ItemPane';

const StyledBlock = styled(Block)`
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints ? theme.breakpoints[0] : '40em'}) {
    padding: 0.4em;
  }
`;

function DashboardFlexibleFormPage({
  title,
  subtitle,
  onSubmit,
  onBack,
  loading,
  saving,
  onRemoveClick,
  onCopyClick,
  copyTitle,
  children,
  initialValues,
  titleKey,
  mutators,
  decorators,
  validate,
  hideBanner,
}) {
  return (
    <FlexibleForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      mutators={mutators}
      decorators={decorators}
      validate={validate}
    >
      {form => (
        <PageTemplate
          actionsPane={
            <FormItemPane
              title={title}
              titleKey={titleKey}
              subtitle={subtitle}
              onSaveClick={form.handleSubmit}
              onBackClick={onBack}
              onRemoveClick={onRemoveClick}
              copyTitle={copyTitle}
              onCopyClick={onCopyClick}
              loading={saving || loading}
            />
          }
          hideBanner={hideBanner}
        >
          <StyledBlock>{children(form)}</StyledBlock>
        </PageTemplate>
      )}
    </FlexibleForm>
  );
}

DashboardFlexibleFormPage.propTypes = {
  children: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onBack: PropTypes.func,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  onCopyClick: PropTypes.func,
  initialValues: PropTypes.object,
  mutators: PropTypes.object,
  titleKey: PropTypes.string,
};

export default DashboardFlexibleFormPage;
