import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import InputLabel from '../../atoms/InputLabel';
import Error from '../../atoms/Error';
import InputWrapper from '../../atoms/InputWrapper';
import { composeValidators } from '../../utils';
import MultiTagTypeSelect from '../../molecules/MultiTagTypeSelect';
import Hint from '../utils/Hint';

const MultiTagTypeSelectField = ({
  name,
  validate,
  label,
  validateOnInit,
  types,
  onChange,
  required,
  optional,
  disabled,
}) => (
  <Field name={name} validate={composeValidators(validate)}>
    {({ input, meta }) => {
      return (
        <InputWrapper>
          {label && <InputLabel htmlFor={input.name}>{label}</InputLabel>}
          <MultiTagTypeSelect
            {...input}
            types={types}
            justifyContent="center"
            onChange={event => {
              if (onChange) onChange(event);
              input.onChange(event);
            }}
            disabled={disabled}
          />
          {(meta.touched || validateOnInit) && meta.error && (
            <Error id={`${input.name}Error`} role="alert" palette="danger">
              {meta.error}
            </Error>
          )}
          {!((meta.touched || validateOnInit) && meta.error) && required && (
            <Hint role="info">Required</Hint>
          )}
          {!((meta.touched || validateOnInit) && meta.error) && optional && (
            <Hint role="info">Optional</Hint>
          )}
        </InputWrapper>
      );
    }}
  </Field>
);

MultiTagTypeSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

MultiTagTypeSelectField.defaultProps = {
  label: null,
  validate: () => {},
};

export default MultiTagTypeSelectField;
