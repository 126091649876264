import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { YAxis, Bar, Line } from 'recharts';
import { withTheme } from 'styled-components';

import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import LoaderLine from '@atoms/LoaderLine';
import SkeletonLoadingCard from '@components/cards/SkeletonLoadingCard';
import ControlledChart from '@organisms/ControlledChart';

const StyledBox = styled(Box)`
  margin-top: 2em;
  ${(props) =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `};
  ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledLoader = styled(LoaderLine)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledCard = styled(Card)`
  position: relative;
  border-left: ${(props) => props.theme.cardLeftBorder};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;
/*
const Composed = adopt({
  MovementQuery: ({ params, render }) =>
    params.movementVariables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery(['count', 'date', 'startTime', 'endTime', 'sum'])}
        variables={params.movementVariables}
      >
        {({ loading, error, data }) =>
          render({
            l1: loading,
            error,
            movementData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  CO2Query: ({ params, render }) =>
    params.co2Variables.filter.pointId ? (
      <Query
        cache="no-cache"
        query={getMineQuery([
          'count',
          'startValue',
          'date',
          'startTime',
          'endTime',
        ])}
        variables={params.co2Variables}
      >
        {({ loading, error, data }) =>
          render({
            l2: loading,
            error,
            co2Data: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
});
*/
class PresenceGraphCard extends React.Component {
  state = {
    resolutions: ['month', 'day', 'hour'],
  };

  render() {
    const { resolutions } = this.state;
    const {
      hidden,
      device,
      onDateChange,
      start,
      end,
      resolution,
      mined,
      loading,
      theme,
    } = this.props;

    const title = 'Presence Data';
    if (!device) return <SkeletonLoadingCard title={title} {...this.props} />;

    return (
      <StyledBox hidden={hidden} p={'.2em'} flex="1 1 auto" width={[1]}>
        <StyledCard title={title} style={{ position: 'relative' }}>
          <Caption>{title}</Caption>
          {loading && <StyledLoader loading />}

          <StyledFlex flexDirection="column" alignItems="flex-start">
            <ControlledChart
              data={mined}
              dataKey="date"
              start={start}
              end={end}
              onDateChange={onDateChange}
              //onResolutionChange={this.handleResolutionChange}
              resolution={resolution}
              resolutions={resolutions}
            >
              <YAxis
                isAnimationActive={false}
                tick={false}
                orientation="right"
              />
              <Bar
                isAnimationActive={false}
                dataKey="movement"
                fill={theme.chartBarFillColor}
              />
              <Line
                connectNulls={resolution === 'minute'}
                isAnimationActive={false}
                dataKey="co2"
                stroke={theme.chartLineStrokeColor}
              />
            </ControlledChart>
          </StyledFlex>
        </StyledCard>
      </StyledBox>
    );
  }
}

export default withTheme(PresenceGraphCard);
