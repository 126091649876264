import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * @render react
 * @name Thumbnail
 * @description Thumbnail component
 * @example
 * <Thumbnail>Thumbnail</Thumbnail>
 */
const StyledOverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.1);
`;
const StyledContainer = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  margin: auto;
  @media (max-width: 1024px) {
    width: 120px;
    height: 120px;
  }
  @media (max-width: 768px) {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`;
const StyledModal = styled.div`
  display: ${props => {
    if (props.zoomed) {
      return 'block';
    } else {
      return 'none';
    }
  }}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 15%;
  top: 10%;
  width: 70%; /* Full width */
  height: 90%; /* Full height */
  overflow: none; /* Enable scroll if needed */
  background-color: rgb(23, 23, 23); /* Fallback color */
  background-color: rgba(23, 23, 23, 0.9); /* Black w/ opacity */
  @media (max-width: 1024px) {
    left: 5%;
    top: 10%;
    width: 90%; /* Full width */
    height: 90%; /* Full height */
  }
  @media (max-width: 768px) {
    left: 0%;
    top: 0%;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
  }
`;
const StyledClose = styled.span`
  position: absolute;
  top: 15px;
  right: 35px;
  color : ${({ theme }) => theme.secondaryThemeColor}
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
  :hover {
    color : ${({ theme }) => theme.tertiaryThemeColor}
    text-decoration: none;
    cursor: pointer;
  }
`;
const StyledCaption = styled.div`
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: ${({ theme }) => theme.tertiaryThemeColor};
  font: ${({ theme }) => theme.primaryFont};
  padding: 20px 0;
  height: 150px;
`;
const StyledImg = styled.img`
  margin: 5% auto 0 auto;
  display: block;
  width: 80%;
  max-width: 700px;
  max-height: 700px;
  object-fit: contain;
  border-radius: 5px;
`;
const StyledThumbnail = styled.img`
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
  :hover {
    opacity: 0.7;
  }
`;

const Thumbnail = ({ src, alt, caption, width, height }) => {
  const [zoomed, setZoomed] = useState(false);

  const handleZoom = () => {
    setZoomed(!zoomed);
  };

  return (
    <StyledContainer id="container" width={width} height={height}>
      <StyledThumbnail
        id="thumbnail"
        src={src}
        alt={alt}
        onClick={handleZoom}
      />
      {zoomed && (
        <StyledOverLay onClick={handleZoom}>
          <StyledModal
            id="modal"
            zoomed={zoomed}
            onClick={e => e.stopPropagation()}
          >
            <StyledClose onClick={handleZoom}>&times;</StyledClose>
            <StyledImg id="img" src={src} alt={alt} />
            <StyledCaption id="caption">{caption}</StyledCaption>
          </StyledModal>
        </StyledOverLay>
      )}
    </StyledContainer>
  );
};

Thumbnail.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  caption: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
};

Thumbnail.defaultProps = {
  alt: 'Thumbnail',
  caption: 'Caption',
  width: '168px',
  height: '150px',
  theme: {},
};

export default Thumbnail;
