import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import CaptionCard from '../../molecules/CaptionCard';
import Skeleton from '../../atoms/Skeleton';

const StyledBox = styled(props => <Box {...props} />)`
  ${props =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `} ${props =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const StyledCard = styled(CaptionCard)`
  position: relative;
  height: ${props => (props.zoomed ? '100%' : '400px')};
  min-height: 400px;
`;

class SkeletonLoadingCard extends React.Component {
  render() {
    const { zoomed, hidden, title, width } = this.props;

    return (
      <StyledBox
        hidden={hidden}
        p={'.2em'}
        flex="1 1 auto"
        width={width || [1, null, 1 / 2]}
      >
        <StyledCard title={title} zoomed={zoomed}>
          <StyledFlex flexDirection="column" alignItems="flex-start">
            <Box width={1} flex="1 1 auto">
              <Skeleton
                block
                height={'1.5em'}
                count={3}
                width={['100%', '50%', '25%']}
              />
            </Box>
            <Flex width={'100%'} justifyContent="flex-end">
              <Skeleton count={2} inline height="2em" width="8em" />
            </Flex>
          </StyledFlex>
        </StyledCard>
      </StyledBox>
    );
  }
}

SkeletonLoadingCard.propTypes = {};

export default SkeletonLoadingCard;
