import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ListItem from '../../atoms/ListItem';
import List from '../../molecules/List';
import Input from '../../atoms/Input';

const StyledItem = styled(props => <ListItem {...props} />)`
  &:first-child {
    border-top-left-radius: ${props => props.theme.primaryBorderRadius};
    border-bottom-left-radius: ${props => props.theme.primaryBorderRadius};
  }
  


  &:last-child {
    border-top-right-radius: ${props => props.theme.primaryBorderRadius};
    border-bottom-right-radius: ${props => props.theme.primaryBorderRadius};
  }

  display: inline;
  position: relative;

  background-color: ${props =>
    props.disabled
      ? props.theme.primaryDisabledBackground

        : props.theme.primaryBackground};

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  color: ${props =>
    props.disabled
      ? props.theme.primaryDisabledColor
      : props.theme.primaryButtonTextColor};

  && {
    padding: 0.2em 1em !important;
    line-height: 1.4;
    text-decoration: none;
  }
`;

const StyledList = styled(props => <List {...props} />)`
  display: flex;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 0;
  justify-content: center;
`;
/**
 * @render react
 * @name Paginate
 * @description Paginate component
 * @example
 * <Paginate />
 */
class Paginate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      after: 0
    };
  }


  handleKeyPress(key, cb) {
    if (key === 'Enter') {
      if (isNaN(parseInt(this.state.page, 10))) {
        return;
      } else {
        if (parseInt(this.state.page, 10) < 1) {
          cb('1');
        } else if (parseInt(this.state.page, 10) > pages) {
          cb(pages.toString());
        } else {
          cb(this.state.page);
        }
      }
    }
  }

  render() {
    const { start, end, hasNext, limit, onChange, ...props } = this.props;
    let {after, page} = this.state;


    if (start === 0 && !hasNext) {
      // don't display pager if there is only 1 page
      return null;
    }

    return (
      <StyledList {...props}>
        <StyledItem
          {...props}
          disabled={this.state.after === 0}
          onClick={() => {
            event.preventDefault();
            onChange((after - limit).toString());
            this.setState({after: after - limit, page: --page});
          }}
        >
          &larr;
        </StyledItem>
        <StyledItem
          {...props}
        >
          {this.state.page}
        </StyledItem>

        <StyledItem
          {...props}
          disabled={!hasNext}
          onClick={(event) => {
            event.preventDefault();
            onChange((after + limit).toString())
            this.setState({after: after + limit, page: ++page});
        }}
        >
          &rarr;
        </StyledItem>
      </StyledList>
    );
  }
}

Paginate.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  hasNext: PropTypes.bool,
  limit: PropTypes.number,
  onChange: PropTypes.func,
};

Paginate.defaultProps = {
  start: 0,
  end: 20,
  hasNext: true,
  limit: 20
};

export default Paginate;
