import gql from 'graphql-tag';

import { userInfoFragment } from '../fragments/users';

export const UPDATE_USER = gql`
  mutation updateUser($id: String!, $input: UserUpdateInput!) {
    updateUser(id: $id, input: $input) {
      ...userInfo
    }
  }
  ${userInfoFragment}
`;

export const CREATE_USER = gql`
  mutation Mutation($input: UserCreateInput!) {
    createUser(input: $input) {
      ...userInfo
    }
  }
  ${userInfoFragment}
`;
export const UPDATE_USER_PASSWORD = gql`
  mutation changePassword($id: String!, $password: String!) {
    changePassword(id: $id, password: $password) {
      ...userInfo
    }
  }
  ${userInfoFragment}
`;
