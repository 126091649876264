import React from "react";
import styled from "styled-components";

const BarContainer = styled.div`
  margin: 30px 0;
`;

const ProgressBarDot = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2.5%;
  background-color: #bbb;
  &.active {
    background-color: ${props => props.theme.tertiaryThemeColor || props.theme.primaryThemeColor};
  }
`;

const ProgressBar = props => {
  let maxSteps = props.maxSteps;
  const steps = [];
  const currentStep = props.currentStep;
  for (let i = 0; i < maxSteps; i++) {
    steps.push(i > currentStep - 1 ? false : true);
  }
  return (
    <BarContainer>
      {steps.map((active, index) => (
        <ProgressBarDot key={index} className={`${active ? "active" : ""}`} />
      ))}
    </BarContainer>
  );
};
export default ProgressBar;
