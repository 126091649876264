import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { Flex, Box } from '@rebass/grid';

import CircleButton from '../../atoms/CircleButton';
import Block from '../../atoms/Block';
import Icon from '../../atoms/Icon';
import Label from '../../atoms/Label';
import Hide from '../../atoms/Hide';
import Search from '../../molecules/Search';
import SidebarButton from '../../atoms/SidebarButton';

const StyledBlock = styled(Block)`
  background: ${({ theme }) => theme.panelBackground};
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${props =>
      _get(props, 'theme.breakpoints[0]', '40em')}) {
    padding: 0.4em;
  }
`;

const StyledLabel = styled(Label)`
  text-transform: none;
  color: ${({ theme }) => theme.subtitleColor};
  font-weight: bold;
  margin-right: 0.75em;
`;

const StyledButtonFlex = styled(Flex)`
  cursor: pointer;
`;

/**
 * @render react
 * @name SearchPane
 * @description SearchPane component
 * @example
 * <SearchPane />
 */
const SearchPane = ({
  loading,
  defaultValue,
  onChange,
  filterable,
  defaultFilters,
  actions,
  searchComponents,
  timestamp,
  tagsSidebar = {},
  ...props
}) => {
  return (
    <StyledBlock>
      <Flex flexWrap="wrap" alignItems="center">
        {searchComponents && searchComponents({ onChange, timestamp })}
        {tagsSidebar.showTagsSidebar && (     <Flex width={[1, 1, 'auto']}>
          <SidebarButton {...tagsSidebar}/>
        </Flex>)}
        <Flex ml={3} flex="1">
          <Box flex="1">
            <Search
              onChange={onChange}
              defaultValue={defaultValue}
              defaultFilters={defaultFilters}
              filterable={filterable}
            />
          </Box>
          {actions.map((action, i) => (
            <Box flex="0 1 auto" key={`searchpane-actions-${i}`}>
              <StyledButtonFlex
                alignItems="center"
                justifyContent="flex-end"
                onClick={action.onClick}
              >
                <Box>
                  <StyledLabel>{action.title}</StyledLabel>
                </Box>
                <Hide xs>
                  <Box>
                    <CircleButton>{action.icon}</CircleButton>
                  </Box>
                </Hide>
              </StyledButtonFlex>
            </Box>
          ))}
        </Flex>
      </Flex>
    </StyledBlock>
  );
};
SearchPane.propTypes = {
  value: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
      order: PropTypes.number,
      Icon: PropTypes.instanceOf(Icon),
    }).isRequired
  ),
  onChange: PropTypes.func,
  tagsSidebar: PropTypes.shape({
    buttonText: PropTypes.string,
    isOpened: PropTypes.boolean,
    onTagsSidebarClick: PropTypes.func,
    showTagsSidebar: PropTypes.boolean
  })
};

SearchPane.defaultProps = {
  actions: [],
  theme: {
    panelBackground: '#d8d8d8',
    subtitleColor: '##9d9d9d',
  },
};

export default SearchPane;
