import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
// import qs from 'qs';
import Cookies from 'universal-cookie';
import { authLib } from './authentication';
import { xAuthMode } from './AppRunMode';
const cookies = new Cookies();

// if needed, need to implement to check domain. to aavoid credentaials leaks
// let api = qs.parse(window.location.search).api;
let api;
let xTag = cookies.get('x-tag');
let xRole = cookies.get('x-role');
let xAdmin = cookies.get('x-admin');
let xApp = localStorage.getItem('x-app');

try {
  if (!api && window.Cypress) {
    /**Cypress configuration, used only when in Cypress context**/
    api = Cypress.env().REACT_APP_CONNECTAPI;
  }
} catch (e) {
  // Do nothing
}
if (!api) {
  api = process.env.REACT_APP_CONNECTAPI;
}
if (!api) {
  api = `${window.location.protocol}//${window.location.host}/api`;
}
const uri = `${api}/graphql`;

const httpLink = createHttpLink({
  uri: uri,
});

const authLink = setContext(({ operationName }, { headers }) => {
  if (operationName === 'refreshIdToken') return headers;
  return authLib.getIdToken(false).then((idToken) => {
    let authHeaders = {
      'x-auth': idToken ? idToken : '',
      'x-auth-type': 'user',
    };
    if (xAuthMode === 'localAuth') {
      authHeaders['x-local-auth'] = '1';
    }
    if (xTag) {
      authHeaders['x-tag'] = xTag;
      if (xRole) authHeaders['x-role'] = xRole;
      if (xAdmin) authHeaders['x-admin'] = '1';
      if (xApp) authHeaders['x-app'] = xApp;
    }
    return {
      headers: {
        ...authHeaders,
        ...headers,
      },
    };
  });
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      if (message === 'forbidden') {
        cookies.remove('x-tag', {
          path: '/',
        });
        cookies.remove('x-role', {
          path: '/',
        });
        console.log(`[GraphQL error]: ${message}`);
        setTag(null);
        if (window.location.pathname !== '/error') {
          window.location.replace('/error');
        }
      }
    });
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

const setTag = (newTag, role) => {
  if (!newTag) {
    cookies.remove('x-tag', {
      path: '/',
    });
    cookies.remove('x-role', {
      path: '/',
    });
  } else {
    cookies.set('x-tag', newTag, { path: '/' });
    if (role) {
      cookies.set('x-role', role, { path: '/' });
    }
  }
  xTag = newTag;
  xRole = role;
};

const getTag = () => {
  return xTag;
};

export { apolloClient, setTag, getTag };
