import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import _get from 'lodash/get';
import { useMutation } from '@apollo/react-hooks';
import { INSTALL_DEVICE, UPDATE_DEVICE } from '../../../api/mutations/devices';
import ProgressBar from '@organisms/Install/ProgressBar';
import Button from '@atoms/Button';
import Input from '@atoms/Input';
import LoaderCube from '@atoms/LoaderCube';
import Error from '@atoms/Error';
import ErrorPage from '@organisms/Install/pages/Error';
import { countries } from '../../../defaults';
import { createSsid } from '../../../utils/ssid';
import {
  validSSID,
  required,
  passwordLengthMax,
  passwordLengthMin,
} from '@components/utils';

const ConfirmContainer = styled.div`
  text-align: center;
  max-width: 60em;
`;

const Title = styled.div`
  color: ${(props) => props.theme.primaryThemeColor};
  font-size: 2.5em;
  margin: 1em 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.4em;
  margin-top: 2em;
`;

const DataGridContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
`;

const DataGridItem = styled.div`
  text-transform: capitalize;
  color: #707070;
  font-size: 1.25em;
  text-align: left;
  padding-top: 10px;
`;

const DataGridInput = styled(Input)`
  text-align: left;
  margin-top: 10px;
`;

const DataGridError = styled(Error)`
  text-align: right;
`;

const Warning = styled.div`
  font-size: 1.25em;
  margin: 0 0 1em 0;
  color: ${(props) => props.theme.internetDisabledColor};
`;
const Confirm = ({
  step,
  nextPage,
  prevPage,
  device,
  maxSteps,
  tagObjects: { organization },
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [networkname, setNetworkname] = useState('');
  const [password, setPassword] = useState('');
  const [networknameError, setNetworknameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);

  /*const { data: tagData, error: tagError, loading: tagLoading } = useQuery(
    GET_APARTMENT,
    {
      variables: { id: _get(device, 'meta.apartmentId') },
      context: { headers: { 'x-tag': getSelectedTag() } },
      skip: !_get(device, 'meta.apartmentId'),
    }
  );
  useEffect(() => {
    if (tagError)
      setError(
        'An error has occured during installation, please contact RoomRobot support.' +
          tagError.message
      );
  }, [tagError]);
*/
  const ssidPrefix = _get(
    organization,
    'meta.public.defaultSsidPrefix',
    'RoomRobot'
  );
  const defaultWifiPassword = _get(
    organization,
    'meta.public.defaultWifiPassword',
    `RoomRobot${new Date().getFullYear()}`
  );
  useEffect(() => {
    setNetworkname(
      `${ssidPrefix} ${createSsid(_get(device, 'meta.apartment'))
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')}`
    );
    setPassword(defaultWifiPassword);
  }, [defaultWifiPassword, device, ssidPrefix]);

  const [installDevice] = useMutation(INSTALL_DEVICE);
  const [updateDevice] = useMutation(UPDATE_DEVICE);
  const ConfirmInstallation = async (inputDevice) => {
    setLoading(true);

    const orgSettings = _get(inputDevice, 'settings.data', {});
    const orgSettingsWlan = _get(
      inputDevice,
      'settings.data.huawei-router.wlan',
      {}
    );
    installDevice({
      variables: {
        id: inputDevice.id,
        input: {
          name: inputDevice.name,
          tag: inputDevice.tag,
          meta: inputDevice.meta,
          replaceDevices: inputDevice.replaceDevices,
        },
      },
    })
      .then(() => {
        updateDevice({
          variables: {
            id: inputDevice.id,
            input: {
              settings: {
                ...orgSettings,
                'huawei-router': {
                  wlan: {
                    ...orgSettingsWlan,
                    state: true,
                    networkname,
                    password,
                  },
                },
              },
            },
          },
        });
      })
      .then(() => {
        setError(null);
        setLoading(false);
        nextPage();
      })
      .catch((e) => {
        setLoading(false);
        setError(
          'An error has occured during installation, please contact RoomRobot support.' +
            e && e.message
        );
      });
  };

  return (
    <React.Fragment>
      {loading && <LoaderCube />}
      {!loading && !error && (
        <ConfirmContainer>
          <ProgressBar maxSteps={maxSteps} currentStep={step} />
          <Title>Install Roomrobot?</Title>
          {_get(device, 'warning') && (
            <Warning>{_get(device, 'warning')}</Warning>
          )}
          {_get(device, 'replaceDevices', []).length > 0 && (
            <Warning>
              The following devices will be deactivated and internet
              subscription will be suspended:{' '}
              {_get(device, 'replaceDevices').join(',')}
            </Warning>
          )}
          <DataGridContainer>
            {_get(device, 'meta.apartment.id') && (
              <React.Fragment>
                <DataGridItem>Aparment Id:</DataGridItem>
                <DataGridItem>{_get(device, 'meta.apartment.id')}</DataGridItem>
              </React.Fragment>
            )}
            <DataGridItem>Name:</DataGridItem>
            <DataGridItem>{device.name}</DataGridItem>
            <DataGridItem>Address:</DataGridItem>
            <DataGridItem>
              {_get(device, 'meta.apartment.address')}
            </DataGridItem>
            <DataGridItem>country:</DataGridItem>
            <DataGridItem>
              {
                countries[
                  _get(device, 'meta.apartment.countryCode', 'fi').toUpperCase()
                ]
              }
            </DataGridItem>
            {_get(device, 'meta.apartment.officeName') && (
              <React.Fragment>
                <DataGridItem>area:</DataGridItem>
                <DataGridItem>
                  {_get(device, 'meta.apartment.officeName')}
                </DataGridItem>
              </React.Fragment>
            )}

            <DataGridItem style={{ paddingTop: '20px' }}>
              Wifi SSID:
            </DataGridItem>
            <DataGridInput
              value={networkname}
              onChange={(e) => {
                setNetworkname(
                  e.target.value
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                );
                setNetworknameError(
                  required(e.target.value) ||
                    validSSID(
                      e.target.value
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                    )
                );
              }}
            />
            {networknameError && (
              <React.Fragment>
                <DataGridError />
                <DataGridError>{networknameError}</DataGridError>
              </React.Fragment>
            )}
            <DataGridItem style={{ paddingTop: '20px' }}>
              Wifi Password:
            </DataGridItem>
            <DataGridInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordError(
                  required(e.target.value) ||
                    passwordLengthMin(
                      e.target.value || passwordLengthMax(e.target.value)
                    )
                );
              }}
            />
            {passwordError && (
              <React.Fragment>
                <DataGridError />
                <DataGridError>{passwordError}</DataGridError>
              </React.Fragment>
            )}
          </DataGridContainer>

          <ButtonContainer>
            <Button
              style={{ marginRight: '30px' }}
              secondary
              onClick={prevPage}
            >
              Previous
            </Button>
            <Button
              style={{ marginLeft: '30px' }}
              onClick={() => ConfirmInstallation(device)}
              disabled={passwordError || networknameError}
            >
              Confirm
            </Button>
          </ButtonContainer>
        </ConfirmContainer>
      )}
      {error && (
        <ErrorPage
          errorMessage={error}
          onPrev={() => setError(null)}
          onDone={() => prevPage()}
        />
      )}
    </React.Fragment>
  );
};

export default Confirm;
