import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

import InputLabel from '../../atoms/InputLabel';
import Input from '../../atoms/Input';
import Error from '../../atoms/Error';
import InputWrapper from '../../atoms/InputWrapper';
import { composeValidators } from '../../utils';

const CheckboxField = ({
  name,
  validate,
  label,
  big,
  value,
  onChange,
  disabled,
  validateOnInit,
  resources,
}) => (
  <Field
    type="checkbox"
    value={value}
    name={name}
    validate={composeValidators(validate)}
  >
    {({ input, meta }) => (
      <InputWrapper data-cy={name}>
        <InputLabel disabled={disabled}>
          <Input
            {...input}
            data-cy={`${resources}-${label}-cb`}
            type="checkbox"
            big={big}
            disabled={disabled}
            onChange={event => {
              if (onChange) onChange(event);
              input.onChange(event);
            }}
            invalid={(meta.touched || validateOnInit) && meta.error}
          />
          {label}
        </InputLabel>
        {(meta.touched || validateOnInit) && meta.error && (
          <Error id={`${input.name}Error`} role="alert" palette="danger">
            {meta.error}
          </Error>
        )}
      </InputWrapper>
    )}
  </Field>
);

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  validateOnInit: PropTypes.bool,
};

CheckboxField.defaultProps = {
  label: null,
  onChange: null,
  validate: () => {},
};

export default CheckboxField;
