import React from 'react';
import styled from 'styled-components';

import Toast from '../../molecules/Toast';

const ToastContainer = styled.div`
  width: 350px;
  padding: 15px;
  display: ${props => (props.toastCount > 0 ? 'block' : 'none')};
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
    
  &.top-left {
    top: 10px;
    left: 10px;
  }
  
   &.top-center {
    top: 10px;
    text-align: center;
  }
  
  &.top-right {
    top: 10px;
    right: 10px;  
  }
  
  &.bottom-left {
    bottom: 10px;
    left: 10px;
  }
  
  &.bottom-right {
    bottom: 10px;
    right: 10px;
  }
}
`;

/**
 * @render react
 * @name Toaster
 * @description Toaster component for toast rendering
 * @example
 * <div>
    <button
      onClick={() => {
        this.value.addToast({
          title: "Heading",
          message: "Message",
          type:
            Math.random() < 0.5
              ? Math.random() < 0.5
                ? "warning"
                : "error"
              : Math.random() < 0.5
                ? "success"
                : null
        });
      }}
    >
      Add toast
    </button>
    <Toaster ref={ref => (this.value = ref)} />
  </div>
 */
class Toaster extends React.Component {
  state = {
    toastsArray: [],
  };

  positionMap = {
    1: 'top-left',
    2: 'top-right',
    3: 'bottom-right',
    4: 'bottom-left',
  };
  timers = [];
  componentWillUnmount = () => {
    this.timers.forEach(t => clearTimeout(t));
  };

  addToast = toastObject => {
    const toast = { ...toastObject, toastEnter: true };
    toastObject && this.setState(({ toastsArray }) => toastsArray.push(toast));

    const timer = setTimeout(
      () => this.removeToast(this.state.toastsArray.indexOf(toast)),
      toast.timeout ? toast.timeout * 1000 : 5000
    );

    setTimeout(() => {
      toast.toastEnter = false;
      this.setState(({ toastsArray }) => toastsArray);
    }, 600);

    this.timers.push(timer);
  };

  removeToast = index => {
    const { toastsArray } = this.state;
    if (!toastsArray[index]) return;
    toastsArray[index].isDeleted = true;
    this.setState({ toastsArray });

    const timer = setTimeout(() => {
      toastsArray.splice(index, 1);
      this.setState({ toastsArray });
    }, 600);
    this.timers.push(timer);
  };

  clearAllToast = () => {};

  render() {
    const { toastsArray } = this.state;
    const position = this.positionMap[this.props.position] || 'top-right';

    return (
      <ToastContainer className={position} toastCount={toastsArray.length}>
        {toastsArray.map((toast, index) => {
          return (
            <Toast
              index={index}
              key={index}
              title={toast.title}
              type={toast.type}
              message={toast.message}
              isDeleted={toast.isDeleted}
              toastEnter={toast.toastEnter}
              onClick={this.removeToast}
            />
          );
        })}
      </ToastContainer>
    );
  }
}

Toaster.propTypes = {};

Toaster.defaultProps = {
  theme: {},
};

export default Toaster;
