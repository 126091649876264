import styled from "styled-components";

/**
 * @render react
 * @name HorizontalRule
 * @description HorizontalRule component
 * @example
 * <HorizontalRule/>
 */
const HorizontalRule = styled.hr`
	border: 1px solid ${props => props.theme.horizontalRuleColor};
	border-width: ${props => props.theme.horizontalRuleBorder};
	// width: 100%;
	margin: ${props => props.theme.horizontalRuleMargin};
`;

HorizontalRule.defaultProps = {
	theme: {
		horizontalRuleColor: "#DBDBDB",
		horizontalRuleBorder: "0 0 2",
		horizontalRuleMargin: "0"
	}
};

export default HorizontalRule;
