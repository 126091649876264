import { useState, useEffect } from 'react';
import { getIdToken } from '../../connect-react-lib';
import { useQuery } from '@apollo/react-hooks';
import { GET_DEVICE } from '../../api/queries/devices';

import connectComm from '@livion/connect2-communication';

export default function useRealtimeDevice(data, fetchDevice = false) {
  const [device, setDevice] = useState({});
  const [connected, setConnected] = useState(null);
  const [deviceData, setDeviceData] = useState(fetchDevice ? null : data);
  const [error, setError] = useState();

  const { data: queryData, error: queryError } = useQuery(GET_DEVICE, {
    variables: { id: data.id },
    skip: !fetchDevice,
  });

  useEffect(() => {
    if (queryData && queryData.device) {
      setDeviceData(queryData.device);
    }

    if (queryError) setError(queryError);
  }, [queryData, queryError]);

  useEffect(() => {
    if (deviceData) {
      let d;
      let connectionStateChange = (state) => {
        if (connected !== state && !d.initializing) {
          d.connected = state;
          setConnected(state);
        }
      };
      async function run() {
        const authFunc = async () => {
          return await getIdToken();
        };
        console.log('REACT_APP_APPNAME', process.env.REACT_APP_APPNAME);
        const token = await getIdToken();
        d = new connectComm(
          token,
          deviceData.id,
          deviceData.socketServer,
          authFunc,
          process.env.REACT_APP_APPNAME
        );
        setDevice(d);
        d.on('connection-state-changed', connectionStateChange);
        d.attach();
        connectionStateChange(d.connected);
      }
      if (!deviceData) {
        console.log('Device not found');
        return;
      }
      run();

      return () => {
        if (d)
          d.removeListener('connection-state-changed', connectionStateChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData]);

  return {
    connected,
    loading: connected === null,
    device,
    deviceData,
    error,
  };
}
