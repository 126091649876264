import React from 'react';
import styled from 'styled-components';
import Heading from '../../atoms/Heading';
import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';

const PullRight = styled.div`
  padding: 1rem 1rem;
  text-align: right;
`;
const StyledOverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;
const StyledDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 20%;
  left: 20%;
  right: 20%;
  padding: 0px;
  z-index: 102;
  background-color: #fff;
  border-radius: 3px;
},
`;

const HeaderWrapper = styled.div`
  background-color: ${props => props.theme.panelBackground};
  color: ${props => props.theme.primaryThemeColor};
  padding: 1rem 1rem;
`;

const StyledClose = styled.div`
  color: ${props => props.theme.primaryThemeColor};
  float: right;
  cursor: pointer;
`;
const ChildrenWrapper = styled.div`
  padding: 1rem 1rem;
`;

const Modal = props => {
  const {
    header,
    subHeader,
    onClose,
    onSave,
    submitText,
    children,
    className,
    submitDisabled,
    closeColor,
  } = props;
  return (
    <StyledOverLay onClick={onClose}>
      <StyledDialogContent
        className={className}
        onClick={e => e.stopPropagation()}
      >
        {typeof header !== undefined && (
          <HeaderWrapper>
            <Heading>
              {header}
              {onClose && (
                <StyledClose onClick={onClose}>
                  <Icon icon="close" size="2em" color={closeColor} />
                </StyledClose>
              )}
            </Heading>
            {subHeader && <Heading level={2}>{subHeader}</Heading>}
          </HeaderWrapper>
        )}
        <ChildrenWrapper>
          {children}
          {onSave && (
            <PullRight>
              <Button onClick={onSave} disabled={submitDisabled}>
                {submitText}
              </Button>
            </PullRight>
          )}
        </ChildrenWrapper>
      </StyledDialogContent>
    </StyledOverLay>
  );
};

Modal.propTypes = {};

export default Modal;
