import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box as FBox, Flex } from '@rebass/grid';

import Block from '../../atoms/Block';
import Icon from '../../atoms/Icon';
import DeleteButton from '../DeleteButton';

const StyledRow = styled.div`
  width: 100%;
  text-align: left;
`;

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const Box = styled(FBox)`
  align-self: center;
`;
const Row = styled(Block)`
  font-family: ${props => props.theme.primaryFont};
  background: ${props =>
    props.selected
      ? props.theme.treeItemBackgroundSelected
      : props.theme.treeItemBackground};
  cursor: pointer;
  display: flex;
  padding: 0 1em;
  font-size: ${props => props.theme.nodeFontSize};
`;

const ToggleIcon = styled(Icon)`
  color: ${props => props.theme.toggleColor};
  margin: auto;
  transition: 0.1s ease-out;
  transform: ${props => (props.open ? 'rotateZ(45deg)' : 'rotateZ(0)')};
  transform-origin: 10px 8px;
  flex: 0 1 auto;
  fill: ${props => props.theme.tertiaryThemeColor};
`;

const Title = styled.div`
  flex: 1;
  padding: 10px 15px;
  font-weight: 700;
  font-size: ${props => props.theme.nodeFontSize};
  color: ${props => props.theme.secondaryThemeColor};
`;

const ChildContainer = styled.ul`
  margin: 0;
  height: ${props => (props.visible ? 'auto' : 0)};
  display: ${props => (props.visible ? 'block' : 'none')};
  overflow: ${props => {
    !props.showOverflow ? 'hidden' : 'visible';
  }};
`;

export class Accordion extends Component {
  state = {
    visible: this.props.open,
    title: this.props.title,
  };

  toggle = () => this.setState(({ visible }) => ({ visible: !visible }));
  changeTitle = title => this.setState({ title });

  render() {
    const {
      children,
      remove,
      title,
      renderTitle,
      customButton,
      disabled,
      ...rest
    } = this.props;
    const { visible, title: titleInState } = this.state;
    const { changeTitle, toggle } = this;
    return (
      <StyledRow {...rest}>
        <Row>
          <StyledFlex onClick={toggle}>
            <Box flex="0 1 auto">
              <ToggleIcon
                size={'1.5em'}
                icon={'chevron-right'}
                open={visible}
              />
            </Box>
            <Box flex={typeof titleInState === 'string' ? '1 0 auto' : '1'}>
              <Title className={'title'}>
                {renderTitle ? renderTitle() : titleInState}
              </Title>
            </Box>
          </StyledFlex>
          {customButton && <Box flex="0 1 auto">{customButton}</Box>}
          {remove && (
            <Box flex="0 1 auto">
              <DeleteButton disabled={disabled} onClick={remove} />
            </Box>
          )}
        </Row>
        <ChildContainer
          className={`${visible ? 'active ' : ''}content`}
          visible={visible}
        >
          {children && typeof children === 'function'
            ? children({ changeTitle })
            : children}
        </ChildContainer>
      </StyledRow>
    );
  }
}

Accordion.propTypes = {
  title: PropTypes.node,
  renderTitle: PropTypes.func,
};

export default Accordion;
