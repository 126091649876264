import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../../atoms/Icon';
import bar_0 from '../../images/grey.svg';
import bar_1 from '../../images/red.svg';
import bar_2 from '../../images/orange.svg';
import bar_3 from '../../images/yellow.svg';
import bar_4 from '../../images/green-1.svg';
import bar_5 from '../../images/green-2.svg';

const SignalIndicator = ({ level, size }) => {
  switch (level) {
    case '0':
      return <Icon svg={bar_0} size={size} />;
    case '1':
      return <Icon svg={bar_1} size={size} />;
    case '2':
      return <Icon svg={bar_2} size={size} />;
    case '3':
      return <Icon svg={bar_3} size={size} />;
    case '4':
      return <Icon svg={bar_4} size={size} />;
    case '5':
      return <Icon svg={bar_5} size={size} />;
    default:
      return <Icon svg={bar_0} size={size} />;
  }
};

SignalIndicator.propTypes = {
  level: PropTypes.string.isRequired,
};

export default SignalIndicator;
