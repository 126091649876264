import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import LoaderCube from '../../atoms/LoaderCube';

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import get from 'lodash/get';
import filter from 'lodash/filter';

import Icon from '../../atoms/Icon';
import Button from '../../atoms/Button';
import Heading from '../../atoms/Heading';
import Label from '../../atoms/Label';

const StyledIcon = styled(Icon)`
  width: 2em;
  fill: ${({ theme }) => theme.inputBorder};
`;

const StyledCube = styled(LoaderCube)`
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

export const GET_ACL = gql`
  query {
    verifyLogin {
      id
      acl {
        path
        role
      }
    }
  }
`;

export const GET_TAGS = gql`
  query tags($filter: TagFilter) {
    tags(filter: $filter) {
      edges {
        id
        name
        type
        tagParents
      }
    }
  }
`;

const Organizations = ({ onClick, filteredAcl, getOrganization }) => {
  let organizations = [];
  const { data, error, loading } = useQuery(GET_TAGS, {
    context: {
      headers: {
        'x-tag': '',
      },
    },
    variables: {
      filter: { id: { $in: filteredAcl.map(acl => acl.path) } },
    },
  });
  if (loading || !data) {
    return null;
  }
  if (data && data.tags) {
    filteredAcl.forEach(({ path }) => {
      const tag = data.tags.edges.find(edge => edge.id === path);
      const organization = getOrganization(tag);
      if (organization)
        organizations.push({ name: organization.name, tag: tag.id });
    });
  }
  return organizations.map((organization, index) => (
    <Box
      key={`organizations-${index}`}
      width={1}
      onClick={() => onClick(organization.tag)}
    >
      <StyledButton>
        <Flex justifyContent="space-between">
          {organization.name}
          <StyledIcon icon="chevron-right" />
        </Flex>
      </StyledButton>
    </Box>
  ));
};

function SelectOrganization({
  history,
  auth: { selectTag, productFilter, getOrganization },
}) {
  const { data, loading } = useQuery(GET_ACL, {
    context: {
      headers: {
        'x-tag': '',
      },
    },
  });

  const filteredAcl = filter(
    get(data, 'verifyLogin.acl', []),
    o => o.role.indexOf(productFilter) === 0
  );

  useEffect(() => {
    if (filteredAcl.length === 1) {
      selectTag(filteredAcl[0].path).then(() => history.replace('/'));
    }
  }, [loading]);

  if (loading || !data || filteredAcl.length === 1) {
    return (
      <Flex mt={[20, 200]} justifyContent="center" alignItems="center">
        <StyledCube />
      </Flex>
    );
  }

  return (
    <Flex mx={'1em'} mt={[10, 100]} justifyContent="center">
      <Box width={[1, '30em']}>
        <Flex flexDirection="column">
          <Box mb={'1em'}>
            <Heading>Select Organization</Heading>
          </Box>
          <Box>
            <Flex flexDirection="column" alignItems="start">
              {filteredAcl.length ? (
                <Organizations
                  onClick={async tag => {
                    await selectTag(tag);
                    history.replace('/');
                  }}
                  filteredAcl={filteredAcl}
                  getOrganization={getOrganization}
                />
              ) : (
                <Label>
                  You don't have any organizations present. Please contact
                  support to get correct organization(s) to your user.
                </Label>
              )}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}

export default withRouter(SelectOrganization);
