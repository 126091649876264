import React, { useState } from 'react';
import Modal from '@molecules/Modal';
import ListItem from '@atoms/ListItem';
import List from '@molecules/List';
import styled from 'styled-components';
import Button from '@atoms/Button';
import Select from '@atoms/Select';
import { Flex, Box } from '@rebass/grid';

const LocationContainer = styled.div`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: 1em 1.5em;
`;

const TextContainer = styled.div`
  color: #707070;
  font-size: 1.25em;
`;

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.background};
`;

const StyledList = styled(List)`
  padding-bottom: 2em;
  border-bottom: 2px solid ${({ theme }) => theme.tertiaryThemeColor};
`;

const ButtonsContainer = styled.div`
  flex-flow: wrap;
  flex-direction: row;
  justify-content: center;
  display: flex;
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid ${({ theme }) => theme.tertiaryThemeColor};
`;

const ReplaceDevices = ({ device, onButtonClick, onReturn }) => {
  const [showReplaceBlock, setShowReplaceBlock] = useState(false);
  const [replaceDevices, setReplaceDevices] = useState();
  return (
    <StyledModal>
      <LocationContainer>
        <TextContainer>
          The following devices are already installed in the apartment:
          <StyledList>
            {device.installedDevices.map((installedDevice) => {
              return (
                <ListItem key={installedDevice.id}>
                  {installedDevice.name} - {installedDevice.id}
                </ListItem>
              );
            })}
          </StyledList>
        </TextContainer>

        {
          <>
            <TextContainer>
              Would you like to select another apartment or install extra
              device?
            </TextContainer>
            <ButtonsContainer>
              <StyledButton onClick={() => onReturn({ replaceDevices: [] })}>
                Select apartment
              </StyledButton>
              <StyledButton
                onClick={() =>
                  onButtonClick({
                    secondary: true,
                    replaceDevices: [],
                  })
                }
              >
                Install extra device
              </StyledButton>
              <StyledButton
                onClick={() => setShowReplaceBlock(!showReplaceBlock)}
              >
                Replace devices
              </StyledButton>
            </ButtonsContainer>
          </>
        }
        {showReplaceBlock && (
          <Box mt={'3em'}>
            <TextContainer>
              Choose devices which should be replaced
            </TextContainer>
            <Flex mt={'2em'} flexDirection={'column'} alignItems={'center'}>
              <Box width={[1, 1, 1 / 3]}>
                <Select
                  isMulti
                  options={device.installedDevices.map(({ id }) => ({
                    label: id,
                    value: id,
                  }))}
                  onChange={(value, values) =>
                    setReplaceDevices(values.map((v) => v.value))
                  }
                />
              </Box>
              <Box mt={'0.5em'}>
                <StyledButton
                  disabled={!replaceDevices || !replaceDevices.length}
                  onClick={() => onButtonClick({ replaceDevices })}
                >
                  Replace
                </StyledButton>
              </Box>
            </Flex>
          </Box>
        )}
      </LocationContainer>
    </StyledModal>
  );
};

export default ReplaceDevices;
