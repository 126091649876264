import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import LoaderLine from '@atoms/LoaderLine';
import SignalIndicator from '@molecules/SignalIndicator';
import { getSignalStrength } from '../../utils';
const StyledCaption = styled(Caption)`
  padding: 0;
  padding-left: 0;
`;
const StyledLoader = styled(LoaderLine)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
const StyledBox = styled(Box)`
  margin-top: 2em;
  font-size: 1em;
  ${(props) =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `};
  ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledCard = styled(Card)`
  position: relative;
  padding: 0.5em 0.8em 0.5em 0.8em;
  border-left: ${(props) => props.theme.cardLeftBorder};
  background: ${(props) => props.theme.cardBackground};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  padding: 1em;
`;

const SignalStrengthCard = ({
  connected,
  mined,
  hidden,
  loading,
  ...props
}) => {
  const title = '4G Signal strength';

  const [level, setLevel] = useState('0');

  useEffect(() => {
    setLevel(getSignalStrength(mined));
  }, [mined]);

  return (
    <StyledBox hidden={hidden} flex="1 1 auto" width={[1]}>
      <StyledCard title={title} style={{ position: 'relative' }}>
        <StyledFlex flexDirection="row" alignItems="flex-start">
          <Box width={[1 / 2]}>
            <StyledCaption>{title}</StyledCaption>
            {loading && <StyledLoader loading />}
          </Box>
          <Box width={[1 / 2]} style={{ textAlign: 'end' }}>
            <SignalIndicator size="3em" level={connected ? level : '0'} />
          </Box>
        </StyledFlex>
      </StyledCard>
    </StyledBox>
  );
};

export default SignalStrengthCard;
