import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';

import theme, { GlobalStyle } from '@components/themes/roomrobot-generic';

import { FirebaseProvider, AuthProvider } from '@contexts/FirebaseContext';

import { SharedToasterProvider } from '@contexts/ToasterContext';
import { QSProvider } from '@components/contexts/QSContext';

import {
  apolloClient,
  /*  setTag,
  getTag,*/
} from '@components/connect-react-lib/server-api';

import { LivionConnectProvider } from '@contexts/LivionConnectContext';

import App from './App';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
};

localStorage.setItem('x-app', 'roomrobot');
localStorage.setItem('theme', 'dark');

const navigationItems = process.env.REACT_APP_COMMONAPPS_IN_USE
  ? [
      { to: '/devices', label: 'Devices' },
      {
        onClick: () => {
          window.location = `/apps/en/users`;
        },
        label: 'Users',
      },
      { to: '/orders', label: 'Orders' },
      { to: '/support', label: 'Support' },
      { to: '/notifications', label: "What's new" },
    ]
  : [
      { to: '/devices', label: 'Devices' },
      { to: '/orders', label: 'Orders' },
      { to: '/support', label: 'Support' },
      { to: '/notifications', label: "What's new" },
    ];

ReactDOM.render(
  <FirebaseProvider {...firebaseConfig}>
    <AuthProvider tagSelect={false} productFilter="roomrobot">
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <LivionConnectProvider navigationItems={navigationItems}>
            <ThemeProvider theme={theme}>
              <SharedToasterProvider>
                <QSProvider>
                  <App />
                  <GlobalStyle />
                </QSProvider>
              </SharedToasterProvider>
            </ThemeProvider>
          </LivionConnectProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </AuthProvider>
  </FirebaseProvider>,
  document.getElementById('root')
);

// registerServiceWorker();
