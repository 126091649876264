import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import LoaderLine from '@atoms/LoaderLine';
import SpeedIndicator from '@molecules/SpeedIndicator';

const StyledCaption = styled(Caption)`
  padding: 0;
  padding-left: 0;
`;
const StyledSubtitle = styled.div``;
const StyledMessage = styled.div`
  font-size: 1.2em;
`;
const StyledLoader = styled(LoaderLine)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;
const StyledBox = styled(Box)`
  margin-top: 2em;
  font-size: 1em;
  ${(props) =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `};
  ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledCard = styled(Card)`
  position: relative;
  padding: 0.5em 0.8em 0.5em 0.8em;
  border-left: ${(props) => props.theme.cardLeftBorder};
  background: ${(props) => props.theme.cardBackground};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
  padding: 1em;
`;
const getMessage = (speed) => {
  const isVeryBadSpeed = speed > 0 && speed < 5;
  const isBadSpeed = speed >= 5 && speed < 7.5;
  const isModerateSpeed = speed >= 7.5 && speed < 10;
  const isGoodSpeed = speed >= 10;
  return isVeryBadSpeed
    ? 'need actions, customer may have or notice problems with emails and internet surffing'
    : isBadSpeed
    ? 'Using streaming services may have some problems, but normal working with emails and internet surffing works fine.'
    : isModerateSpeed
    ? 'Streaming services will work moderately'
    : isGoodSpeed
    ? 'Most common streaming services will work well'
    : 'No speed available';
};
const SpeedCard = ({ connected, minedSpeed, hidden, loading, ...props }) => {
  const title = 'Network speed ';
  const subtitle =
    'Latest 24 hour average - Speed measurements may show slower speed (compared to subscription) when multiple clients are connected and/or high data usage applications are used (streaming, gaming...)';

  const [speed, setSpeed] = useState(0);
  const [message, setMessage] = useState(getMessage(0));

  useEffect(() => {
    setSpeed(minedSpeed);
    setMessage(getMessage(minedSpeed));
  }, [minedSpeed]);

  return (
    <StyledBox hidden={hidden} width={[1]}>
      <StyledCard title={title}>
        <StyledFlex flexWrap={['wrap', null]}>
          <Box width={[1]} style={{ paddingBottom: '2em' }}>
            <StyledCaption>{title}</StyledCaption>
            <StyledSubtitle>{subtitle}</StyledSubtitle>
            {loading && <StyledLoader loading />}
          </Box>
          <Box width={[1]}>
            <Flex alignItems="center">
              <Box width={[1, 1, 2 / 12]}>
                <SpeedIndicator speed={speed || 0} />
              </Box>
              <Box width={[1, 1, 10 / 12]}>
                <StyledMessage>{message}</StyledMessage>
              </Box>
            </Flex>
          </Box>
        </StyledFlex>
      </StyledCard>
    </StyledBox>
  );
};

export default SpeedCard;
