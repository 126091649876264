export const LANDING = '/';
export const DEVICES = '/devices';
export const INSTALL = '/install';
export const REGISTER = '/register';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgotpassword';
export const SELECT_ORGANIZATION = '/selectorganization';
export const ORDERS = '/orders';
export const ORDER_CREATE = '/orders/create';
export const ORDER_EDIT = '/orders/:id/edit';
export const USERS = '/users';
export const USER_EDIT = '/users/:id/edit';
export const USER_CREATE = '/users/create';
export const USER = '/users/:id';
export const SUPPORT = '/support';
export const PRIVACY = '/privacy';
export const NOTIFICATION_CENTER = '/notifications';
export const ERROR = '/error';
