import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Caption from '../../atoms/Caption';

const StyledTable = styled.table`
  font-family: ${props => props.theme.primaryFont};
  border-collapse: collapse;
  width: 100%;
  border: none;
  color: ${props => props.theme.textPrimaryColor};
`;

/**
 * @render react
 * @name Table
 * @description Table component.
 * @example
 * <Table>
    <thead>
      <tr>
        <th>Heading Cell 1</th>
        <th>Heading Cell 2</th>
        <th>Heading Cell 3</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Cell 1</td>
        <td>Cell 2</td>
        <td>Cell 3</td>
      </tr>
      <tr>
        <td>Cell 1</td>
        <td>Cell 2</td>
        <td>Cell 3</td>
      </tr>
    </tbody>
  </Table>
 */
const Table = ({ caption, children, ...props }) => {
  return (
    <StyledTable {...props}>
      {caption && <Caption>{caption}</Caption>}
      <tbody>{children}</tbody>
    </StyledTable>
  );
};

Table.propTypes = {
  /**
   * @property {propTypes.string} caption - Table caption.
   */
  caption: PropTypes.string,
  children: PropTypes.any,
};

Table.defaultProps = {
  theme: {
    textPrimaryColor: '#565656',
  },
};

export default Table;
