import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Label from '@components/atoms/Label';
import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import Button from '@atoms/Button';
import { alarmsLabels } from '../../utils';
const StyledCaption = styled(Caption)`
  padding: 0.2em 0.8em 0.5em 0;
  color: ${(props) => !props.ack && '#F46060'};
`;

const BoxWithRightRuler = styled(Box)`
  border-right: ${(props) => props.theme.cardBoxRuler};
  margin-right: 2em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    border-right: none;
    margin-right: inherit;
  }
`;
const StyledCard = styled(Card)`
  position: relative;
  padding: 2em;
  margin-top : 2em 
  border-left: ${(props) => props.theme.cardLeftBorder};
  border-color: ${(props) => '#F46060'};
  background: ${(props) => !props.ack && 'rgba(244, 96, 96, 0.1)'};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const StyledLabel = styled(Label)`
  color: ${(props) => props.theme.primaryThemeColor};
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${(props) => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;

const LabelHeading = styled.p`
  display: inline;
  font-weight: bold;
  color: ${(props) => props.theme.labelBoldColor};
`;

const AlarmsCards = ({
  alarmRules,
  onAck,
  hidden,
  alarmsLoading,
  ackLoading,
  user,
}) => {
  return alarmRules.map((rule) => {
    const alarms = (rule.alarms && rule.alarms.edges) || [];
    return alarms.map((alarm) => {
      const title = alarmsLabels[alarm.ruleId] || rule.alarmName;
      const solutions =
        rule.solutions && rule.solutions.filter((s) => s.language === 'en-gb');

      const steps = (solutions && solutions.length && solutions[0].steps) || [];
      return (
        <StyledCard
          key={alarm.id}
          ack={alarm.ack}
          title={alarm.name}
          style={{ position: 'relative' }}
        >
          <StyledCaption ack={alarm.ack}>Alarm: {title}</StyledCaption>
          <StyledFlex flexWrap={['wrap', null]}>
            <Box width={[1, 1, 2 / 3]}>
              <Flex>
                <Box width={[1, 1, 1 / 6]}>
                  <StyledLabel short>
                    <LabelHeading>What is wrong? </LabelHeading>
                  </StyledLabel>
                </Box>
                <Box width={[1, 1, 4 / 6]}>
                  <StyledLabel short style={{ lineHeight: '1.2em' }}>
                    {rule.rootCause}
                  </StyledLabel>
                </Box>
              </Flex>
              <Flex style={{ paddingTop: '0.5em' }}>
                <Box width={[1, 1, 1 / 6]}>
                  <StyledLabel short>
                    <LabelHeading>What can I do? </LabelHeading>
                  </StyledLabel>
                </Box>
                <Box width={[1, 1, 4 / 6]}>
                  <StyledLabel short>
                    {steps.map((s) => (
                      <span style={{ lineHeight: '1.2em' }} key={s}>
                        {s}
                      </span>
                    ))}
                  </StyledLabel>
                </Box>
              </Flex>
            </Box>

            {!alarm.ack && (
              <React.Fragment>
                <BoxWithRightRuler flex="1 1 auto" />
                <Box width={'210px'}>
                  <Flex
                    style={{ height: '100%' }}
                    justifyContent="center"
                    flexDirection="column"
                  >
                    <Button
                      style={{ width: '100% ' }}
                      secondary
                      onClick={() =>
                        onAck({
                          variables: {
                            filter: { id: alarm.id },
                            input: {
                              comment: 'Acknowledged from RoomRobot UI',
                              timestamp: new Date(),
                              user,
                            },
                          },
                        })
                      }
                      disabled={ackLoading}
                    >
                      {ackLoading ? 'Acknowledging' : 'Acknowledge'}
                    </Button>
                  </Flex>
                </Box>
              </React.Fragment>
            )}
          </StyledFlex>
          <StyledFlex alignItems="flex-end" />
        </StyledCard>
      );
    });
  });
};

export default AlarmsCards;
