import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Box} from '@rebass/grid';
import Button from '../../atoms/Button';
import Sidebar from '../../atoms/Sidebar';
import {useQuery, useApolloClient} from '@apollo/react-hooks';
import {GET_TAGSTREE, GET_TAG_WITH_NESTED_CHILDREN} from '../../api/queries/tags';
import ToasterContext from '../../contexts/ToasterContext';
import LoaderCube from '../../atoms/LoaderCube';
import { withQS } from '../../contexts/QSContext';
import { withAuth } from '../../contexts/FirebaseContext';

import Paginate from '../Paginate';

import get from 'lodash/get';

import Tree from '../../atoms/Tree';

const { TreeNode } = Tree;

const StyledAllButton = styled(Button)`
border: 1px solid ${props => !props.selectedTag ? props.theme.tertiaryThemeColor : props.theme.secondaryThemeColor};
font-size: 16px;
margin: 1.5em 0em 0em 0em;
padding: 0.2em 3em;
background: none
:hover {
  background: none;
}
`;

const StyledAllButtonBox = styled(Box)`
text-align: center;
`
const timeout = 24 * 60 * 60;
const TagsSidebar = ({onChange, auth, onTagChanged, isOpened, location, getQueryString }) => {
    const {addToast} = useContext(ToasterContext);
 
    const xTag = auth.getSelectedTag();
    const client = useApolloClient();
    const limit = 25;
    const variables = {sort: {name: 1}, limit};
    if (xTag) variables.id =  xTag;
    const {data, error, loading, refetch} = useQuery(GET_TAGSTREE, {variables});
    const [tags, setTags] =   useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [defaultExpandedKey] = useState();
    const [selectedTag, setSelectedTag] = useState(getQueryString(location).tag);
    const [expanded, setExpanded] = useState(false);
    const [isLastTag, setIsLastTag] = useState(false);

    if (error) {
      addToast({
        type: 'error',
        title: `Error`,
        message: error.message,
        timeout,
      });
    }
  const processTagChildren = children => {
    const {edges} = children || {};
    if (edges) {
      edges.forEach(child => {
        if (child.children) {
          child.children = processTagChildren(child.children);
        }
      })
      return edges;
    }
  };

    const loadTagNestedChildren = async tags => {
        const parentTagIndex = tags && tags.findIndex(({id}) => (selectedTag.includes(id)));
        if (parentTagIndex > -1) {
          let parentTag = tags[parentTagIndex];
          let levels = selectedTag.split('/').length - parentTag.id.split('/').length;
          if (levels !== 0) {
            setExpanded(true);
            const {data} = await client.query({query: GET_TAG_WITH_NESTED_CHILDREN(levels), variables: {id: parentTag.id}});
            parentTag.children = processTagChildren(data.tag.children)
          }
          return true;
        };
        return true;
    };
    useEffect(() => {
      async function loadTags () {
        let edges = [...data.tagsTree.children.edges];

        if (edges && edges.length === 0) {
          setSelectedTag(data.tagsTree.id);
          setIsLastTag(true);
          edges = [{id: data.tagsTree.id, name: data.tagsTree.name}];
        }

        if (selectedTag) {
          await loadTagNestedChildren(edges);
        }

        setTags(edges);
        setPageInfo(data.tagsTree.children.pageInfo);
      }

      if (data && data.tagsTree) {
        loadTags();
      }
    }, [data]);

    const onItemSelect = select => {
      const tag = select[0];
      onChange({tag});
      setSelectedTag(tag);
      onTagChanged(tag);
    }


    const handleShowAll = () => {
      onChange({tag: null});
      setSelectedTag(null);
      onTagChanged();

    }

    const onLoadData =  treeNode =>
    new Promise(resolve => {
      if (treeNode.props.children) {
        resolve();
        return;
      }
      client.query({
          query: GET_TAG_WITH_NESTED_CHILDREN(1),
          variables: {id: treeNode.props.dataRef.id, limit: 0}
      })
      .then(({data}) => {
          treeNode.props.dataRef.children = (get(data, 'tag.children.edges')) || [];
          setTags([...tags]);
          resolve();
      })
      .catch()
    });

    const onPaginationChange = (after) => {
      refetch({...variables, after})
    }

    const onExpand = (expandedKeys, expanded) => {
      const parent = expanded.node.props.eventKey;
      const isChild = selectedTag && (selectedTag.includes(parent)) && (selectedTag !== parent);

      if (!expanded.expanded && isChild) onItemSelect([])
    };

    const renderTreeNodes = (data) => {
    return data.map((item, index) => {
        item.title = item.name;
        item.key = item.id;
        item.isLeaf = !item.hasChildren || item.type === 'area';

      if (item.children) {
        return (
          <TreeNode className={'tag-tree-node'} title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }

      return <TreeNode className={'tag-tree-node'} key={item.key} {...item} dataRef={item} />;
    });
  }



    return (
      <Sidebar isOpened={isOpened}>
        {loading && <LoaderCube/>}
        {tags.length > 0 && (
        <>
        <StyledAllButtonBox>
       {!isLastTag && (<StyledAllButton selectedTag={selectedTag} onClick={handleShowAll}>Show all</StyledAllButton>)}
       </StyledAllButtonBox>
        <Tree onExpand={onExpand} defaultExpandedKeys={(expanded && [selectedTag]) || [defaultExpandedKey]} selectedKeys={[selectedTag]} onSelect={onItemSelect} loadData={onLoadData}>{renderTreeNodes(tags)}</Tree>
              <Box>
              <Paginate
        {...pageInfo}
        limit={limit}
        onChange={onPaginationChange}
        />

        </Box>
        </>)}
        </Sidebar>
)
}

TagsSidebar.propTypes = {
  onChange: PropTypes.func,
  onTagChanged: PropTypes.func,
 
};

export default withAuth(withQS(TagsSidebar));