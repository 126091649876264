import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import Card from '@components/atoms/Card';
import Icon from '@components/atoms/Icon';
import Caption from '@components/atoms/Caption';
import LoaderLine from '@atoms/LoaderLine';
import SkeletonLoadingCard from '@components/cards/SkeletonLoadingCard';
import GanttChart from '@organisms/GanttChart';

import { personInRoom } from './person-detect';

const StyledBox = styled(Box)`
  margin-top: 2em;
  ${(props) =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `};
  ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledLoader = styled(LoaderLine)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledCard = styled(Card)`
  position: relative;
  border-left: ${(props) => props.theme.cardLeftBorder};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

export default class PersonDetectCard extends React.Component {
  state = { timeline: [] };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.mined !== this.props.mined) {
      let c = true;
      Object.keys(this.props.mined).forEach((k) => {
        if (!this.props.mined[k]) c = false;
      });

      if (c) {
        let presences = [];
        try {
          presences = personInRoom(this.props.mined);
        } catch (err) {
          console.log('error in detection', err);
        }
        // bitmask
        const clients = 8; // 1000
        const soundV = 4; // 0100
        const sound = 2; // 0010
        const movement = 1; // 0001

        const timeline = [];
        let currentItem;
        let combinedPresence;
        presences.forEach((presence) => {
          var newPresence = presence.aggregatedPresence;

          if (newPresence > 0 && (!currentItem || !currentItem.updated)) {
            combinedPresence = parseInt(newPresence + '', 2);

            currentItem = {
              startTime: presence.startTime,
              type: 'Person',
              endTime: new Date(),
              updated: true,
            };
            timeline.push(currentItem);
            // console.log('person in', currentItem);
          }

          if (newPresence > 0) {
            combinedPresence = combinedPresence | parseInt(newPresence + '', 2);
          }

          if (newPresence < 1 && currentItem && currentItem.updated) {
            delete currentItem.updated;
            currentItem.title = (
              <Flex>
                {(combinedPresence & movement) > 0 && (
                  <Box mr="0.3em">
                    <Icon icon="movement" size={'1.2em'} />
                  </Box>
                )}
                {((combinedPresence & sound) > 0 ||
                  (combinedPresence & soundV) > 0) && (
                  <Box mr="0.3em">
                    <Icon icon="volume-up" size={'1.2em'} />
                  </Box>
                )}
                {(combinedPresence & clients) > 0 && (
                  <Box mr="0.3em">
                    <Icon icon="phone" size={'1.2em'} />
                  </Box>
                )}
              </Flex>
            );

            combinedPresence = 0;
            currentItem.endTime = presence.startTime;
          }
        });
        this.setState({ timeline });
      }
    }
  }

  render() {
    const { hidden, device, loading, start, end } = this.props;

    const { timeline } = this.state;
    const title = 'Person Detection';
    if (!device) return <SkeletonLoadingCard title={title} {...this.props} />;

    return (
      <StyledBox hidden={hidden} p={'.2em'} flex="1 1 auto" width={[1]}>
        <StyledCard style={{ position: 'relative' }}>
          <Caption>{title}</Caption>
          {loading && <StyledLoader loading />}
          <StyledFlex flexDirection="column" alignItems="flex-start" />
          {timeline.length > 0 && (
            <GanttChart
              data={timeline}
              keys={['Person']}
              margin={{ top: 20, right: 30, bottom: 30, left: 60 }}
              xDomain={[start, end]}
            />
          )}
        </StyledCard>
      </StyledBox>
    );
  }
}
