import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Card from '../../atoms/Card';
import Caption from '../../atoms/Caption';

const StyledCaption = styled(Caption)`
  padding-top: 0.5em;
  padding-left: 0;
`;

const StyledCard = styled(Card)`
  padding: 1.25em;
  overflow: hidden;
`;

/**
 * @render react
 * @name CaptionCard
 * @description CaptionCard component.
 * @example
 *   <CaptionCard title={"Going Nowhere"} style={{ width: "20em" }}>
    <img
      src="https://images.unsplash.com/photo-1511090724501-e01da99e9869?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4617a9e73808b37e5023619c796df0db&auto=format&fit=crop&w=1234&q=80"
      alt="Pic"
      style={{ width: "100%" }}
    />
    <div style={{ padding: "0.2em 1em" }}>
      <p>Photo by Simon Migaj</p>
    </div>
  </CaptionCard>
 */

// NOTE adding className={className} to Styled Caption will remove console warning but caused styling problems
const CaptionCard = ({ className, children, title, ...props }) => {
  return (
    <React.Fragment>
      {title && <StyledCaption className={'foo'}>{title}</StyledCaption>}
      <StyledCard className={className} {...props}>
        {children}
      </StyledCard>
    </React.Fragment>
  );
};

CaptionCard.propTypes = {
  /**
   * @property {propTypes.string} title - Title of card.
   */
  title: PropTypes.string,
  children: PropTypes.node,
};

export default CaptionCard;
