import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { min, max } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { select, event } from 'd3-selection';

import { timeHour } from 'd3-time';
import { scaleBand, scaleTime } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { zoom } from 'd3-zoom';

import d3blackbox from 'd3blackbox';

const rectHeight = 60;
const generateColor = (style, color, index) =>
  style +
  `
  .bar-${index} {
    fill: ${color};
    stroke: ${color};
  }
`;

const StyledText = styled.text`
  font-size: 0.75em;
  fill: ${props =>
    props.theme.gantChartTextColor ? props.theme.gantChartTextColor : '#666'};
  user-select: none;
`;

const StyledChart = styled.div`
  * {
    font-family: ${({ theme }) => theme.primaryFont};
    //user-select: none;
  }

  .domain {
    fill: none;
    stroke: #a2a2a2;
    shape-rendering: crispEdges;
    stroke-width: 2px;
  }

  .tick {
    line {
      stroke: #a2a2a2;
      stroke-width: 1px;
    }
    font-size: 0.8rem;
    color: #666;
  }

  width: 100%;
  height: 100%;
  font-size: 0.8rem;

  ${({ theme }) => (theme.chartColors || []).reduce(generateColor, '')};
`;

StyledChart.defaultProps = {
  theme: {
    primaryFont: 'system-ui, sans-serif',
  },
};

const StyledTooltip = styled.div`
  background: ${({ theme }) => theme.background};
  font-family: ${({ theme }) => theme.primaryFont};
  padding: 0.75rem;
  border-radius: 2px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
  z-index: 1;

  div {
    color: #333;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  label {
    display: block;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li:not(:last-of-type) {
    margin-bottom: 0.333rem;
  }

  ${({ theme }) =>
    (theme.chartColors || []).reduce(
      (acc, color, index) =>
        acc +
        `
    li:nth-child(${index + 1}) {
      color: ${color};
    }
  `,
      ''
    )};
`;

StyledTooltip.defaultProps = {
  theme: {
    background: '#fff',
    primaryFont: 'system-ui, sans-serif',
  },
};

const Tooltip = props => {
  const { title, type, x, y, startTime, endTime, updated } = props.hoveredBar;
  const styles = {
    background: '#fff',
    position: 'fixed',
    pointerEvents: 'none',
    left: `${x - 30}px`,
    top: `${y}px`,
  };

  return (
    <StyledTooltip style={styles}>
      <div>{title || type}</div>
      <ul>
        <li>
          <label>
            Start:{' '}
            <strong>{dayjs(startTime).format('DD.MM.YYYY H:mm:ss')}</strong>
          </label>
          <label>
            {updated ? 'Updated:' : 'End:'}{' '}
            <strong>{dayjs(endTime).format('DD.MM.YYYY H:mm:ss')}</strong>
          </label>
        </li>
      </ul>
    </StyledTooltip>
  );
};

function multiFormat(date) {
  return timeFormat('%d.%m %H:%M')(date);
}

function formatYTick(val) {
  if (val.length <= 15) return val;
  return val.slice(0, 15) + '...';
}

const BottomAxis = d3blackbox((anchor, props) => {
  select(anchor.current).call(props.axis.tickFormat(multiFormat));
});

const LeftAxis = d3blackbox((anchor, props) => {
  const axis = axisLeft()
    .scale(props.scale)
    .tickFormat(formatYTick)
    .tickPadding(10)
    .tickSize(0);

  select(anchor.current).call(axis);
});

const Rects = ({
  data,
  xScale,
  yScale,
  y,
  x,
  transform,
  onMouseEnter,
  onMouseLeave,
  categories,
}) =>
  data.map(({ type, startTime, endTime, color, ...rest }, i) => {
    const x = xScale(dayjs(startTime));
    const y = 5 + yScale(type);
    const w = Math.max(0, xScale(dayjs(endTime)) - xScale(dayjs(startTime)));
    const h = yScale.bandwidth() - 10;

    return (
      <g key={`bar-${i}`} transform={transform}>
        <rect
          className={`bar-${categories.indexOf(type)}`}
          rx="5"
          ry="5"
          x={x}
          y={y}
          width={w}
          height={h}
          fill={color}
          stroke={color}
          onMouseEnter={e =>
            onMouseEnter({
              x: e.clientX,
              y: e.clientY,
              color,
              type,
              startTime,
              endTime,
              ...rest,
            })
          }
          onMouseLeave={onMouseLeave}
        />
        {w > 100 && (
          <g>
            <StyledText x={x + 5} y={y + 10}>
              {dayjs(startTime).format('DD.MM.YYYY HH:mm:ss')}
            </StyledText>
            <StyledText x={x + w - 93} y={y + h - 5}>
              {dayjs(endTime).format('DD.MM.YYYY HH:mm:ss')}
            </StyledText>
          </g>
        )}
      </g>
    );
  });

class ResponsiveGanttGraph extends React.Component {
  constructor(props) {
    super(props);

    let minimum, maximum;
    if (props.xDomain[0] === 'auto')
      minimum = min(props.data, d => dayjs(d.startTime));
    else minimum = dayjs(props.xDomain[0]);

    if (props.xDomain[1] === 'auto')
      maximum = max(props.data, d => dayjs(d.endTime));
    else maximum = dayjs(props.xDomain[1]);

    if (props.maxX) maximum = dayjs(props.maxX);

    let m = minimum;
    if (props.maxXDomain)
      m = dayjs(maximum).subtract(props.maxXDomain, 'milliseconds');
    //if (dayjs(minimum) > m) m = minimum;

    const xScale = scaleTime()
      .domain([m, maximum])
      .range([0, props.width - props.margin.left - props.margin.right]);

    const xAxis = axisBottom()
      .scale(xScale)
      .ticks(Math.max(props.width / 80, 2));

    this.state = {
      xScale,
      xAxis,
      yScale: scaleBand()
        .domain(props.keys)
        .rangeRound([props.height - 50, 0])
        .padding(0.2),
      zoomTransform: null,
    };

    this.zoom = zoom()
      //.extent([[xScale(min), 0], [xScale(max), props.height]])
      .translateExtent([[xScale(minimum) - 20, 0], [props.width, props.height]])
      .scaleExtent([1, 1])
      .on('zoom', this.onZoom);
  }
  zoomRef = React.createRef();
  zoomRef1 = React.createRef();

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.width !== this.props.width ||
      prevProps.height !== this.props.height ||
      prevProps.maxXDomain !== this.props.maxXDomain ||
      prevProps.data !== this.props.data
    ) {
      let { xScale, yScale, zoomTransform, xAxis } = this.state;
      const {
        data,
        width,
        height,
        keys,
        margin,
        maxX,
        maxXDomain,
        xDomain,
      } = this.props;

      let minimum, maximum;
      if (xDomain[0] === 'auto') minimum = min(data, d => dayjs(d.startTime));
      else minimum = dayjs(xDomain[0]);

      if (xDomain[1] === 'auto') maximum = max(data, d => dayjs(d.endTime));
      else maximum = dayjs(xDomain[1]);

      if (maxX) maximum = dayjs(maxX);

      let m = minimum;
      if (maxXDomain) m = dayjs(maximum).subtract(maxXDomain, 'milliseconds');

      xScale
        .domain([m, maximum])
        .range([0, width - margin.left - margin.right]);

      xAxis.ticks(Math.max(width / 80, 2));
      yScale.rangeRound([height - 50, 0]);
      xAxis = this.state.xAxis.scale(xScale);

      this.setState({
        xAxis,
        xScale,
        yScale,
      });
    }
  }

  componentDidMount() {
    select(this.zoomRef.current).call(this.zoom);
    // d3.select(this.zoomRef1.current).call(this.zoom);
  }

  onZoom = d => {
    event.transform.y = 0;

    const xScale = event.transform.rescaleX(this.state.xScale);

    const xAxis = this.state.xAxis.scale(xScale);
    this.setState({ xAxis, zoomTransform: event.transform });
  };

  render() {
    const {
        data,
        width,
        height,
        keys,
        margin,
        mouse,
        onMouseEnter,
        onMouseLeave,
      } = this.props,
      { yScale, xScale, xAxis, zoomTransform } = this.state;

    return (
      <g transform={`translate(${margin.left},${margin.top})`}>
        <g clipPath="url(#clip)">
          <defs>
            <clipPath id="clip">
              <rect x="0" y="0" width={width} height={height} />
            </clipPath>
          </defs>

          <rect
            width={width}
            height={height}
            fill="transparent"
            ref={this.zoomRef}
            style={{ cursor: 'move' }}
          />

          <Rects
            categories={keys}
            transform={zoomTransform}
            data={data}
            xScale={xScale}
            yScale={yScale}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            y={height / keys.length}
          />
        </g>

        <LeftAxis scale={yScale} keys={keys} x={0} y={0} />
        <BottomAxis axis={xAxis} scale={xScale} x={0} y={height - 50} />
      </g>
    );
  }
}

class GanttGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      margin: props.margin || { top: 20, right: 30, bottom: 30, left: 80 },
    };
  }

  static propTypes = {
    data: PropTypes.array.isRequired,
    keys: PropTypes.array.isRequired,
    margin: PropTypes.object,
    maxXDomain: PropTypes.number,
    maxX: PropTypes.object,
    xDomain: PropTypes.array,
  };
  static defaultProps = {
    maxXDomain: 0,
    xDomain: ['auto', 'auto'],
  };
  svgRef = React.createRef();

  componentDidMount() {
    this.measureSVG();
    window.addEventListener('resize', this.measureSVG);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.measureSVG);
  }

  measureSVG = () => {
    if (!this.svgRef.current) return;
    const { width, height } = this.svgRef.current.getBoundingClientRect();

    this.setState({
      width,
      height,
    });
  };

  render() {
    const { data, keys, maxXDomain, maxX, xDomain } = this.props;
    const { width, height, margin, hoveredBar } = this.state;

    return (
      <StyledChart>
        <svg width="100%" height="100%" ref={this.svgRef}>
          {data && width && height && (
            <ResponsiveGanttGraph
              xDomain={xDomain}
              maxXDomain={maxXDomain}
              maxX={maxX}
              data={data}
              keys={keys}
              width={width}
              height={height}
              margin={margin}
              onMouseEnter={data => this.setState({ hoveredBar: data })}
              onMouseLeave={() => this.setState({ hoveredBar: null })}
            />
          )}
        </svg>
        {hoveredBar ? <Tooltip hoveredBar={hoveredBar} /> : null}
      </StyledChart>
    );
  }
}

export default GanttGraph;
