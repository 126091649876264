import { createGlobalStyle } from 'styled-components';
import c from 'color';
import Nunito from './Nunito-Regular.ttf';
//import headerbg from './headerBg.svg'; // custom background image in theme folder
import headerbg from './headerBg.svg'; // custom background image in theme folder
import backgroundImage from './background.svg';
import Logo from './Logo';

const primaryThemeColor = '#949494';
const secondaryThemeColor = '#949494';
const tertiaryThemeColor = '#00BCF1';
const background = '#171717';
const alertColor = primaryThemeColor;
const warningColor = secondaryThemeColor;
const connectedColor = '#7CC46D';
const disconnectedColor = '#6E6E6E';
const internetDisabledColor = '#D66363';

const noLoginBg = true;

export const GlobalStyle = createGlobalStyle`
  body {
    font-size: 14px;
    font-family: Nunito, sans-serif;
    background: ${background};
    background-image: url('${backgroundImage}');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100% auto;
    color: ${primaryThemeColor};
    margin: 0;
    min-height: 100vh;
  }
`;

const theme = {
  Logo,
  //breakpoints: ["40em", "52em", "64em"],
  name: 'Roomrobot-generic Theme',
  disabled: '#6E6E6E',
  /**
   * Global stylenames/styles, used in various atoms
   */
  primaryFont: 'Nunito, sans-serif',
  primaryThemeColor,
  secondaryThemeColor,
  tertiaryThemeColor,
  background,
  alertColor,
  connectedColor,
  disconnectedColor,
  internetDisabledColor,
  noLoginBg,

  /**
   * Block styles
   */
  blockBackground: 'none',
  blockColor: '#717171',
  blockPadding: '0 0 0 0',

  /**
   * Caption styles
   */
  captionColor: '#EDEDED',
  captionFontWeight: '700',
  captionLineHeight: 'normal',
  captionFontSize: '1.55em',
  captionTextTransform: 'none',
  captionTextAlign: 'left',
  captionPadding: '1.5em 0.8em 0.5em 0.8em',
  captionMargin: '0',

  /**
   * CircleButton styles
   */
  circleButtonBackground: tertiaryThemeColor,
  circleButtonDisabledBackground: '#9A9A9A',
  circleButtonDisabledColor: '#6A6A6A',
  circleButtonTextColor: '#fff',
  circleButtonActiveBackground: '#688C8B',
  circleButtonFontSize: '1.35em',
  circleLetterSpacing: 'normal',

  /**
   * Heading styles
   */
  headingFontWeight: '500',
  headingColor: '#EEEEEE',
  headingPadding: '0.2em 0.2em 0.1em 0em',

  /**
   * HorizontalRule styles
   */
  horizontalRuleColor: '#DBDBDB',
  horizontalRuleBorder: '0 0 2',
  horizontalRuleMargin: '0',

  /**
   * Link styles
   */
  linkTextDecoration: 'underline',
  linkFontWeight: '500',
  linkTransform: 'none',
  linkColor: primaryThemeColor,

  /**
   * ListItem styles
   */
  listItemColor: primaryThemeColor,
  listItemPadding: '1em 0 0 0',
  listItemStyleType: 'none',

  /**
   * Loader styles
   */
  loaderBackground: tertiaryThemeColor,

  /**
   * PrimaryButton styles
   */
  primaryBackground: tertiaryThemeColor,
  primaryButtonTextColor: '#fff',
  primaryButtonBorder: 'none',
  primaryBorderRadius: '50px',
  primaryButtonMargin: '0.5em 0',
  primaryButtonTextPadding: '0.48em 1.8em',
  primaryButtonTextTransform: 'none',
  primaryHoverBackground: c(tertiaryThemeColor)
    .darken(0.3)
    .string(),
  primaryActiveBackground: '#688C8B',
  primaryDisabledBackground: '#9A9A9A',
  primaryDisabledColor: '#6A6A6A',
  primaryButtonFontSize: '1.2em',
  primaryButtonMinWidth: '0',
  primaryButtonMinHeight: '0',
  primaryButtonFontWeight: '400',
  primaryLetterSpacing: '0.045em',
  primaryButtonTextAlign: 'left',

  /**
   * SecondaryButton styles
   */
  secondaryBackground: tertiaryThemeColor,
  secondaryColor: '#fff',
  secondaryButtonMargin: '0.5em 0',
  secondaryBorderRadius: '50em',
  secondaryButtonBorder: 'none',
  secondaryButtonTextPadding: '0.48em 1.8em',
  secondaryButtonTextTransform: 'none',
  secondaryDisabledBackground: '#9A9A9A',
  secondaryHoverBackground: c(secondaryThemeColor)
    .darken(0.3)
    .string(),
  secondaryActiveBackground: '#688C8B',
  secondaryDisabledColor: '#6A6A6A',
  secondaryButtonFontSize: '1em',
  secondaryButtonMinWidth: '43px',
  secondaryButtonMinHeight: '1.5em',
  secondaryButtonFontWeight: '400',
  secondaryLetterSpacing: '0.045em',
  secondaryButtonTextAlign: 'center',

  /**
   * ToggleButton styles
   */

  toggleButtonSelected: tertiaryThemeColor,
  toggleButtonNotSelected: '#212121',
  toggleButtonBorder: `1px solid ${tertiaryThemeColor}`,
  toggleButtonTextColorSelected: '#fff',
  toggleButtonTextColorNotSelected: tertiaryThemeColor,

  /**
   * Table components start-->
   */

  //TableHead
  tableHeadBackground: 'none',
  tableHeadColor: '#D1D1D1',
  tableHeadFontWeight: '500',
  tableHeadLineHeight: 'normal',
  tableHeadFontSize: '1.13em',
  tableHeadTextTransform: 'none',
  tableHeadTextAlign: 'left',
  tableHeadPadding: '1em 0 0.25em 1em',
  tableHeadMargin: '0',
  tableHeadTopBorder: 'none 0',

  //TableCell
  tableCellColor: primaryThemeColor,
  tableCellFontWeight: '400',
  tableCellLineHeight: 'normal',
  tableCellFontSize: '1em',
  tableCellTextTransform: 'none',
  tableCellTextAlign: 'left',
  tableCellPadding: '0.5em 0.5em 0.5em 0.5em',
  tableCellMargin: '0',
  tableCellTopBorder: 'none 0',

  //TableRow
  tableRowPadding: '0',
  tableRowlMargin: '0',
  tableRowBackground: 'none',
  tableFirstRowBg: background,
  tableSecondRowBg: background,

  tableBottomMargin: '3%',

  /**
   * Input(field) styles
   */
  inputPlaceholderColor: '#c7c7c7',
  inputPlaceholderWeight: '400',
  inputFontWeight: '400',
  inputHeight: '2.75em',
  inputFontSize: '1em',
  inputFontColor: '#fff',
  inputBackground: '#343434',
  inputBorderRadius: '1.250em',
  inputBorderFocus: '#43BDFC',
  inputBorder: '#C1C1C1',
  inputBorderError: '#C54747',
  inputPadding: '0 1.25em',
  inputBorderWidth: '1px',
  inputLabelColor: '#989898',
  inputIconFill: '#c7c7c7',
  /**
   * Field error
   */
  fieldError: '#C54747',

  /**
   *  <-- Table components end
   */

  /**
   * Label styles
   */
  labelColor: primaryThemeColor,
  labelTransform: 'uppercase',
  labelBoldColor: tertiaryThemeColor,

  /**
   * StatusLabel Styles
   */
  statusLabelSize: '0.7em',
  statusLabelConnectedColor: tertiaryThemeColor,
  statusLabelDisconnectedColor: '',

  plugColor: '#7D7777',

  /**
   * Navigation syles
   */
  navigationFontWeigt: '600',
  navigationPadding: '0em 1.5em 0em 1.5em',
  navigationFontSize: '1em',
  navigationFontColor: '#fff',
  navigationActiveColor: tertiaryThemeColor,

  /**
   * DropDown (menu) styles
   */
  dropdownBackground: '#ffffff',
  dropdownTextColor: '#595959',
  dropdownIconColor: '#fff',
  dropdownShadow: '0 1px 1px 0 rgba(0,0,0,0.17)',
  dropdownBorderRadius: '0 0 5px 5px',
  dropdownPadding: '0.75em',
  dropdownWidth: '200px',
  dropdownItemPadding: '0.5em',
  dropdownMenuHover: '#f8f8f8',

  /**
   * Mobile menu
   */
  mobileMenuIconColor: primaryThemeColor,
  drawerBackground: '#fff',
  drawerProfileColor: '#5B5B5B',
  drawerItemColor: '#5B5B5B',
  drawerItemBackgroundColor: '#fff',
  drawerItemActiveColor: '#fff',
  drawerItemActiveBackground: primaryThemeColor,

  /**
   * Card styles
   */
  cardBackground: '#212121',
  cardBoxShadow: '0 0.1em 0.2em 0 rgba(0,0,0,0.2)',
  cardBoxShadowHover: '0 0.2em 0.4em 0 rgba(0,0,0,0.2)',
  cardPadding: '0',
  cardColor: 'primaryThemeColor',
  cardLeftBorder: '.30em solid #393939',
  cardBoxRuler: '1px solid #323232;',

  /**
   * Header styles
   */
  headerBackground: background,

  /**
   * Logo styles
   */
  logoFill: '#fff',

  /**
   * Panel styles
   */
  subtitleColor: '#9d9d9d',
  panelBackground: background,

  /**
   * Toast styles
   */
  toastBackground: '#ffffff',
  toastBorder: '1px solid #E7E7E7',
  toastPadding: '0.5em 0.5em 1em 1em',
  toastShadow: '0px 14px 27px -16px rgba(0,0,0,0.75);',
  toastSuccessColor: '#71E190',
  toastErrorColor: '#E16767',
  toastWarningColor: '#FEFF00',
  toastTitleColor: '#5B5B5B',
  toastMessageColor: '#5B5B5B',

  /**
   * Tree styles
   */
  treeItemBackground: 'none',
  nodeFontSize: '0.95rem',
  toggleArrowSize: '0.65rem',
  treeTitleColor: primaryThemeColor,
  treeSubtitleColor: '#959595',
  actionIconColor: '#5B5B5B',
  toggleColor: tertiaryThemeColor,

  /**
   * Chart
   */
  chartColors: new Array(12).fill('#1D7292').map((a, i) =>
    c(a)
      .rotate(i * 24)
      .hex()
  ),
  chartBarFillColor: tertiaryThemeColor,
  chartLineStrokeColor: tertiaryThemeColor,
  gantChartTextColor: '#fff',
  chartStrokeColor: '#fff',
  chartTooltipColor: tertiaryThemeColor,

  /**
   * Accordion styles
   */
  accordionBackgroundColor: '#212121',
  accordionBackground: '1px solid #212121',

  /**
   * Month Picker styles
   */
  monthPickerBackground: '#343434',
  monthPickerDateBackground: '#343434',
  monthPickerButtonBackground: primaryThemeColor,
  monthPickerFontColor: '#fff',
  monthPickerColor: tertiaryThemeColor,
  monthPickerButtonBorder: 'none',
  monthPickerButtonRadius: '0',
  monthPickerButtonMargin: '0.5em 0',
  monthPickerButtonTextPadding: '0.75em 1.05em',
  monthPickerButtonTextTransform: 'uppercase',
  monthPickerHoverBackground: primaryThemeColor,
  monthPickerActiveBackground: primaryThemeColor,
  monthPickerDisabledBackground: '#9A9A9A',
  monthPickerDisabledColor: '#6A6A6A',
  monthPickerButtonFontSize: '0.95em',
  monthPickerButtonMinWidth: '0',
  monthPickerButtonMinHeight: '0',
  monthPickerButtonFontWeight: '400',
  monthPickerLetterSpacing: '0.045em',
  monthPickerButtonTextAlign: 'center',
  monthPickerDateBorderRadius: '20%',

  /**
   * Modal
   */
  modalClose: tertiaryThemeColor,
};

export default theme;
