import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Box } from '@rebass/grid';

import LogoImage from '../../atoms/LogoImage';
import Hide from '../../atoms/Hide';

import Navigation from '../../molecules/Navigation';
import MobileMenu from '../../organisms/Menu';
import Label from '../../atoms/Label';

const StyledLogo = styled(LogoImage)`
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    width: 6.25em;
  }
`;

const StyledFlex = styled(Flex)`
  margin: 0;
  background: url(${(props) => props.theme.headerBackground}) no-repeat top
    center fixed;
  background-color: ${(props) => props.theme.headerBackground};
  background-size: cover;
  height: 5em;
  padding: 0 1.875em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    height: 4em;
    padding: 0 0.45em;
  }
`;

const StyledLabel = styled(Label)`
  text-transform: none;
  user-select: none;
  padding-left: 0.25em;
  cursor: pointer;
`;
/**
 * @render react
 * @name Header
 * @description Header
 * @example
 * <Header navigationItems={[
  { to: "/", label: "Home" },
  { to: "/users", label: "Users" }
  ]} />
 */

const Header = ({ navigationItems, user, dropdownItems = [] }) => {
  return (
    <StyledFlex alignItems="center" justifyContent="space-between">
      <Box>
        <StyledLogo version={process.env.REACT_APP_VERSION} />
      </Box>
      <Hide style={{ width: '100%' }} xs>
        <Navigation items={navigationItems} />
      </Hide>

      <Hide sm md lg>
        <Box>
          <MobileMenu user={user} navigationItems={navigationItems}>
            {dropdownItems
              .filter((t) => !t.hideMobile)
              .map((t, i) =>
                t.text ? (
                  <StyledLabel key={`tag-select-${i}`} onClick={t.onClick}>
                    {t.text}
                  </StyledLabel>
                ) : (
                  <StyledLabel key={`tag-select-${i}`} onClick={t.onClick}>
                    {t}
                  </StyledLabel>
                )
              )}
          </MobileMenu>
        </Box>
      </Hide>
    </StyledFlex>
  );
};

Header.propTypes = {
  /**
   * @property {propTypes.array} navigationItems - Items to navigation.
   */
  navigationItems: PropTypes.array,
  /**
   * @property {propTypes.object} user - User firebase object for profile dropdown.
   */
  user: PropTypes.object,
};

Header.defaultProps = {
  navigationItems: [],
  theme: {
    headerBackground: '#fff',
  },
};

export default Header;
