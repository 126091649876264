import styled, { css } from 'styled-components';

const styles = css`
  color: ${props => props.theme.primaryThemeColor};
  font-family: ${props => props.theme.primaryFont};
  padding: ${props => props.theme.tableRowPadding};
  margin: ${props => props.theme.tableRowMargin};
  background: ${props => props.theme.tableRowBackground};
  :nth-child(even) {
    background: ${props => props.theme.tableFirstRowBg};
  }
  :nth-child(odd) {
    background: ${props => props.theme.tableSecondRowBg};
  }
  cursor: ${props => props.onClick && 'pointer'};
`;

/**
 * @render react
 * @name TableRow
 * @description TableRow component.
 * @example
 *     <table>
    <thead>
      <TableRow>
        <th>Heading Cell 1</th>
        <th>Heading Cell 2</th>
        <th>Heading Cell 3</th>
      </TableRow>
    </thead>
    <tbody>
      <TableRow>
        <td>Cell 1</td>
        <td>Cell 2</td>
        <td>Cell 3</td>
      </TableRow>
      <TableRow>
        <td>Cell 1</td>
        <td>Cell 2</td>
        <td>Cell 3</td>
      </TableRow>
    </tbody>
  </table>
 */
const TableRow = styled.tr`
  ${styles};
`;

TableRow.defaultProps = {
  theme: {
    tableRowPadding: '0',
    tableRowlMargin: '0',
    tableRowBackground: 'none',
    tableFirstRowBg: 'none',
    tableSecondRowBg: 'none',
  },
};

export default TableRow;
