import styled from 'styled-components';

/**
 * @render react
 * @description Styled block component
 *
 * @example
 * <Block>Text inside block</Block>
 */
const Block = styled.div`
  font-family: ${props => props.theme.primaryFont};
  background-color: ${props => props.theme.blockBackground};
  color: ${props => props.theme.blockColor};
  padding: ${props => props.theme.blockPadding};
`;

Block.propTypes = {};

Block.defaultProps = {
  theme: {
    blockBackground: 'none',
    blockColor: '#000000',
    blockPadding: '0 0 0 0',
  },
};

export default Block;
