import PropTypes from 'prop-types';
import styled from 'styled-components';

const defaultBreakpoints = ['39.999em', '40em', '51.999em', '52em', '63.999em', '64em'];
export const breakpoints = breakpoints => ({
  xs: `@media screen and (max-width: ${breakpoints[0]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]}) and (max-width: ${breakpoints[2]})`,
  md: `@media screen and (min-width: ${breakpoints[3]}) and (max-width: ${breakpoints[4]})`,
  lg: `@media screen and (min-width: ${breakpoints[5]})`,
})

export const hidden = key => props =>
  props[key]
    ? {
        [breakpoints(props.theme.breakpoints || defaultBreakpoints)[key]]: {
          display: "none"
        }
      }
    : null;

const xs = hidden("xs");
const sm = hidden("sm");
const md = hidden("md");
const lg = hidden("lg");

/**
 * @render react
 * @name Hide
 * @description Hide component
 * @example
 * <Hide md lg>I‘m hidden on medium and large screens</Hide>
 */
const Hide = styled.div([], xs, sm, md, lg);

Hide.defaultProps = {
  theme: {
    breakpoints: defaultBreakpoints
  }
};

Hide.propTypes = {
  /**
   * @property {propTypes.bool} xs - Hide in xtra small screens.
   */
  xs: PropTypes.bool,
  /**
   * @property {propTypes.bool} sm - Hide in small screens.
   */
  sm: PropTypes.bool,
  /**
   * @property {propTypes.bool} md - Hide in medium screens.
   */
  md: PropTypes.bool,
  /**
   * @property {propTypes.bool} lg - Hide in large screen.
   */
  lg: PropTypes.bool
};

export default Hide;
