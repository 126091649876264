module.exports = {
  countries: { FI: 'Finland', SE: 'Sweden', NO: 'Norway', DK: 'Denmark' },
  selectCountries: [
    { value: 'FI', label: 'Finland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'NO', label: 'Norway' },
    { value: 'DK', label: 'Denmark' },
  ],
  hws: [
    {
      value: '4g',
      label: '4G',
      imgUrl:
        'https://storage.googleapis.com/livionconnect-public/roomrobot.jpg',
    },
    {
      value: '4gAntenna',
      label: '4G + Antenna',
      imgUrl:
        'https://storage.googleapis.com/livionconnect-public/roomrobot.jpg',
    },
    // {
    //   value: 'rj45',
    //   label: 'RJ45',
    // },
  ],
  billingInfo: {
    BuyerPartyDetails: {
      BuyerCode: '',
      BuyerCommunicationDetails: {
        BuyerEmailaddressIdentifier: '',
        BuyerPhoneNumberIdentifier: '',
      },
      BuyerContactPersonName: '',
      BuyerOrganisationName: '',
      BuyerOrganisationTaxCode: '',
      BuyerOrganisationUnitNumber: '',
      BuyerPartyIdentifier: '',
      BuyerPostalAddressDetails: {
        BuyerPostCodeIdentifier: '',
        BuyerStreetName: '',
        BuyerTownName: '',
        CountryCode: '',
        CountryName: '',
      },
    },
  },
  deliveryInfo: {
    DeliveryPartyDetails: {
      DeliveryOrganisationName: '',
      DeliveryPostalAddressDetails: {
        CountryCode: '',
        CountryName: '',
        DeliveryPostCodeIdentifier: '',
        DeliveryStreetName: '',
        DeliveryTownName: '',
      },
    },
  },
};
