import React from 'react';
import styled from 'styled-components';
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  display: block;
`;
const StyledCheckbox = styled.div`
  margin: 0.5rem;
  opacity: 1;
  background: ${props => (props.checked ? props.theme.tertiaryThemeColor : '#FFF')}
  width: ${props => (props.big ? '20px' : '14px')}
  height:${props => (props.big ? '20px' : '14px')}
  border: solid 1px #53779A;
  border-radius: 4px;
  transition: all 150ms;

  &:focus {
    border: 2px solid ${props => props.theme.inputBorderFocus};
  }

  &.error {
    border: 2px solid ${props => props.theme.inputBorderError};
  }
  
  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')}

  }
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckBox = ({ className, big, value, onClick, ...props }) => {
  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox checked={props.checked} {...props} />
      <StyledCheckbox checked={props.checked} big={big} onClick={onClick}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default CheckBox;
