import React from 'react';
import DashboardDefaultPage from '@components/pages/DashboardDefaultPage';
import { withRouter } from 'react-router-dom';
import { withLivionConnect } from '@components/contexts/LivionConnectContext';

import Block from '@atoms/Block';
import Label from '@atoms/Label';
import styled from 'styled-components';

const StyledBlock = styled((props) => <Block {...props} />)`
  padding: 1.5em 8.375em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    padding: 0.4em;
  }
`;
const StyledIntro = styled.p`
  font-size: 1.5em;
`;
const StyldCategory = styled(Label)`
  font-size: 1.5em;
  color: ${(props) => props.theme.tertiaryThemeColor};
  text-transform: capitalize;
`;

const StyledLink = styled.a`
  font-size: 1.5em;

  margin-bottom: 10px;
  text-decoration: none;
  color: ${(props) => props.theme.primaryThemeColor};
  &:visited {
    color: ${(props) => props.theme.primaryThemeColor};
  }
`;

const StyledItem = styled.li`
  margin-top: 10px;
`;
const Documents = ({ documents }) => (
  <ul>
    {documents.map(({ title, url }) => (
      <StyledItem key={url}>
        {' '}
        <StyledLink target="_blank" rel="noopener noreferrer" href={url}>
          {title}
        </StyledLink>
      </StyledItem>
    ))}
  </ul>
);
export const Support = ({
  history,
  auth: { logout },
  state: { user, navigationItems },
}) => {
  return (
    <DashboardDefaultPage
      navigationItems={navigationItems}
      user={user}
      title="Support"
      subtitle="Find all documents to help you with RoomRobot"
      onProfileClick={(v) => history.push(`/users/${v.uid}/edit`)}
      onLogoutClick={logout}
      onBack={history.goBack}
    >
      <StyledBlock>
        <StyledIntro>
          You will find on this page all the documentation needed to install and
          use our RoomRobot device. Click on any document to access it.
          <br /> If you have any questions or feedback, you can send an email to
          :{' '}
          <a
            href="mailto:roomrobot@livion.fi"
            style={{ color: 'white', fontWeight: 400 }}
          >
            roomrobot@livion.fi
          </a>
        </StyledIntro>
        <StyldCategory>RoomRobot Installation</StyldCategory>
        <Documents
          documents={[
            {
              url:
                'https://drive.google.com/file/d/1mqnujmuFIyHX47oQftcfARWOrZpJVL5rw_Z7_MJKHLc/preview',

              title: 'Device installation guidelines',
            },

            {
              url:
                'https://drive.google.com/file/d/1kKViziZXKcSJSNWS-bByEOekuFJcomI0DgQnGv53o40/preview',

              title: 'RoomRobot Wifi extender',
            },
            {
              url:
                'https://drive.google.com/file/d/1WinyHEf8Y4mwwVMrMTo_tz7yhy0t7l_JyczUzEylz9k/preview',
              title: 'Device LED indicator',
            },
          ]}
        />{' '}
        <StyldCategory>Roomrobot.rocks App</StyldCategory>
        <Documents
          documents={[
            {
              url:
                'https://drive.google.com/file/d/18_PtSS1OwbXWgtKCrxpvpKcX8_fzZ3_QXJBX-gdIgTM/preview',

              title: 'Device ordering guidelines',
            },
            {
              url:
                'https://drive.google.com/file/d/1NejJ6MMde2MOuQKzc1gyqZkrdrvb8Salnb8bb4Y1MD8/preview',

              title: 'Device deactivation guidelines',
            },

            {
              url:
                'https://drive.google.com/file/d/1hPLIluYCk7_3Bab8tXYPpsWAJ0G-PDpzd7uVgvVLGdg/preview',

              title: 'User management guidelines',
            },
          ]}
        />{' '}
      </StyledBlock>
    </DashboardDefaultPage>
  );
};

export default withRouter(withLivionConnect(Support));
