import React, { Component } from 'react';
import { FormSpy } from 'react-final-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _debounce from 'lodash/debounce';

import _get from 'lodash/get';
// import TrackVisibility from 'react-on-screen';

import { Flex, Box } from '@rebass/grid';

import CircleButton from '../../atoms/CircleButton';
import Block from '../../atoms/Block';
import Icon from '../../atoms/Icon';
import Heading from '../../atoms/Heading';
import Label from '../../atoms/Label';
import LoaderLine from '../../atoms/LoaderLine';
import Dropdown from '../../molecules/Dropdown';
import Hide from '../../atoms/Hide';

const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.circleButtonTextColor};
`;

const StyledBlock = styled(Block)`
  background: ${(props) => props.theme.panelBackground};
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    padding: 0.4em;
  }
`;

const Button = styled(Flex)`
  cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
  label {
    cursor: ${(props) => (props.disabled ? 'inherit' : 'pointer')};
  }
`;
const StyledFloatingCircleButton = styled(CircleButton)`
  background: ${(props) => props.offset && props.theme.background};
`;
const StyledFloatingIcon = styled(Icon)`
  color: ${(props) => props.offset && props.theme.subtitleColor};
`;
const SaveButton = styled(Button)`
  transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.4s;

  @media screen and (max-width: 40em) {
    background: ${(props) => props.theme.panelBackground};
    padding: 0.4em 0.6em;
    border-radius: 1.75em;
    box-shadow: 0px 1px 1px #989898;
    position: fixed;
    bottom: 2em;
    right: 2em;
    z-index: 1;
  }

  @media screen and (min-width: 40.1em) {
    ${(props) =>
      props.offset &&
      `
    z-index: 1;
    background: #949494;
    color: #171717;
    padding: 0.2em 0.6em;
    border-radius: 1.75em;
    box-shadow: 0px 1px 1px #949494;
    transform: translate3d(${0}px, ${props.offset.y}px, 999px);
    `}
  }
`;

const StyledHeading = styled(Heading)`
  line-height: 1em;
  margin-top: 0.3em;
  &:empty::after {
    content: '';
    display: block;
    width: 40%;
    height: 1em;
    border-radius: 2px;
    background: #ffffff96;
  }

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 1em;
    line-height: 1em;
  }
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.subtitleColor};
  align-self: center;
  font-weight: bold;
  line-height: 1em;
  &:empty::after {
    content: '';
    display: block;
    width: 110px;
    height: 1em;
    border-radius: 2px;
    background: #ffffff96;
  }
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.65em;
    line-height: 0.65em;
  }
`;

const StyledLabel = styled(Label)`
  text-transform: none;
  color: ${(props) => props.theme.subtitleColor};
  font-weight: bold;
  margin-right: 0.75em;
`;
const StyledFloatingLabel = styled(Label)`
  text-transform: none;
  color: ${(props) =>
    props.offset ? props.theme.background : props.theme.subtitleColor};
  font-weight: bold;
  margin-right: 0.75em;
`;

const BackBox = styled(Box)`
  cursor: pointer;
`;

const BackIcon = styled(Icon)`
  fill: ${(props) => props.theme.headingColor};
`;

const ButtonBox = styled(Box)`
  pointer-events: none;
`;

/**
 * @render react
 * @name ItemPane
 * @description ItemPane component used in specific items view
 * @example
 * <ItemPane
      title="Rantakatu 6"
      subtitle="005033"
      onEditClick={() => alert("edit")}
    />
 */
let lastScrollY = 0;
//let _isMounted = false;

class ItemPane extends Component {
  state = {};
  _isMounted = false;

  saveBtnRef = React.createRef();
  componentDidMount() {
    window.addEventListener(
      'scroll',
      (this._debounce1 = _debounce(this.handleScroll, 100))
    );
    window.addEventListener(
      'resize',
      (this._debounce2 = _debounce(this.handleScroll, 100))
    );
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._debounce1) this._debounce1.cancel();
    if (this._debounce2) this._debounce2.cancel();
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll = (event) => {
    this._isMounted &&
      this.setState({
        offset:
          window.pageYOffset > 200 && this.saveBtnRef && this.saveBtnRef.current
            ? {
                y: window.pageYOffset - 100,
                x: this.saveBtnRef.current.offsetWidth,
              }
            : null,
      });
  };

  render() {
    const {
      title,
      titleKey,
      values, // coming with formspy when we are inside form
      submitting, // coming with formspy when we are inside form
      pristine, // coming with formspy when we are inside form
      valid, // coming with formspy when we are inside form
      subtitle,
      onEditClick,
      onRemoveClick,
      onSaveClick,
      onAddClick,
      onCopyClick,
      copyTitle,
      addTitle,
      onBackClick,
      loading,
      children,
      status,
      synced,
    } = this.props;
    let disableSave = submitting || loading || pristine || !valid;

    return (
      <React.Fragment>
        <StyledBlock>
          <Flex alignItems="center">
            {onBackClick && (
              <BackBox pr={'1em'} onClick={onBackClick}>
                <BackIcon icon={'chevron-left'} size={'2em'} />
              </BackBox>
            )}
            <Box flex="1 1 auto">
              <StyledHeading>
                {titleKey ? _get(values, titleKey) : title}
              </StyledHeading>
              <Flex>
                <Subtitle>{subtitle}</Subtitle>
                {status && status}
                {synced && synced}
              </Flex>
            </Box>
            <Box>
              <Flex>
                {children && (
                  <Flex alignItems="center" style={{ marginRight: '.5em' }}>
                    <Box>{children}</Box>
                  </Flex>
                )}
                {(onRemoveClick || onCopyClick) && (
                  <Dropdown
                    options={[
                      ...(onCopyClick
                        ? [{ text: 'Duplicate', onClick: onCopyClick }]
                        : []),
                      ...(onRemoveClick
                        ? [{ text: 'Remove', onClick: onRemoveClick }]
                        : []),
                    ]}
                  >
                    <Hide sm md lg>
                      <Icon icon={'more-vertical-f'} size={'1.5em'} />
                    </Hide>
                    <Hide xs>
                      <Button
                        alignItems="center"
                        justifyContent="flex-end"
                        style={{ marginRight: '.5em' }}
                      >
                        <Box>
                          <StyledLabel>Actions</StyledLabel>
                        </Box>
                        <ButtonBox>
                          <CircleButton type="button">
                            <StyledIcon icon={'cogs'} size={'1.5em'} />
                          </CircleButton>
                        </ButtonBox>
                      </Button>
                    </Hide>
                  </Dropdown>
                )}

                {onEditClick && (
                  <Button
                    alignItems="center"
                    justifyContent="flex-end"
                    onClick={onEditClick}
                  >
                    <Box>
                      <StyledLabel>Edit</StyledLabel>
                    </Box>
                    <Box>
                      <CircleButton>
                        <Icon icon={'pencil'} size={'1.5em'} />
                      </CircleButton>
                    </Box>
                  </Button>
                )}

                {onSaveClick && (
                  <SaveButton
                    disabled={disableSave}
                    offset={this.state.offset}
                    ref={this.saveBtnRef}
                    alignItems="center"
                    justifyContent="flex-end"
                    onClick={!disableSave ? onSaveClick : () => {}}
                  >
                    <Box>
                      <StyledFloatingLabel offset={this.state.offset}>
                        Save
                      </StyledFloatingLabel>
                    </Box>
                    <Box>
                      <StyledFloatingCircleButton disabled={disableSave}>
                        <Icon
                          fill={this.state.offset}
                          icon={'check'}
                          size={'2em'}
                        />
                      </StyledFloatingCircleButton>
                    </Box>
                  </SaveButton>
                )}
                {onAddClick && (
                  <Button
                    alignItems="center"
                    justifyContent="flex-end"
                    onClick={onAddClick}
                  >
                    <Box>
                      <StyledLabel>{addTitle}</StyledLabel>
                    </Box>
                    <Box>
                      <CircleButton>
                        <Icon icon={'plus'} size={'2em'} />
                      </CircleButton>
                    </Box>
                  </Button>
                )}
              </Flex>
            </Box>
          </Flex>
        </StyledBlock>
        <LoaderLine loading={loading || submitting} />
      </React.Fragment>
    );
  }
}

ItemPane.propTypes = {
  /**
   * @property {propTypes.string} title - Title
   */
  title: PropTypes.string,
  /**
   * @property {propTypes.string} subtitle - subtitle
   */
  subtitle: PropTypes.string,
  /**
   * @property {propTypes.func} onEditClick - onEditClick
   */
  onEditClick: PropTypes.func,
  /**
   * @property {propTypes.func} onRemoveClick - onRemoveClick
   */
  onRemoveClick: PropTypes.func,
  /**
   * @property {propTypes.func} onSaveClick - onSaveClick
   */
  onSaveClick: PropTypes.func,
  /**
   * @property {propTypes.func} onAddClick - onAddClick
   */
  onAddClick: PropTypes.func,
  /**
   * @property {propTypes.func} onCopylick - onCopylick
   */
  onCopylick: PropTypes.func,
  /**
   * @property {propTypes.bool} loading - Loading indicator
   */
  loading: PropTypes.bool,
};

ItemPane.defaultProps = {
  theme: {
    panelBackground: '#d8d8d8',
    subtitleColor: '##9d9d9d',
  },
};

const FormItemPane = (props) => (
  <FormSpy
    {...props}
    subscription={{
      values: true,
      submitting: true,
      pristine: true,
      valid: true,
      errors: true,
    }}
    component={ItemPane}
  />
);

export default ItemPane;
export { FormItemPane };
