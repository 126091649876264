import _get from 'lodash/get';
import _filter from 'lodash/filter';

export const getDatapointIdFromDevice = (device, type, name) => {
  const sensor = _filter(_get(device, 'sensors', []), (sensor) => {
    return Array.isArray(type)
      ? type.includes(sensor.type)
      : sensor.type === type;
  })[0];

  let pointId;
  if (sensor) {
    const datapoint = _filter(_get(sensor, 'datapoints', []), {
      name,
    })[0];
    pointId = datapoint && datapoint.id ? datapoint.id : null;
  }

  return pointId;
};

export const getDatapointIdFromSensor = (sensor, name) => {
  const datapoint = _filter(_get(sensor, 'datapoints', []), {
    name,
  })[0];
  return datapoint && datapoint.id ? datapoint.id : null;
};

export const getDatapointFromSensor = (sensor, name) => {
  const datapoint = _filter(_get(sensor, 'datapoints', []), {
    name,
  })[0];
  return datapoint ? datapoint : null;
};

export const getSignalStrength = (value) => {
  const rssi = parseInt(value, 10);
  let level;
  console.log('getSignalStrength', rssi);
  switch (true) {
    case rssi >= -59:
      level = '5';
      break;
    case rssi >= -67 && rssi < -59:
      level = '4';
      break;
    case rssi >= -79 && rssi < -67:
      level = '3';
      break;
    case rssi >= -89 && rssi < -79:
      level = '2';
      break;
    case rssi >= -95 && rssi < -89:
      level = '1';
      break;
    case rssi < -95:
      level = '0';
      break;

    default:
      level = '0';
  }

  return level;
};

export const alarmsLabels = {
  '5d11cf34d3dfd7000616b414': 'Device not upright',
  '5cd92a667bada40007c0a84f': 'Too warm in the room',
  '5ccbed2d5771ed0007426ae2': 'Too cold in the room',
  '5cdc0c1ec5096c00078310be': 'Too much noise in the room',
  '5b8cf706c9521a0006f9664c': 'Device is offline',
};

export const ruleList = [
  {
    value: '5d11cf34d3dfd7000616b414',
    label: 'Wrong orientation',
  },
  {
    value: '5b8cf706c9521a0006f9664c',
    label: 'Device disconnected',
  },
  {
    value: '5cd92a667bada40007c0a84f',
    label: 'High temperature',
  },
  {
    value: '5ccbed2d5771ed0007426ae2',
    label: 'Low temperature',
  },
  {
    value: '5cdc0c1ec5096c00078310be',
    label: 'High noise',
  },
];
