import gql from 'graphql-tag';

export const accessoryInfoFragment = gql`
  fragment accessoryInfo on Accessory {
    id
    products
    name
    unitPrice
    imgUrl
    description
    quantity
  }
`;
