import styled from 'styled-components';

/**
 * @render react
 * @name Card
 * @description Caption
 * @example
 * <Card style={{ width: "20em" }}>
    <img
      src="https://images.unsplash.com/photo-1511090724501-e01da99e9869?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4617a9e73808b37e5023619c796df0db&auto=format&fit=crop&w=1234&q=80"
      alt="Pic"
      style={{ width: "100%" }}
    />
    <div style={{ padding: "0.2em 1em" }}>
      <h4>
        <b>Going Nowhere</b>
      </h4>
      <p>Photo by Simon Migaj</p>
    </div>
  </Card>
 */
const Card = styled.div`
  font-family: ${props => props.theme.primaryFont};
  background-color: ${props => props.theme.cardBackground};
  color: ${props => props.theme.cardColor};
  padding: ${props => props.theme.cardPadding};
  box-shadow: ${props => props.theme.cardBoxShadow};
  transition: 0.3s;
  &:hover {
    box-shadow: ${props => props.theme.cardBoxShadowHover};
  }
`;

Card.propTypes = {};

Card.defaultProps = {
  theme: {
    cardBackground: '#fff',
    cardBoxShadow: '0 0.1em 0.2em 0 rgba(0,0,0,0.2)',
    cardBoxShadowHover: '0 0.2em 0.4em 0 rgba(0,0,0,0.2)',
    cardPadding: '0',
    cardColor: '#000000',
  },
};

export default Card;
