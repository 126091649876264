import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const loading = keyframes`
from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
`;

/**
 * @render react
 * @name LoaderLine
 * @description LoaderLine component
 * @example
 * <LoaderLine loading />
 */
const LoaderLine = styled.div`
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.loaderNotActiveBackground};

  &:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: ${props => props.theme.loaderBackground};
    animation: ${props =>
      props.loading ? loading : "none"} 2s linear infinite;
  }
}
`;

LoaderLine.propTypes = {
  /**
   * @property {propTypes.bool} loading - loading animation is running.
   */
  loading: PropTypes.bool
};

LoaderLine.defaultProps = {
  theme: {
    loaderNotActiveBackground: "#ddd",
    loaderBackground: "#0789CC"
  }
};

export default LoaderLine;
