import gql from 'graphql-tag';

export const deviceInfoFragment = gql`
  fragment deviceInfo on Device {
    id
    name
    tag
    product
    lifecycleState
    socketServer
    connectionEvent {
      timestamp
      data {
        after
      }
    }
  }
`;
