import gql from 'graphql-tag';
import { deviceInfoFragment } from '../fragments/devices';

export const GET_DEVICE = gql`
  query device($id: String!) {
    device(id: $id) {
      ...deviceInfo
    }
  }
  ${deviceInfoFragment}
`;
