import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const styles = css`
  font-family: ${(props) => props.theme.primaryFont};
  text-decoration: none;
  font-weight: 500;
  text-transform: uppercase;
  color: ${(props) => props.theme.linkColor};
  cursor: pointer;
`;

const StyledNavLink = styled(({ theme, ...props }) => <NavLink {...props} />)`
  ${styles};
`;

const defaultProps = {
  theme: {
    linkFontSize: '1.375em',
    linkColor: '#0789CC',
    linkFontWeight: '300',
  },
};

StyledNavLink.defaultProps = defaultProps;

const Anchor = styled.a`
  ${styles};
`;
const Button = styled.span`
  ${styles};
`;

Anchor.defaultProps = defaultProps;
Button.defaultProps = defaultProps;

/**
 * @render react
 * @name Link
 * @description Link component
 * @example
 * <Link href="https://livion.fi">Livion</Link>
 */

const Link = ({ theme, ...props }) => {
  if (props.to) {
    return <StyledNavLink {...props} />;
  } else if (props.onClick) {
    return <Button {...props} />;
  } else return <Anchor {...props} />;
};

Link.propTypes = {
  /**
   * @property {propTypes.string} to - uses react-routers NavLink.
   */
  to: PropTypes.string,
};

Link.defaultProps = {
  theme: {
    linkTextDecoration: 'underline',
    linkFontWeight: '500',
    linkTransform: 'none',
    linkColor: '#0027ff',
  },
};

export default Link;
