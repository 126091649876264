import PropTypes from "prop-types";
import styled from "styled-components";
import c from "color";

/**
 * @render react
 * @name CircleButton
 * @description Primary button
 * @example
 * <CircleButton>Example</CircleButton>
 */

const CircleButton = styled.button`
  height: 2.2em;
  width: 2.2em;

  padding: 0;
  margin: 0 auto;
  display: flex;
  transition: 0.2s all;
  text-align: center;
  justify-content: center;
  background: ${props =>
    props.alert ? props.theme.alertColor : props.theme.circleButtonBackground};
  border: none;
  outline: none;
  border-radius: 50%;
  cursor: pointer;
  color: ${props => props.theme.circleButtonTextColor};
  fill: ${props => props.theme.circleButtonTextColor};
  font-size: ${props => props.theme.circleButtonFontSize};
  letter-spacing: ${props => props.theme.circleLetterSpacing};
  font-weight: normal;
  font-family: ${props => props.theme.primaryFont};

  &:active {
    background: ${props =>
      c(props.theme.circleButtonActiveBackground)
        .darken(0.2)
        .string()};
  }
  &:hover:not([disabled]) {
    box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
  &:disabled {
    background: ${props => props.theme.circleButtonDisabledBackground};
    color: ${props => props.theme.circleButtonDisabledColor};
    cursor: inherit;
    > svg {
      fill: ${props => props.theme.circleButtonDisabledColor};
    }
  }
`;

CircleButton.defaultProps = {
  theme: {
    circleButtonBackground: "#0789CC",
    circleButtonDisabledBackground: "#CECECE",
    circleButtonDisabledColor: "#9C9C9C",
    circleButtonTextColor: "#fff",
    circleButtonActiveBackground: "#0789CC",
    circleButtonFontSize: "0.95em",
    circleLetterSpacing: "0.045em"
  }
};

CircleButton.propTypes = {
  /**
   * @property {propTypes.bool} disabled - button to disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * @property {propTypes.bool} alert - button as an alert button.
   */
  alert: PropTypes.bool
};

export default CircleButton;
