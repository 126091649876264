import React, { useCallback, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom';
import _get from 'lodash/get';
import { Flex, Box } from '@rebass/grid';
import {
  CREATE_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
} from '../api/mutations/orders';

import { ORDER_EDIT } from '../api/queries/orders';
import { GET_TAG, GET_TAG_BILLING } from '../api/queries/tags';
import createDecorator from 'final-form-calculate';
import { apolloClient } from '@components/connect-react-lib';
import { useQuery, useMutation } from '@apollo/react-hooks';

import ToasterContext from '@components/contexts/ToasterContext';
import { withLivionConnect } from '@components/contexts/LivionConnectContext';

import DashboardFlexibleFormPage from '@components/pages/DashboardFlexibleFormPage';
import Label from '@atoms/Label';
import Caption from '@atoms/Caption';
import Thumbnail from '@atoms/Thumbnail';
import NumberField from '@components/fields/NumberField';
import SelectField from '@components/fields/SelectField';
import TextField from '@components/fields/TextField';
import CheckBoxField from '@components/fields/CheckboxField';
import Condition from '@components/fields/Condition';
import MultiTagTypeSelectField from '@components/fields/MultiTagTypeSelectField';
import Accordion from '@components/molecules/Accordion';
import ArrayField from '@components/fields/ArrayField';
import Fieldset from '@components/atoms/Fieldset';
import {
  mustBeValidPhoneNumber,
  required,
  mustBeEmail,
  isNumber,
  composeValidators,
} from '@components/utils';

import styled from 'styled-components';
import dayjs from 'dayjs';
import { selectCountries, countries, hws } from '../defaults';
import { GET_ACCESSORIES } from '../api/queries/accessories';

export const isArea = async (value) => {
  const id =
    value[value.length - 1] === '/'
      ? value.substring(0, value.length - 1)
      : value;
  const res = await apolloClient.query({
    query: GET_TAG,
    variables: { id },
    skip: !value,
  });
  const type = _get(res, 'data.tag.type', '');
  return type === 'area' ? undefined : 'Complete destination  is not selected';
};
const StyledHint = styled(Caption)`
  font-size: 1em;
  font-weight: 100;
  color: ${(props) => props.theme.primaryThemeColor};
  padding-top: 0.5em;
  margin-bottom: 1em;
`;
const StyledCreator = styled(Box)``;
const StyledThumbnailBox = styled(Box)`
  padding-top: 1em;
  @media (max-width: 1024px) {
    padding-top: 3em;
  }
  @media (max-width: 768px) {
    padding-top: 1em;
  }
`;
const StyledMessage = styled(Flex)``;
const StyledTime = styled(Box)`
  font-size: 0.8em;
`;
const StyledText = styled(Box)`
  font-size: 1.1em;
  padding-left: 5px;
`;
const timeout = 24 * 60 * 60;
let queryData = null;
let queryLoading = false;
let queryError = null;

const validateOrder = (values) => {
  const errors = {};
  if (
    !values.configuration &&
    (!values.accessories || !values.accessories.length)
  ) {
    errors.configuration = 'Products or Accessories required';
    if (values.accessories && values.accessories.length)
      errors.accessories[0].name = 'Products or Accessories required';
  }
  if (values.configuration && !values.quantity) {
    errors.quantity = 'Required';
  }
  return errors;
};

const Delivery = ({ data }) => (
  <StyledCreator width={[1, 1, 2 / 3]}>
    <Fieldset label="Delivery Information">
      {_get(data, 'order.details.deliveryEstimation') && (
        <Box width={(1, 1, 1 / 2)}>
          <Label>
            <span style={{ fontWeight: 600 }}>Estimated Delivery : </span>
            <span style={{ color: '#FFFFFF' }}>
              {dayjs(_get(data, 'order.details.deliveryEstimation')).format(
                'DD MMM YYYY'
              )}
            </span>
            <br />
          </Label>
        </Box>
      )}
      {_get(data, 'order.details.deliveryDate') && (
        <Box width={(1, 1, 1 / 2)}>
          <Label>
            <span style={{ fontWeight: 600 }}>Delivery Date : </span>
            <span style={{ color: '#FFFFFF' }}>
              {dayjs(_get(data, 'order.details.deliveryDate')).format(
                'DD MMM YYYY'
              )}
            </span>
            <br />
          </Label>
        </Box>
      )}
      {_get(data, 'order.details.trackingId') && (
        <Label>
          <span style={{ fontWeight: 600 }}>trackingId : </span>
          <span style={{ color: '#FFFFFF' }}>
            {_get(data, 'order.details.trackingId')}
          </span>
          <br />
        </Label>
      )}
    </Fieldset>
  </StyledCreator>
);

const Messages = ({ messages }) => (
  <StyledCreator width={[1, 1, 2 / 3]}>
    <Fieldset label="Messages">
      {messages
        .sort((a, b) => a.timestamp - b.timestamp)
        .map(({ timestamp, message }, i) => (
          <StyledMessage key={`message-${i}`}>
            <StyledTime width={[1 / 10]}>
              {dayjs(timestamp).format('DD.MM.YYYY')} :{' '}
            </StyledTime>
            <StyledText width={[9 / 10]} style={{ color: '#FFFFFF' }}>
              {message.split('<br />').map((m, j) => (
                <StyledText key={`text-${j}`}>{m} </StyledText>
              ))}
            </StyledText>
          </StyledMessage>
        ))}
    </Fieldset>
  </StyledCreator>
);

const adressDecorator = createDecorator({
  field: /tag|customAddress/, // when a field matching this pattern changes...
  updates: {
    name: async (ignoredValue, allValues) => {
      const res = !allValues.customAddress
        ? await apolloClient.query({
            query: GET_TAG_BILLING,
            variables: { id: allValues.tag },
            skip: !allValues.tag,
          })
        : {};
      return allValues.customAddress || allValues.unitBilling === 'custom'
        ? allValues.name
        : _get(
            res,
            'data.tag.billingInfo.unit.BuyerPartyDetails.BuyerContactPersonName'
          ) ||
            _get(
              res,
              'data.tag.billingInfo.BuyerPartyDetails.BuyerContactPersonName'
            );
    },
    streetName: async (ignoredValue, allValues) => {
      const res = !allValues.customAddress
        ? await apolloClient.query({
            query: GET_TAG_BILLING,
            variables: { id: allValues.tag },
            skip: !allValues.tag,
          })
        : {};
      const streetName =
        _get(
          res,
          'data.tag.deliveryInfo.unit.DeliveryPartyDetails.DeliveryPostalAddressDetails.DeliveryStreetName'
        ) ||
        _get(
          res,
          'data.tag.deliveryInfo.DeliveryPartyDetails.DeliveryPostalAddressDetails.DeliveryStreetName',
          ''
        ) ||
        _get(
          res,
          'data.tag.billingInfo.unit.BuyerPartyDetails.BuyerPostalAddressDetails.BuyerStreetName'
        ) ||
        _get(
          res,
          'data.tag.billingInfo.BuyerPartyDetails.BuyerPostalAddressDetails.BuyerStreetName'
        );
      return allValues.customAddress || allValues.unitBilling === 'custom'
        ? allValues.streetName
        : streetName;
    },
    townName: async (ignoredValue, allValues) => {
      const res = !allValues.customAddress
        ? await apolloClient.query({
            query: GET_TAG_BILLING,
            variables: { id: allValues.tag },
            skip: !allValues.tag,
          })
        : {};
      const townName =
        _get(
          res,
          'data.tag.deliveryInfo.unit.DeliveryPartyDetails.DeliveryPostalAddressDetails.DeliveryTownName'
        ) ||
        _get(
          res,
          'data.tag.deliveryInfo.DeliveryPartyDetails.DeliveryPostalAddressDetails.DeliveryTownName',
          ''
        ) ||
        _get(
          res,
          'data.tag.billingInfo.unit.BuyerPartyDetails.BuyerPostalAddressDetails.BuyerTownName'
        ) ||
        _get(
          res,
          'data.tag.billingInfo.BuyerPartyDetails.BuyerPostalAddressDetails.BuyerTownName'
        );
      return allValues.customAddress || allValues.unitBilling === 'custom'
        ? allValues.townName
        : townName;
    },
    postCode: async (ignoredValue, allValues) => {
      const res = !allValues.customAddress
        ? await apolloClient.query({
            query: GET_TAG_BILLING,
            variables: { id: allValues.tag },
            skip: !allValues.tag,
          })
        : {};
      const postCode =
        _get(
          res,
          'data.tag.deliveryInfo.unit.DeliveryPartyDetails.DeliveryPostalAddressDetails.DeliveryPostCodeIdentifier'
        ) ||
        _get(
          res,
          'data.tag.deliveryInfo.DeliveryPartyDetails.DeliveryPostalAddressDetails.DeliveryPostCodeIdentifier',
          ''
        ) ||
        _get(
          res,
          'data.tag.billingInfo.unit.BuyerPartyDetails.BuyerPostalAddressDetails.BuyerPostCodeIdentifier'
        ) ||
        _get(
          res,
          'data.tag.billingInfo.BuyerPartyDetails.BuyerPostalAddressDetails.BuyerPostCodeIdentifier'
        );

      return allValues.customAddress || allValues.unitBilling === 'custom'
        ? allValues.postCode
        : postCode;
    },
    country: async (ignoredValue, allValues) => {
      const res = await apolloClient.query({
        query: GET_TAG_BILLING,
        variables: { id: allValues.tag },
        skip: !allValues.tag,
      });
      const countryCode =
        _get(
          res,
          'data.tag.deliveryInfo.unit.DeliveryPartyDetails.DeliveryPostalAddressDetails.CountryCode'
        ) ||
        _get(
          res,
          'data.tag.deliveryInfo.DeliveryPartyDetails.DeliveryPostalAddressDetails.CountryCode',
          ''
        ) ||
        _get(
          res,
          'data.tag.billingInfo.unit.BuyerPartyDetails.BuyerPostalAddressDetails.CountryCode',
          ''
        ) ||
        _get(
          res,
          'data.tag.billingInfo.BuyerPartyDetails.BuyerPostalAddressDetails.CountryCode',
          ''
        );

      return allValues.customAddress || allValues.unitBilling === 'custom'
        ? allValues.country
        : countries[countryCode.toUpperCase()];
    },
  },
});
export const OrderEdit = ({
  history,
  match: {
    params,
    params: { id },
  },
  auth,
}) => {
  const { addToast } = useContext(ToasterContext);
  //const [acc, setAcc] = useState([]);
  let order = useQuery(ORDER_EDIT, {
    variables: {
      id: params.id,
    },
    fetchPolicy: 'network-only',
    skip: !params.id,
  });

  if (order) {
    queryData = order.data;
    queryError = order.error;
    queryLoading = order.loading;
  }

  const [updateOrder] = useMutation(UPDATE_ORDER);
  const [createOrder] = useMutation(CREATE_ORDER);
  const [deleteOrder] = useMutation(DELETE_ORDER);

  if (queryError) {
    addToast({
      type: 'error',
      title: `Error`,
      message: queryError.message,
      timeout,
    });
  }

  // function should be defined with useCallback,
  // it will memoize function and not create it again in every render cycle if there is state or props changes
  const submit = useCallback(
    (order) => {
      console.log(order);
      const accessories = order.accessories
        ? order.accessories.map(({ name, quantity }) => ({
            name,
            quantity: parseInt(quantity, 10),
          }))
        : [];
      const countryCode =
        Object.keys(countries).find((c) => countries[c] === order.country) ||
        order.country;

      let input = {
        notificationReceivers: [
          {
            mail: auth.user.email,
            sms: auth.user.phoneNumber,
            language: 'en-gb',
            activation: true,
            deactivation: true,
            rules: [
              {
                id: '5d78f9cbeb45ff00070b6765', //test 5d78e32a69da0b9929b343e5
                sendEmail: true,
                sendSms: false,
              },
            ],
          },
        ],
        products: order.configuration && [
          {
            name: 'roomrobot',
            configuration: order.configuration,
            quantity: order.quantity,
          },
        ],
        accessories,
        customer: {
          customData: order.unitBilling === 'custom',
          tag: order.tag,
          billingInfo:
            order.unitBilling === 'custom'
              ? {
                  vatRate: 0,
                  paymentTerms: '',
                  sellerAccount: '',
                  vatCode: '',
                  InvoiceRecipientLanguageCode: 'en',
                  BuyerPartyDetails: {
                    BuyerCode: '',
                    BuyerCommunicationDetails: {
                      BuyerEmailaddressIdentifier: order.email,
                      BuyerPhoneNumberIdentifier: order.phoneNumber,
                    },
                    BuyerContactPersonName: order.name,
                    BuyerOrganisationName: order.organisationName,
                    BuyerOrganisationTaxCode: order.taxCode,
                    BuyerOrganisationUnitNumber: order.ovt,
                    BuyerPartyIdentifier: order.partyIdentifier,
                    BuyerPostalAddressDetails: {
                      BuyerPostCodeIdentifier: order.postCode,
                      BuyerStreetName: order.streetName,
                      BuyerTownName: order.townName,
                      CountryCode: countryCode,
                      CountryName: countries[countryCode],
                    },
                  },
                }
              : order.customAddress
              ? {
                  customAddress: true,
                  BuyerPartyDetails: {
                    BuyerContactPersonName: order.name,
                    BuyerPostalAddressDetails: {
                      BuyerPostCodeIdentifier: order.postCode,
                      BuyerStreetName: order.streetName,
                      BuyerTownName: order.townName,
                      CountryCode: countryCode,
                      CountryName: countries[countryCode],
                    },
                  },
                }
              : { nodata: 'noData' },
          deliveryInfo:
            order.unitBilling === 'custom'
              ? {
                  DeliveryPartyDetails: {
                    DeliveryOrganisationName: order.organisationName,
                    DeliveryPostalAddressDetails: {
                      DeliveryStreetName: order.streetName,
                      DeliveryTownName: order.townName,
                      DeliveryPostCodeIdentifier: order.postCode,
                      CountryCode: countryCode,
                      CountryName: countries[countryCode],
                    },
                  },
                }
              : order.customAddress
              ? {
                  customAddress: true,
                  DeliveryPartyDetails: {
                    DeliveryPostalAddressDetails: {
                      DeliveryStreetName: order.streetName,
                      DeliveryTownName: order.townName,
                      DeliveryPostCodeIdentifier: order.postCode,
                      CountryCode: countryCode,
                      CountryName: countries[countryCode],
                    },
                  },
                }
              : { nodata: 'noData' },
        },
      };

      let mutationParams = {
        variables: {
          input: input,
        },
      };
      if (id) {
        mutationParams.variables.id = id;
      }
      if (id) {
        updateOrder(mutationParams)
          .then(
            ({
              data: {
                updateOrder: { id: updatedOrderId },
              },
            }) => {
              addToast({
                type: 'success',
                title: `Success`,
                message: `Order ${id} updated successfully`,
              });
              history.push(`/orders`);
            }
          )
          .catch((error) => {
            addToast({
              type: 'error',
              title: `Error`,
              message: `Order ${id} update
             failed ${error.message}`,
              timeout,
            });
          });
      } else {
        createOrder(mutationParams)
          .then(
            ({
              data: {
                createOrder: { id: updatedOrderId },
              },
            }) => {
              addToast({
                type: 'success',
                title: `Success`,
                message: `Order created successfully`,
              });
              history.push(`/orders`);
            }
          )
          .catch((error) => {
            addToast({
              type: 'error',
              title: `Error`,
              message: `Order create failed ${error.message}`,
              timeout,
            });
          });
      }
    },
    [
      addToast,
      auth.user.email,
      auth.user.phoneNumber,
      createOrder,
      history,
      id,
      updateOrder,
    ]
  );

  const remove = useCallback(() => {
    if (id) {
      deleteOrder({ variables: { id } })
        .then(() => {
          addToast({
            type: 'success',
            title: `Success`,
            message: `Order deleted successfully`,
          });
          history.push(`/orders`);
        })
        .catch((error) => {
          addToast({
            type: 'error',
            title: `Error`,
            message: `Order delete failed ${error.message}`,
            timeout,
          });
        });
    }
  }, [addToast, deleteOrder, history, id]);

  const getInitialValues = (order) => {
    if (order) {
      const {
        customer: { customData, tag, billingInfo },
        products,
        accessories,
      } = order;
      const roomrobot = products.filter((p) => p.name === 'roomrobot');
      const customAddress = _get(billingInfo, 'customAddress', false);
      return {
        customAddress,
        accessories,
        configuration: roomrobot && roomrobot[0] && roomrobot[0].configuration,
        quantity: roomrobot && roomrobot[0] && roomrobot[0].quantity,
        tag,
        unitBilling: customData ? 'custom' : 'default',
        monthlyBilling: 'default',
        organisationName: customData
          ? _get(billingInfo, 'BuyerPartyDetails.BuyerOrganisationName', '')
          : '',
        taxCode: customData
          ? _get(billingInfo, 'BuyerPartyDetails.BuyerOrganisationTaxCode', '')
          : '',
        partyIdentifier: customData
          ? _get(billingInfo, 'BuyerPartyDetails.BuyerPartyIdentifier', '')
          : '',
        name:
          customData || customAddress
            ? _get(billingInfo, 'BuyerPartyDetails.BuyerContactPersonName', '')
            : '',
        email: customData
          ? _get(
              billingInfo,
              'BuyerPartyDetails.BuyerCommunicationDetails.BuyerEmailaddressIdentifier',
              ''
            )
          : '',
        phoneNumber: customData
          ? _get(
              billingInfo,
              'BuyerPartyDetails.BuyerCommunicationDetails.BuyerPhoneNumberIdentifier',
              ''
            )
          : '',
        streetName:
          customData || customAddress
            ? _get(
                billingInfo,
                'BuyerPartyDetails.BuyerPostalAddressDetails.BuyerStreetName',
                ''
              )
            : '',
        townName:
          customData || customAddress
            ? _get(
                billingInfo,
                'BuyerPartyDetails.BuyerPostalAddressDetails.BuyerTownName',
                ''
              )
            : '',
        postCode:
          customData || customAddress
            ? _get(
                billingInfo,
                'BuyerPartyDetails.BuyerPostalAddressDetails.BuyerPostCodeIdentifier',
                ''
              )
            : '',
        country:
          customData || customAddress
            ? _get(
                billingInfo,
                'BuyerPartyDetails.BuyerPostalAddressDetails.CountryCode',
                ''
              )
            : '',
        ovt: customData
          ? _get(
              billingInfo,
              'BuyerPartyDetails.BuyerOrganisationUnitNumber',
              ''
            )
          : '',
      };
    }
  };

  const getState = () => {
    const orderState = _get(queryData, 'order.state', '');
    switch (orderState) {
      case 'waiting for confirmation':
        return 'Waiting for confirmation';
      case 'waiting for delivery':
        return 'Confirmed - Waiting for delivery';
      case 'delivered':
        return 'Delivered';
      default:
        return orderState;
    }
  };

  const handleAccessoryNameChange = (value, changeTitle, form, index) => {
    changeTitle(
      `${
        (accessoriesList.filter((h) => h.value === value)[0] &&
          accessoriesList.filter((h) => h.value === value)[0].label) ||
        value
      } : ${
        (form.getFieldState('accessories') &&
          form.getFieldState('accessories').value &&
          form.getFieldState('accessories').value[index] &&
          form.getFieldState('accessories').value[index].quantity) ||
        0
      } unit (s)`
    );
  };

  const handleAccessoryQuantityChange = (event, changeTitle, form, index) => {
    const type = form.getFieldState('accessories').value[index].name;

    changeTitle(
      `${
        type
          ? accessoriesList.filter((h) => h.value === type)[0] &&
            accessoriesList.filter((h) => h.value === type)[0].label + ' : '
          : ''
      }${event.target.value} unit (s)`
    );
  };

  const handleRemoveAccesory = (form, fields, index) => {
    fields.remove(index);
  };

  const [accessoriesList, setAccessoriesList] = useState([]);
  const onCompleted = useCallback(({ accessories: { edges: data } = [] }) => {
    setAccessoriesList(
      data.map(({ name, imgUrl }) => ({ value: name, label: name, imgUrl }))
    );
  }, []);

  const { error } = useQuery(GET_ACCESSORIES, {
    variables: { filters: { products: _get(queryData, 'order.devices', []) } },
    onCompleted,
  });

  if (error) {
    addToast({
      type: 'error',
      title: `Error`,
      message: `Order ${id} update
             failed ${error.message}`,
      timeout,
    });
  }

  return (
    <DashboardFlexibleFormPage
      initialValues={
        id
          ? getInitialValues(_get(queryData, 'order'))
          : {
              tag: '',
              unitBilling: 'default',
              monthlyBilling: 'default',
            }
      }
      onSubmit={submit}
      title={id ? `Order N° ${id} - ${getState()}` : 'Create Order'}
      subtitle={id ? 'Edit Order' : ''}
      onBack={() => history.push(`/orders`)}
      loading={queryLoading}
      onRemoveClick={
        id && _get(queryData, 'order.state', '') === 'waiting for confirmation'
          ? remove
          : null
      }
      validate={validateOrder}
      decorators={[adressDecorator]}
    >
      {({ form }) => {
        const isConfirmed =
          id &&
          _get(queryData, 'order.state', 'waiting for confirmation') !==
            'waiting for confirmation';

        const customAddress = form.getFieldState('customAddress')
          ? !form.getFieldState('customAddress').value
          : false;
        return (
          <React.Fragment>
            <Flex flexWrap="wrap" flexDirection="column" alignItems="center">
              {id && (
                <StyledCreator width={[1, 1, 2 / 3]}>
                  <Label>
                    <span style={{ fontWeight: 600 }}>Creator : </span>
                    <span style={{ color: '#FFFFFF' }}>
                      {_get(queryData, 'order.creator.email', '')}
                    </span>
                  </Label>
                </StyledCreator>
              )}
              {isConfirmed && <Delivery data={queryData} />}
              {isConfirmed && _get(queryData, 'order.details.messages') && (
                <Messages
                  messages={_get(queryData, 'order.details.messages')}
                />
              )}
              <Box width={[1, 1, 2 / 3]}>
                <MultiTagTypeSelectField
                  validate={composeValidators(required, isArea)}
                  name="tag"
                  label="Select order destination"
                  required={true}
                  disabled={isConfirmed}
                />
              </Box>
              <Box width={[1, 1, 2 / 3]}>
                <Fieldset label="Add products">
                  <StyledHint>
                    <span style={{ fontWeight: 400, fontSize: '1em' }}>
                      Hint :{' '}
                    </span>
                    Multiple RoomRobots are delivered in cardboard boxes that
                    can fit max 8 units. Delivery cost for your organization is
                    minimized if you order larger quantities in multiples of 8.
                    For example 8, 16, 24, 32 etc...
                  </StyledHint>
                  <Flex flexWrap="wrap" style={{ paddingLeft: '2em' }}>
                    <Box width={[1, 1, 1 / 3]}>
                      <SelectField
                        name="configuration"
                        label="Roomrobot type"
                        options={hws}
                        wrapperStyles={{
                          paddingTop: '2em',
                          paddingRight: '1em',
                        }}
                        disabled={isConfirmed}
                        required={true}
                      />
                    </Box>
                    <Box width={[1, 1, 1 / 3]}>
                      <NumberField
                        name={`quantity`}
                        validate={isNumber}
                        label="Quantity"
                        min={1}
                        disabled={isConfirmed}
                        required={true}
                        wrapperStyles={{
                          paddingTop: '2em',
                          paddingRight: '1em',
                        }}
                      />
                    </Box>

                    {form.getFieldState('configuration') &&
                      form.getFieldState('configuration').value && (
                        <StyledThumbnailBox width={[1, 1, 1 / 3]}>
                          <Thumbnail
                            src={
                              hws.filter(
                                (a) =>
                                  a.value ===
                                  form.getFieldState('configuration').value
                              )[0].imgUrl
                            }
                            caption="RoomRobot"
                            height="150px"
                            width="200px"
                            alt="Product image"
                          />
                        </StyledThumbnailBox>
                      )}
                  </Flex>
                </Fieldset>
              </Box>
              <Box width={[1, 1, 2 / 3]}>
                <Fieldset label="Add accessories">
                  <ArrayField name="accessories" disabled={isConfirmed}>
                    {({ fields }) =>
                      fields.map((name, index) => {
                        const type =
                          form.getFieldState('accessories') &&
                          form.getFieldState('accessories').value &&
                          form.getFieldState('accessories').value[index] &&
                          form.getFieldState('accessories').value[index]
                            .configuration;
                        return (
                          <Accordion
                            key={index}
                            remove={() =>
                              handleRemoveAccesory(form, fields, index)
                            }
                            title={
                              type
                                ? `${
                                    (accessoriesList.filter(
                                      (h) => h.value === type
                                    )[0] &&
                                      accessoriesList.filter(
                                        (h) => h.value === type
                                      )[0].label) ||
                                    type
                                  } : ${
                                    form.getFieldState('accessories') &&
                                    form.getFieldState('accessories').value &&
                                    form.getFieldState('accessories').value[
                                      index
                                    ] &&
                                    form.getFieldState('accessories').value[
                                      index
                                    ].quantity
                                  } unit (s)`
                                : 'select accessory'
                            }
                            open={true}
                            disabled={isConfirmed}
                          >
                            {({ changeTitle }) => (
                              <Flex flexWrap="wrap">
                                <Box width={[1, 1, 1 / 3]}>
                                  <SelectField
                                    name={`${name}.name`}
                                    label="Accessory type"
                                    onChange={(value) =>
                                      handleAccessoryNameChange(
                                        value,
                                        changeTitle,
                                        form,
                                        index
                                      )
                                    }
                                    options={accessoriesList}
                                    wrapperStyles={{
                                      paddingTop: '2em',
                                      paddingRight: '1em',
                                    }}
                                    disabled={isConfirmed}
                                    required={true}
                                  />
                                </Box>
                                <Box width={[1, 1, 1 / 3]}>
                                  <NumberField
                                    name={`${name}.quantity`}
                                    validate={composeValidators(
                                      isNumber,
                                      required
                                    )}
                                    label="Quantity"
                                    min={1}
                                    onChange={(event) =>
                                      handleAccessoryQuantityChange(
                                        event,
                                        changeTitle,
                                        form,
                                        index
                                      )
                                    }
                                    disabled={isConfirmed}
                                    required={true}
                                    wrapperStyles={{
                                      paddingTop: '2em',
                                      paddingRight: '1em',
                                    }}
                                  />
                                </Box>
                                {form.getFieldState('accessories').value &&
                                  form.getFieldState('accessories').value[
                                    index
                                  ] &&
                                  form.getFieldState('accessories').value[index]
                                    .name && (
                                    <StyledThumbnailBox width={[1, 1, 1 / 3]}>
                                      <Thumbnail
                                        src={
                                          (_get(
                                            queryData,
                                            'order.accessories'
                                          ) &&
                                            _get(
                                              queryData,
                                              'order.accessories'
                                            )[index] &&
                                            _get(
                                              queryData,
                                              'order.accessories'
                                            )[index].imgUrl) ||
                                          accessoriesList.filter(
                                            (a) =>
                                              a.value ===
                                              form.getFieldState('accessories')
                                                .value[index].name
                                          )[0].imgUrl
                                        }
                                        caption={
                                          _get(
                                            queryData,
                                            'order.accessories'
                                          ) &&
                                          _get(queryData, 'order.accessories')[
                                            index
                                          ] &&
                                          _get(queryData, 'order.accessories')[
                                            index
                                          ].description
                                        }
                                        height="150px"
                                        width="200px"
                                        alt="Accessory image"
                                      />
                                    </StyledThumbnailBox>
                                  )}
                              </Flex>
                            )}
                          </Accordion>
                        );
                      })
                    }
                  </ArrayField>
                </Fieldset>
              </Box>

              <Box width={[1, 1, 2 / 3]}>
                <SelectField
                  validate={required}
                  name="monthlyBilling"
                  label="Monthly fee payer"
                  options={[
                    {
                      value: 'default',
                      label: form.getFieldState('tag')
                        ? `Area - ${
                            form.getFieldState('tag').value &&
                            form.getFieldState('tag').value.split('/')[
                              form.getFieldState('tag').value.split('/')
                                .length - 1
                            ]
                          }`
                        : 'Area',
                    },
                  ]}
                  disabled={true}
                  required={true}
                />
              </Box>
              <Box width={[1, 1, 2 / 3]}>
                <SelectField
                  validate={required}
                  name="unitBilling"
                  label="Products Payer"
                  options={[
                    {
                      value: 'default',
                      label: form.getFieldState('tag')
                        ? form.getFieldState('tag').value &&
                          form.getFieldState('tag').value.includes('forenom')
                          ? 'Forenom leasing (3 Step IT)'
                          : `Area - ${
                              form.getFieldState('tag') &&
                              form.getFieldState('tag').value &&
                              form.getFieldState('tag').value.split('/')[
                                form.getFieldState('tag').value.split('/')
                                  .length - 1
                              ]
                            }`
                        : 'Area',
                    },
                    {
                      value: 'custom',
                      label: 'Enter different payer info (e.g. Landlord) ',
                    },
                  ]}
                  required={true}
                  disabled={isConfirmed}
                />
                {!isConfirmed && (
                  <Condition when="unitBilling" is="default">
                    <CheckBoxField
                      big
                      name="customAddress"
                      label="Custom delivery address"
                      type="checkbox"
                      validateOnInit={true}
                      wrapperStyles={{
                        paddingTop: '2em',
                        paddingRight: '1em',
                      }}
                      disabled={false}
                    />
                  </Condition>
                )}
              </Box>

              <Box width={[1, 1, 2 / 3]}>
                <Condition when="unitBilling" is="default">
                  <Fieldset label="Delivery Address">
                    <Flex flexWrap="wrap">
                      <Box width={[1, 1, 1 / 2]}>
                        <TextField
                          name="name"
                          label="Name"
                          wrapperStyles={{ paddingRight: '10px' }}
                          disabled={customAddress || isConfirmed}
                        />
                      </Box>
                      <Box width={[1, 1, 1 / 2]}>
                        <TextField
                          name="streetName"
                          label="Street name or P.O. box"
                          wrapperStyles={{ paddingRight: '10px' }}
                          disabled={customAddress || isConfirmed}
                        />
                      </Box>
                      <Box width={[1, 1, 1 / 2]}>
                        <TextField
                          name="townName"
                          label="Town name"
                          wrapperStyles={{ paddingRight: '10px' }}
                          disabled={customAddress || isConfirmed}
                        />
                      </Box>
                      <Box width={[1, 1, 1 / 2]}>
                        <TextField
                          name="postCode"
                          label="Postal code"
                          wrapperStyles={{ paddingRight: '10px' }}
                          disabled={customAddress || isConfirmed}
                        />
                      </Box>
                      <Box width={[1, 1, 1 / 2]}>
                        <SelectField
                          name="country"
                          label="Country"
                          options={selectCountries}
                          wrapperStyles={{ paddingRight: '10px' }}
                          disabled={customAddress || isConfirmed}
                        />
                      </Box>
                    </Flex>
                  </Fieldset>
                </Condition>
              </Box>
            </Flex>
            <Flex>
              <Box width={[1, 1, 1]}>
                <Condition when="unitBilling" is="custom">
                  <Fieldset label="Enter products billing details">
                    <Fieldset label="Personal details">
                      <Flex flexWrap="wrap">
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            validate={required}
                            name="name"
                            label="Name"
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            validate={mustBeEmail}
                            name="email"
                            label="Email"
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            validate={mustBeValidPhoneNumber}
                            name="phoneNumber"
                            label="Phone Number"
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                      </Flex>
                    </Fieldset>
                    <Fieldset label="Organization details">
                      <Flex flexWrap="wrap">
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            validate={required}
                            name="organisationName"
                            label="Organisation name"
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            validate={required}
                            name="taxCode"
                            label="VAT code"
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            validate={required}
                            name="partyIdentifier"
                            label="Business ID"
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            name="ovt"
                            label="OVT"
                            wrapperStyles={{ paddingRight: '10px' }}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            name="streetName"
                            label="Street name or P.O. box"
                            validate={required}
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            name="townName"
                            label="Town name"
                            validate={required}
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <TextField
                            name="postCode"
                            label="Postal code"
                            validate={required}
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                        <Box width={[1, 1, 1 / 2]}>
                          <SelectField
                            name="country"
                            label="Country"
                            validate={required}
                            options={selectCountries}
                            wrapperStyles={{ paddingRight: '10px' }}
                            required={true}
                            disabled={isConfirmed}
                          />
                        </Box>
                      </Flex>
                    </Fieldset>
                  </Fieldset>
                </Condition>
              </Box>
            </Flex>
          </React.Fragment>
        );
      }}
    </DashboardFlexibleFormPage>
  );
};

export default withRouter(withLivionConnect(OrderEdit));
