import gql from 'graphql-tag';
export const GET_TAG = gql`
  query tag($id: String!) {
    tag(id: $id) {
      id
      name
      type
      meta
      hasChildren
      tagParents
    }
  }
`;

export const GET_TAG_BILLING = gql`
  query tag($id: String!) {
    tag(id: $id) {
      id
      name
      type
      meta
      tagParents
      billingInfo
      deliveryInfo
    }
  }
`;
