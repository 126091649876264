import firebase from 'firebase/app';
// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import qs from 'qs';
import { apolloClient, setTag, getTag } from './server-api';
import Cookies from 'universal-cookie';
import { GET_USER } from '../api/queries/users';
const cookies = new Cookies();

export { apolloClient };
export { getCurrentUser, getIdToken } from './authentication';

let AUTH_URI,
  API = process.env.REACT_APP_CONNECTAPI,
  PRIVATE_ROOT;

const checkConfiguration = () => {
  if (!AUTH_URI) {
    AUTH_URI = `${window.location.protocol}//${window.location.host}/auth`;
  }
  if (!API) {
    API = `${window.location.protocol}//${window.location.host}/api`;
  }
};
// eslint-disable-next-line no-unused-vars
const listenCustomClaimsChanges = (user) => {
  const userRef = firebase
    .database()
    .ref('/users/' + user.uid + '/customClaimChanged');
  userRef.on('value', (snapshot) => {
    if (snapshot.val()) {
      user.getIdToken(true);
      userRef.set(false);
    }
  });
};

export const getUserNotification = async (notificationId) => {
  let value = false;
  //console.log('getting user notification');
  const user = await firebase.auth().currentUser;
  await firebase
    .database()
    .ref(`/users/${user.uid}/notifications/${notificationId}`)
    .once('value', (snapshot) => {
      if (snapshot.exists()) {
        //console.log('exists!');
        value = true;
      }
    });

  return value;
};
export const setUserNotification = async (notificationId) => {
  const user = await firebase.auth().currentUser;
  const notificationRef = await firebase
    .database()
    .ref(`/users/${user.uid}/notifications/${notificationId}`);
  return notificationRef.set(true);
};

export const getRedirect = () => {
  try {
    let orgR = new URL(qs.parse(window.location.search)['?redirect']);
    const orgParams = qs.parse(orgR.search);
    const params = Object.assign(orgParams, {
      //email: firebase.auth().currentUser.email,
    });
    const redirect = `${orgR.protocol}//${orgR.host}${
      orgR.pathname
    }?${qs.stringify(params)}`;
    return redirect;
  } catch (err) {
    return null;
  }
};

export const setAuthToken = async () => {
  let idToken = await firebase.auth().currentUser.getIdToken();
  const now = new Date();
  const exp = new Date(now.getTime() + 3600 * 1000); // expire in one hour

  cookies.set('auth', idToken, {
    domain: document.domain,
    expires: exp,
    path: '/',
    secure: true,
    sameSite: 'none',
    //secure: false, //process.env.NODE_ENV === 'production' ? true : false,
  });
};

export const init = (opts, callback) => {
  // Configure Firebase.

  var config = {
    apiKey: opts.REACT_APP_FIREBASE_apiKey,
    authDomain: opts.REACT_APP_FIREBASE_authDomain,
    databaseURL: opts.REACT_APP_FIREBASE_databaseURL,
    projectId: opts.REACT_APP_FIREBASE_projectId,
    storageBucket: opts.REACT_APP_FIREBASE_storageBucket,
    messagingSenderId: opts.REACT_APP_FIREBASE_messagingSenderId,
  };

  if (window.Cypress) {
    // Firebase needed in Cypress context
    global.firebase = firebase.initializeApp(config);
  } else {
    firebase.initializeApp(config);
  }

  AUTH_URI = opts.REACT_APP_AUTH_URI;
  API = opts.REACT_APP_API;
  /* react create app set PUBLIC_URL from package.json homepage*/
  PRIVATE_ROOT = opts.PUBLIC_URL || '/';
  checkConfiguration();

  const redirect = getRedirect();
  let unregisterAuthObserver = firebase
    .auth()
    .onAuthStateChanged(async function (user) {
      unregisterAuthObserver();
      if (user) listenCustomClaimsChanges(user); //force refresh token if claims is changed
      if (user && redirect) {
        // user is alread logged in and there is redirect request
        await setAuthToken();
        window.location = redirect;
      } else {
        if (callback) callback(apolloClient);
      }
    });
};

export const logoutLocally = async () => {
  /*  await firebase.auth().signOut();
  cookies.remove("auth", {
    path: "/"
  });
  window.location = PRIVATE_ROOT;*/
};

// eslint-disable-next-line no-unused-vars
const logoutUrl = (opts) => {
  checkConfiguration();
  return `${AUTH_URI}/logout?redirect=${opts.redirectUrl}&api=${opts.api}`;
};

export const logout = async () => {
  checkConfiguration();
  await firebase.auth().signOut();
  setTag(null);
  if (process.env.REACT_APP_COMMONAPPS_IN_USE === 'true') {
    cookies.remove('x-tag', { path: '/' });
    cookies.remove('x-role', { path: '/' });
    cookies.remove('x-admin', { path: '/' });
    window.location.href = '/apps/';
  }
  if (process.env.REACT_APP_LEGACYAPPS_IN_USE === 'true') {
    cookies.remove('x-tag', { path: '/' });
    cookies.remove('x-role', { path: '/' });
    cookies.remove('x-admin', { path: '/' });
    window.location.href = '/';
  }
};

export const selectTag = setTag;
export const getSelectedTag = getTag;
export const getUserRole = async ({ uid }, selecteTag) => {
  try {
    const res = await apolloClient.query({
      query: GET_USER,
      variables: { id: uid },
      fetchPolicy: 'no-cache',
    });
    return { role: res.data.user.role, acl: res.data.user.acl };
  } catch (err) {
    console.log('error fetching user role', (err && err.message) || err);
    return null;
  }
};

export const getUserClaims = async () => {
  try {
    const user = firebase.auth().currentUser;
    if (user) {
      const res = await apolloClient.query({
        query: GET_USER,
        variables: { id: user.uid },
        fetchPolicy: 'no-cache',
      });
      return res.data.user.claims || {};
    }
  } catch (err) {
    console.log('error fetching user claims', (err && err.message) || err);
    return {};
  }
};
export const getUserTags = async () => {
  const claims = await getUserClaims();
  return claims.userTags;
};

export const authUser = () => {
  if (firebase.auth().currentUser) {
    return firebase.auth().currentUser;
  }
};

export const AuthRoute = ({ component, publicRoute, ...props }) => {
  checkConfiguration();

  let { isPublic } = component;
  isPublic = isPublic || publicRoute;

  if (authUser()) {
    //User is Authenticated
    if (isPublic !== true) {
      //If the route is private the user may proceed.
      return <Route {...props} component={component} />;
    } else {
      //If the route is public, the user is redirected to the app's private root.
      return <Redirect to={PRIVATE_ROOT} />;
    }
  } else {
    //User is not Authenticated
    if (isPublic !== true && props.path !== '/auth/login') {
      /*const PUBLIC_ROOT = loginUrl({
        redirectUrl: window.location,
        api: API,
      });*/
      const PUBLIC_ROOT = '/auth/login';

      //If the route is private the user is redirected to the app's public root.
      //window.location = PUBLIC_ROOT;
      //return null;
      return <Redirect to={PUBLIC_ROOT} />;
    } else {
      //If the route is public, the user may proceed.
      return <Route {...props} component={component} />;
    }
  }
};

const { element, func } = PropTypes;

AuthRoute.propTypes = {
  component: PropTypes.oneOfType([element, func]).isRequired,
};
