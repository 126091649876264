import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import _get from 'lodash/get';
import Label from '@components/atoms/Label';
import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import Button from '@atoms/Button';
import { withRouter } from 'react-router';
import { GET_TAG } from '../../api/queries/tags.js';
import { countries, hws } from '../../defaults';

const delay = (ms) => {
  //console.log('waiting...', ms);
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};
const StyledButton = styled(Button)`
  font-size: 12px;
`;

const StyledCaption = styled(Caption)`
  padding: 0.2em 0.8em 0.5em 0;
`;

const BoxWithRightRuler = styled(Box)`
  border-right: ${(props) => props.theme.cardBoxRuler};
  margin-right: 2em;
  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    border-right: none;
    margin-right: inherit;
  }
`;
const StyledCard = styled(Card)`
  border-left: ${(props) => props.theme.cardLeftBorder};
  padding: 2em;
  position: 'relative';
`;

const StyledLabel = styled(Label)`
  color: ${(props) => props.theme.primaryThemeColor};
  display: block;
  text-transform: none;
  &:empty::after {
    content: '';
    display: block;
    width: ${(props) => (props.short ? '40%' : '60%')};
    height: 1.7em;
    border-radius: 2px;
    background: rgba(216, 216, 216, 0.5);
    margin: 0.2em 0;
  }
`;

const LabelHeading = styled.p`
  display: inline;
  font-weight: bold;
  color: ${(props) => props.theme.labelBoldColor};
`;
export const InfoCard = ({
  title,
  info,
  history,
  match: { params },
  user,
  rebootDevice,
  repeaterSetup,
  addToast,
  deviceStatus,
  tagObjects: { organization },
}) => {
  const wifi = _get(info, 'device.settings.data.huawei-router.wlan', null);
  const secondary = _get(info, 'device.meta.secondary');
  const isGliRouter =
    _get(info, 'device.hwConfiguration.router', '') === 'glinet';
  const extras = _get(info, 'device.extras', []);
  const highSpeed = extras.some(
    (e) => e.name === '50mb' && e.state === 'enabled'
  );
  const cellularState = _get(info, 'device.meta.cellularState');
  const isTerminated =
    _get(info, 'device.lifecycleEvent.data.after.state') === 'deactivated' &&
    _get(info, 'device.lifecycleEvent.data.after.reason') === 'terminated';

  const tag = _get(info, 'device.tag');
  const config = _get(info, 'device.configuration');
  const type = hws.filter((t) => t.value === config);
  const [reboot, setReboot] = useState(false);
  const [repeaterConfiguration, setRepeaterConfiguration] = useState(false);
  const [apartmentLoading, setApartmentLoading] = useState(true);

  const [apartment, setApartment] = useState({});

  const installWithApartmentId = _get(
    organization,
    'meta.public.installWithApartmentId',
    false
  );

  const client = useApolloClient();

  useEffect(() => {
    async function fetchTag() {
      try {
        const result = await client.query({
          query: GET_TAG,
          variables: { id: tag },
          fetchPolicy: 'no-cache',
        });

        const tagData = _get(result, 'data');
        setApartment({
          id: _get(tagData, 'tag.meta.apartment.id'),
          address: _get(tagData, 'tag.meta.apartment.address'),
          postCode: _get(tagData, 'tag.meta.apartment.postCode'),
          city: _get(tagData, 'tag.meta.apartment.city'),
          country:
            countries[
              _get(tagData, 'tag.meta.apartment.countryCode') &&
                _get(tagData, 'tag.meta.apartment.countryCode').toUpperCase()
            ],
          area: _get(tagData, 'tag.meta.apartment.officeName'),
        });

        setApartmentLoading(false);
      } catch (error) {
        addToast({
          type: 'error',
          title: `Error`,
          message: error.message,
        });
      }
    }
    if (organization && installWithApartmentId && tag) fetchTag();
    return function cleanup() {};
  }, [addToast, client, installWithApartmentId, organization, tag]);

  useEffect(() => {
    if (organization && !installWithApartmentId) {
      setApartment({
        address: _get(info, 'device.meta.apartment.address'),
        postCode: _get(info, 'device.meta.apartment.postCode'),
        city: _get(info, 'device.meta.apartment.city'),
        country:
          countries[
            _get(info, 'device.meta.apartment.countryCode') &&
              _get(info, 'device.meta.apartment.countryCode').toUpperCase()
          ],
        //area: AREA NOT supported currently
      });
      setApartmentLoading(false);
    }
    return function cleanup() {};
  }, [organization, info, installWithApartmentId]);

  return (
    <StyledCard title={title}>
      <StyledCaption>Device info</StyledCaption>
      <Flex flexWrap={['wrap', null]}>
        <BoxWithRightRuler flex="1 1 auto">
          <StyledLabel short>
            <LabelHeading>Internet connection: </LabelHeading>
            {`${
              isTerminated
                ? 'Terminated'
                : cellularState
                ? 'Active'
                : 'Deactive'
            }`}
          </StyledLabel>
          <StyledLabel short>
            {wifi && <LabelHeading>Landing page: </LabelHeading>}
            {wifi && `${wifi.landingpage ? 'Enabled' : 'Disabled'}`}
          </StyledLabel>
          <StyledLabel short>
            {wifi && <LabelHeading>Wifi state: </LabelHeading>}
            {wifi && `${wifi.state ? 'Enabled' : 'Disabled'}`}
          </StyledLabel>
          <StyledLabel>
            {wifi && <LabelHeading>Network Name(ssid): </LabelHeading>}
            {wifi && `${wifi.networkname}`}
          </StyledLabel>
          <StyledLabel short>
            {wifi && <LabelHeading>Password: </LabelHeading>}
            {wifi && `${wifi.password}`}
          </StyledLabel>
        </BoxWithRightRuler>
        <BoxWithRightRuler flex="1 1 auto">
          {!apartmentLoading && (
            <React.Fragment>
              <StyledLabel short>
                {wifi && <LabelHeading>Type: </LabelHeading>}
                {wifi && `${(type[0] && type[0].label) || config}`}
              </StyledLabel>

              <StyledLabel short>
                <LabelHeading>Max speed: </LabelHeading>
                {highSpeed ? '50 Mb/s' : '50 Mb/s'}
              </StyledLabel>

              {secondary && (
                <StyledLabel short>
                  {<LabelHeading>Secondary: </LabelHeading>}
                  true
                </StyledLabel>
              )}
              {apartment.id && (
                <StyledLabel short>
                  <LabelHeading>Apartment Id: </LabelHeading>
                  {`${apartment.id}`}
                </StyledLabel>
              )}
              <StyledLabel short>
                <LabelHeading>Address: </LabelHeading>
                {`${apartment.address} ${apartment.postCode} ${apartment.city}`}
              </StyledLabel>

              <StyledLabel short>
                <LabelHeading>Country: </LabelHeading>
                {`${apartment.country}`}
              </StyledLabel>
              {apartment.area && (
                <StyledLabel short>
                  <LabelHeading>Area: </LabelHeading>
                  {`${apartment.area}`}
                </StyledLabel>
              )}
            </React.Fragment>
          )}
        </BoxWithRightRuler>
        <Box width="210px">
          <Flex
            style={{ height: '100%' }}
            justifyContent="center"
            flexDirection="column"
          >
            <StyledButton
              secondary
              onClick={() => history.push(`/devices/${params.id}/edit`)}
            >
              Edit configuration
            </StyledButton>
            {installWithApartmentId && (
              <StyledButton
                secondary
                onClick={() =>
                  history.push(
                    `/devices/${params.id}/apartment/${encodeURIComponent(tag)}`
                  )
                }
              >
                Change/Uninstall apartment
              </StyledButton>
            )}

            <StyledButton
              secondary
              onClick={async () => {
                setRepeaterConfiguration(true);
                try {
                  const { status, message } = await repeaterSetup(
                    _get(info, 'device.id')
                  );

                  addToast({
                    type: status === 'err' ? 'error' : 'info',
                    title: status === 'err' ? 'Error' : 'Info',
                    message,
                    timeout: status === 'err' ? 10 : 5,
                  });
                } catch (error) {
                  addToast({
                    type: 'error',
                    title: 'Error',
                    message: `${(error && error.message) || error}`,
                    timeout: 10,
                  });
                }

                setRepeaterConfiguration(false);
              }}
              disabled={
                deviceStatus !== 'connected' ||
                reboot ||
                repeaterConfiguration ||
                !isGliRouter
              }
            >
              {!isGliRouter
                ? 'Repeater auto install not supported'
                : repeaterConfiguration
                ? 'Looking for repeater..'
                : 'Install repeater'}{' '}
            </StyledButton>
            <StyledButton
              secondary
              onClick={async () => {
                //console.log('setting reboot to true');
                setReboot(true);
                await rebootDevice(_get(info, 'device.id'));
                addToast({
                  type: 'info',
                  title: 'Info',
                  message: `Reboot command sent. Device may take few minutes to reconnect.`,
                  timeout: 5,
                });
                await delay(60 * 1000);

                //console.log('setting reboot to false');
                setReboot(false);
              }}
              disabled={
                deviceStatus !== 'connected' || reboot || repeaterConfiguration
              }
            >
              {reboot && deviceStatus === 'connected'
                ? 'Rebooting device..'
                : 'Reboot device'}
            </StyledButton>
          </Flex>
        </Box>
      </Flex>
    </StyledCard>
  );
};

export default withRouter(InfoCard);
