import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'react-final-form-arrays';

import Button from '../../atoms/Button';
import { composeValidators } from '../../utils';

const checkEquals = (a, b) => {
  if (a && b && a.length !== b.length) return false;

  return true;
};

const ArrayField = ({
  name,
  children,
  add,
  addText,
  model = {},
  validate,
  disabled,
}) => {
  return (
    <FieldArray
      subscription={{ value: false, submitting: true, valid: true }}
      name={name}
      validate={composeValidators(validate)}
      isEqual={checkEquals}
    >
      {arrayFields => [
        children(arrayFields),
        add && (
          <Button
            key={`${arrayFields.fields.name}`}
            type="button"
            disabled={disabled}
            onClick={() =>
              arrayFields.fields.push({
                ...model,
                tempCreateTime: Date.now(),
              })
            }
          >
            {addText}
          </Button>
        ),
      ]}
    </FieldArray>
  );
};

ArrayField.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  add: PropTypes.bool,
  addText: PropTypes.string,
  model: PropTypes.any,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

ArrayField.defaultProps = {
  validate: () => {},
  label: null,
  add: true,
  addText: 'Add',
  model: undefined,
};

export default ArrayField;
