import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { Line } from 'recharts';
import { withTheme } from 'styled-components';

import Card from '@components/atoms/Card';
import Caption from '@components/atoms/Caption';
import LoaderLine from '@atoms/LoaderLine';
import SkeletonLoadingCard from '@components/cards/SkeletonLoadingCard';
import ControlledChart from '@organisms/ControlledChart';

const StyledBox = styled(Box)`
  margin-top: 2em;
  ${(props) =>
    props.zoomed &&
    `
    width:100%;
    height:100%;
  `};
  ${(props) =>
    props.hidden &&
    `
    display:none;
  `};
`;

const StyledLoader = styled(LoaderLine)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const StyledCard = styled(Card)`
  position: relative;
  border-left: ${(props) => props.theme.cardLeftBorder};
`;

const StyledFlex = styled(Flex)`
  height: 100%;
`;

const SoundGraphCard = (props) => {
  const [resolutions] = useState(['month', 'day', 'hour']);

  const {
    hidden,
    device,
    onDateChange,
    start,
    end,
    resolution,
    loading,
    mined,
    theme,
  } = props;

  const title = 'dBm Measurements';
  if (!device) return <SkeletonLoadingCard title={title} {...props} />;

  return (
    <StyledBox hidden={hidden} p={'.2em'} flex="1 1 auto" width={[1]}>
      <StyledCard title={title} style={{ position: 'relative' }}>
        <Caption>{title}</Caption>
        {loading && <StyledLoader loading />}
        <StyledFlex flexDirection="column" alignItems="flex-start">
          <ControlledChart
            data={mined}
            dataKey="formattedDate"
            start={start}
            end={end}
            onDateChange={onDateChange}
            resolution={resolution}
            resolutions={resolutions}
          >
            <Line
              connectNulls={resolution === 'minute'}
              isAnimationActive={false}
              dataKey="soundBackgroundLevel"
              stroke={theme.chartLineStrokeColor}
            />
            <Line
              connectNulls={resolution === 'minute'}
              isAnimationActive={false}
              dataKey="soundAverage"
              stroke="#5B5B5B"
            />
            <Line
              connectNulls={resolution === 'minute'}
              isAnimationActive={false}
              dataKey="soundMaxLevel"
              stroke="#5B5B5B"
            />
          </ControlledChart>
        </StyledFlex>
      </StyledCard>
    </StyledBox>
  );
};

export default withTheme(SoundGraphCard);
