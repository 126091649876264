import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from '@rebass/grid';
import dayjs from 'dayjs';

import Chart from '../../atoms/Chart';
import DateRange from '../../atoms/DateRange';
import Legends from '../../atoms/Legends';
import ToggleButtonGroup from '../../molecules/ToggleButtonGroup';
import ToggleButton from '../../atoms/ToggleButton';
import DatePicker from '../../atoms/DatePicker';
import Input from '../../atoms/Input';

export class ChartController extends Component {
  static defaultProps = {
    start: PropTypes.object,
    end: PropTypes.object,
    resolution: PropTypes.string,
  };

  static defaultProps = {
    start: null,
    end: null,
    resolution: 'day',
  };

  state = {
    start: this.props.start,
    end: this.props.end,
    resolution: this.props.resolution,
  };

  onDateChange = ({ from, to }) => {
    if (from && to) {
      this.setState({
        start: from,
        end: to,
      });
    }
  };

  onResolutionChange = resolution => {
    this.setState({ resolution });
  };

  render() {
    const { render } = this.props;
    const { start, end, resolution } = this.state;
    const { onDateChange, onResolutionChange } = this;

    return render({
      start,
      end,
      resolution,
      onDateChange,
      onResolutionChange,
    });
  }
}
class ControlledChart extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    dataKey: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    legends: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.objectOf(PropTypes.bool),
    ]),
    start: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    end: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onDateChange: PropTypes.func,
    onResolutionChange: PropTypes.func,
    resolution: PropTypes.string,
    resolutions: PropTypes.array,
    onLegendChange: PropTypes.func,
    tooltip: PropTypes.func,
  };

  static defaultProps = {
    legends: false,
    start: null,
    end: null,
    onDateChange: () => {},
    onResolutionChange: () => {},
    onLegendChange: () => {},
    resolution: '',
    resolutions: [],
    tooltip: null,
  };

  constructor(props) {
    super(props);

    if (typeof props.legends === 'object') {
      this.state = { legends: props.legends };
    } else if (typeof props.legends !== 'object') {
      const legends = Array.isArray(props.children)
        ? props.children.reduce(
            (acc, { props }) => ({ ...acc, [props.dataKey]: true }),
            {}
          )
        : { [props.children.props.dataKey]: true };

      this.state = { legends };
    } else {
      this.state = {
        legends: false,
      };
    }

    this.state.resolution = props.resolution || 'day';

    this.state.start = props.start
      ? dayjs(props.start)
      : dayjs().startOf('day');
    this.state.end = props.end ? dayjs(props.end) : dayjs().endOf('day');

    this.state.hour = dayjs().hour();
  }

  componentDidUpdate({ dataKey, legends, start, end, resolution }) {
    const state = {};
    if (legends !== this.props.legends) {
      state.legends = legends;
    }

    if (start !== this.state.start) state.start = start;

    if (end !== this.state.end) state.end = end;

    if (resolution !== this.state.resolution) state.resolution = resolution;

    if (Object.keys(state).length > 0) this.setState({ ...state });
  }

  handleLegendToggle = ({ name, value }) => {
    const payload = { legends: { ...this.state.legends, [name]: value } };
    this.setState(payload);
    this.props.onLegendChange(payload);
  };

  onResolutionChange = resolution => {
    let start = this.state.start;
    let end = this.state.end;
    let hour = this.state.hour;
    if (resolution === 'minute') {
      start = dayjs(start)
        .set('hour', hour)
        .startOf('hour');
      end = dayjs(start).endOf('hour');
    }
    if (resolution === 'day') {
      //start = dayjs(end).startOf("day");
      //end = dayjs(end).endOf("day");
    }
    if (resolution === 'hour') {
      start = dayjs(end).startOf('day');
      end = dayjs(end).endOf('day');
    }
    if (resolution === 'month') {
      start = dayjs(end).startOf('month');
      end = dayjs(end).endOf('month');
    }

    this.setState({ start, end, resolution, hour });
    this.props.onDateChange({ from: start, to: end, resolution });
  };

  onDateChange = ({ start, end, hour }) => {
    //console.log("onDateChange", start, end);
    let s = start;
    let e = end;
    const h = !isNaN(hour) ? hour : this.state.hour;
    if (this.state.resolution === 'minute') {
      s = dayjs(s)
        .set('hour', h)
        .startOf('hour');
      e = dayjs(s).endOf('hour');
    }

    //console.log({ start: s, end: e, hour: h });
    this.setState({ start: s, end: e, hour: h });
    this.props.onDateChange({
      from: s,
      to: e,
      resolution: this.state.resolution,
      hour: hour || this.state.hour,
    });
  };

  onHourChanged = e => {
    const hour = parseInt(e.target.value);
    const s = dayjs(this.state.start)
      .set('hour', hour)
      .startOf('hour');
    this.onDateChange({
      start: s,
      end: dayjs(s).endOf('hour'),
      hour,
    });
  };

  render() {
    const { data, dataKey, children, resolutions, tooltip } = this.props;
    const { legends, start, end, resolution } = this.state;

    const filteredChildren = Array.isArray(children)
      ? children.filter(({ props }) => legends[props.dataKey])
      : legends[children.props.dataKey]
      ? children
      : null;

    return (
      <Flex flexWrap="wrap">
        <Box width={1} p={4}>
          <Flex justifyContent="space-between" flexWrap="wrap">
            <Box>
              <ToggleButtonGroup
                style={{ lineHeight: '50px' }}
                selected={resolution}
                onChange={this.onResolutionChange}
              >
                {resolutions.map((r, i) => (
                  <ToggleButton key={`toggle-${i}`} value={r}>
                    {r}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>
            <Box>
              {resolution !== 'minute' && (
                <DateRange
                  start={start}
                  end={end}
                  onChange={({ from, to }) =>
                    this.onDateChange({ start: dayjs(from), end: dayjs(to) })
                  }
                />
              )}
              {resolution === 'minute' && (
                <DatePicker
                  value={dayjs(start).format('DD.MM.YYYY')}
                  onChange={v => {
                    this.onDateChange({
                      start: dayjs(v),
                      end: dayjs(v).endOf(
                        resolution === 'minute' ? 'hour' : 'day'
                      ),
                    });
                  }}
                />
              )}
            </Box>
            {resolution === 'minute' && (
              <Box>
                Hour:
                <Input
                  style={{ display: 'inline-block', width: 'auto' }}
                  type="native-select"
                  value={this.state.hour}
                  onChange={this.onHourChanged}
                >
                  {Array(24)
                    .fill()
                    .map((o, i) => (
                      <option key={`option-${i}`} vaĺue={`${i}`}>
                        {i}
                      </option>
                    ))}
                </Input>
              </Box>
            )}
          </Flex>
        </Box>
        <Box mt="1em" mb="1em" style={{ height: 400 }} width={1}>
          <Chart tooltip={tooltip} data={data} dataKey={dataKey}>
            {filteredChildren}
          </Chart>
        </Box>

        {/*<Box width={1} px="2rem" mt="1rem">
          <Flex justifyContent="flex-start">
            <Box mr="1rem">
              {legends && (
                <Legends
                  items={legends}
                  onChange={this.handleLegendToggle}
                  toggleable
                />
              )}
            </Box>
          </Flex>
        </Box>*/}
      </Flex>
    );
  }
}

export default ControlledChart;
