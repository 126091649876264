import React, { useContext, useState, useEffect } from 'react';
import _get from 'lodash.get';
import ToasterContext from '@components/contexts/ToasterContext';
import { withLivionConnect } from '@components/contexts/LivionConnectContext';
import Accordion from '@components/molecules/Accordion';
import Block from '@components/atoms/Block';
import { UI_NOTIFICATIONS } from '../api/queries/ui-notifications';
import { useQuery } from '@apollo/react-hooks';
import { Flex, Box } from '@rebass/grid';
import DashboardDefaultPage from '@components/pages/DashboardDefaultPage';
import styled from 'styled-components';
import { getIconFromType } from '@components/utils';
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const StyledIcon = styled(Box)`
  padding: 0 1em;
`;
const StyledTitle = styled.div`
  color: ${({ read, type, theme }) => {
    if (read) {
      return '#fff';
    } else {
      switch (type) {
        case 'info':
        case 'news':
        case 'update':
          return '#00BCF1';
        case 'warning':
          return '#f0c534';
        case 'incident-network':
        case 'incident-maintenance':
        case 'incident-broken':
          return '#f46060';
        case 'resolution':
          return '#9bde7e';

        default:
          return '#5a5a5a';
      }
    }
  }};
  font-weight: ${({ read }) => (read ? 400 : 800)};
  font-size: ${({ read, theme }) => (read ? '1em' : '1.2em')};
`;
const StyledContent = styled.div`
  color: color: ${({ read, theme }) =>
    read ? theme.primaryThemeColor : '#fff'};
  padding: 1em 1em;
`;
const StyledEmpty = styled(Block)`
  color: #fff;
  font-size: 1.2em;
  text-align: center;
`;
const StyledBlock = styled(Block)`
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints ? theme.breakpoints[0] : '40em'}) {
    padding: 0.4em;
  }
`;

const Notification = ({
  discardable,
  title,
  updatedAt,
  type,
  id,
  content,
  getUserNotification,
  setUserNotification,
}) => {
  const [read, setRead] = useState(true);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const checkNotification = async () => {
      const read = await getUserNotification(id);
      setLoading(false);
      setRead(read);
    };
    checkNotification();
  }, [getUserNotification, id]);

  const renderTitle = () => {
    return (
      <StyledTitle read={read} type={type}>
        <Flex alignItems="center">
          <StyledIcon>{getIconFromType(type)}</StyledIcon>

          <Box>{`${dayjs(updatedAt)
            .utc()
            .local()
            .format('YYYY-MM-DD HH:mm')} - ${title}`}</Box>
        </Flex>
      </StyledTitle>
    );
  };
  const handleDiscard = async () => {
    if (!read && discardable) {
      await setUserNotification(id);
      setRead(true);
    }
  };
  return (
    !loading && (
      <Accordion renderTitle={renderTitle} onClick={handleDiscard}>
        <StyledContent
          read={read}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Accordion>
    )
  );
};
const NotificationCenter = ({
  history,
  state: { user, navigationItems },
  actions: { getUserNotification, setUserNotification, logout },
}) => {
  const { addToast } = useContext(ToasterContext);
  const { data, error, loading, networkStatus } = useQuery(UI_NOTIFICATIONS, {
    variables: {
      filter: { products: ['roomrobot'] },
      sort: { updatedAt: -1 },
    },
  });
  if (error) {
    addToast({
      type: 'error',
      title: `Error`,
      message: error.message,
    });
  }
  const notifications = _get(data, 'uiNotifications.edges', []);
  return (
    <DashboardDefaultPage
      title="Latest news from Livion"
      subtitle=" "
      navigationItems={navigationItems}
      user={user}
      loading={loading}
      error={error}
      onProfileClick={(v) => history.push(`/users/${v.uid}/edit`)}
      onLogoutClick={logout}
      onBack={history.goBack}
      hideBanner={true}
    >
      <StyledBlock>
        {notifications.length > 0 ? (
          <Flex flexDirection="column">
            {notifications.map((n, i) => (
              <Notification
                key={`item-${i}`}
                width={[1, 1, 1 / 2]}
                center
                getUserNotification={getUserNotification}
                setUserNotification={setUserNotification}
                {...n}
              />
            ))}
          </Flex>
        ) : (
          networkStatus === 7 && (
            <StyledEmpty>Nothing new at the moment</StyledEmpty>
          )
        )}
      </StyledBlock>
    </DashboardDefaultPage>
  );
};

export default withLivionConnect(NotificationCenter);
