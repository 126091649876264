import React, { useState, useEffect } from 'react';
import { Box } from '@rebass/grid';
import { useQuery } from '@apollo/react-hooks';
import Select from '../../atoms/Select';
import { getSelectedTag } from '../../connect-react-lib';
import { GET_COUNTRY_AREA_TAGS } from '../../api/queries/tags';
import _get from 'lodash/get';

import { withQS } from '../../contexts/QSContext';
import { withAuth } from '../../contexts/FirebaseContext';
import { withRouter } from 'react-router-dom';

import styled from 'styled-components';

const StyledSelect = styled(Select)`
  text-transform: capitalize;
`;

const ALL_COUNTRIES = 'All countries';
const ALL_AREAS = 'All areas';

const CountryAreaTagSearch = ({
  onChange,
  extractTags,
  location,
  getQueryString,
}) => {
  let [values, setValues] = useState([]);
  let [organization, setOrganization] = useState('');
  let [initialValue, setInitialValue] = useState(getQueryString(location).tag);
  let [countries, setCountries] = useState([
    { name: ALL_COUNTRIES, id: ALL_COUNTRIES },
  ]);
  let [selectedCountry, setSelectedCountry] = useState('');
  let [selectedArea, setSelectedArea] = useState('');
  let [tagFilter, setTagFilter] = useState('');
  let [activeAreas, setActiveAreas] = useState([
    { name: ALL_AREAS, id: ALL_AREAS },
  ]);

  useEffect(() => {
    async function fetchOrga() {
      const tag = getSelectedTag();
      const { organization } = (await extractTags(tag)) || {};
      setOrganization(organization.id);
    }
    fetchOrga();
  }, []);

  const { data } = useQuery(GET_COUNTRY_AREA_TAGS, {
    variables: { id: getSelectedTag() },
  });

  const setCountryAndArea = (country, area) => {
    const countryRegEx = new RegExp(`^${organization}/${country}$`);
    const _activeAreas = [{ id: ALL_AREAS, name: ALL_AREAS }];
    let countryExists = false;
    setSelectedArea(ALL_AREAS);

    if (values) {
      const filtered = values.filter(({ id }) => id.match(countryRegEx));
      countryExists = filtered.length;

      filtered.map(({ areas }) => {
        if (areas) {
          const areaRegEx = new RegExp(`^${organization}/${country}/${area}$`);
          areas.map(({ name, id }) => {
            if (id.match(areaRegEx)) {
              setSelectedArea(area);
            }

            if (name && !_activeAreas.some(obj => obj.id === id)) {
              _activeAreas.push({ name, id: id.split('/').pop() });
            }
          });
          setActiveAreas(_activeAreas);
        }
      });
    }

    (countryExists || country === ALL_COUNTRIES) && setSelectedCountry(country);
  };

  useEffect(() => {
    setValues(_get(data, 'countriesAndAreasTree', []));
  }, [data]);

  useEffect(() => {
    const _countries = [];

    values.map(({ name, id }) => {
      if (name && !_countries.some(obj => obj.id === id)) {
        _countries.push({ id: id.split('/').pop(), name });
      }
    });

    setCountries([...countries, ..._countries]);
  }, [values]);

  useEffect(() => {
    if (initialValue && initialValue === organization) {
      setInitialValue(null);
      return;
    }

    if (initialValue && values.length) {
      async function setInitialCountryAndArea() {
        const data = await extractTags(initialValue);
        const country = data.country && data.country.id.split('/').pop();
        const area = data.area && data.area.id.split('/').pop();
        setCountryAndArea(country, area);
      }

      setInitialCountryAndArea();
    }
  }, [initialValue, values]);

  useEffect(() => {
    if (organization && selectedCountry) {
      setTagFilter(
        `${organization}${
          selectedCountry && selectedCountry !== ALL_COUNTRIES
            ? `/${selectedCountry}`
            : ''
        }${
          selectedCountry && selectedArea && selectedArea !== ALL_AREAS
            ? `/${selectedArea}`
            : ''
        }`
      );
    }

    if (organization && !initialValue && !selectedCountry) {
      setTagFilter(organization);
    }
  }, [selectedCountry, selectedArea, organization]);

  useEffect(() => {
    tagFilter && onChange({ tag: tagFilter });
  }, [tagFilter]);

  return (
    <>
      <Box mr={2} flex="1">
        <StyledSelect
          instanceId="country"
          value={selectedCountry}
          select={{
            options: countries,
            valueKey: 'id',
            labelKey: 'name',
            placeholder: ALL_COUNTRIES,
          }}
          onChange={country => {
            setCountryAndArea(country);
          }}
        />
      </Box>
      <Box ml={2} flex="1">
        <StyledSelect
          instanceId="area"
          value={selectedArea}
          select={{
            options: activeAreas,
            valueKey: 'id',
            labelKey: 'name',
            placeholder: ALL_AREAS,
            isDisabled: !selectedCountry || selectedCountry === ALL_COUNTRIES,
          }}
          onChange={area => setSelectedArea(area)}
        />
      </Box>
    </>
  );
};

export default withRouter(withAuth(withQS(CountryAreaTagSearch)));
