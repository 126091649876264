import React from 'react';
import DashboardDefaultPage from '@pages/DashboardDefaultPage';
import _get from 'lodash/get';

import Install from '@organisms/Install';
import Identification from '@organisms/Install/pages/Identification';
import ApartmentWithId from './pages/ApartmentWithId';
import ApartmentWithAddress from './pages/ApartmentWithAddress';
import Complete from '@organisms/Install/pages/Complete';
import Confirm from './pages/Confirm';

const InstallWizard = (props) => {
  const {
    match,
    addToast,
    history,
    tagObjects: { organization },
  } = props;
  const heading = 'Installing a new device';
  const subHeading = 'INSTALL WIZARD';
  let url = match.url;
  url = url.substring(0, url.lastIndexOf('/'));
  const installinstallWithApartmentId = _get(
    organization,
    'meta.public.installWithApartmentId',
    false
  );
  let pages = installinstallWithApartmentId
    ? [
        { component: <Identification />, location: '/identification' },
        { component: <ApartmentWithId />, location: '/apartment' },
        { component: <Confirm />, location: '/confirm' },
        { component: <Complete />, location: '/complete' },
      ]
    : [
        { component: <Identification />, location: '/identification' },
        { component: <ApartmentWithAddress />, location: '/apartment' },
        { component: <Confirm />, location: '/confirm' },
        { component: <Complete />, location: '/complete' },
      ];

  return (
    <React.Fragment>
      <DashboardDefaultPage
        title={heading}
        subtitle={subHeading}
        onBack={() => history.push(`${url}`)}
      >
        <Install {...props} addToast={addToast} pages={pages} />
      </DashboardDefaultPage>
    </React.Fragment>
  );
};

export default InstallWizard;
