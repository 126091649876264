import React from 'react';
import _get from 'lodash/get';
import { withRouter } from 'react-router';
import { Query, Mutation } from 'react-apollo';
import { adopt } from 'react-adopt';
import styled from 'styled-components';

import { GET_SENSOR, GET_DEVICE } from '../api/queries/devices';
import {
  EDIT_SENSOR,
  CREATE_SENSOR,
  DELETE_SENSOR,
} from '../api/mutations/devices';

import { SharedToasterConsumer } from '@components/contexts/ToasterContext';

import DashboardFlexibleFormPage from '@components/pages/DashboardFlexibleFormPage';
import Block from '@components/atoms/Block';
import TextField from '@components/fields/TextField';

const StyledBlock = styled(Block)`
  padding: 1.5em 4.375em;
  @media screen and (max-width: ${({ theme }) =>
      _get(theme, 'breakpoints[0]', '40em')}) {
    padding: 0.4em;
  }
`;

const required = (value) => (value ? undefined : 'Required');

const timeout = 24 * 60 * 60;

const Composed = adopt({
  Toast: <SharedToasterConsumer />,
  QueryDevice: ({ Toast: { addToast }, params, render }) => (
    <Query
      query={GET_DEVICE}
      variables={{ id: params.id }}
      errorPolicy="all"
      onError={({ message }) =>
        addToast({
          type: 'error',
          title: `Error`,
          message,
          timeout,
        })
      }
    >
      {({ loading, error, data }) =>
        render({
          deviceLoading: loading,
          deviceError: error,
          deviceData: data,
        })
      }
    </Query>
  ),
  QuerySensor: ({ Toast: { addToast }, params, render }) =>
    params.sensorId ? (
      <Query
        query={GET_SENSOR}
        variables={{ id: params.sensorId }}
        onError={({ message }) =>
          addToast({
            type: 'error',
            title: `Error`,
            message,
            timeout,
          })
        }
      >
        {({ loading, error, data }) =>
          render({
            sensorLoading: loading,
            sensorError: error,
            sensorData: data,
          })
        }
      </Query>
    ) : (
      render({})
    ),
  UpsertSensor: ({ history, Toast: { addToast }, params, render, ...rest }) => (
    <Mutation
      mutation={params.sensorId ? EDIT_SENSOR : CREATE_SENSOR}
      onCompleted={() => {
        const message = `Key Fob saved successfully`;

        addToast({
          type: 'success',
          title: `Success`,
          message,
        });
      }}
      onError={(error) => {
        addToast({
          type: 'error',
          title: `Error`,
          message: error.message,
          timeout,
        });
      }}
    >
      {(upsertSensor, { loading, error }) =>
        render({
          upsertSensor,
          saving: loading,
          saveError: error,
        })
      }
    </Mutation>
  ),
  DeleteSensor: ({ history, Toast: { addToast }, params, render, ...rest }) => (
    <Mutation
      mutation={DELETE_SENSOR}
      onCompleted={() => {
        const message = `Key Fob deleted`;

        addToast({
          type: 'success',
          title: `Success`,
          message,
        });

        history.goBack();
      }}
      onError={(error) => {
        addToast({
          type: 'error',
          title: `Error`,
          message: error.message,
          timeout,
        });
      }}
    >
      {(deleteSensor, { loading, error }) =>
        render({
          deleteSensor,
          deleting: loading,
          deleteError: error,
        })
      }
    </Mutation>
  ),
});

export const KeysEdit = ({
  history,
  match: {
    params,
    params: { id },
  },
}) => (
  <Composed params={params} history={history}>
    {({
      QueryDevice: { deviceLoading, deviceData },
      QuerySensor: { sensorLoading, sensorData },
      DeleteSensor: { deleteSensor },
      UpsertSensor: { upsertSensor },
    }) => {
      let title;

      if (deviceData.device)
        title = `${_get(deviceData, 'device.id')} ${_get(
          deviceData,
          'device.name'
        )}`;

      const hwId = _get(sensorData, 'sensor.hwId', '').split(',')[0];
      const hwIdSecondary = _get(sensorData, 'sensor.hwId', '').split(',')[1];
      return (
        <DashboardFlexibleFormPage
          initialValues={{
            name: _get(sensorData, 'sensor.name'),
            hwId,
            hwIdSecondary,
          }}
          onRemoveClick={(e) => {
            e.stopPropagation();
            deleteSensor({ variables: { id: params.sensorId } });
          }}
          onSubmit={({ name, hwId, hwIdSecondary }) => {
            let hwIdCombined = hwId;
            const datapoints = [
              {
                name: 'btrssi',
                active: true,
                description: 'keyring rssi',
                unit: 'dBm',
                type: 'value',
                minInterval: 300,
              },
              {
                name: 'presence',
                active: true,
                description: 'keyring presence',
                type: 'stateValue',
                minInterval: 300,
                maxInterval: 3600,
              },
            ];
            if (hwIdSecondary && hwIdSecondary.length) {
              hwIdCombined = `${hwId},${hwIdSecondary}`;
              datapoints.push({
                name: 'movement',
                active: true,
                description: 'keyring movement',
                type: 'stateValue',
                minInterval: 40,
                maxInterval: 3600,
              });
            }

            const variables = {
              input: {
                type: 'BluetoothKeyring',
                hwId: hwIdCombined,
                name: name,
                tag: 'livion/roomrobot',
                datapoints,
                devices: [
                  {
                    id: params.id,
                    state: 'active',
                  },
                ],
              },
            };

            if (params.sensorId) variables.id = params.sensorId;

            upsertSensor({
              variables,
            });
          }}
          title={title}
          subtitle="Add Key Fob Beacon"
          onBack={history.goBack}
          loading={deviceLoading || sensorLoading}
        >
          {() => (
            <StyledBlock>
              <TextField name="name" validate={required} label="Key Fob name" />
              <TextField
                name="hwId"
                validate={required}
                placeholder="<uuid>/<major>/<minor>"
                label="HW id, <uuid>/<major>/<minor>, '3A2BD820-8F2E-41AC-871B-8E64B0977E16/1/8"
              />
              <TextField
                name="hwIdSecondary"
                placeholder="<uuid>/<major>/<minor>"
                label="HW id secondary, <uuid>/<major>/<minor>, 'A5F369EF-3CDD-44AA-A5E9-951637DCBA40/1/8"
              />
            </StyledBlock>
          )}
        </DashboardFlexibleFormPage>
      );
    }}
  </Composed>
);

export default withRouter(KeysEdit);
