import React, { Component } from 'react';
import styled from 'styled-components';

import ghostIcon from './ghost.svg';
import Button from '../../../../atoms/Button';

const ErrorContainer = styled.div`
  max-width: 60em;
`;

const Title = styled.div`
  color: ${(props) => props.theme.primaryThemeColor};
  font-size: 2.5em;
`;

const TextContainer = styled.div`
  color: #707070;
  font-size: 1.25em;
`;

const ImageContainer = styled.div`
  margin: 1em 0;
`;

const MessageContainer = styled.div`
  color: #fff;
  font-size: 1.25em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.4em;
`;

class Error extends Component {
  render() {
    const { errorMessage, onPrev, onDone } = this.props;

    return (
      <React.Fragment>
        <ErrorContainer>
          <Title>OOPS!</Title>
          <ImageContainer>
            <img src={ghostIcon} alt="" />
          </ImageContainer>
          <MessageContainer>{errorMessage}</MessageContainer>
          <ButtonContainer>
            <Button style={{ marginRight: '30px' }} secondary onClick={onPrev}>
              Previous
            </Button>
            <Button style={{ marginLeft: '30px' }} onClick={onDone}>
              Done
            </Button>
          </ButtonContainer>
        </ErrorContainer>
      </React.Fragment>
    );
  }
}

export default Error;
