import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import get from 'lodash/at';

import Button from '../../atoms/Button';
import Icon from '../../atoms/Icon';
import Block from '../../atoms/Block';
import TableRow from '../../atoms/TableRow';
import TableCell from '../../atoms/TableCell';
import TableHead from '../../atoms/TableHead';
import Label from '../../atoms/Label';
import Link from '../../atoms/Link';

import Table from '../../molecules/Table';
import Dropdown from '../../molecules/Dropdown';

const StyledBlock = styled(Block)`
  padding: 0;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints ? props.theme.breakpoints[2] : '64em'}) {
    padding: 0 2em;
  }
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: ${props => props.theme.primaryThemeColor};
`;

/**
 * @render react
 * @name LoadMoreTable
 * @description LoadMoreTable component. Load more style table component
 * @example
 * <LoadMoreTable columns={[{ key: "name", name: "name" }, { key: "tag", name: "tag" }];} data={[
  { name: "Matti", tag: "tag"}]}/>
 */
const LoadMoreTable = ({
  columns,
  data,
  onLoadMore,
  actions,
  onRowClick,
  onSortClick,
  loading,
  hasMore,
  sortOrder,
  rowLink,
  ...props
}) => {
  return (
    <StyledBlock {...props}>
      <Table {...props}>
        <TableRow>
          <TableHead xs>#</TableHead>
          {columns.map((c, i) => {
            const hideProps =
              c.hide &&
              c.hide.reduce((acc, cur) => {
                acc[cur] = true;
                return acc;
              }, {});
            c.hideProps = hideProps;
            return (
              <TableHead
                onSort={
                  c.sortBy
                    ? s => {
                        c.onSortClick && c.onSortClick(s);
                        onSortClick &&
                          onSortClick(
                            s
                              ? c.extraSort ? {...c.extraSort, [c.sortBy || c.name]: s} : {[c.sortBy || c.name]: s} : null
                          );
                      }
                    : null
                }
                sortOrder={sortOrder && sortOrder[c.sortBy || c.name]}
                {...c.hideProps}
                key={`${c.key}-th-${i}`}
              >
                {c.name}
              </TableHead>
            );
          })}
          {actions && <TableHead />}
          {onRowClick && <TableHead />}
        </TableRow>
        {data.map((d, i) => (
          <TableRow key={`row-${i}`}>
            <TableCell xs>{i + 1}</TableCell>
            {columns.map(c => {
              const cellData = get(d, c.key)[0];

              return (
                <TableCell {...c.hideProps} key={`${c.name}-${i}`}>
                  {c.render
                    ? c.render(!c.key && !cellData ? d : cellData)
                    : cellData}
                </TableCell>
              );
            })}
            {actions && (
              <TableCell>
                <Dropdown
                  icon={
                    <Icon
                      size={'1.5em'}
                      color={'#333'}
                      icon={'more-vertical-f'}
                    />
                  }
                  options={actions}
                />
              </TableCell>
            )}
            {onRowClick && (
              <TableCell>
                <StyledIcon
                  {...props}
                  size={'2em'}
                  icon={'chevron-right'}
                  onClick={() => onRowClick(d)}
                />
              </TableCell>
            )}
          </TableRow>
        ))}
      </Table>
      <Flex justifyContent="center">
        <Box>
          {loading ? (
            <Label>Loading more...</Label>
          ) : (
            hasMore && (
              <Button secondary onClick={onLoadMore}>
                Load more
              </Button>
            )
          )}
        </Box>
      </Flex>
    </StyledBlock>
  );
};

LoadMoreTable.propTypes = {
  /**
   * @property {propTypes.func}  onLoadMore - Load more Callback
   */
  onLoadMore: PropTypes.func,
  /**
   * @property {propTypes.array} columns - Columns spec
   */
  columns: PropTypes.array,
  /**
   * @property {propTypes.array} data - Table data
   */
  data: PropTypes.array,
  /**
   * @property {propTypes.bool} hasMore - Enables load more button
   */
  hasMore: PropTypes.bool,
  /**
   * @property {propTypes.array} actions - dropdown submenu for rows
   */
  actions: PropTypes.array,
  /**
   * @property {propTypes.func} onRowClick - Row click callback
   */
  onRowClick: PropTypes.func,
};

LoadMoreTable.defaultProps = {
  columns: [],
  data: [],
};

export default LoadMoreTable;
