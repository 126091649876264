import gql from 'graphql-tag';

export const userInfoFragment = gql`
  fragment userInfo on User {
    id
    email
    displayName
    language
    phoneNumber
    role
    acl {
      path
      role
      name
    }
  }
`;

export const roleInfoFragment = gql`
  fragment roleInfo on Role {
    role
  }
`;
