import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Flex, Box } from '@rebass/grid';

import Icon from '../../atoms/Icon';
import Label from '../../atoms/Label';
import HorizontalRule from '../../atoms/HorizontalRule';
import { NavLink } from 'react-router-dom';
import Link from '../../atoms/Link';

const StyledMenuItem = styled(Link)`
  background-color: ${(props) => props.theme.drawerItemBackground};
  color: ${(props) => props.theme.drawerItemColor};
  border-bottom: 1px solid #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  height: 4em;
  margin: 0.45em 0;
  padding-left: 0.85em;
  :active {
    color: ${(props) => props.theme.drawerItemActiveColor};
    background-color: ${(props) => props.theme.drawerItemActiveBackground};
  }
  &.selected {
    color: ${(props) => props.theme.drawerItemActiveColor};
    background-color: ${(props) => props.theme.drawerItemActiveBackground};
  }
`;

const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.mobileMenuIconColor};
`;

const ItemBox = styled(Box)`
  display: flex;
  // justify-content: center;
  flex-direction: row;
  align-items: flex-start;
  margin: 0.45em 0;
  padding-left: 0.85em;
  cursor: pointer;
`;

const StyledImg = styled.img`
  border-radius: 50%;
  margin: 0px 2px -3px;
  overflow: hidden;
  position: relative;
  height: 2em;
  width: 2em;
  // border: 2px solid ${(props) => props.theme.dropdownIconColor};
`;

const StyledProfileLabel = styled(Label)`
  color: ${(props) => props.theme.drawerItemColor};
  line-height: 2.5em;
  margin-left: 0.25em;
  text-transform: none;
  cursor: pointer;
`;

const StyledMenuLabel = styled(Label)`
  text-transform: uppercase;
`;

const menuWidth = 300;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

const Drawer = styled(Flex)`
  background: ${(props) => props.theme.drawerBackground};
  display: ${(props) => (props.hide ? 'none' : 'flex')};
  text-align: left;
  flex-direction: column;
  height: 100%;
  position: fixed;
  top: 0;
  border-left: 1px solid #efefef;
  box-shadow: -1px 0 2px 0 rgba(0, 0, 0, 0.29);
  z-index: 2;
  left: auto;
  right: ${menuWidth * -1}px;
  width: ${menuWidth}px;
  height: 100%;
  transition: transform ease 250ms;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${(props) =>
    props.show ? `transform: translate3d(${menuWidth * -1}px, 0, 0);` : ''};
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: 1,
      show: false,
    };
  }

  openMenu() {
    this.setState({ hide: false });
    setTimeout(() => this.setState({ show: 1 }), 10);
  }

  closeMenu(e) {
    if (e) e.preventDefault();
    this.setState({ show: false });
    setTimeout(() => {
      this.setState({ hide: 1 });
    }, 250);
  }

  render() {
    const {
      navigationItems,
      user,
      onLogoutClick,
      onProfileClick,
      children,
    } = this.props;

    return (
      <React.Fragment>
        <StyledIcon
          onClick={() => this.openMenu()}
          size={'2em'}
          icon={'menu'}
        />
        {this.state.show && <Overlay onClick={(e) => this.closeMenu(e)} />}
        <Drawer
          show={this.state.show ? 1 : undefined}
          hide={this.state.hide ? 1 : undefined}
        >
          <Box flex={'1 1 auto'}>
            <Flex flexDirection="column">
              {user && (
                <ItemBox onClick={onProfileClick}>
                  {
                    /*user.photoURL && (
                      <StyledImg src={user.photoURL} alt={user.email} />
                    )*/
                    // Temporary fix until firebase has fixed its problem of invalid user.photoURL
                    Array.isArray(user.providerData) &&
                      user.providerData[0] && (
                        <StyledImg
                          src={user.providerData[0].photoURL}
                          alt={user.email}
                        />
                      )
                  }
                  <StyledProfileLabel>{user.email}</StyledProfileLabel>
                </ItemBox>
              )}
              <HorizontalRule />
              {children && children.length > 0 && (
                <React.Fragment>
                  {children}
                  <HorizontalRule />
                </React.Fragment>
              )}
              {navigationItems &&
                navigationItems.map((l, i) => (
                  <Box key={`menu-navi-${i}`}>
                    <StyledMenuItem
                      to={l.to ? l.to : null}
                      onClick={l.onClick ? l.onClick : null}
                      activeClassName="selected"
                    >
                      {l.label}
                    </StyledMenuItem>
                  </Box>
                ))}
            </Flex>
          </Box>
          <HorizontalRule />
          <Box>
            <Flex flexDirection="column">
              <ItemBox onClick={onLogoutClick}>
                <StyledProfileLabel>Logout</StyledProfileLabel>
              </ItemBox>
            </Flex>
          </Box>
        </Drawer>
      </React.Fragment>
    );
  }
}
Menu.propTypes = {
  navigationItems: PropTypes.array,
  user: PropTypes.object,
  onProfileClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
};

Menu.defaultProps = {
  navigationItems: [],
  theme: {},
};

export default Menu;
