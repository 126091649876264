import React, { useState, useEffect } from 'react';
import useRealtimeDevice from '../../hooks/useRealtimeDevice';
import useForceUpdate from 'use-force-update';
import dayjs from 'dayjs';
import get from 'lodash/get';
import relativeTime from 'dayjs/plugin/relativeTime';

import styled from 'styled-components';
import Label from '@components/atoms/Label';

dayjs.extend(relativeTime);

const ConnectionStateLabel = styled(Label)`
  text-transform: ${({ style: { textTransform = 'none' } = {} }) =>
    textTransform};
  cursor: pointer;
  line-height: ${({ style: { lineHeight = '1.5em' } = {} }) => lineHeight};
  font-size: ${({ style: { fontSize = '1em' } = {} }) => fontSize};
  padding-left: ${({ style: { paddingLeft = '0em' } = {} }) => paddingLeft};
  color: ${props => {
    if (props.disabled) {
      return props.theme.internetDisabledColor;
    }
    return props.connected
      ? props.theme.connectedColor
      : props.theme.disconnectedColor;
  }};
`;

const MessageLabel = styled(Label)`
  text-transform: none;
  font-size: 0.8em;
  line-height: 1em;
  color: ${props => props.theme.secondaryThemeColor};
  display: block;
  @media screen and (max-width: ${props =>
      props.theme.breakpoints ? props.theme.breakpoints[0] : '40em'}) {
    font-size: 0.6em;
  }
`;

const ConnectionState = ({
  listeners,
  onClick,
  device,
  showTime = true,
  onStateChange,
  style,
}) => {
  let connectionEvent;
  if (device.connectionEvent) {
    // devices query returns last event only
    connectionEvent = get(device, 'connectionEvent');
  } else {
    connectionEvent = get(device, 'connectionEvents.edges[0]');
  }
  const connectionLogState = get(connectionEvent, 'data.after.state');
  const connectionLogTs = get(connectionEvent, 'timestamp');
  const cellularState = get(device, 'meta.cellularState');
  const disabled = cellularState === undefined ? false : !cellularState;
  const [{ ts, state }, setlastChange] = useState({
    ts: connectionLogTs,
    state: connectionLogState === 'connected' ? true : false,
  });
  const { loading, connected, device: connectedDevice } = useRealtimeDevice(
    device
  );
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate(); // update component every 30 sec just to update timestamp
    }, 30 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    listeners &&
      Object.keys(connectedDevice).length &&
      listeners.forEach(({ name, func }) => {
        connectedDevice.on(name, func);
      });
    return () => {
      listeners &&
        Object.keys(connectedDevice).length &&
        listeners.forEach(({ name, func }) => {
          connectedDevice.removeListener(name, func);
        });
    };
  }, [connectedDevice]);

  useEffect(() => {
    let interval;
    // delay showing of disconnect for but show connected event right away
    // Ui is then better sync with connection log
    let timeout = 0;
    if (connected === false) timeout = 60 * 1000;
    if (!loading) {
      interval = setTimeout(() => {
        if (connected !== state) {
          setlastChange({
            ts: new Date(),
            state: connected,
          });
          if (onStateChange) onStateChange(state);
        }
      }, timeout);
    }
    return () => {
      clearInterval(interval);
    };
  }, [loading, state, connected]);

  const text = disabled ? 'Deactive' : state ? 'Connected' : 'Disconnected';

  return (
    <ConnectionStateLabel
      connected={state}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {text}
      {!disabled && showTime && (
        <MessageLabel>{ts && dayjs(ts).fromNow()}</MessageLabel>
      )}
    </ConnectionStateLabel>
  );
};

export default ConnectionState;
