import gql from 'graphql-tag';
import {
  orderInfoFragment,
  productsInfoFragment,
  accessoriesInfoFragment,
  customerInfoFragment,
  notificationReceiversFragment,
  devicesInfoFragment,
  detailsInfoFragment,
} from '../fragments/orders';

export const ORDERS = gql`
  query orders($after: String, $limit: Int, $filter: OrderFilter, $sort: JSON) {
    orders(after: $after, limit: $limit, filter: $filter, sort: $sort) {
      edges {
        ...orderInfo
        ...productsInfo
        ...accessoriesInfo
        ...customerInfo
        ...notificationReceivers
        ...devices
        ...detailsInfo
      }
      pageInfo {
        start
        end
        hasNext
      }
    }
  }
  ${orderInfoFragment}
  ${productsInfoFragment}
  ${accessoriesInfoFragment}
  ${customerInfoFragment}
  ${notificationReceiversFragment}
  ${devicesInfoFragment}
  ${detailsInfoFragment}
`;
export const ORDER_EDIT = gql`
  query order($id: ID!) {
    order(id: $id) {
      ...orderInfo
      ...productsInfo
      ...accessoriesInfo
      ...customerInfo
      ...notificationReceivers
      ...devices
      ...detailsInfo
    }
  }
  ${orderInfoFragment}
  ${productsInfoFragment}
  ${accessoriesInfoFragment}
  ${customerInfoFragment}
  ${notificationReceiversFragment}
  ${devicesInfoFragment}
  ${detailsInfoFragment}
`;
