import React, { useState, useCallback, useEffect } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from 'styled-components';
import _get from 'lodash/get';

import Input from '@atoms/Input';
import Select from '@atoms/Select';

import Icon from '@atoms/Icon';
import Label from '@atoms/Label';

import Button from '@atoms/Button';
import ProgressBar from '@organisms/Install/ProgressBar';
import LoaderCube from '@atoms/LoaderCube';
import Error from '@organisms/Install/pages/Error';

const GET_COUNTRY_TAGS = gql`
  query tag($id: String!, $filter: TagFilter) {
    tag(id: $id) {
      id
      name
      type
      meta
      children(filter: $filter) {
        edges {
          id
          name
          type
        }
      }
    }
  }
`;

const LocationContainer = styled.div`
  text-align: center;
  max-width: 60em;
`;

const TextContainer = styled.div`
  color: #707070;
  font-size: 1.25em;
`;

const ImageContainer = styled.div`
  margin: 1em 0;
`;

const InputContainer = styled.div`
  padding: 0.4em;
  text-align: left;
`;

const FieldLabel = styled(Label)`
  text-transform: capitalize;
  padding-left: 15px;
  font-weight: bold;
  color: #707070;
`;

const ButtonContainer = styled.div`
  padding: 0.4em;
  display: flex;
  justify-content: space-between;
`;

const Apartment = ({
  prevPage,
  nextPage,
  setDeviceData,
  device,
  maxSteps,
  step,
  tagObjects: { organization, selected, country },
}) => {
  const [addressData, setAddressData] = useState({
    address: '',
    postCode: '',
    city: '',
    countryCode: '',
  });
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);

  const client = useApolloClient();

  const validAddressData = useCallback(() => {
    console.log(addressData);
    return (
      addressData.address &&
      addressData.address.length > 0 &&
      addressData.postCode &&
      addressData.postCode.length > 0 &&
      addressData.city &&
      addressData.city.length > 0 &&
      addressData.countryCode &&
      addressData.countryCode.length > 0
    );
  }, [addressData]);

  useEffect(() => {
    async function fetchCountries() {
      if (organization.id === selected.id) {
        console.log('we can fetch countries');
        const result = await client.query({
          query: GET_COUNTRY_TAGS,
          variables: {
            id: organization.id,
            filter: {
              type: 'country',
            },
          },
          fetchPolicy: 'no-cache',
        });

        console.log(result);
        const countryList = _get(result, 'data.tag.children.edges', []);

        setCountries(
          countryList.map((entry) => {
            const countryCode = entry.id.split('/')[
              entry.id.split('/').length - 1
            ];
            return { value: countryCode, label: entry.name };
          })
        );
      } else if (country) {
        const countryCode = country.id.split('/')[
          country.id.split('/').length - 1
        ];
        setCountries([{ value: countryCode, label: country.name }]);
      } else {
        // we should always have country info, Show error to user
      }
      setLoadingCountries(false);
    }
    if (organization && selected) fetchCountries();
    return function cleanup() {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, selected, country]);

  // if current tag == organization tag -> load countries below org
  // if current tag !== organization tag -> only add country from tagParants

  const next = useCallback(async () => {
    try {
      if (validAddressData()) {
        setError(null);
        setDeviceData({
          name: addressData.address,
          tag: `${organization.id}/${addressData.countryCode}`,
          meta: {
            apartment: addressData,
            cellularState: true,
          },
          settings: device.settings,
        });
        nextPage();
      } else {
        setError(`Please fill all fields`);
      }
    } catch (e) {
      setError(e);
    }
  }, [
    addressData,
    device.settings,
    nextPage,
    organization.id,
    setDeviceData,
    validAddressData,
  ]);
  return (
    <React.Fragment>
      {loadingCountries && <LoaderCube />}
      {!loadingCountries && !error && (
        <LocationContainer>
          <ProgressBar maxSteps={maxSteps} currentStep={step} />
          <div>
            <TextContainer>Please enter address for installation</TextContainer>
            <ImageContainer>
              <Icon icon="map-marker" size={'150px'} color="#707070" />
            </ImageContainer>
            <InputContainer>
              <FieldLabel>Address</FieldLabel>
              <Input
                onChange={(e) =>
                  setAddressData({ ...addressData, address: e.target.value })
                }
                placeholder="Address"
              />
            </InputContainer>
            <InputContainer>
              <FieldLabel>Postal code</FieldLabel>
              <Input
                onChange={(e) =>
                  setAddressData({ ...addressData, postCode: e.target.value })
                }
                placeholder="Postal code"
              />
            </InputContainer>
            <InputContainer>
              <FieldLabel>City</FieldLabel>
              <Input
                onChange={(e) =>
                  setAddressData({ ...addressData, city: e.target.value })
                }
                placeholder="City"
              />
            </InputContainer>
            <InputContainer>
              <FieldLabel>Country</FieldLabel>
              <Select
                onChange={(countryCode) => {
                  setAddressData({ ...addressData, countryCode });
                }}
                select={{
                  isMulti: false,
                  options: countries,
                }}
              />
            </InputContainer>

            <ButtonContainer>
              <Button
                style={{ marginRight: '30px' }}
                secondary
                onClick={prevPage}
              >
                Previous
              </Button>
              <Button
                style={{ marginLeft: '30px' }}
                disabled={validAddressData() ? false : true}
                onClick={() => next()}
              >
                Next
              </Button>
            </ButtonContainer>
          </div>
        </LocationContainer>
      )}
      {error && (
        <Error
          errorMessage={error}
          onPrev={() => setError(null)}
          onDone={() => prevPage()}
        />
      )}
    </React.Fragment>
  );
};

export default Apartment;
