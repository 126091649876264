import styled from 'styled-components';

/**
 * @render react
 * @name Caption
 * @description Caption
 * @example
 * <Caption>Example</Caption>
 */

const Caption = styled.div`
  font-family: ${props => props.theme.primaryFont};
  color: ${props => props.theme.captionColor};
  font-weight: ${props => props.theme.captionFontWeight};
  line-height: ${props => props.theme.captionLineHeight};
  font-size: ${props => props.theme.captionFontSize};
  text-transform: ${props => props.theme.captionTextTransform};
  text-align: ${props => props.theme.captionTextAlign};
  padding: ${props => props.theme.captionPadding};
  margin: ${props => props.theme.captionMargin};
`;

Caption.defaultProps = {
  theme: {
    captionColor: '#327EA7',
    captionFontWeight: '500',
    captionLineHeight: 'normal',
    captionFontSize: '1.15em',
    captionTextTransform: 'none',
    captionTextAlign: 'left',
    captionPadding: '0.8em 0 0.8em 0',
    captionMargin: '0',
  },
};

Caption.propTypes = {};

export default Caption;
